import React, {useEffect, useState} from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import DateTimeModal from "./DateTimeModal";

const DateTimePickerButton = ({ onDateChange, isTime, isHistory, buttonText = "" }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showTimePicker, setShowTimePicker] = useState(false);
    const [sdate, setDate] = useState("");
    const [time, setTime] = useState("");
    const [hours, setHours] = useState(12);
    const [minutes, setMinutes] = useState(0);
    const [isTodaySelected, setIsTodaySelected] = useState(true);

    const now = new Date();
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();

    const formatDateTime = (dateStr, timeStr = null) => {
        const [year, month, day] = dateStr.split('-').map(Number);
        if (timeStr?.length > 0) {
            const [hours, minutes] = timeStr.split(':').map(Number);
            const date = new Date(year, month - 1, day, hours, minutes);
            return date.toISOString();
        } else {
            const date = new Date(year, month - 1, day);
            return date.toISOString().split('T')[0]; // Return only the date part
        }
    };

    const handleDateConfirm = (params) => {
        const selected = new Date(params.date);
        const year = selected.getFullYear();
        const month = selected.getMonth() + 1; // Months are zero-indexed
        const day = selected.getDate();
        setSelectedDate(selected);
        console.log("selected:",selected);
        if(selected){
            setIsTodaySelected(
                year === now?.getFullYear() &&
                month === (now?.getMonth() + 1) &&
                day === now?.getDate()
            );

        }
        setShowDatePicker(false);
        setShowTimePicker(true);
    };

    useEffect(()=>{
        if(sdate?.length > 0){
            setSelectedDate(formatDateTime(sdate, time));
            onDateChange(formatDateTime(sdate, time));
            console.log(formatDateTime(sdate, time))
        }
    },[sdate,time])

    return (
        <View style={styles.container}>
            <TouchableOpacity
                onPress={() => setShowDatePicker(true)}
                style={styles.button}
            >
                <Text style={styles.buttonText}>
                    {selectedDate
                        ? `${buttonText}: ${sdate}. ${time} `
                        : buttonText}
                </Text>
            </TouchableOpacity>

            <DateTimeModal
                visible={showDatePicker}
                setDate={setDate}
                setTime={setTime}
                isTime={isTime}
                isHistory={isHistory}
                onClose={()=> setShowDatePicker(false)}
            />

        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
    },
    button: {
        backgroundColor: '#f1b44c',
        padding: 10,
        borderRadius: 5,
        elevation: 3,
        alignItems: 'center',
    },
    buttonText: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
    },
});

export default DateTimePickerButton;
