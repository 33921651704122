import React, {useContext, cloneElement, useEffect, useState} from 'react';
import { Platform, View, Text, TouchableOpacity, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import WalkthroughTooltip from 'react-native-walkthrough-tooltip';
import tooltips from './StoredToolTips';
import { TourContext } from './TourContext';
import {CartContext} from "./CartContext";

export default function CustomTooltip({ isVisible, onClose, onNext, wrapped, module, placement, parentStyle, topMargin = 0, bottomMargin = 0 }) {
    const { currentStep, tourCompleted } = useContext(TourContext);
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const {setCart} = useContext(CartContext);

    useEffect(()=>{
        if(!tourCompleted && tooltips){
            const { title, description } = tooltips[module][currentStep] ;
            setTitle(title);
            setDescription(description);
        }

    },[currentStep, tourCompleted]);
    return (
        <WalkthroughTooltip
            isVisible={isVisible && currentStep > -1 && !tourCompleted}
            contentStyle={{flex: 1, height: '100%', width: '100%', marginTop: topMargin, marginBottom: bottomMargin}}
            parentWrapperStyle={parentStyle}
            children={wrapped}
            showChildInTooltip={false}
            tooltipContainerStyle={{ backgroundColor: 'transparent', padding: 0 }} // Avoid extra styling
            content={
                <View style={styles.tooltipContainer}>
                    <Text style={styles.title}>{title}</Text>
                    <Text style={styles.description}>{description}</Text>

                    <View style={styles.buttonContainer}>
                        <TouchableOpacity style={styles.closeButton} onPress={() => {
                            setCart([]);
                            onClose();
                        }}>
                            <Text style={styles.buttonText}>Close</Text>
                        </TouchableOpacity>

                        {onNext && (
                            <TouchableOpacity style={styles.nextButton} onPress={onNext}>
                                <Text style={styles.buttonText}>Next</Text>
                            </TouchableOpacity>
                        )}
                    </View>
                </View>
            }
            placement={placement}
            onClose={onNext}
        >

        </WalkthroughTooltip>
    );
}

const styles = StyleSheet.create({
    tooltipContainer: {
        padding: 16,
        width: 250,
        backgroundColor: "#fff",
        borderRadius: 10,
        alignItems: "center",
    },
    title: {
        fontSize: 16,
        fontWeight: "bold",
        marginBottom: 5,
    },
    description: {
        fontSize: 14,
        textAlign: "center",
        marginBottom: 10,
    },
    buttonContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
    },
    closeButton: {
        backgroundColor: "#ccc",
        padding: 8,
        borderRadius: 5,
        flex: 1,
        alignItems: "center",
        marginRight: 5,
    },
    nextButton: {
        backgroundColor: "rgb(241,179,75)",
        padding: 8,
        borderRadius: 5,
        flex: 1,
        alignItems: "center",
    },
    buttonText: {
        color: "#fff",
        fontWeight: "bold",
    },
});