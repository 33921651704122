import React, {useState} from 'react';
import { View, Text, StyleSheet, Modal, TouchableOpacity, ScrollView } from 'react-native';
import { useTranslation } from 'react-i18next';
import { priceFormatter } from '../helpers/numbers';
import DriverDetailsModal from "./DriverDetailsModal";

const DeliveryStatisticsModal = ({ statistics }) => {
    const { t } = useTranslation();
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [driverOrders, setDriverOrders] = useState([]);

    const driverData = statistics?.orders?.reduce((acc, order) => {
        const user = order.delivery?.user;
        if (user && user?.name) {
            const fullName = `${user?.name?.first} ${user?.name?.last}`;
            const totalPrice = order?.orderSum || 0;
            const distance = order?.delivery?.distance || 0;

            if (!acc[fullName]) {
                acc[fullName] = { count: 0, total: 0, distance: 0, orders: [] };
            }

            acc[fullName].count += 1;
            acc[fullName].total += totalPrice;
            acc[fullName].distance += distance;
            acc[fullName].orders.push(order);
        }
        return acc;
    }, {});

    const handleRowPress = (name, orders) => {
        setSelectedDriver(name);
        setDriverOrders(orders);
    };

    return (
        <View style={styles.modalContainer}>
            <ScrollView>
                <View style={styles.tableContainer}>
                    <View style={styles.tableHeader}>
                        <Text style={styles.tableHeaderText}>{t("driver_name")}</Text>
                        <Text style={styles.tableHeaderText}>{t("orders_delivered")}</Text>
                        <Text style={styles.tableHeaderText}>{t("distance_travelled")}</Text>
                        <Text style={styles.tableHeaderText}>{t("sum_of_orders")}</Text>
                    </View>
                    {Object.entries(driverData).map(([name, { count, total, distance, orders }]) => (
                        <TouchableOpacity key={name} onPress={() => handleRowPress(name, orders)}>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableCell}>{name}</Text>
                                <Text style={styles.tableCell}>{count}</Text>
                                <Text style={styles.tableCell}>{distance.toFixed(2)} km</Text>
                                <Text style={styles.tableCell}>{priceFormatter(total)}</Text>
                            </View>
                        </TouchableOpacity>
                    ))}
                </View>
            </ScrollView>
            {selectedDriver && (
                <DriverDetailsModal
                    visible={!!selectedDriver}
                    onClose={() => setSelectedDriver(null)}
                    driver={selectedDriver}
                    orders={driverOrders}
                />
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    modalBackground: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContainer: {
        width: '100%',
        height: '80%',
        borderRadius: 10,
        padding: 20,
    },
    closeButton: {
        alignSelf: 'flex-end',
    },
    closeButtonText: {
        fontSize: 18,
        fontWeight: 'bold',
        paddingBottom: 8,
        paddingHorizontal: 8,
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    tableContainer: {
        marginTop: 10,
        borderWidth: 1,
        borderColor: '#ddd',
        borderRadius: 5,
    },
    tableHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#f8f8f8',
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ddd',
    },
    tableHeaderText: {
        fontSize: 16,
        fontWeight: 'bold',
        flex: 1,
        textAlign: 'center',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ddd',
    },
    tableCell: {
        fontSize: 14,
        flex: 1,
        textAlign: 'center',
    },
});

export default DeliveryStatisticsModal;