import React, { useEffect } from 'react';
import { View, Button, Platform } from 'react-native';
import { WebView } from 'react-native-webview';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {getUserInfo} from "../helpers/storage";

const SMARTSUPP_KEY = 'c5fbbe1b7bb29e9ad83c33628662617a3e466603'; // Replace with your actual SmartSupp key

const SmartSuppChat = () => {
    if (Platform.OS === 'web') {
        useEffect(() => {
            const script = document.createElement('script');
            script.src = 'https://www.smartsuppchat.com/loader.js?';
            script.async = true;
            document.body.appendChild(script);
            script.onload = async () => {
                window._smartsupp = window._smartsupp || {};
                window._smartsupp.key = SMARTSUPP_KEY;
                window.smartsupp = window.smartsupp || function () {
                    (window.smartsupp._ = window.smartsupp._ || []).push(arguments);
                };
                smartsupp('chat:open');


                const getRestaurant = async () => {
                    const restaurantStored = await AsyncStorage.getItem("restaurant");
                    return JSON.parse(restaurantStored);
                }
                const getRestaurantPlace = async () => {
                    const restaurantPlaceStored = await AsyncStorage.getItem("restaurantPlace");
                    return JSON.parse(restaurantPlaceStored);
                }
                const userInfo = await getUserInfo();

                if(userInfo.name) smartsupp('name', userInfo.name.first+ " " + userInfo.name.last);
                if(userInfo.email) smartsupp('email', userInfo.email || "");


                const getRestaurantData = await getRestaurant();
                const getRestaurantPlaceData = await getRestaurantPlace();
                if(getRestaurantData) smartsupp('variables', {
                    restaurantName: getRestaurantData.name,
                    restaurantPlace: getRestaurantPlaceData.name || getRestaurantPlaceData.raw,
                    deviceName: "",
                    deviceType: "android"
                });
            };
        }, []);

        return <div />;
    }

    const smartsuppHTML = `
    <!DOCTYPE html>
    <html>
    <head>
      <script>
        var _smartsupp = _smartsupp || {};
        _smartsupp.key = '${SMARTSUPP_KEY}';
        window.smartsupp || (function(d) {
          var s, c, o = smartsupp = function() { o._.push(arguments) };
          o._ = [];
          s = d.createElement('script'); s.type = 'text/javascript'; s.async = true;
          s.src = 'https://www.smartsuppchat.com/loader.js?';
          c = d.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
        })(document);
        
        window.addEventListener("message", function(event) {
          if (event.data === "openChat") {
            smartsupp('chat:open');
          } else if (event.data === "closeChat") {
            smartsupp('chat:close');
          }
        });
      </script>
    </head>
    <body>
</body>
    </html>
  `;


    return (
        <View style={{ flex: 1, height: '100%', width: '100%', }}>
            <WebView
                originWhitelist={['*']}
                source={{ uri: 'https://support.happygastro.hu/' }}
                useWebView2={true}
                javaScriptEnabled
                domStorageEnabled
                style={{ height: '100%', width: '100%', flex: 1 }} // Hide WebView
            />
        </View>
    );
};

export default SmartSuppChat;
