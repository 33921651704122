import React, { useState, useEffect } from "react";
import { Modal, View, Text, TouchableOpacity, StyleSheet } from "react-native";
import { Picker } from "@react-native-picker/picker";
import { useTranslation } from "react-i18next";

const DateTimeModal = ({ visible, setDate, setTime, isTime, isHistory, onClose }) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const startYear = 1996;
    const endYear = isHistory ? currentYear : currentYear + 1;

    const { t } = useTranslation();
    const [year, setYear] = useState(currentYear);
    const [month, setMonth] = useState(currentDate.getMonth() + 1);
    const [day, setDay] = useState(currentDate.getDate());
    const [hour, setHour] = useState(currentDate.getHours());
    const [minute, setMinute] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        setMinute(0);
    }, []);

    const getDaysInMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);
    const months = Array.from({ length: 12 }, (_, i) => i + 1);
    const days = Array.from({ length: getDaysInMonth(year, month) }, (_, i) => i + 1);
    const hours = Array.from({ length: 24 }, (_, i) => i);
    const minutes = [0, 15, 30, 45];

    const handleSave = () => {
        const selectedDate = new Date(year, month - 1, day, hour, minute);
        const selectedDateOnly = new Date(year, month - 1, day);
        const currentDateOnly = new Date(currentYear, currentDate.getMonth(), currentDate.getDate());

        if (isHistory && selectedDateOnly > currentDateOnly) {
            setErrorMessage(t("error_future_date"));
            return;
        } else if (!isHistory && selectedDateOnly < currentDateOnly) {
            setErrorMessage(t("error_past_date"));
            return;
        } else if (isTime && isHistory && selectedDate > currentDate) {
            setErrorMessage(t("error_future_time"));
            return;
        } else if (isTime && !isHistory && selectedDate < currentDate) {
            setErrorMessage(t("error_past_time"));
            return;
        }
        setErrorMessage("");
        setDate(`${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`);
        if (isTime) {
            setTime(`${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`);
        }
        onClose();
    };

    return (
        <Modal transparent={true} visible={visible} animationType="slide">
            <View style={styles.overlay}>
                <View style={styles.modalContainer}>
                    <Text style={styles.label}>{t("select_date")}</Text>
                    <View style={styles.pickerContainer}>
                        <View style={styles.pickerColumn}>
                            <Text style={styles.pickerLabel}>{t("year")}</Text>
                            <Picker selectedValue={year} onValueChange={setYear} style={styles.picker}>
                                {years.map(y => <Picker.Item key={y} label={y.toString()} value={y} />)}
                            </Picker>
                        </View>
                        <View style={styles.pickerColumn}>
                            <Text style={styles.pickerLabel}>{t("month")}</Text>
                            <Picker selectedValue={month} onValueChange={setMonth} style={styles.picker}>
                                {months.map(m => <Picker.Item key={m} label={m.toString()} value={m} />)}
                            </Picker>
                        </View>
                        <View style={styles.pickerColumn}>
                            <Text style={styles.pickerLabel}>{t("day")}</Text>
                            <Picker selectedValue={day} onValueChange={setDay} style={styles.picker}>
                                {days.map(d => <Picker.Item key={d} label={d.toString()} value={d} />)}
                            </Picker>
                        </View>
                    </View>
                    {isTime && (
                        <>
                            <Text style={styles.label}>{t("select_time")}</Text>
                            <View style={styles.pickerContainer}>
                                <View style={styles.pickerColumn}>
                                    <Text style={styles.pickerLabel}>{t("hour")}</Text>
                                    <Picker selectedValue={hour} onValueChange={setHour} style={styles.picker}>
                                        {hours.map(h => <Picker.Item key={h} label={h.toString()} value={h} />)}
                                    </Picker>
                                </View>
                                <Text style={styles.separator}></Text>
                                <View style={styles.pickerColumn}>
                                    <Text style={styles.pickerLabel}>{t("minute")}</Text>
                                    <Picker selectedValue={minute} onValueChange={setMinute} style={styles.picker}>
                                        {minutes.map(m => <Picker.Item key={m} label={m.toString()} value={m} />)}
                                    </Picker>
                                </View>
                            </View>
                        </>
                    )}
                    {errorMessage ? <Text style={styles.errorText}>{errorMessage}</Text> : null}
                    <View style={styles.buttonContainer}>
                        <TouchableOpacity onPress={onClose} style={styles.closeButton}>
                            <Text style={styles.buttonText}>{t("close")}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={handleSave} style={styles.saveButton}>
                            <Text style={styles.buttonText}>{t("save")}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    overlay: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    modalContainer: {
        backgroundColor: "#fff",
        padding: 20,
        borderRadius: 10,
        width: "fit-content",
        alignItems: "center",
    },
    label: {
        fontSize: 16,
        fontWeight: "bold",
        marginBottom: 15,
    },
    pickerContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 10,
    },
    pickerColumn: {
        alignItems: "center",
    },
    pickerLabel: {
        fontSize: 14,
        fontWeight: "bold",
        marginBottom: 5,
    },
    picker: {
        minWidth: 120,
        height: 40,
    },
    separator: {
        fontSize: 20,
        fontWeight: "bold",
        marginHorizontal: 5,
        paddingHorizontal: 5,
    },
    buttonContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 20,
        width: "70%",
    },
    closeButton: {
        padding: 15,
        backgroundColor: "#808080",
        borderRadius: 5,
        flex: 1,
        marginRight: 5,
        alignItems: "center",
    },
    saveButton: {
        padding: 15,
        backgroundColor: "#F1b34b",
        borderRadius: 5,
        flex: 1,
        marginLeft: 5,
        alignItems: "center",
    },
    buttonText: {
        color: "white",
        fontWeight: "bold",
    },
    errorText: {
        color: "red",
        marginTop: 10,
    },
});

export default DateTimeModal;