import React, { useState } from 'react';
import { View, Text, TextInput, Modal, TouchableOpacity, TouchableWithoutFeedback, FlatList, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';

const DeliveryUsersModal = ({ visible, onClose, deliveryUsers, onSelect, anchorPosition, updateOdersDelivery }) => {
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState('');

    const filteredUsers = deliveryUsers.filter(user =>
        (user.name?.first + ' ' + user.name?.last).toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <Modal
            animationType="none"
            transparent={true}
            visible={visible}
            onRequestClose={onClose}
        >
            <TouchableWithoutFeedback onPress={onClose}>
                <View style={styles.overlay}>
                    <TouchableWithoutFeedback>
                        <View style={[styles.modalContent, { top: anchorPosition?.top, left: anchorPosition?.left }]}>
                            <TextInput
                                style={styles.searchInput}
                                placeholder={t('search_courier')}
                                value={searchText}
                                onChangeText={setSearchText}
                            />
                            <FlatList
                                data={filteredUsers}
                                keyExtractor={user => user._id}
                                renderItem={({ item }) => (
                                    <TouchableOpacity
                                        style={styles.userItem}
                                        onPress={() => {
                                            updateOdersDelivery(item._id);
                                            onClose();
                                        }}
                                    >
                                        <Text style={styles.userText}>{item.name?.last} {item.name?.first}</Text>
                                    </TouchableOpacity>
                                )}
                            />
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            </TouchableWithoutFeedback>
        </Modal>
    );
};

const styles = StyleSheet.create({
    overlay: {
        flex: 1,
        backgroundColor: 'rgba(0,0,0,0.1)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        position: 'absolute',
        width: 220,
        backgroundColor: 'white',
        padding: 10,
        borderRadius: 5,
        elevation: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 4,
    },
    searchInput: {
        width: '100%',
        padding: 8,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        marginBottom: 5,
        backgroundColor: 'white',
    },
    userItem: {
        paddingVertical: 8,
        borderBottomWidth: 1,
        borderBottomColor: '#ddd',
    },
    userText: {
        fontSize: 16,
    }
});

export default DeliveryUsersModal;
