import React, { useState, useEffect } from "react";
import { StyleSheet, Text, TouchableOpacity, View, ScrollView, RefreshControl } from "react-native";
import IonIcons from "react-native-vector-icons/Ionicons";

const MonthlyCalendarView = ({
                                 bookings,
                                 selectedDate,
                                 onDateSelect,
                                 onViewChange,
                                 refreshing,
                                 onRefresh
                             }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date(selectedDate));
    const [calendarDays, setCalendarDays] = useState([]);

    // Update current month when selected date changes
    useEffect(() => {
        const newMonth = new Date(selectedDate);
        setCurrentMonth(new Date(newMonth.getFullYear(), newMonth.getMonth(), 1));
    }, [selectedDate]);

    // Generate calendar days for the current month
    useEffect(() => {
        generateCalendarDays();
    }, [currentMonth, bookings]);

    // Function to generate days for the current month view
    const generateCalendarDays = () => {
        const year = currentMonth.getFullYear();
        const month = currentMonth.getMonth();

        // Get the first day of the month
        const firstDay = new Date(year, month, 1);
        // Get the last day of the month
        const lastDay = new Date(year, month + 1, 0);

        // Get the day of the week for the first day (0 = Sunday, 1 = Monday, etc.)
        const firstDayOfWeek = firstDay.getDay();
        // Adjust to make Monday the first day of the week (0 = Monday, 6 = Sunday)
        const adjustedFirstDayOfWeek = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;

        const totalDays = lastDay.getDate();
        const totalCells = Math.ceil((totalDays + adjustedFirstDayOfWeek) / 7) * 7;

        const days = [];

        // Add previous month's days
        for (let i = 0; i < adjustedFirstDayOfWeek; i++) {
            const prevMonthDate = new Date(year, month, -adjustedFirstDayOfWeek + i + 1);
            days.push({
                date: prevMonthDate,
                isCurrentMonth: false,
                bookings: getBookingsForDate(prevMonthDate)
            });
        }

        // Add current month's days
        for (let i = 1; i <= totalDays; i++) {
            const date = new Date(year, month, i);
            days.push({
                date,
                isCurrentMonth: true,
                bookings: getBookingsForDate(date)
            });
        }

        // Add next month's days
        const remainingCells = totalCells - days.length;
        for (let i = 1; i <= remainingCells; i++) {
            const nextMonthDate = new Date(year, month + 1, i);
            days.push({
                date: nextMonthDate,
                isCurrentMonth: false,
                bookings: getBookingsForDate(nextMonthDate)
            });
        }

        setCalendarDays(days);
    };

    // Get bookings for a specific date
    const getBookingsForDate = (date) => {
        if (!bookings || bookings.length === 0) return [];

        return bookings.filter(booking => {
            const bookingDate = new Date(booking.dateFrom);
            return (
                bookingDate.getDate() === date.getDate() &&
                bookingDate.getMonth() === date.getMonth() &&
                bookingDate.getFullYear() === date.getFullYear()
            );
        });
    };

    // Navigate to previous month
    const goToPreviousMonth = () => {
        const prevMonth = new Date(currentMonth);
        prevMonth.setMonth(prevMonth.getMonth() - 1);
        setCurrentMonth(prevMonth);
    };

    // Navigate to next month
    const goToNextMonth = () => {
        const nextMonth = new Date(currentMonth);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        setCurrentMonth(nextMonth);
    };

    // Navigate to today
    const goToToday = () => {
        const today = new Date();
        setCurrentMonth(new Date(today.getFullYear(), today.getMonth(), 1));

        // Find the today cell in the calendar
        const todayCell = calendarDays.find(day => isToday(day.date));
        if (todayCell) {
            onDateSelect(todayCell.date);
        } else {
            onDateSelect(today);
        }
    };

    // Get current month and year display
    const getMonthYearDisplay = () => {
        const options = { year: 'numeric', month: 'long' };
        return currentMonth.toLocaleDateString('hu-HU', options);
    };

    // Check if a date is today
    const isToday = (date) => {
        const today = new Date();
        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    };

    // Check if a date is selected
    const isSelected = (date) => {
        return (
            date.getDate() === selectedDate.getDate() &&
            date.getMonth() === selectedDate.getMonth() &&
            date.getFullYear() === selectedDate.getFullYear()
        );
    };

    // Render weekday headers
    const renderWeekdays = () => {
        const weekdays = ['H', 'K', 'Sze', 'Cs', 'P', 'Szo', 'V'];

        return (
            <View style={styles.weekdaysContainer}>
                {weekdays.map((day, index) => (
                    <View key={index} style={styles.weekdayCell}>
                        <Text style={styles.weekdayText}>{day}</Text>
                    </View>
                ))}
            </View>
        );
    };

    // Render calendar grid
    const renderCalendarGrid = () => {
        // Split the days into weeks (chunks of 7)
        const weeks = [];
        for (let i = 0; i < calendarDays.length; i += 7) {
            weeks.push(calendarDays.slice(i, i + 7));
        }

        return (
            <View style={styles.calendarGrid}>
                {weeks.map((week, weekIndex) => (
                    <View key={weekIndex} style={styles.weekRow}>
                        {week.map((day, dayIndex) => (
                            <TouchableOpacity
                                key={dayIndex}
                                style={[
                                    styles.dayCell,
                                    !day.isCurrentMonth && styles.outOfMonthDay,
                                    isToday(day.date) && styles.todayCell,
                                    isSelected(day.date) && styles.selectedCell
                                ]}
                                onPress={() => {
                                    onDateSelect(day.date);
                                    onViewChange();
                                }}
                            >
                                <Text style={[
                                    styles.dayNumber,
                                    !day.isCurrentMonth && styles.outOfMonthText,
                                    isToday(day.date) && styles.todayText,
                                    isSelected(day.date) && styles.selectedText
                                ]}>
                                    {day.date.getDate()}
                                </Text>

                                {day.bookings.length > 0 && (
                                    <View style={styles.bookingIndicatorContainer}>
                                        {day.bookings.slice(0, 3).map((booking, index) => (
                                            <View
                                                key={index}
                                                style={[
                                                    styles.bookingIndicator,
                                                    {backgroundColor: booking.status === "accepted" ? "#5cb85c" : booking.status === "pending" ? "#f1b34b" : "#f6abb4"}
                                                ]}
                                            />
                                        ))}

                                        {day.bookings.length > 3 && (
                                            <Text style={styles.moreBookingsText}>+{day.bookings.length - 3}</Text>
                                        )}
                                    </View>
                                )}
                            </TouchableOpacity>
                        ))}
                    </View>
                ))}
            </View>
        );
    };

    return (
        <ScrollView
            style={styles.container}
            contentContainerStyle={styles.contentContainer}
            refreshControl={
                <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
        >
            <View style={styles.header}>
                <TouchableOpacity onPress={goToPreviousMonth} style={styles.navButton}>
                    <IonIcons name="chevron-back" size={24} color="#333" />
                </TouchableOpacity>

                <Text style={styles.headerText}>{getMonthYearDisplay()}</Text>

                <TouchableOpacity onPress={goToNextMonth} style={styles.navButton}>
                    <IonIcons name="chevron-forward" size={24} color="#333" />
                </TouchableOpacity>
            </View>

            {renderWeekdays()}
            {renderCalendarGrid()}

            <View style={styles.legend}>
                <View style={styles.legendItem}>
                    <View style={[styles.legendIndicator, { backgroundColor: "#5cb85c" }]} />
                    <Text style={styles.legendText}>Elfogadott</Text>
                </View>

                <View style={styles.legendItem}>
                    <View style={[styles.legendIndicator, { backgroundColor: "#f1b34b" }]} />
                    <Text style={styles.legendText}>Függőben lévő</Text>
                </View>

                <View style={styles.legendItem}>
                    <View style={[styles.legendIndicator, { backgroundColor: "#d9534f" }]} />
                    <Text style={styles.legendText}>Elutasított</Text>
                </View>
            </View>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
    },
    contentContainer: {
        paddingBottom: 20,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 10,
        width: '90%',
        alignSelf: 'center',
    },
    headerText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#333',
    },
    navButton: {
        padding: 5,
    },
    todayButtonSmall: {
        width: 36,
        height: 36,
        borderRadius: 18,
        backgroundColor: '#4CAF50',
        justifyContent: 'center',
        alignItems: 'center',
    },
    weekdaysContainer: {
        flexDirection: 'row',
        width: '90%',
        alignSelf: 'center',
        marginTop: 10,
    },
    weekdayCell: {
        flex: 1,
        alignItems: 'center',
        paddingVertical: 10,
    },
    weekdayText: {
        fontWeight: 'bold',
        color: '#666',
    },
    calendarGrid: {
        width: '90%',
        alignSelf: 'center',
        marginTop: 5,
        borderWidth: 1,
        borderColor: '#ddd',
        borderRadius: 5,
        overflow: 'hidden',
    },
    weekRow: {
        flexDirection: 'row',
        height: 80,
    },
    dayCell: {
        flex: 1,
        padding: 5,
        borderWidth: 0.5,
        borderColor: '#ddd',
        backgroundColor: '#fff',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    outOfMonthDay: {
        backgroundColor: '#f5f5f5',
    },
    todayCell: {
        backgroundColor: '#e8f4ff',
    },
    selectedCell: {
        backgroundColor: '#d4e6ff',
    },
    dayNumber: {
        fontWeight: '600',
        marginBottom: 5,
    },
    outOfMonthText: {
        color: '#aaa',
    },
    todayText: {
        color: '#007bff',
    },
    selectedText: {
        color: '#0056b3',
        fontWeight: 'bold',
    },
    bookingIndicatorContainer: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    bookingIndicator: {
        width: 8,
        height: 8,
        borderRadius: 4,
        marginVertical: 1,
    },
    moreBookingsText: {
        fontSize: 10,
        color: '#666',
        marginTop: 2,
    },
    legend: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 15,
    },
    legendItem: {
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 10,
    },
    legendIndicator: {
        width: 12,
        height: 12,
        borderRadius: 6,
        marginRight: 5,
    },
    legendText: {
        fontSize: 14,
        color: '#333',
    },
});

export default MonthlyCalendarView;