const deviceDefault = {
	restaurantPlace: null,
	restaurant: null,
	device: {},
	searchString: '',
	categories: [],
	extras: [],
	cartItem: undefined,
	item: undefined,
	showExtras: false,
	orderType: "takeAway",
	discounts: { percentage: 0, amount: 0 },
	foods: [],
	orders: [],
	printers: [],
	printerEvents: [],
	cartItems: [],
	loading: true,
	processing: false,
	locationTracking: true,
};

export default deviceDefault;
