import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet, Dimensions } from 'react-native';
import { BarChart } from 'react-native-chart-kit';
import { useNavigation } from '@react-navigation/native';

function sortObject(obj) {
    let arr = [];
    for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            arr.push({ key: prop, value: obj[prop] });
        }
    }
    arr.sort((a, b) => b.value - a.value);
    return arr;
}

const ProductStatistics = ({ orders }) => {
    const { t } = useTranslation("misc");
    const [filteredOrders, setFilteredOrders] = useState(orders || []);
    const navigation = useNavigation();

    useEffect(() => {
        setFilteredOrders(orders);
    }, [orders]);

    let productsList = {};

    filteredOrders.filter(o => !o.storno && o.status !== 'cancelled').forEach(order => {
        order.items.forEach(cartItem => {
            const productName = cartItem.item.name['hu'];
            const productAmount = cartItem.amount;

            productsList[productName] = (productsList[productName] || 0) + productAmount;
        });
    });

    const orderList = sortObject(productsList).slice(0, 10);
    const labels = orderList.map(product => product.key);
    const dataValues = orderList.map(product => product.value);


    return (
        <View style={styles.container}>
            {orderList?.length > 0 && <BarChart
                data={{
                    labels: labels,
                    datasets: [{ data: dataValues }]
                }}
                width={Dimensions.get("window").width * 0.9}
                height={400} // Increase height for better spacing
                yAxisLabel=""
                chartConfig={{
                    backgroundGradientFrom: "#fff",
                    backgroundGradientTo: "#fff",
                    decimalPlaces: 0,
                    color: (opacity = 1) => `rgba(241, 180, 76, ${opacity})`,
                    labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
                    style: { borderRadius: 16 },
                }}
                style={{ marginVertical: 8, borderRadius: 16, }}
                horizontal={true} // Enables horizontal bars
                showValuesOnTopOfBars={true} // Show values on bars
                withHorizontalLabels={true} // Proper horizontal alignment
                verticalLabelRotation={20} // Rotate labels
            />}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 15,
        minHeight: 450, // Adjust for better spacing
    },
    title: {
        fontSize: 16,
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: 10,
    }
});

export default ProductStatistics;