import {
  SET_CATEGORIES,
  SET_DATAS,
  SET_EXTRAS,
  SET_FOODS,
  SET_LOADING,
  SET_PRINTER_EVENTS,
  SET_PRINTERS,
  SET_PROCESSING,
  SET_RESTAURANT,
  SET_RESTAURANT_PLACE,
  SET_SEARCH,
  SHOW_EXTRA,
  SHOW_LOCATION,
} from "../actions/keys";

import DefaultState from "./default";

const INIT_STATE = DefaultState.device;

const DeviceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.loading || true };
    case SET_EXTRAS:
      return { ...state, extras: action.extras || [] };
    case SHOW_LOCATION:
      return { ...state, locationTracking: action.showLocation };
    case SET_PROCESSING:
      return { ...state, processing: action.s || false };
    case SET_SEARCH:
      return { ...state, searchString: action.searchString };
    case SHOW_EXTRA:
      return {
        ...state,
        showExtras: action.show,
        item: action.item,
        cartItem: action.cartItem || undefined,
      };
    case SET_DATAS:
      return { ...state, categories: action.categories, foods: action.foods };
    case SET_CATEGORIES:
      return { ...state, categories: action.categories };
    case SET_FOODS:
      return { ...state, foods: action.foods };
    case SET_RESTAURANT:
      return { ...state, restaurant: action.restaurant || null };
    case SET_RESTAURANT_PLACE:
      return { ...state, restaurantPlace: action.restaurantPlace || null };
    case SET_PRINTERS:
      return { ...state, printers: action.printers || [] };
    case SET_PRINTER_EVENTS:
      return { ...state, printerEvents: action.printerEvents || [] };
    default:
      return state;
  }
};

export default DeviceReducer;
