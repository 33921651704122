import React, {useContext, useEffect, useRef, useState} from 'react';
import {FlatList, Modal, SafeAreaView, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {Picker} from '@react-native-picker/picker';
import Icon from 'react-native-vector-icons/FontAwesome';
import {useTranslation} from 'react-i18next';
import {SystemContext} from "../helpers/SystemContext";
import DateTimePickerButton from "../components/DateTimePickerButton";
import {getAccessToken} from "../helpers/storage";

const StandModal = ({ isVisible, onClose, saveInventory, restaurant }) => {
    const { t } = useTranslation();
    const formValuesRef = useRef({});
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [date, setDate] = useState(new Date());
    const [warehouses, setWarehouses] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [comment, setComment] = useState('');
    const [expandedCategories, setExpandedCategories] = useState([]);
    const [warehouseItems, setWarehouseItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [isCategorizedView, setIsCategorizedView] = useState(false);
    const [showDateModal, setShowDateModal] = useState(false);
    const [foods, setFoods] = useState([]);
    const { categories } = useContext(SystemContext);

    function getUnitText(sizeId, item) {
                const size = item.sizes.find(size => size._id === sizeId);
                return size ? size.unit : '';
    }
    // Time handling
    const getRoundedTime = () => {
        const currentDate = new Date();
        const minutes = currentDate.getMinutes();
        const roundedMinutes = Math.round(minutes / 15) * 15;

        currentDate.setMinutes(roundedMinutes);
        currentDate.setSeconds(0);

        return currentDate;
    };
    const toggleViewMode = () => {
        setIsCategorizedView(!isCategorizedView);
    };
    useEffect(() => {
        setDate(getRoundedTime());
        fetchWarehouses()
    }, []);

    useEffect(() => {
        if(selectedWarehouse) {
            fetchStocks()
            //fetchInventory()
        }
    }, [selectedWarehouse]);

    const fetchWarehouses = async()=> {
        const token = await getAccessToken();
        const response = await  fetch('https://api.happygastro.hu/api/warehouse', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })
        const data = await response.json();
        setWarehouses(data)
        return data
    }
    const fetchStocks = async()=> {
        const token = await getAccessToken();
        const response = await  fetch(`https://api.happygastro.hu/api/warehouse/all/stock`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })
        const data = await response.json();
        setFoods(data)
        return data
    }
    const fetchInventory = async()=> {
        const token = await getAccessToken();
        const response = await  fetch(`https://api.happygastro.hu/api/inventories/${selectedWarehouse._id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })
        const data = await response.json();
        console.log("inventory",data)
        setFoods(data)
        return data
    }

    // Load warehouse items when warehouse is selected
    useEffect(() => {
        if (selectedWarehouse) {
            // Simulating loading warehouse items
            // In a real app, you would fetch this data from your API
            console.log(foods)
            const items = foods.filter(item => {
                return item.warehouse === selectedWarehouse._id
            });
            setWarehouseItems(items);
            setFilteredItems(items);
        }
    }, [foods]);

    // Filter items based on keyword
    useEffect(() => {
        if (warehouseItems.length > 0) {
            const filtered = warehouseItems.filter(item => {
                   return item.item?.name?.hu?.toLowerCase().includes(keyword.toLowerCase())
                }
            )
            setFilteredItems(filtered);
        }
    }, [keyword]);

useEffect(() => {
    console.log(filteredItems)
},[filteredItems])
    const handleInputChange = (itemId, sizeId, value) => {
        formValuesRef.current = {
            ...formValuesRef.current,
            [itemId]: {
                ...formValuesRef.current[itemId],
                [sizeId]: value,
            },
        };
    };

    const handleSaveInventory = () => {
        const collectInventory = [];

        Object.keys(formValuesRef.current).forEach((itemId) => {
            Object.keys(formValuesRef.current[itemId]).forEach((sizeId) => {
                collectInventory.push({
                    itemId: itemId,
                    sizeId: sizeId,
                    amount: Number(formValuesRef.current[itemId][sizeId]),
                });
            });
        });

        saveInventory({
            warehouseId: selectedWarehouse._id,
            items: collectInventory,
            inventoryDate: date,
            comment: comment,
            receiptType: "stand",
        });

        onClose();
    };

    const toggleCategory = (categoryId) => {
        if (expandedCategories.includes(categoryId)) {
            setExpandedCategories(expandedCategories.filter(id => id !== categoryId));
        } else {
            setExpandedCategories([...expandedCategories, categoryId]);
        }
    };

    const expandAll = () => {
        const allCategoryIds = categories.map(category => category._id);
        setExpandedCategories(allCategoryIds);
    };

    const collapseAll = () => {
        setExpandedCategories([]);
    };

    const renderCategoryItem = ({ item }) => {
        // Only render parent categories
        if (item.parent) return null;

        const isExpanded = expandedCategories.includes(item._id);
        const childCategories = categories.filter(cat => cat.parent === item._id);

        return (
            <View>
                <TouchableOpacity
                    style={styles.categoryHeader}
                    onPress={() => toggleCategory(item._id)}
                >
                    <Icon
                        name={isExpanded ? 'caret-down' : 'caret-right'}
                        size={20}
                        color="#000"
                    />
                    <Text style={styles.categoryTitle}>{item.item?.name?.hu || '(No name)'}</Text>
                </TouchableOpacity>

                {isExpanded && (
                    <View style={styles.categoryContent}>
                        {childCategories.map(childCat => {
                            const categoryItems = filteredItems.filter(item => item.item?.category === childCat._id);

                            return (
                                <View key={childCat._id} style={styles.subCategory}>
                                    <Text style={styles.subCategoryTitle}>{childCat.item?.name?.hu || '(No name)'}</Text>

                                    {categoryItems.map(item => (
                                        <View key={item.item?._id} style={styles.itemRow}>
                                            <Text style={styles.itemName}>{item.item?.name?.hu || '(No name)'}</Text>
                                            <TextInput
                                                style={styles.itemInput}
                                                keyboardType="numeric"
                                                onChangeText={(value) => handleInputChange(item.item?._id, item.size, value)}
                                                defaultValue={formValuesRef.current[item.item?._id]?.[item.size]?.toString() || ''}
                                            />
                                        </View>
                                    ))}
                                </View>
                            );
                        })}
                    </View>
                )}
            </View>
        );
    };

    return (
        <Modal
            visible={isVisible}
            animationType="slide"
            transparent={false}
        >
            <SafeAreaView style={styles.container}>
                <View style={styles.header}>
                    <Text style={styles.headerTitle}>{t('inventory')}</Text>
                    <TouchableOpacity style={styles.closeButton} onPress={onClose}>
                        <Text style={styles.closeButtonText}>{t('close')}</Text>
                    </TouchableOpacity>
                </View>

                <View style={styles.formContainer}>
                    <View style={styles.formRow}>
                        <View style={styles.formGroup}>
                            <Text style={styles.label}>{t('select_warehouse')}</Text>
                            <View style={styles.pickerContainer}>
                                <Picker
                                    defaultValue={"placeholder"}
                                    style={styles.picker}
                                    selectedValue={selectedWarehouse?._id}
                                    onValueChange={(itemValue) => {
                                        const selected = warehouses.find(warehouse => warehouse._id === String(itemValue));
                                        setSelectedWarehouse(selected);
                                    }}
                                >
                                    <Picker.Item label={t("select_warehouse")} value="placeholder" enabled={false} />
                                    {warehouses?.map((warehouse) => (
                                        <Picker.Item key={warehouse._id} label={warehouse.name} value={warehouse._id} />
                                    ))}
                                </Picker>
                            </View>
                        </View>

                        <View style={styles.formGroup}>
                            <Text style={styles.label}>{t('inventory_time')}</Text>
                            <DateTimePickerButton onDateChange={setDate} isTime={true} isHistory={true} buttonText={"Dátum"}/>
                        </View>

                        <View style={styles.formGroup}>
                            <Text style={styles.label}>{t('comment')}</Text>
                            <TextInput
                                style={styles.input}
                                value={comment}
                                onChangeText={setComment}
                                placeholder={t('comment')}
                            />
                        </View>

                        <View style={styles.formGroup}>
                            <Text style={styles.label}>{t('search')}</Text>
                            <TextInput
                                style={styles.input}
                                value={keyword}
                                onChangeText={setKeyword}
                                placeholder={t('search')}
                            />
                        </View>
                    </View>

                    <View style={styles.expandButtonsContainer}>
                        <TouchableOpacity style={styles.expandButton} onPress={collapseAll} >
                            <Icon name="caret-up" size={16} color="#fff" />
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.expandButton} onPress={expandAll}>
                            <Icon name="caret-down" size={16} color="#fff" />
                        </TouchableOpacity>

                    </View>
                </View>

                {!selectedWarehouse && (
                    <View style={styles.missingInfoContainer}>
                        <Text style={styles.missingInfoText}>
                            {t('first_provide_warehouse_and_inventory_time')}
                        </Text>
                    </View>
                )}

                {selectedWarehouse && (
                    <View style={styles.inventoryContainer}>
                        {isCategorizedView ? (
                            <FlatList
                                data={categories.filter(cat => !cat.parent)}
                                renderItem={renderCategoryItem}
                                keyExtractor={item => item._id}
                                ListHeaderComponent={
                                    <View style={styles.inventoryHeader}>
                                        <Text style={styles.productNameHeader}>{t('product_name')}</Text>
                                        <Text style={styles.amountHeader}>{t('amount')}</Text>
                                    </View>
                                }
                            />
                        ) : (
                            <FlatList
                                data={filteredItems}
                                renderItem={({ item }) => (
                                    <View style={styles.itemRow}>
                                        <Text style={styles.itemName}>{item.item?.name?.hu || '(No name)'} (1 {item.unit || ""})</Text>
                                        <TextInput
                                            style={styles.itemInput}
                                            keyboardType="numeric"
                                            onChangeText={(value) => handleInputChange(item.item?._id, item.size, value)}
                                            defaultValue={formValuesRef.current[item.item?._id]?.[item.size]?.toString() || ''}
                                        />
                                    </View>
                                )}
                                keyExtractor={item => item._id}
                                ListHeaderComponent={
                                    <View style={styles.inventoryHeader}>
                                        <Text style={styles.productNameHeader}>{t('product_name')}</Text>
                                        <Text style={styles.amountHeader}>{t('amount')}</Text>
                                    </View>
                                }
                            />
                        )}
                    </View>
                )}

                {selectedWarehouse && (
                    <View style={styles.footer}>
                        <TouchableOpacity
                            style={styles.saveButton}
                            onPress={handleSaveInventory}
                        >
                            <Text style={styles.saveButtonText}>{t('save')}</Text>
                        </TouchableOpacity>
                    </View>
                )}
            </SafeAreaView>
        </Modal>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#FEE6DE',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#c9c4cc',
        padding: 15,
    },
    headerTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    closeButton: {
        backgroundColor: '#dc3545',
        padding: 8,
        borderRadius: 4,
    },
    closeButtonText: {
        color: 'white',
        fontWeight: 'bold',
    },
    formContainer: {
        padding: 10,
        backgroundColor: '#EBE5F2',
    },
    formGroup: {
        marginBottom: 10,
        flex: 1
    },
    formRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    label: {
        fontWeight: 'bold',
        marginBottom: 5,
        textAlign: 'center',
    },
    pickerContainer: {
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 4,
        backgroundColor: 'white',
    },
    picker: {
        height: 40,
        width: '100%',
    },
    input: {
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 4,
        padding: 8,
        backgroundColor: 'white',
    },
    dateTimeContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    datePickerButton: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 4,
        padding: 8,
        backgroundColor: 'white',
        flex: 1,
        marginRight: 5,
    },
    timePickerButton: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 4,
        padding: 8,
        backgroundColor: 'white',
        width: 80,
    },
    expandButtonsContainer: {
        flexDirection: 'row',
        marginTop: 10,
        flexWrap: 'wrap',
    },
    expandButton: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#f0ad4e',
        padding: 8,
        borderRadius: 4,
        marginRight: 10,
    },
    expandButtonText: {
        color: 'white',
        marginLeft: 5,
    },
    missingInfoContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
    },
    missingInfoText: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    inventoryContainer: {
        flex: 1,
        padding: 10,
    },
    inventoryHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        marginBottom: 10,
    },
    productNameHeader: {
        fontWeight: 'bold',
        flex: 3,
    },
    amountHeader: {
        fontWeight: 'bold',
        flex: 1,
        textAlign: 'center',
    },
    categoryHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#EBE5F2',
        padding: 10,
        marginBottom: 5,
        borderRadius: 4,
    },
    categoryTitle: {
        fontWeight: 'bold',
        marginLeft: 10,
    },
    categoryContent: {
        marginLeft: 15,
        marginBottom: 10,
    },
    subCategory: {
        marginBottom: 10,
    },
    subCategoryTitle: {
        fontWeight: 'bold',
        marginBottom: 5,
        padding: 5,
        backgroundColor: '#f8f9fa',
    },
    itemRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 8,
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
    },
    itemName: {
        flex: 3,
    },
    itemInput: {
        flex: 1,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 4,
        padding: 5,
        backgroundColor: 'white',
        textAlign: 'center',
    },
    footer: {
        padding: 15,
        borderTopWidth: 1,
        borderTopColor: '#ccc',
        backgroundColor: '#EBE5F2',
    },
    saveButton: {
        backgroundColor: '#F76E24',
        padding: 12,
        borderRadius: 4,
        alignItems: 'center',
    },
    saveButtonText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 16,
    },
});

export default StandModal;