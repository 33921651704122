import React, {useContext, useEffect, useState} from 'react';
import {
    View,
    Text,
    TextInput,
    TouchableOpacity,
    StyleSheet,
    FlatList,
    ScrollView,
    Dimensions,
    Platform,
    KeyboardAvoidingView
} from 'react-native';
import { SystemContext } from '../helpers/SystemContext';
import {MaterialIcons} from "@expo/vector-icons";
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import DropDownPicker from 'react-native-dropdown-picker';
import DateTimePickerButton from "./DateTimePickerButton";
const {height} = Dimensions.get('window')
const InventoryForm = ({onClose}) => {
    const [showInput, setShowInput] = useState(false);
    const [items, setItems] = useState([]);
    const [warehouse, setWarehouse] = useState('');
    const [supplier, setSupplier] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [comment, setComment] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [invoiceDate, setInvoiceDate] = useState(new Date());
    const [totals, setTotals] = useState(0)
    const [currentInput, setCurrentInput] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const { foods } = useContext(SystemContext);

    // States for dropdown pickers
    const [warehouseOpen, setWarehouseOpen] = useState(false);
    const [supplierOpen, setSupplierOpen] = useState(false);
    const [paymentMethodOpen, setPaymentMethodOpen] = useState(false);

    // Warehouse options
    const [warehouseItems, setWarehouseItems] = useState([
        {label: 'Válassz egy raktárt', value: ''},
        {label: 'Happy Raktár', value: 'happy_raktar'},
        {label: 'Central Raktár', value: 'central_raktar'}
    ]);

    // Supplier options
    const [supplierItems, setSupplierItems] = useState([
        {label: 'Válassz egy beszállítót', value: ''},
        {label: 'Coca-cola', value: 'Coca-cola'},
        {label: 'Pepsi', value: 'pepsi'},
        {label: 'Nestlé', value: 'nestle'},
        {label: 'Tesco', value: 'tesco'}
    ]);

    // Payment method options
    const [paymentMethodItems, setPaymentMethodItems] = useState([
        {label: 'Fizetés módja', value: ''},
        {label: 'Készpénz', value: 'cash'},
        {label: 'Bankkártya', value: 'credit_card'},
        {label: 'Banki utalás', value: 'transfer'}
    ]);

    // Get screen width
    const screenWidth = Dimensions.get('window').width;

    useEffect(() => {
        setTotals(calculateTotals())
    }, [items])
    useEffect(() => {
    }, [totals])

    const handleInputChange = (text) => {
        setCurrentInput(text);
        if (text.length > 0) {
            const filteredFoods = foods.filter(food => food.name.hu.toLowerCase().includes(text.trim().toLowerCase()));
            setSuggestions(filteredFoods);
        } else {
            setSuggestions([]);
        }
    };

    const handleSelectItem = (selectedItem) => {
        setItems([...items, {
            name: selectedItem.name.hu,
            quantity: 1,
            vat: 27,
            netUnitPrice: selectedItem.sizes[0].price || 0,
            unit: selectedItem.sizes[0].unit
        }]);
        setCurrentInput('');
        setSuggestions([]);
    };

    const handleQuantityChange = (index, value) => {
        const newItems = [...items];
        newItems[index].quantity = parseFloat(value) || 0;
        updateTotals(newItems, index);
        setItems(newItems);
    };

    const handleVatChange = (index, value) => {
        const newItems = [...items];
        newItems[index].vat = parseFloat(value);
        updateTotals(newItems, index);
        setItems(newItems);
    };

    const handlePriceChange = (index, value) => {
        const newItems = [...items];
        newItems[index].netUnitPrice = parseFloat(value) || 0;
        updateTotals(newItems, index);
        setItems(newItems);
    };

    const updateTotals = (itemsArray, index) => {
        const item = itemsArray[index];
        item.netTotal = item.quantity * item.netUnitPrice;
        item.grossTotal = item.netTotal * (1 + item.vat / 100);
    };

    const calculateTotals = () => {
        const totalItems = items.length;
        const totalNetAmount = items.reduce((sum, item) => sum + (item.netTotal || 0), 0);
        const totalGrossAmount = items.reduce((sum, item) => sum + (item.grossTotal || 0), 0);
        const totalVatAmount = totalGrossAmount - totalNetAmount;

        return {
            totalItems,
            totalNetAmount,
            totalGrossAmount,
            totalVatAmount
        };
    };

    return (
        <View  style={styles.container}>
            <View style={styles.buttonContainer}>
                <TouchableOpacity onPress={onClose} style={styles.saveButton}>
                    <Text style={styles.buttonText}>MENTÉS</Text>
                </TouchableOpacity>
                <DateTimePickerButton
                    onDateChange={setInvoiceDate}
                    isTime={true}
                    isHistory={true}
                    buttonText={"Dátum"}/>
                <TouchableOpacity onPress={onClose} style={styles.closeButton}>
                    <Text style={styles.buttonText}>BEZÁRÁS</Text>
                </TouchableOpacity>
            </View>
            <ScrollView
            >
                <View style={[styles.dropdownContainer, {zIndex: 3000}]}>
                    <Text style={styles.label}>Warehouse</Text>
                    <DropDownPicker
                        open={warehouseOpen}
                        value={warehouse}
                        items={warehouseItems}
                        setOpen={setWarehouseOpen}
                        setValue={setWarehouse}
                        setItems={setWarehouseItems}
                        style={styles.dropdown}
                        dropDownContainerStyle={styles.dropdownList}
                        zIndex={3000}
                        zIndexInverse={2200}
                    />
                </View>

                <View style={[styles.dropdownContainer, {marginBottom: 5, zIndex: 2000}]}>
                    <Text style={styles.label}>Supplier</Text>
                    <DropDownPicker
                        open={supplierOpen}
                        value={supplier}
                        items={supplierItems}
                        setOpen={setSupplierOpen}
                        setValue={setSupplier}
                        setItems={setSupplierItems}
                        style={styles.dropdown}
                        dropDownContainerStyle={styles.dropdownList}
                        zIndex={2000}
                        zIndexInverse={2000}
                    />
                </View>

                <View style={[styles.dropdownContainer, {zIndex: 1000}]}>
                    <Text style={styles.label}>Payment Method</Text>
                    <DropDownPicker
                        open={paymentMethodOpen}
                        value={paymentMethod}
                        items={paymentMethodItems}
                        setOpen={setPaymentMethodOpen}
                        setValue={setPaymentMethod}
                        setItems={setPaymentMethodItems}
                        style={styles.dropdown}
                        dropDownContainerStyle={styles.dropdownList}
                        zIndex={1000}
                        zIndexInverse={3000}
                    />
                </View>

                <View style={styles.inputContainer}>
                    <Text style={styles.label}>Comment</Text>
                    <TextInput
                        style={styles.input}
                        placeholder="Megjegyzés"
                        value={comment}
                        onChangeText={setComment}
                    />
                </View>

                <View style={styles.inputContainer}>
                    <Text style={styles.label}>Invoice Number</Text>
                    <TextInput
                        style={styles.input}
                        placeholder="Számla sorszáma"
                        value={invoiceNumber}
                        onChangeText={setInvoiceNumber}
                    />
                </View>
            <TouchableOpacity
                style={styles.addButton}
                onPress={() => setCurrentInput(' ')}
            >
                <Text style={styles.addButtonText}>+ TÉTEL HOZZÁADÁSA</Text>
            </TouchableOpacity>

            {currentInput !== '' && (
                <View style={styles.searchContainer}>
                    <TextInput
                        style={styles.input}
                        placeholder="Adj hozzá egy tételt"
                        value={currentInput}
                        autoFocus={true}
                        onChangeText={handleInputChange}
                    />

                    {suggestions.length > 0 && (
                        <FlatList
                            data={suggestions}
                            keyExtractor={(item) => item.id.toString()}
                            renderItem={({ item }) => (
                                <TouchableOpacity onPress={() => handleSelectItem(item)}>
                                    <Text style={styles.suggestionItem}>{item.name.hu}</Text>
                                </TouchableOpacity>
                            )}
                            style={styles.suggestionsList}
                        />
                    )}
                </View>
            )}

            {items.length > 0 && (
                <View style={styles.tableWrapper}>
                    <View style={styles.headerRow}>
                        <Text style={[styles.headerCell, {flex: 2}]}>Termék</Text>
                        <Text style={[styles.headerCell, {flex: 1}]}>Mennyiség</Text>
                        <Text style={[styles.headerCell, {flex: 1}]}>Áfa</Text>
                        <Text style={[styles.headerCell, {flex: 1.5}]}>Nettó Egységár</Text>
                        <Text style={[styles.headerCell, {flex: 1.5}]}>Nettó Összesen</Text>
                        <Text style={[styles.headerCell, {flex: 1.5}]}>Bruttó Összesen</Text>
                        <Text style={[styles.headerCell, {flex: 1.5}]}>Raktár</Text>
                        <Text style={[styles.headerCell, {width: 40}]}></Text>
                    </View>

                    <ScrollView>
                        {items.map((item, index) => {
                            // VAT dropdown states for each item

                            return (
                                <View key={index} style={styles.dataRow}>
                                    <View style={[styles.cell, {flex: 2}]}>
                                        <Text style={styles.productName} numberOfLines={2}>{item.name}</Text>
                                        <Text style={styles.productInfo}>({item.unit})</Text>
                                    </View>

                                    <View style={[styles.cell, {flex: 1}]}>
                                        <TextInput
                                            style={styles.numberInput}
                                            value={String(item.quantity)}
                                            keyboardType="numeric"
                                            onChangeText={(value) => handleQuantityChange(index, value)}
                                        />
                                        <Text style={styles.unitLabel}>1 ADAG</Text>
                                    </View>

                                    <View style={[styles.cell, {flex: 1, zIndex: 1000 - index}]}>
                                        <View style={styles.smallDropdownContainer}>
                                            <DropDownPicker
                                                open={item.vatOpen}
                                                value={String(item.vat)}
                                                items={[
                                                    { label: '27%', value: '27' },
                                                    { label: '18%', value: '18' },
                                                    { label: '5%', value: '5' }
                                                ]}
                                                setOpen={(open) => {
                                                    const newItems = [...items];
                                                    newItems[index].vatOpen = open;
                                                    setItems(newItems);
                                                }}
                                                setValue={(callback) => {
                                                    const newItems = [...items];
                                                    newItems[index].vat = parseInt(callback(newItems[index].vat));
                                                    handleVatChange(index, newItems[index].vat);
                                                }}
                                                setItems={() => {}}
                                                style={styles.dropdownButton}
                                                dropDownContainerStyle={styles.dropdown}
                                            />
                                        </View>
                                    </View>

                                    <View style={[styles.cell, {flex: 1.5}]}>
                                        <TextInput
                                            style={styles.numberInput}
                                            value={String(item.netUnitPrice)}
                                            keyboardType="numeric"
                                            onChangeText={(value) => handlePriceChange(index, value)}
                                        />
                                    </View>

                                    <View style={[styles.cell, {flex: 1.5}]}>
                                        <Text style={styles.totalText}>
                                            {(item.quantity * item.netUnitPrice).toFixed(2)}
                                        </Text>
                                    </View>

                                    <View style={[styles.cell, {flex: 1.5}]}>
                                        <Text style={styles.totalText}>
                                            {((item.quantity * item.netUnitPrice) * (1 + item.vat / 100)).toFixed(2)}
                                        </Text>
                                    </View>

                                    <View style={[styles.cell, {flex: 1.5, zIndex: 500 - index}]}>
                                        <View style={styles.smallDropdownContainer}>
                                            <DropDownPicker
                                                open={item.warehouseOpen}
                                                value={item.warehouse}
                                                items={[
                                                    { label: '', value: '' },
                                                    { label: 'Happy Raktár', value: 'happy_raktar' },
                                                    { label: 'Central Raktár', value: 'central_raktar' }
                                                ]}
                                                setOpen={(open) => {
                                                    const newItems = [...items];
                                                    newItems[index].warehouseOpen = open;
                                                    setItems(newItems);
                                                }}
                                                setValue={(callback) => {
                                                    const newItems = [...items];
                                                    newItems[index].warehouse = callback(newItems[index].warehouse);
                                                    setItems(newItems);
                                                }}
                                                setItems={() => {}}
                                                style={styles.dropdownButton}
                                                dropDownContainerStyle={styles.dropdown}
                                            />
                                        </View>
                                    </View>

                                    <TouchableOpacity
                                        style={[styles.cell, {width: 40, justifyContent: 'center', alignItems: 'center'}]}
                                        onPress={() => {
                                            const newItems = items.filter((_, i) => i !== index);
                                            setItems(newItems);
                                        }}
                                    >
                                        <MaterialIcons name="delete" size={24} color="red" />
                                    </TouchableOpacity>
                                </View>
                            );
                        })}
                    </ScrollView>

                    <View style={styles.totalRow}>
                        <Text style={[styles.totalLabel, {flex: 2}]}>
                            Termékek összesen ({totals.totalItems})
                        </Text>
                        <View style={{flex: 1}}></View>
                        <View style={{flex: 1}}></View>
                        <View style={{flex: 1.5}}></View>
                        <View style={[styles.totalValueCell, {flex: 1.5}]}>
                            <Text style={styles.totalSumText}>{totals.totalNetAmount.toFixed(2)}</Text>
                        </View>
                        <View style={[styles.totalValueCell, {flex: 1.5}]}>
                            <Text style={styles.totalSumText}>{totals.totalGrossAmount.toFixed(2)}</Text>
                        </View>
                        <View style={{flex: 1.5}}></View>
                        <View style={{width: 40}}></View>
                    </View>
                </View>
            )}
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 5,
        backgroundColor: '#FEE6DE',
        width: '100%',
        minHeight: height-100,
        maxHeight: height,
        paddingBottom: 50,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 15,
        padding: 10,
    },
    saveButton: {
        backgroundColor: '#4CAF50',
        paddingVertical: 12,
        paddingHorizontal: 20,
        borderRadius: 5,
        alignItems: 'center',
        minWidth: 100,
    },
    closeButton: {
        backgroundColor: '#E57373',
        paddingVertical: 12,
        paddingHorizontal: 20,
        borderRadius: 5,
        alignItems: 'center',
        minWidth: 100,
    },
    datepicker: {
        width: '100%',
        padding: 12,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#ccc',
        backgroundColor: '#fff',
        marginBottom: 10,
        fontSize: 16,
        marginHorizontal: 10,
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 16,
    },
    dropdownContainer: {
        marginBottom: 10,
        paddingHorizontal: 10,
        marginTop: 10,
        zIndex: 3000
    },
    dropdown: {
        backgroundColor: '#fff',
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#ddd',
    },
    dropdownList: {
        backgroundColor: '#fff',
        borderColor: '#ddd',
        borderWidth: 1,
    },
    input: {
        borderWidth: 1,
        borderColor: '#ccc',
        padding: 12,
        borderRadius: 5,
        backgroundColor: '#fff',
        marginBottom: 10,
        fontSize: 16,
        marginHorizontal: 10,
    },
    searchContainer: {
        marginVertical: 10,
        zIndex: 100,
    },
    addButton: {
        backgroundColor: '#ffb74d',
        padding: 15,
        borderRadius: 5,
        alignItems: 'center',
        marginHorizontal: 10,
        marginVertical: 10,
    },
    addButtonText: {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 16,
    },
    suggestionsList: {
        backgroundColor: '#fff',
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#ddd',
        maxHeight: 200,
        marginHorizontal: 10,
        zIndex: 1000,
    },
    suggestionItem: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
    },
    tableWrapper: {
        flex: 1,
        width: '100%',
        backgroundColor: '#fff',
        borderTopWidth: 1,
        borderTopColor: '#ddd',
    },
    inputContainer: {
        marginBottom: 10,
        paddingHorizontal : 10,
    },
    headerRow: {
        flexDirection: 'row',
        backgroundColor: '#f9f9f9',
        borderBottomWidth: 2,
        borderBottomColor: '#ddd',
        paddingVertical: 12,
        paddingHorizontal: 5,
    },
    headerCell: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 14,
    },
    dataRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
        paddingVertical: 10,
        paddingHorizontal: 5,
        backgroundColor: '#fff',
        alignItems: 'center',
    },
    cell: {
        padding: 5,
        justifyContent: 'center',
    },
    productName: {
        fontWeight: '500',
        fontSize: 14,
    },
    productInfo: {
        fontSize: 12,
        color: '#666',
        marginTop: 4,
    },
    numberInput: {
        borderWidth: 1,
        borderColor: '#ddd',
        borderRadius: 4,
        padding: 8,
        textAlign: 'right',
        backgroundColor: '#fff',
        minWidth: 50,
    },
    smallDropdownContainer: {
        width: '100%',
    },
    dropdownButton: {
        width: '100%',
        height: 40,
        backgroundColor: '#fff',
        borderRadius: 4,
        borderWidth: 1,
        borderColor: '#ddd',
    },
    dropdownButtonText: {
        fontSize: 14,
    },
    unitLabel: {
        fontSize: 12,
        color: '#ff9800',
        textAlign: 'center',
        marginTop: 4,
        fontWeight: 'bold',
    },
    totalText: {
        textAlign: 'right',
        fontWeight: '500',
    },
    totalRow: {
        flexDirection: 'row',
        backgroundColor: '#f0f0f0',
        paddingVertical: 15,
        paddingHorizontal: 5,
        borderTopWidth: 2,
        borderTopColor: '#ddd',
    },
    totalLabel: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    totalValueCell: {
        backgroundColor: '#e8f5e9',
        padding: 8,
        borderRadius: 4,
        justifyContent: 'center',
    },
    totalSumText: {
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: 16,
        color: '#2e7d32',
    },
    datePickerContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginVertical: 5,
        zIndex: 500,
    },
});

export default InventoryForm;