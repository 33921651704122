import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Popup } from 'react-native-popup-confirm-toast';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import { Icon } from 'react-native-elements';
import Observables from '@happy-gastro/global-observables';
import HappyAlert from '../helpers/crossPlatformTools/HappyAlert';
import { useTranslation } from "react-i18next"; // Import i18n

const SettingsPlaceChooser = ({ restaurant }) => {
    const { t } = useTranslation();
    const navigation = useNavigation();
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);

    const handlePlaceSelection = async (place) => {
        await AsyncStorage.setItem('restaurantPlace', JSON.stringify(place));
        setTimeout(() => Observables.call('fetchSystemData'), 333);
        Popup.hide();
        navigation.navigate('HomeScreen');
    };

    const confirmPlaceSelection = async () => {
        if (selectedPlace) {
            await handlePlaceSelection(selectedPlace);
        }
        setShowConfirm(false);
    };

    const onSelectPlace = (place) => {
        setSelectedPlace(place);
        setShowConfirm(true);
    };

    const bodyComponent = ({ bodyProps }) => (
        <View
            style={{
                padding: 20,
                width: '100%',
                backgroundColor: 'white',
                borderRadius: 15,
                shadowColor: '#000',
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.2,
                shadowRadius: 5,
                elevation: 5,
            }}
            onLayout={(e) => {
                if (bodyProps.onLayout) bodyProps.onLayout(e);
            }}
        >
            <Text
                style={{
                    fontSize: 18,
                    fontWeight: 'bold',
                    marginBottom: 20,
                    textAlign: 'center',
                }}
            >
                {t("select_business")}
            </Text>
            {restaurant?.places?.map((place, index) => (
                <TouchableOpacity
                    key={index}
                    style={{
                        backgroundColor: '#ffedd5',
                        borderRadius: 10,
                        padding: 18,
                        marginBottom: 15,
                        alignItems: 'center',
                        shadowColor: '#000',
                        shadowOffset: { width: 1, height: 1 },
                        shadowOpacity: 0.2,
                        shadowRadius: 3,
                        elevation: 2,
                    }}
                    onPress={() => onSelectPlace(place)}
                    activeOpacity={0.7}
                >
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Icon name="store" size={30} color="#333" style={{ marginRight: 10 }} />
                        <Text style={{ color: '#333', fontSize: 16, fontWeight: '600' }}>
                            {place.name || place.raw}
                        </Text>
                    </View>
                </TouchableOpacity>
            ))}
        </View>
    );

    useEffect(() => {
        Popup.show({
            type: 'confirm',
            useNativeDriver: true,
            label: t("select_business"),
            textBody: t("choose_business_to_continue"),
            bodyComponentForce: true,
            bodyComponent: (bodyProps) => bodyComponent({ bodyProps }),
            iconEnabled: true,
            buttonEnabled: false,
        });
    }, []);

    return (
        <>
            <HappyAlert
                visible={showConfirm}
                onConfirm={confirmPlaceSelection}
                onCancel={() => setShowConfirm(false)}
                type="info"
                message={t("confirm_select_business")}
                confirmText={t("yes")}
                cancelText={t("no")}
            />
        </>
    );
};

export default SettingsPlaceChooser;
