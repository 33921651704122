// AdminScreen.js
import React from "react";
import WebView from "react-native-webview";
import {StyleSheet, View} from "react-native";
import {LinearGradient} from "expo-linear-gradient";

export default function TermsOfServicesScreen({navigation}) {
    return (
        <LinearGradient
            colors={['#f2e2d2', '#e6d6c1', '#dcd5ce']}
            style={styles.background}
        >
            <View style={styles.container}>
                <WebView source={{uri: "https://happysolutions.hu/mobile/tos"}} style={styles.webview}/>
            </View>
        </LinearGradient>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
    webview: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
    background: {
        flex: 1,
    },
});
