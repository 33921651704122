export const paymentTypes = {
    'cash': 'Készpénz',
    'creditCard': 'Bankkártya',
    'onlineCreditCard': 'Bankkártya',
    'szepCreditCard': 'Szép kártya',
    'temporary': 'Fizetetlen',
    // Extend for more
    'voucher': 'Utalvány',
    'coupon': 'Kupon',
    'loyalty': 'Hűségpont',
    'bankTransfer': 'Banki átutalás'
};

const calcProductPrice = () => 0;

// Function to filter duplicated cart items
export const removeDuplicatedItem = (array) => [
    ...new Map(array.map((item) => [item._id || item.id, item])).values(),
];

export const convertCartItem = (cartItem, orderType = "dineIn") => {
    let sizeId = null;

    if (typeof cartItem.sizeIndex === "number") {
        sizeId = cartItem.item.sizes[cartItem.sizeIndex]._id;
    } else if (typeof cartItem.size === "number") {
        sizeId = cartItem.item.sizes[cartItem.size]._id;
    } else {
        const findSize = cartItem.item.sizes.find((s) =>
            cartItem.sizeIndex
                ? s._id === cartItem.sizeIndex
                : s._id === cartItem.size
        );
        sizeId = findSize ? findSize._id : null;
    }

    let convertedObject = {
        ...cartItem,
        size: sizeId,
        //item: cartItem.item._id,
        extras: convertExtras(cartItem),
        amount: cartItem.quantity || cartItem.selectedAmount,
        quantity: cartItem.quantity || cartItem.selectedAmount,
        price: Number(calcProductPrice(orderType, cartItem.item, sizeId)),
        remotePrice: Number(calcProductPrice(orderType, cartItem.item, sizeId)),
    };
    return {
        ...convertedObject,
    };
};

export const convertOldItem = (cartItem) => {
    let convertedObject = {
        ...cartItem,
    };
    return {
        ...convertedObject,
    };
};
export const convertOriginalItems = (cartItem) => {
    let convertedObject = {
        ...cartItem,
    };
    return {
        ...convertedObject,
    };
};

export const convertExtras = (cartItem) => {
    return (
        [...cartItem.extras].map((_) => {
            let itemFindPrice;
            let item;
            if (_.item) {
                item = _.item._id;
            } else {
                item = _;
            }
            return { item: item, price: _.sizes ? _.sizes[0].price : _.price };
        }) || []
    );
};

export const calcNewCart = (
    items,
    splitOrderBase = false,
    splitOrder = false,
    transfer = false
) => {
    if (!splitOrder) return [...items.originalItems, ...items.cartItems];

    let tmpOriginalItems = [];
    removeDuplicatedItem([...items.originalItems, ...items.cartItems]).map(
        (item) => {
            if (splitOrderBase) {
                const findSelectedItems = [...items.selectedItems].find(
                    (sI) => sI._id === item._id
                );
                tmpOriginalItems.push({
                    ...item,
                    amount: findSelectedItems
                        ? item.amount - findSelectedItems.selectedAmount
                        : item.amount,
                    quantity: findSelectedItems
                        ? item.amount - findSelectedItems.selectedAmount
                        : item.amount,
                });
            } else {
                tmpOriginalItems.push({
                    ...item,
                    amount: item.amount || item.selectedAmount,
                    quantity: item.amount || item.selectedAmount,
                });
            }
        }
    );
    return !splitOrderBase ? [...items.selectedItems] : tmpOriginalItems;
};

export const getReceiptPaymentType = (paymentType) => {
    if (paymentType === "temporary") return "Fizetendő"
    if (paymentType === "cash") return "Készpénz"
    if (paymentType === "creditCard") return "Bankkártya"
    if (paymentType === "tip") return "Borravaló"
    if (paymentType === "szepCard") return "Szép kártya"
}


export const getPaymentType = ({
                                   paymentType = "temporary",
                                   orderFrom = "",
                               }) => {
    let sendPaymentType = paymentType;

    if (paymentType === "takeAwayCreditCard") {
        sendPaymentType = "creditCard";
    }
    if (paymentType === "takeAwayCash") {
        sendPaymentType = "cash";
    }
    if (paymentType === "phoneCreditCard") {
        orderFrom = "phone";
        sendPaymentType = "creditCard";
    }
    if (paymentType === "phoneCash") {
        orderFrom = "phone";
        sendPaymentType = "cash";
    }
    if (paymentType === "webshopCreditCard") {
        orderFrom = "webshop";
        sendPaymentType = "creditCard";
    }
    if (paymentType === "webshopCash") {
        orderFrom = "webshop";
        sendPaymentType = "cash";
    }
    if (paymentType === "orditCreditCard") {
        orderFrom = "ordit";
        sendPaymentType = "creditCard";
    }
    if (paymentType === "boltCreditCard") {
        orderFrom = "bolt";
        sendPaymentType = "creditCard";
    }
    if (paymentType === "woltCreditCard") {
        orderFrom = "wolt";
        sendPaymentType = "creditCard";
    }
    if (paymentType === "netpincerCreditCard") {
        orderFrom = "netpincer";
        sendPaymentType = "creditCard";
    }
    if (paymentType === "netpincerCash") {
        orderFrom = "netpincer";
        sendPaymentType = "cash";
    }
    if (paymentType === "woltCreditCard") {
        orderFrom = "wolt";
        sendPaymentType = "creditCard";
    }
    if (paymentType === "falatozzCreditCard") {
        orderFrom = "falatozz";
        sendPaymentType = "creditCard";
    }
    if (paymentType === "falatozzCash") {
        orderFrom = "falatozz";
        sendPaymentType = "cash";
    }
    if (paymentType === "cash") {
        sendPaymentType = "cash";
    }
    if (paymentType === "creditCard") {
        sendPaymentType = "creditCard";
    }
    return { paymentType: sendPaymentType, orderFrom: orderFrom };
};

export const calcVatPercentages = (items, order = {}, longText = false) => {
    let netSum = {},
        vatSum = {},
        grossSum = {},
        serviceFees = 0;

    const type = order.orderType || "dineIn";

    items.forEach((cartItem) => {
        const extrasPrice = cartItem.extras.reduce(
            (a, b) => +a + (b.sizes ? b.sizes[0].price : b.price),
            0
        );
        let itemFullPrice =
            ((cartItem.remotePrice ||
                    Number(
                        calcProductPrice(
                            type,
                            cartItem.item,
                            (cartItem.sizeIndex || cartItem.size
                                ? cartItem.size
                                : cartItem.item.sizes[cartItem.sizeIndex || cartItem.size]._id) ||
                            cartItem.item.sizes[0]._id,
                            order.placeId
                        )
                    )) +
                Number(extrasPrice)) *
            Number(cartItem.amount || cartItem.quantity);

        let discountPercentage = Number(
            (order.discount &&
                order.discount.percentage &&
                order.currentOrder.discount.percentage) ||
            0
        );
        if (discountPercentage > 0) {
            itemFullPrice =
                Number(itemFullPrice) -
                Number((Number(itemFullPrice) / 100) * Number(discountPercentage));
        }

        if (!netSum[cartItem.item.vat[type]]) netSum[cartItem.item.vat[type]] = 0;
        const net = itemFullPrice / (Number(cartItem.item.vat[type]) / 100 + 1);
        netSum[cartItem.item.vat[type]] += net;

        if (!vatSum[cartItem.item.vat[type]]) vatSum[cartItem.item.vat[type]] = 0;
        vatSum[cartItem.item.vat[type]] += itemFullPrice - net;

        if (!grossSum[cartItem.item.vat[type]])
            grossSum[cartItem.item.vat[type]] = 0;
        grossSum[cartItem.item.vat[type]] += itemFullPrice;

        // For ServiceFee
        if (order.serviceFee) {
            const productServiceFee = Number(
                (itemFullPrice / 100) * order.serviceFee
            );
            serviceFees += productServiceFee;

            if (!netSum[cartItem.item.vat[(longText ? "Szervízdíj " : "Sz") + type]])
                netSum[(longText ? "Szervízdíj " : "Sz") + cartItem.item.vat[type]] = 0;
            const serviceFeeNet =
                productServiceFee / (Number(cartItem.item.vat[type]) / 100 + 1);
            netSum[(longText ? "Szervízdíj " : "Sz") + cartItem.item.vat[type]] +=
                serviceFeeNet;

            if (!vatSum[(longText ? "Szervízdíj " : "Sz") + cartItem.item.vat[type]])
                vatSum[(longText ? "Szervízdíj " : "Sz") + cartItem.item.vat[type]] = 0;
            vatSum[(longText ? "Szervízdíj " : "Sz") + cartItem.item.vat[type]] +=
                productServiceFee - serviceFeeNet;

            if (
                !grossSum[(longText ? "Szervízdíj " : "Sz") + cartItem.item.vat[type]]
            )
                grossSum[
                (longText ? "Szervízdíj " : "Sz") + cartItem.item.vat[type]
                    ] = 0;
            grossSum[(longText ? "Szervízdíj " : "Sz") + cartItem.item.vat[type]] +=
                productServiceFee;
        }
    });
    return {
        vat: vatSum,
        net: netSum,
        gross: grossSum,
    };
};