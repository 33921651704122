import React, { useContext } from 'react';
import { Alert, Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import { CartContext } from './CartContext';
import {TableContext} from "./TableContext";
import {OnlineOrderContext} from "./OnlineOrderContext";

const useConfirmNavigation = (navigation) => {
    const { t } = useTranslation();
    const { deleteCart, setAlreadySentCartItems } = useContext(CartContext);
    const { resetOnlineOrder } = useContext(OnlineOrderContext);
    const { removeTable } = useContext(TableContext);

    const confirmNavigation = (screen) => {
        if (Platform.OS === 'web') {
            if (window.confirm(t('delete_cart_confirm'))) {
                deleteCart();
                setAlreadySentCartItems([]);
                removeTable();
                resetOnlineOrder()
                navigation.navigate(screen);
            }
        } else {
            Alert.alert(
                t('attention'),
                t('delete_cart_confirm'),
                [
                    {
                        text: t('no'),
                        onPress: () => {},
                        style: 'cancel',
                    },
                    {
                        text: t('yes'),
                        onPress: () => {
                            deleteCart();
                            navigation.navigate(screen);
                        },
                    },
                ],
                { cancelable: false }
            );
        }
    };

    return confirmNavigation;
};

export default useConfirmNavigation;