import React, { useState, useEffect } from 'react';
import {
    SafeAreaView,
    ScrollView,
    StatusBar,
    StyleSheet,
    Text,
    TextInput,
    TouchableOpacity,
    View
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { LinearGradient } from 'expo-linear-gradient';

export default function NovoTerminalSettingsScreen({ navigation }) {
    const [ipAddress, setIpAddress] = useState('');
    const [portNumber, setPortNumber] = useState('');
    const [timeout, setTimeout] = useState('');

    useEffect(() => {
        const loadSettings = async () => {
            const storedSettings = await AsyncStorage.getItem('novoSettings');
            if (storedSettings) {
                const { novoIpAddress, novoPortNumber, novoTimeout } = JSON.parse(storedSettings);
                setIpAddress(novoIpAddress);
                setPortNumber(novoPortNumber);
                setTimeout(novoTimeout);
            }
        };
        loadSettings();
    }, []);

    const handleSave = async () => {
        const settings = {
            novoIpAddress: ipAddress,
            novoPortNumber: portNumber,
            novoTimeout: timeout
        };
        await AsyncStorage.setItem('novoSettings', JSON.stringify(settings));
        navigation.goBack();
    };

    return (
        <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={{ flex: 1, width: '100%', height: '100%' }}
        >
            <SafeAreaView style={{ flex: 1, backgroundColor: 'transparent', paddingTop: 10 }}>
                <StatusBar backgroundColor="transparent" translucent barStyle="dark-content" />
                <ScrollView contentContainerStyle={styles.content}>
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Novo Payment Device Settings</Text>
                        <View style={styles.sectionBody}>
                            <TextInput
                                style={styles.input}
                                placeholder="IP Address"
                                value={ipAddress}
                                onChangeText={setIpAddress}
                            />
                            <TextInput
                                style={styles.input}
                                placeholder="Port Number"
                                value={portNumber}
                                onChangeText={setPortNumber}
                                keyboardType="numeric"
                            />
                            <TextInput
                                style={styles.input}
                                placeholder="Timeout"
                                value={timeout}
                                onChangeText={setTimeout}
                                keyboardType="numeric"
                            />
                            <TouchableOpacity style={styles.button} onPress={handleSave}>
                                <Text style={styles.buttonText}>Save</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </ScrollView>
            </SafeAreaView>
        </LinearGradient>
    );
}

const styles = StyleSheet.create({
    content: {
        paddingHorizontal: 16,
        backgroundColor: 'transparent'
    },
    section: {
        paddingVertical: 12,
    },
    sectionTitle: {
        margin: 8,
        marginLeft: 12,
        fontSize: 13,
        letterSpacing: 0.33,
        fontWeight: '500',
        color: '#a69f9f',
        textTransform: 'uppercase',
    },
    sectionBody: {
        borderRadius: 12,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.5,
        shadowRadius: 1.41,
        elevation: 3,
        backgroundColor: '#fff',
        padding: 16,
    },
    input: {
        height: 40,
        borderColor: '#ccc',
        borderWidth: 1,
        borderRadius: 8,
        marginBottom: 12,
        paddingHorizontal: 8,
    },
    button: {
        backgroundColor: '#007BFF',
        padding: 12,
        borderRadius: 8,
        alignItems: 'center',
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: '600',
    },
});