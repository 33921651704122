import React, { useContext, useEffect, useState, useRef } from 'react';
import { Platform } from 'react-native';
import { OrdersContext } from "./OrdersContext";
import { CurrentScreenContext } from "./CurrentScreenContext";

const createSoundPlayer = () => {
  if (Platform.OS === 'android') {
    try {
      // React Native Sound for Android
      const Sound = require('react-native-sound');
      Sound.setCategory('Playback');

      return {
        play: (soundPath, volume = 1.0) => {
          return new Promise((resolve, reject) => {
            const sound = new Sound(soundPath, (error) => {
              if (error) {
                console.error('Failed to load the sound', error);
                reject(error);
                return;
              }

              sound.setVolume(volume);
              sound.play((success) => {
                if (!success) {
                  console.error('Sound did not play successfully');
                }
                resolve(sound);
              });
            });
          });
        },
        stop: (soundInstance) => {
          if (soundInstance) {
            soundInstance.stop();
            soundInstance.release();
          }
        }
      };
    } catch (error) {
      console.warn('Failed to load react-native-sound', error);
      // Fallback to web audio if sound library fails
      return createWebSoundPlayer();
    }
  } else {
    // Web Audio API for web and other platforms
    return createWebSoundPlayer();
  }
};

// Web Audio API implementation
const createWebSoundPlayer = () => {
  return {
    play: (soundPath, volume = 1.0) => {
      return new Promise((resolve, reject) => {
        // Create audio context
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        const audioContext = new AudioContext();

        // Fetch the audio file
        fetch(soundPath)
            .then(response => response.arrayBuffer())
            .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
            .then(audioBuffer => {
              // Create source
              const source = audioContext.createBufferSource();
              source.buffer = audioBuffer;

              // Create gain node for volume control
              const gainNode = audioContext.createGain();
              gainNode.gain.setValueAtTime(volume, audioContext.currentTime);

              // Connect nodes
              source.connect(gainNode);
              gainNode.connect(audioContext.destination);

              // Play the sound
              source.start(0);

              // Handle sound completion
              source.onended = () => {
                resolve(source);
              };

              // Return the source for potential stopping
              resolve({
                source: source,
                context: audioContext
              });
            })
            .catch(error => {
              console.error('Error playing sound:', error);
              reject(error);
            });
      });
    },
    stop: (audioInstance) => {
      if (audioInstance && audioInstance.source) {
        try {
          audioInstance.source.stop();
          if (audioInstance.context) {
            audioInstance.context.close();
          }
        } catch (error) {
          console.error('Error stopping sound:', error);
        }
      }
    }
  };
};

const OrderNotificationService = () => {
  const { orders } = useContext(OrdersContext);
  const [pendingOrders, setPendingOrders] = useState([]);
  const soundPlayerRef = useRef(null);
  const intervalRef = useRef(null);
  const { currentScreen } = useContext(CurrentScreenContext);

  // Notification sound options
  const notificationSounds = Platform.select({
    android: {
      Default: require('../../public/sounds/default.mp3'),
      Alarm: require('../../public/sounds/alarm.mp3'),
      Clock: require('../../public/sounds/clock.mp3'),
      Ringtone:  require('../../public/sounds/ringtone.mp3'),
      SciFi: require('../../public/sounds/sci-fi.wav'),
      Doorbell: require('../../public/sounds/doorbell.mp3'),
    },
    web: {
      Default: require('../../public/sounds/default.mp3'),
      Alarm: require('../../public/sounds/alarm.mp3'),
      Clock: require('../../public/sounds/clock.mp3'),
      Ringtone:  require('../../public/sounds/ringtone.mp3'),
      SciFi: require('../../public/sounds/sci-fi.wav'),
      Doorbell: require('../../public/sounds/doorbell.mp3'),
    },
    default: {
      Default: require('../../public/sounds/default.mp3'),
      Alarm: require('../../public/sounds/alarm.mp3'),
      Clock: require('../../public/sounds/clock.mp3'),
      Ringtone: require('../../public/sounds/ringtone.mp3'),
      SciFi: require('../../public/sounds/sci-fi.wav'),
      Doorbell: require('../../public/sounds/doorbell.mp3'),
    }
  });

  // Initialize sound player
  const soundPlayer = useRef(createSoundPlayer()).current;

  // Update pending orders
  useEffect(() => {
    if (orders?.length > 0) {
      setPendingOrders(orders.filter(order => (order.status === "pending" && !order.storno)) || []);
    }
  }, [orders]);

  // Function to play sound
  const playNotificationSound = async () => {
    if (currentScreen === 'OrderDetailsScreen') {
      console.log("Sound suppressed on OrderDetailsScreen");
      return;
    }
    try {
      // Stop previous sound if playing
      if (soundPlayerRef.current) {
        soundPlayer.stop(soundPlayerRef.current);
      }

      // Play new sound
      soundPlayerRef.current = await soundPlayer.play(
          notificationSounds.Default,
          1.0
      );
    } catch (error) {
      console.error('Error playing sound:', error);
    }
  };

  // Sound interval logic
  useEffect(() => {
    // Clear any existing interval
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    if (pendingOrders.length > 0) {
      // Play sound immediately
      playNotificationSound();

      // Set up interval to repeat sound
      intervalRef.current = setInterval(playNotificationSound, 7500);
    }

    // Cleanup function
    return () => {
      // Clear interval
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      // Release sound resource
      if (soundPlayerRef.current) {
        soundPlayer.stop(soundPlayerRef.current);
      }
    };
  }, [pendingOrders]);

  // Return null as this is a background service
  return null;
};

export default OrderNotificationService;