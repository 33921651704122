import React, {useContext} from 'react';
import {ScrollView, StyleSheet, Text, TouchableOpacity, View, Alert, Modal} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import IonIcons from 'react-native-vector-icons/Ionicons';
import { LinearGradient } from "expo-linear-gradient";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { FontAwesome, FontAwesome5, MaterialIcons } from "@expo/vector-icons";
import { useTranslation } from 'react-i18next';
import '../../i18n';
import InventoryForm from "../components/InventoryForm";
import TodoList from "../components/TodoList";
// import InventoryTable from "../components/InventoryTable";
import InventoryModal from "../components/InventoryModal";
import DateTimeModal from "../components/DateTimeModal";
import StandModal from "../components/StandModal";

const FunctionsScreen = (props) => {
    const { navigation } = props;
    const { t } = useTranslation();
    const [inventoryModalVisible, setInventoryModalVisible] = React.useState(false);
    const [todoModalVisible, setTodoModalVisible] = React.useState(false);
    const [showInventoryTable, setShowInventoryTable] = React.useState(false); // State for InventoryTable visibility
    const [showStand, setShowStand] = React.useState(false);
    const [showdatemodal, setShowDateModal] = React.useState(false);
    const toggleTodoModal = () => {
        setTodoModalVisible(!todoModalVisible);
    };
    const [isFiscal, setIsFiscal] = React.useState(false);


    React.useEffect(() => {
        const getIsFiscal = async () => setIsFiscal(await AsyncStorage.getItem('selectedCashierDevice'))
        getIsFiscal();

        return () => setIsFiscal(false);
    }, []);

    const toggleModal = () => {
        setInventoryModalVisible(!inventoryModalVisible);
    };
    return (
        <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={{ flex: 1, width: '100%', height: '100%' }}
        >
            <ScrollView style={{ paddingBottom: 50 }}>
                <View style={styles.container}>
                    <View style={{ width: "100%", marginTop: 30, padding: 15 }}>
                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Text style={{ fontWeight: "bold", textAlign: "left", fontSize: 17 }}>
                                {t('daily_reports_and_cash_register_functions')}
                            </Text>
                            <TouchableOpacity activeOpacity={0.5} onPress={() => navigation.navigate("SettingsScreen")}>
                                <IonIcons name={"settings"} color={"#000"} size={30} style={{ marginTop: 10 }} />
                            </TouchableOpacity>
                        </View>
                        <Text style={{ textAlign: "left", marginTop: 15, borderRadius: 10 }}>
                            {t('daily_reports_description')}
                        </Text>
                    </View>
                    <View style={{ width: "100%", marginTop: 20, paddingHorizontal: 15 }}>
                        <Text style={{ fontWeight: "bold", textAlign: "left", fontSize: 17 }}>
                            {t('traffic_report')}
                        </Text>
                        <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 15, padding: 20 }}>

                            <TouchableOpacity activeOpacity={0.7} style={[styles.box, { width: '49%' }]} onPress={() => navigation.navigate("StatisticsScreen")}>
                                <View style={[styles.boxInner, { flexDirection: 'row' }]}>
                                    <Icon name="chart-line" size={30} color="#000" style={{ marginRight: 30 }} />
                                    <Text style={[styles.boxTitle, { marginTop: 0 }]}>{t('daily_traffic')}</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity activeOpacity={0.7} style={[styles.box, { width: '49%', backgroundColor: '#f1b44c', padding: 20 }]} onPress={() => navigation.navigate("DayClosingScreen")}>
                                <View style={[styles.boxInner, { flexDirection: 'row' }]}>
                                    <Icon name="lock" size={30} color="#fff" style={{ marginRight: 30 }} />
                                    <Text style={[styles.boxTitle, { marginTop: 0, color: 'white' }]}>{t('financial_day_closing')}</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    </View>
                    {isFiscal && <View style={{ width: "100%", marginTop: 20, paddingHorizontal: 15 }}>
                        <Text style={{ fontWeight: "bold", textAlign: "left", fontSize: 17 }}>
                            {t('cash_register_functions')}
                        </Text>
                        <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 15 }}>
                            <TouchableOpacity activeOpacity={1} style={styles.box}>
                                <View style={styles.boxInner}>
                                    <Icon name="feed" size={30} color="#000" style={{ marginRight: 15 }} />
                                    <Text style={styles.boxTitle}>{t('status_query')}</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity activeOpacity={1} style={styles.box}>
                                <View style={styles.boxInner}>
                                    <Icon name="chart-line" size={30} color="#000" style={{ marginRight: 15 }} />
                                    <Text style={styles.boxTitle}>{t('daily_query')}</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.box}>
                                <View style={styles.boxInner}>
                                    <MaterialIcons name="table" size={30} color="#000" style={{ marginRight: 15 }} />
                                    <Text style={styles.boxTitle}>{t('periodic_query')}</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 15 }}>
                            <TouchableOpacity activeOpacity={1} style={styles.box}>
                                <View style={styles.boxInner}>
                                    <MaterialIcons name="attach-money" size={30} color="#000" style={{ marginRight: 15 }} />
                                    <Text style={styles.boxTitle}>{t('money_in')}</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity activeOpacity={1} style={styles.box}>
                                <View style={styles.boxInner}>
                                    <MaterialIcons name="money-off" size={30} color="#000" style={{ marginRight: 15 }} />
                                    <Text style={styles.boxTitle}>{t('money_out')}</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity activeOpacity={0.7} style={styles.box}>
                                <View style={styles.boxInner}>
                                    <MaterialCommunityIcons name="bottle-soda-classic-outline" size={30} color="#000" style={{ marginRight: 15 }} />
                                    <Text style={styles.boxTitle}>{t('deposit')}</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    </View>}
                    <View style={{ width: "100%", marginTop: 20, paddingHorizontal: 15 }}>
                        <Text style={{ fontWeight: "bold", textAlign: "left", fontSize: 17 }}>
                            {t('tasks')}
                        </Text>
                        <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 15 }}>
                            <TouchableOpacity activeOpacity={0.7} style={styles.box} onPress={() => navigation.navigate("PendingOrdersScreen")}>
                                <View style={styles.boxInner}>
                                    <MaterialIcons name="dynamic-feed" size={30} color="#000" style={{ marginRight: 15 }} />
                                    <Text style={styles.boxTitle}>{t('pre_orders')}</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={toggleTodoModal} activeOpacity={0.7} style={styles.box}>
                                <View style={styles.boxInner}>
                                    <FontAwesome name="list-ul" size={30} color="#000" style={{ marginRight: 15 }} />
                                    <Text style={styles.boxTitle}>{t('to_do')}</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity activeOpacity={0.7} style={styles.box} onPress={() => navigation.navigate("TablesBookingsScreen")}>
                                <FontAwesome name="calendar-check-o" size={30} color="#000" style={{ marginRight: 15 }} />
                                <Text style={styles.boxTitle}>{t('bookings')}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={{ width: "100%", marginTop: 20, paddingHorizontal: 15 }}>
                        <Text style={{ fontWeight: "bold", textAlign: "left", fontSize: 17 }}>
                            {t('warehouse_operations')}
                        </Text>
                        <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 15 }}>
                            <TouchableOpacity activeOpacity={0.7} onPress={()=> setShowStand(true)} style={styles.box} >
                                <View style={styles.boxInner}>
                                    <Icon name="book" size={30} color="#000" style={{ marginRight: 15 }} />
                                    <Text style={styles.boxTitle}>{t('inventory')}</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity activeOpacity={0.7} style={styles.box} onPress={toggleModal}>
                                <View style={styles.boxInner}>
                                    <FontAwesome5 name="file-invoice" size={30} color="#000" style={{ marginRight: 15 }} />
                                    <Text style={styles.boxTitle}>{t('receiving')}</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity  activeOpacity={0.7} style={styles.box} onPress={() => {
                                setShowInventoryTable(true);
                            }}>
                                <View style={styles.boxInner}>
                                    <MaterialCommunityIcons name="chart-bell-curve" onPress={()=>setShowDateModal(true)} size={30} color="#000" style={{ marginRight: 15 }} />
                                    <Text style={styles.boxTitle}>{t('consumable_stock')}</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={{ width: "100%", marginTop: 20, paddingHorizontal: 15 }}>
                        <Text style={{ fontWeight: "bold", textAlign: "left", fontSize: 17 }}>
                            {t('expenses')}
                        </Text>
                        <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 15 }}>
                            <TouchableOpacity activeOpacity={0.7} style={styles.box} onPress={() => navigation.navigate("ExpensesScreen")}>
                                <View style={styles.boxInner}>
                                    <MaterialIcons name="currency-exchange" size={30} color="#000" style={{ marginRight: 15 }} />
                                    <Text style={styles.boxTitle}>{t('expenses')}</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </ScrollView>
            {inventoryModalVisible && <Modal
                animationType="slide"
                transparent={true}
                visible={inventoryModalVisible}
                onRequestClose={toggleModal}
            >
                <View style={{flex: 1}}>
                    <View style={styles.modalContent}>
                        <InventoryForm onClose={() => setInventoryModalVisible(false)}/>
                    </View>
                </View>
            </Modal>}
            {showStand && <StandModal isVisible={showStand}
                                      saveInventory={console.log}
                                      onClose={() => setShowStand(false)}              />}
            {todoModalVisible && <TodoList visible={todoModalVisible} onClose={toggleTodoModal} />}
            {showdatemodal && <DateTimeModal
                isVisible={showdatemodal}
                setDate={console.log}
                setTime={console.log}
                isTime={true}
                onClose={() => setShowDateModal(false)}
            />}
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    subtitle: {
        fontSize: 16,
        marginBottom: 40,
    },
    boxInner: {
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'row',
    },
    box: {
        flexDirection: 'row',
        width: "30%",
        padding: 10,
        backgroundColor: "#fff",
        borderRadius: 10,
        alignItems: "center",
        justifyContent: "center",
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
        elevation: 3,
    },
    boxTitle: {
        marginTop: 0,
        fontSize: 14,
        fontWeight: "bold",
        textAlign: "center",
    }
});

export default FunctionsScreen;