import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Alert,
    Dimensions, FlatList,
    Linking, Modal,
    Platform, ScrollView,
    StyleSheet,
    Switch,
    Text,
    TextInput,
    ToastAndroid,
    TouchableOpacity,
    View
} from 'react-native';
import { LinearGradient } from "expo-linear-gradient";
import Feather from 'react-native-vector-icons/Feather';
import Spinner from "react-native-loading-spinner-overlay";
import { priceFormatter } from "../helpers/numbers";
import VivaPaymentModule from "../paymentModules/VivaPaymentsModule";
import { CartContext } from "../helpers/CartContext";
import { BluetoothContext } from "../helpers/BluetoothContext";
import { OrdersContext } from "../helpers/OrdersContext";
import { handlePayment, send_storeOrder } from "../helpers/paymentHelper";
import Icon from "react-native-vector-icons/Feather";
import VatModal from "../components/VatModal";
import {TableContext} from "../helpers/TableContext";
import { hungarianRoundNumbers } from '../helpers/hungarianRoundNumbers';
import PinPad from "../components/PinPad";
import {HappyDialog} from "../helpers/crossPlatformTools/dialogs";
import {VipCardContext} from "../helpers/VipCardContext";
import {useTranslation} from "react-i18next";
import ItemComponent from "../components/ItemComponent";
import Observables from "@happy-gastro/global-observables";
import {RawBTPrintKitchen, RawBTPrintTicket} from "../helpers/rawBTDriver";

const { width, height } = Dimensions.get('window');

const PaymentScreen = ({ navigation, setIsModalVisible }) => {
    const { cartTotal = 0, setCart, cartItems = [], originalCartTotal, productsPrice, discountsForWholeCart, setCartItems, serviceFeeAmount, cartTotalWithServiceFee, alreadySentCartItems, serviceFeePercentage ,setAlreadySentCartItems} = useContext(CartContext);
    const { currentTable } = useContext(TableContext);
    const { selectedVipCard, removeVipCard, selectedEmployee, removeEmployee } = useContext(VipCardContext);
    const { sendData } = useContext(BluetoothContext);
    const [processing, setProcessing] = useState(false);
    const [needInvoice, setNeedInvoice] = useState(false);
    const [paymentType, setPaymentType] = useState(false);
    const [originalAmount, setOriginalAmount] = useState(cartTotal || 0);
    const [amount, setAmount] = useState(cartTotal || 0);
    const [paidAmount, setPaidAmount] = useState(0);
    const [amountToPay, setAmountToPay] = useState((originalAmount));
    const [changeToReturn, setChangeToReturn] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [vatInvoiceData, setVatInvoiceData] = useState(false);
    const [isSplitOrder, setIsSplitOrder] = useState(true);
    const [payments, setPayments] = useState([]);
    const [paymentsSum, setPaymentsSum] = useState(0);
    const {orders, } = useContext(OrdersContext);
    const [showSplitItems, setShowSplitItems] = useState(false);
    const [paymentsModal, setPaymentsModal] = useState(false);
    const [selectedItemsToPay, setSelectedItemsToPay] = useState([]);
    const { t } = useTranslation();
    const [combinedCartItems, setCombinedCartItems] = useState([...alreadySentCartItems.map(item => ({ ...item, canBeModified: false })), ...cartItems.map(item => ({ ...item, canBeModified: true })).reverse()] || []);
    const [selectedItemsSum, setSelectedItemsSum] = useState(0);
    const textInputRef = useRef(null);

    useEffect(() => {
        setCombinedCartItems([...alreadySentCartItems.map(item => ({ ...item, canBeModified: false })), ...cartItems.map(item => ({ ...item, canBeModified: true })).reverse()])
    },[alreadySentCartItems, cartItems])

    useEffect(() => {
        setSelectedItemsSum(selectedItemsToPay?.length > 0 ? Math.round(selectedItemsToPay.reduce((sum, item) => sum + (item.remotePrice || item.price), 0)) : 0);
    },[selectedItemsToPay])


    useEffect(() => {
        if(currentTable){
            const tableOrder = orders?.find(
                (order) =>
                    !order.storno &&
                    order.status !== "cancelled" &&
                    order.status !== "closed" &&
                    order.tableId === currentTable?._id
            );
            if(tableOrder?.items) {
                setAlreadySentCartItems(tableOrder?.items)
            }
        }

    },[orders])


    const toggleModal = () => {
        setPaymentsModal(!paymentsModal);
    };
    useEffect(() => {
        setPaymentsSum(payments.reduce((acc, payment) => acc + payment.amount, 0))
    },[payments])
    // useEffect(() => {
    //     console.log("cartTotal,",cartTotal)
    //     console.log("originalAmount,",originalAmount)
    //     console.log("cartTotalWithServiceFee,",cartTotalWithServiceFee)
    // },[cartTotal, originalAmount, cartTotalWithServiceFee])

    useEffect(() => {
        if (currentTable){
            if(paymentType === 'Készpénz' || paymentType === 'cash') {
                if(selectedItemsToPay?.length > 0){
                    const serviceFee = Number(serviceFeePercentage);
                    if (!isNaN(serviceFee)) {
                        setOriginalAmount(hungarianRoundNumbers((selectedItemsSum * (serviceFee)) + selectedItemsSum));
                        setAmount(hungarianRoundNumbers(selectedItemsSum * serviceFee + selectedItemsSum - paymentsSum));
                    } else {
                        console.error("Invalid serviceFeePercentage:", serviceFeePercentage);
                        setAmount(hungarianRoundNumbers(selectedItemsSum -paymentsSum));
                    }
                }else{
                    const roundedAmount = hungarianRoundNumbers(cartTotalWithServiceFee);
                    setOriginalAmount(roundedAmount);
                    setAmount(roundedAmount - paymentsSum);
                }
            }else{
                if(selectedItemsToPay?.length > 0){
                    const serviceFee = Number(serviceFeePercentage);
                    setOriginalAmount(Math.round((selectedItemsSum * serviceFee) + selectedItemsSum));
                    setAmount(Math.round(Number(selectedItemsSum) * (Number(serviceFeePercentage))+ Number(selectedItemsSum) - paymentsSum));
                }
                else{
                    setOriginalAmount(cartTotalWithServiceFee);
                    setAmount(cartTotalWithServiceFee - paymentsSum);
                }
            }
        }
    }, [currentTable, cartTotalWithServiceFee, selectedItemsToPay, selectedItemsSum]);

    const renderItem = ({ item }) => (
        <ItemComponent
            item={item}
            selectedItemsToPay={selectedItemsToPay}
            setSelectedItemsToPay={setSelectedItemsToPay}
            priceFormatter={priceFormatter}
            styles={styles}
        />
    );

    function translateDisplay(text){
        const {t} = useTranslation();
        if(text === "Készpénz") return t("cash");
        if(text === "Bankkártya") return t("credit_card");
        if(text === "Szép kártya") return t("Szép kártya");
        if(text === "Utalvány") return t("voucher");
        if(text === "VIP Számla") return t("vip_balance");
        if(text === "Személyzeti") return t("employee_balance");
    }
    const getPaymentOptions = () => {

        if (selectedVipCard){
            return [
                { type: 'Készpénz', icon: 'dollar-sign' },
                { type: 'Bankkártya', icon: 'credit-card' },
                { type: 'Szép kártya', icon: 'credit-card' },
                { type: 'Utalvány', icon: 'tag' },
                { type: 'VIP Számla', icon: 'star' } , // Add VIP Balance payment type
            ]
        }else if (selectedEmployee){
            return [
                { type: 'Készpénz', icon: 'dollar-sign' },
                { type: 'Bankkártya', icon: 'credit-card' },
                { type: 'Szép kártya', icon: 'credit-card' },
                { type: 'Utalvány', icon: 'tag' },
                { type: 'Személyzeti', icon: 'star' } , // Add employee Balance payment type
            ]
        }else{
            return [
                { type: 'Készpénz', icon: 'dollar-sign' },
                { type: 'Bankkártya', icon: 'credit-card' },
                { type: 'Szép kártya', icon: 'credit-card' },
                { type: 'Utalvány', icon: 'tag' },
            ]
        }
    }

    const formatAmountWithSpaces = (value) => {
        if (!value) return '';
        if ((typeof value === 'number' && value < 0)) return '';

        // Round the value to 2 decimal places
        const roundedValue = Math.round(value * 100) / 100;

        // Split number into integer and decimal parts
        const [integerPart, decimalPart] = roundedValue.toString().split('.');

        // Add spaces for thousands in the integer part
        const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

        // Ensure the decimal part has 2 digits
        const formattedDecimal = decimalPart ? decimalPart.padEnd(2, '0') : '00';

        // Return formatted value without decimal part if both decimal digits are zero
        return formattedDecimal === '00' ? formattedInteger : `${formattedInteger}.${formattedDecimal}`;
    };


    const reverseConvertPaymentType = (paymentType) => {
        if (paymentType === "cash") return "Készpénz";
        if (paymentType === "creditCard") return "Bankkártya";
        if (paymentType === "szepCreditCard") return "Szép kártya";
        if (paymentType === "voucher") return "Utalvány";
        if (paymentType === "vipBalance") return "VIP egyenleg";
        if (paymentType === "employeeBalance") return "Személyzeti";
        return `${paymentType}`;
    }

    const confirmPaymentAmount = async (isTip) => {
        const totalPaidAmount = isSplitOrder ? paymentsSum : paidAmount;
        const amountToBePaid = originalAmount;
        const surplus = totalPaidAmount - amountToBePaid;
        const tableOrder = orders?.find(
            (order) =>
                !order.storno &&
                order.status !== "cancelled" &&
                order.status !== "closed" &&
                order.tableId === currentTable?._id
        );
        if (totalPaidAmount > amountToBePaid) {
            const tip = (paymentType === 'Bankkártya' || paymentType === 'creditCard' || paymentType === 'szepCreditCard' || paymentType === 'Szép kártya') ? surplus : hungarianRoundNumbers(surplus);

            if (isTip) {
                //Todo: test if works as expected
                //Todo: test with creditcard if works as expected
                if(selectedItemsToPay?.length > 0){
                    //split
                    //Split payment with not exceeding payment
                    const filteredItemsArrayForSplitOrder = selectedItemsToPay ? alreadySentCartItems.map(item => {
                        const matchingItems = selectedItemsToPay.filter(selected => selected._id === item._id && selected.size === item.size);
                        if (matchingItems.length > 0) {
                            const totalAmountToDecrease = matchingItems.reduce((sum, selectedItem) => sum + 1, 0);
                            return {
                                ...item,
                                amount: Math.max((item.amount || 0) - totalAmountToDecrease, 0),
                                quantity: Math.max((item.quantity || 0) - totalAmountToDecrease, 0)
                            };
                        }
                        return item;
                    }) : alreadySentCartItems;

                    //selectedItems payment
                    const firstPayment = await handlePayment(paymentType, paidAmount, amountToBePaid, setProcessing, navigation, cartTotal, setCart, cartItems, sendData, () => {/*onClose*/
                    }, originalCartTotal, productsPrice, discountsForWholeCart, vatInvoiceData, needInvoice, false, currentTable, serviceFeeAmount, selectedItemsToPay, undefined, payments, tip, (selectedVipCard ? selectedVipCard : selectedEmployee),);

                    //order update.
                    await send_storeOrder(cartTotal, cartItems, paymentType, paidAmount, discountsForWholeCart, vatInvoiceData, needInvoice, currentTable, serviceFeeAmount, true, filteredItemsArrayForSplitOrder ,tableOrder._id,payments,tip,originalAmount,(selectedVipCard ? selectedVipCard : selectedEmployee),undefined,selectedItemsToPay,tableOrder);
                    Observables.call("reloadOrders");
                }else{

                    return handlePayment(paymentType, paidAmount, amountToBePaid, setProcessing, navigation, cartTotal, setCart, cartItems, sendData, () => {/*onClose*/}, originalCartTotal, productsPrice, discountsForWholeCart, vatInvoiceData, needInvoice, false, currentTable, serviceFeeAmount, alreadySentCartItems, tableOrder?._id, payments, tip, (selectedVipCard ? selectedVipCard : selectedEmployee));
                }
            }else{
                //Todo: test if works as expected
                //Todo: test with creditcard if works as expected
                //this happens when the user clicks on the "Visszajáró" button
                if(selectedItemsToPay?.length > 0){
                    //split
                    //selectedItems payment
                    const firstPayment = await handlePayment(paymentType, amountToBePaid, amountToBePaid, setProcessing, navigation, cartTotal, setCart, cartItems, sendData, () => {/*onClose*/
                    }, originalCartTotal, productsPrice, discountsForWholeCart, vatInvoiceData, needInvoice, false, currentTable, serviceFeeAmount, selectedItemsToPay, undefined, payments, 0, (selectedVipCard ? selectedVipCard : selectedEmployee),);

                    //order update.
                    await send_storeOrder(cartTotal, cartItems, paymentType, paidAmount, discountsForWholeCart, vatInvoiceData, needInvoice, currentTable, serviceFeeAmount, true, filteredItemsArrayForSplitOrder ,tableOrder._id,payments,0,originalAmount,(selectedVipCard ? selectedVipCard : selectedEmployee),undefined,selectedItemsToPay,tableOrder);
                    Observables.call("reloadOrders");
                     }else {
                    return handlePayment(paymentType, amountToBePaid, amountToBePaid, setProcessing, navigation, cartTotal, setCart, cartItems, sendData, () => {/*onClose*/
                    }, originalCartTotal, productsPrice, discountsForWholeCart, vatInvoiceData, needInvoice, false, currentTable, serviceFeeAmount, alreadySentCartItems, tableOrder?._id, payments, 0, (selectedVipCard ? selectedVipCard : selectedEmployee));
                }
            }
        } else {
            if(selectedItemsToPay?.length > 0){
                //Split payment with not exceeding payment
                const filteredItemsArrayForSplitOrder = selectedItemsToPay ? alreadySentCartItems.map(item => {
                    const matchingItems = selectedItemsToPay.filter(selected => selected._id === item._id && selected.size === item.size);
                    if (matchingItems.length > 0) {
                        const totalAmountToDecrease = matchingItems.reduce((sum, selectedItem) => sum + 1, 0);
                        return {
                            ...item,
                            amount: Math.max((item.amount || 0) - totalAmountToDecrease, 0),
                            quantity: Math.max((item.quantity || 0) - totalAmountToDecrease, 0)
                        };
                    }
                    return item;
                }) : alreadySentCartItems;
                //selectedItems payment

                const firstPayment = await handlePayment(paymentType, paidAmount, amountToBePaid, setProcessing, navigation, cartTotal, setCart, cartItems, sendData, () => {/*onClose*/
                }, originalCartTotal, productsPrice, discountsForWholeCart, vatInvoiceData, needInvoice, false, currentTable, serviceFeeAmount, selectedItemsToPay, undefined, payments, 0, (selectedVipCard ? selectedVipCard : selectedEmployee),);

                //order update.
                await send_storeOrder(cartTotal, cartItems, paymentType, paidAmount, discountsForWholeCart, vatInvoiceData, needInvoice, currentTable, serviceFeeAmount, true, filteredItemsArrayForSplitOrder ,tableOrder._id,payments,0,originalAmount,(selectedVipCard ? selectedVipCard : selectedEmployee),undefined,selectedItemsToPay,tableOrder);
               Observables.call("reloadOrders");
            }else {
                //not exceeding payment
                console.log("13s")
                return handlePayment(paymentType, paidAmount, amountToBePaid, setProcessing, navigation, cartTotal, setCart, cartItems, sendData, () => {/*onClose*/
                }, originalCartTotal, productsPrice, discountsForWholeCart, vatInvoiceData, needInvoice, false, currentTable, serviceFeeAmount, alreadySentCartItems, tableOrder?._id, payments, 0, (selectedVipCard ? selectedVipCard : selectedEmployee));
            }
        }
    };

    useEffect(() => {
        console.log("useeffect")
        const numericAmount = parseInt(amount) || 0;
        const splitPaymentSum = paymentsSum;
        //const selectedItemsSum = selectedItemsToPay?.length > 0 ? selectedItemsToPay.reduce((sum, item) => sum + (item.remotePrice || item.price), 0) : 0;
        const roundedOriginalAmount = originalAmount;
        const remainingAmount = payments?.length > 0 ? Math.max(roundedOriginalAmount - splitPaymentSum - numericAmount, 0) : Math.max(roundedOriginalAmount - numericAmount, 0);
        const change = payments?.length > 0 ? Math.max(splitPaymentSum - roundedOriginalAmount, 0) : Math.max(numericAmount - roundedOriginalAmount, 0);

        setPaidAmount(numericAmount);
        if(paymentType === 'Készpénz'){
            console.log("f")
            setAmountToPay(hungarianRoundNumbers(remainingAmount));
        } else {
            console.log("f2")
            setAmountToPay((remainingAmount));
        }
        console.log("--change")
        setChangeToReturn(change);
    }, [amount, originalAmount, [...payments], paymentType]);


    useEffect(() => {
        if (currentTable){
            if(paymentType === 'Készpénz' || paymentType === 'cash') {
                console.log("3453")
                if(selectedItemsToPay?.length > 0){
                    const serviceFee = Number(serviceFeePercentage);
                    if (!isNaN(serviceFee)) {
                        console.log("asd1")
                        const x = ((selectedItemsSum * (serviceFee)) + selectedItemsSum);
                        console.log("x",x)
                        console.log("hungarianRoundNumbers(x)")
                        setOriginalAmount(hungarianRoundNumbers(x));
                    } else {
                        console.log("asd13")

                        setAmount(hungarianRoundNumbers(selectedItemsSum -paymentsSum));
                    }
                }else{
                    const roundedAmount = hungarianRoundNumbers(cartTotalWithServiceFee);
                    setOriginalAmount(roundedAmount);
                }
            }else{
                if(selectedItemsToPay?.length > 0){
                    const serviceFee = Number(serviceFeePercentage);
                    setOriginalAmount(Math.round((selectedItemsSum * serviceFee) + selectedItemsSum));
                }
                else{
                    setOriginalAmount(cartTotalWithServiceFee);
                }
            }
        }
    },[paymentType])
    const handleSwitchChange = (value) => {
        setNeedInvoice(value);
        if (value) {
            setModalVisible(true);
        }
    };

    const handleModalDismiss = () => {
        setModalVisible(false);
        setNeedInvoice(false);
        setVatInvoiceData(false);
    };
    useEffect(() => {
        if(vatInvoiceData){
            setModalVisible(false)
        }
    },[vatInvoiceData]);

    const handleInput = (value) => {
        setAmount((prev) => ((prev === originalAmount || prev === 0) ? value : prev + value));
    };

    const handleBackspace = () => {
        setAmount((prev) => (prev.length > 1 ? prev.slice(0, -1) : ''));
    };

    const handleClear = () => {
        setAmount(0);
    };
    function canBeClosed(){
        if(isSplitOrder){
            return ((paymentsSum >= (originalAmount - 4)))
        }
        return amountToPay <= 1;
    }

    const convertPaymentType = () => {
        if (paymentType === "Készpénz") return "cash";
        if (paymentType === "Bankkártya") return "creditCard";
        if (paymentType === "Utalvány") return "voucher";
        if (paymentType === "VIP Számla") return "vipBalance";
        if (paymentType === "Személyzeti") return "employeeBalance";
        return "temporary";
    }

    const handleDeletePayment = (index) => {
        const updatedPayments = [...payments];
        updatedPayments.splice(index, 1);
        setPayments(updatedPayments);
    };


    function addPayment(){
        if (!paymentType) {
            if (Platform.OS === "android") {
                return ToastAndroid.showWithGravity('Kérlek válassz fizetési módot', 5000, ToastAndroid.CENTER);
            } else {
                setProcessing(false);
                return alert('Kérlek válassz fizetési módot');
            }
        }
        if(paymentType === 'Készpénz' || paymentType === 'cash'){
            setPayments([...payments, {
                "paymentType": convertPaymentType(),
                "amount": hungarianRoundNumbers(Number(amount)) || 0,
                "change": 1,
                "currency": "HUF",
            }]);
        }else{
            setPayments([...payments, {
                "paymentType": convertPaymentType(),
                "amount": Number(amount) || 0,
                "change": 1,
                "currency": "HUF",
            }]);
        }
        setAmount(amountToPay)
        setPaymentType(false)
    }

    const confirmRemovePayments = (onConfirm) => {
        if (Platform.OS === 'web') {
            if (window.confirm("Már vannak kifizetett összegek. Szeretné eltávolítani őket és kikapcsolni a bontott rendelést?")) {
                onConfirm();
            }
        } else {
            Alert.alert(
                "Létező kifizetések",
                "Már vannak kifizetett összegek. Szeretné eltávolítani őket és kikapcsolni a bontott rendelést?",
                [
                    {
                        text: "Mégse",
                        style: "cancel"
                    },
                    {
                        text: "Igen",
                        onPress: onConfirm
                    }
                ],
                { cancelable: false }
            );
        }

    };
    function handleSplitorderToggle() {
        if (isSplitOrder && payments.length > 0) {
            confirmRemovePayments(() => {
                setPayments([]);
                setIsSplitOrder(false);
            });
        } else {
            setIsSplitOrder(!isSplitOrder);
            if (!isSplitOrder) {
                setAmount(originalAmount - paymentsSum);
            }
        }
    }

    const handlePaymentTypeChange = (type) => {
        setPaymentType(type);
        //second click on a payment type add it to payments IF split order
        if(paymentType === type && isSplitOrder && amount > 0){
            console.log("o-o")
            addPayment()
        }else{
            //input amount change based on choose payment type
            if(type === 'Készpénz'){
                if(isSplitOrder){
                    console.log("a")
                    if(paymentsSum < originalAmount && (amount === 0 || !amount)){
                        console.log("b")
                        setAmount(hungarianRoundNumbers((originalAmount - paymentsSum)))
                    }else{
                        console.log("c")
                        setAmount(hungarianRoundNumbers(amount))
                    }
                }else{
                    console.log("d")
                    setAmount(hungarianRoundNumbers(amount))
                }
            } else if (type === 'Bankkártya') {
                if(cartTotalWithServiceFee && currentTable){
                    console.log("1")
                    if(!isSplitOrder) {console.log("2")
                        setAmount((cartTotalWithServiceFee))
                    }else{console.log("3")
                        if(paymentsSum < originalAmount && (amount === 0 || !amount)){console.log("4")
                            if(selectedItemsToPay?.length > 0){
                                setAmount(Math.round((selectedItemsSum * serviceFeePercentage) + selectedItemsSum - paymentsSum));
                            }else{
                                setAmount(((cartTotalWithServiceFee - paymentsSum)))
                            }
                        }else{console.log("5")
                            if(selectedItemsToPay?.length > 0){
                                setAmount(Math.round((selectedItemsSum * serviceFeePercentage) + selectedItemsSum- paymentsSum));
                            }else{
                                setAmount((cartTotalWithServiceFee - paymentsSum))
                            }
                        }
                    }
                }else{console.log("16")
                    if(!isSplitOrder) {console.log("17")
                        setAmount((originalAmount));
                    }else{console.log("18")
                        if(paymentsSum < originalAmount){console.log("19")
                            setAmount(((originalAmount - paymentsSum)))
                        }
                    }
                }
            }else if (type === 'VIP Számla') {
                setPaymentType('VIP Számla');
                setAmount(((originalAmount - paymentsSum)))
            }else if (type === 'Személyzeti') {
                setPaymentType('Személyzeti');
                setAmount(((originalAmount - paymentsSum)))
            }else{
                if(!isSplitOrder) {
                    setAmount(originalAmount || 0)
                }else{
                    if(paymentsSum < originalAmount && (amount === 0 || !amount)){
                        setAmount(((originalAmount - paymentsSum)))
                    }
                }
            }
        }
    };

    useEffect(() => {
        return () => {
            setProcessing(false);
        }
    }, []);

        useEffect(() => {
            const callback = async (response) => {
                try {
                    if (response.responseData && response.responseData.status === "success" || response.responseData.status === "SUCCESS") {
                        setCart([]);
                        const tableOrder = orders?.find(
                            (order) =>
                                !order.storno &&
                                order.status !== "cancelled" &&
                                order.status !== "closed" &&
                                order.tableId === currentTable?._id
                        );
                        console.log("1xxxs")
                        await handlePayment(paymentType, paidAmount, originalAmount, setProcessing, navigation, currentTable && serviceFeeAmount > 0 ? cartTotalWithServiceFee : cartTotal, setCart, cartItems, sendData, onClose, originalCartTotal, productsPrice, discountsForWholeCart, vatInvoiceData, needInvoice, true, currentTable, serviceFeeAmount, alreadySentCartItems, tableOrder?._id, payments)
                        setIsModalVisible(false)
                        return setProcessing(false);
                    } else {
                        setIsModalVisible(false)
                        setProcessing(false);
                        return navigation.navigate('FailedPayment', {...response.responseData, orderResponse: null});
                    }
                } catch (e) {
                    setIsModalVisible(false)
                    setProcessing(false);
                    console.error("Failed to redirect, error: ", e);
                }
            }

            const handleUrl = async ({url}) => {
                const response = VivaPaymentModule.handleCallbackUrl(url);
                return callback(response)
            };

            const subscription = Linking.addEventListener('url', handleUrl);

            return () => {
                subscription.remove();
            };
        }, []);
    const printReceiptAndTicket = (order) => {
        if (Platform.OS !== "web") {
            try {
                // Print receipt tickets
                new Promise(() => RawBTPrintTicket(order, order.items));

                if(cartItems.length > 0) new Promise(() => setTimeout(() => RawBTPrintKitchen(order, order.items), 500));

            } catch (e) {
                if (Platform.OS === "android") {
                    return ToastAndroid.showWithGravity('Nem sikerült a nyomtatás!', ToastAndroid.LONG, ToastAndroid.BOTTOM, 50);
                } else {
                    return alert('Nem sikerült a nyomtatás!');
                }
            }
        }
    }
    async function handleProforma() {
        try {
            const proforma = true;
            const tableOrder = orders?.find(
                (order) =>
                    !order.storno &&
                    order.status !== "cancelled" &&
                    order.status !== "closed" &&
                    order.tableId === currentTable?._id
            );
        const storeOrderResponse = await send_storeOrder(cartTotal, cartItems, "", 0, discountsForWholeCart, undefined, false,
            currentTable, serviceFeeAmount, true, alreadySentCartItems, tableOrder?._id, [],
            0, currentTable && serviceFeeAmount > 0 ? cartTotalWithServiceFee : cartTotal, (selectedVipCard ? selectedVipCard : selectedEmployee), undefined, selectedItemsToPay, tableOrder, proforma);
            printReceiptAndTicket(storeOrderResponse);

            if (setIsModalVisible !== undefined) {
                setIsModalVisible(false);
            }

            navigation.navigate('TablesScreen');
            removeEmployee();
            removeVipCard();

        } catch (error) {
            console.error("Error handling proforma:", error);
            if (Platform.OS === 'android') {
                ToastAndroid.showWithGravity(
                    'Error handling proforma',
                    ToastAndroid.LONG,
                    ToastAndroid.CENTER
                );
            } else {
                alert('Error handling proforma');
            }
        }
    }

    return (
        <View style={[styles.container, { width: '100%' , height: "100%"}]}>
            <LinearGradient
                colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
                style={{ flex: 1, width: '100%', height: '100%' , flexDirection:"row"}}
            >
                <Spinner
                    visible={processing}
                    textContent={t("processing...")}
                    textStyle={styles.spinnerTextStyle}
                />
                <View style={{flex: 2}}>
                    <View style={styles.tabsContainer}>
                    {getPaymentOptions().map(({ type, icon }) => (
                        <TouchableOpacity
                            key={type}
                            style={[styles.tab, paymentType === type && styles.activeTab]}
                            onPress={() => handlePaymentTypeChange(type)}
                        >
                            <Feather name={icon} size={18} color={paymentType === type ? '#f1b34b' : '#666'} />
                            <Text style={[styles.tabText, paymentType === type && styles.activeTabText]}>{translateDisplay(type)}</Text>
                        </TouchableOpacity>
                    ))}
                    {currentTable && (<TouchableOpacity
                        style={[styles.tab, showSplitItems && styles.activeTab]}
                        onPress={() => {setShowSplitItems(!showSplitItems)}}
                    >
                        <Feather name="users" size={18} color={showSplitItems ? '#f1b34b' : '#666'}/>
                        <Text style={[styles.tabText,showSplitItems && styles.activeTabText]}>split</Text>
                    </TouchableOpacity>)}
                        {currentTable && (<TouchableOpacity
                            style={[styles.tab]}
                            onPress={() => {
                               handleProforma()
                            }}
                        >
                            <Feather name="file-text" size={18} color='#666'/>
                            <Text style={[styles.tabText]}>{t("proforma")}</Text>
                        </TouchableOpacity>)}
                    {/*<TouchableOpacity*/}
                    {/*    style={[styles.tab, isSplitOrder && styles.splitTab]}*/}
                    {/*    onPress={() => {*/}
                    {/*todo refactor it for bontott for now every order is "split" order so every order can be paid with multiple payment options*/}
                    {/*        handleSplitorderToggle()*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <View style={{ flexDirection: 'row' }}>*/}
                    {/*        <View style={{ flexDirection: 'column', paddingRight: "4px" }}>*/}
                    {/*            <Feather name={"dollar-sign"} size={10} color={isSplitOrder ? 'white' : '#666'} />*/}
                    {/*            <Feather name={"credit-card"} size={10} color={isSplitOrder ? 'white' : '#666'} />*/}
                    {/*        </View>*/}
                    {/*        <Feather name={"shuffle"} size={19} color={isSplitOrder ? 'white' : '#666'} />*/}
                    {/*    </View>*/}
                    {/*    <Text style={[styles.tabText, isSplitOrder && styles.activeSplitTabText]}>Bontott</Text>*/}
                    {/*</TouchableOpacity>*/}
                </View>
                    {selectedVipCard && <View style={styles.vipCardContainer}>
                        {(selectedVipCard?.user?.name?.first.length < 1 && selectedVipCard?.user?.name?.last.length < 1)
                        || (!selectedVipCard?.user?.name && !selectedVipCard?.user?.name)
                            ?
                            <Text style={styles.vipCardText}>
                                {selectedVipCard.cardId}
                            </Text> : <Text style={styles.vipCardText}>
                                {selectedVipCard?.user?.name?.first} {selectedVipCard?.user?.name?.last}
                            </Text>}
                    </View>}
                    {selectedEmployee && <View style={styles.vipCardContainer}>
                        {(selectedEmployee?.name?.first.length < 1 && selectedEmployee?.name?.last.length < 1)
                        || (!selectedEmployee?.name && !selectedEmployee?.name)
                            ?
                            <Text style={styles.vipCardText}>
                                {t("unnamed")}
                            </Text> : <Text style={styles.vipCardText}>
                                {selectedEmployee?.name?.first} {selectedEmployee?.name?.last}
                            </Text>}
                    </View>}
                    {/* Payment Amount Input */}
                    <View style={styles.amountContainer}>
                        {/*{isSplitOrder && <TouchableOpacity onPress={addPayment} >*/}
                        {/*    <Feather name={"plus-square"} size={28} color="black"/>*/}
                        {/*</TouchableOpacity>}*/}

                        <TouchableOpacity onPress={handleClear} style={styles.clearButton}>
                            <Feather name="trash-2" size={28} color="black" />
                        </TouchableOpacity>
                        <TextInput
                            style={styles.amountInput}
                            value={formatAmountWithSpaces(amount)}
                            editable={true}
                            keyboardType="numeric"
                            placeholder={t("received_amount")}
                            placeholderTextColor={'#AAA'}
                            onFocus={() => {
                                setAmount('');
                                if (Platform.OS === 'android') {
                                    textInputRef.current?.blur(); // Prevents keyboard from opening on Android
                                }
                            }}
                            onChangeText={(text) => {
                                // Allow only numbers and one decimal point
                                const numericText = text.replace(/[^0-9.]/g, '');
                                setAmount(numericText);
                            }}
                            ref={textInputRef}
                            showSoftInputOnFocus={Platform.OS === 'android' ? false : true} // Prevents keyboard on Android
                        />
                        <TouchableOpacity onPress={handleBackspace} style={styles.backspaceButton}>
                            <Text style={styles.backspaceText}>⌫</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{flexDirection: "row"}}>
                        <View style={{flex: 2, paddingLeft: 10}}>
                            {/* Info Lines */}
                            {(cartItems.length > 0 || alreadySentCartItems?.length > 0) &&
                                <View style={styles.infoContainer}>
                                    <View style={styles.infoRow}>
                                        <Text style={styles.infoLabel}>{t("payable")}:</Text>
                                        <Text style={styles.infoValue}>{(originalAmount)}</Text>
                                    </View>
                                    <View style={[styles.infoRow, {
                                        color: Number(amountToPay) > 0 ? "red" : "#333",
                                        fontWeight: Number(amountToPay) > 0 ? "900" : "200"
                                    }]}>
                                        {(amountToPay >= 1 && !isSplitOrder) && (<><Text style={[styles.infoLabel, {
                                            color: Number(amountToPay) > 0 ? "red" : "#333",
                                            fontWeight: Number(amountToPay) > 0 ? "900" : "200"
                                        }]}>{t("amount_due")}:</Text>
                                            <Text style={[styles.infoValue, {
                                                color: Number(amountToPay) > 0 ? "red" : "#333",
                                                fontWeight: Number(amountToPay) > 0 ? "900" : "200"
                                            }]}>{priceFormatter(amountToPay)}</Text></>)}
                                        {(isSplitOrder) && (<><TouchableOpacity disabled
                                            onPress={() => toggleModal()}><Text style={[styles.infoLabel, {
                                            color: Math.round(paymentsSum) >= Math.round(originalAmount) ? "#3baa1a" : "#f1b34b",
                                            fontWeight: "900",
                                        }]}>{t("paid_so_far")}:</Text></TouchableOpacity>

                                            <TouchableOpacity disabled onPress={() => toggleModal()}><Text
                                                style={[styles.infoValue, {
                                                    color: Math.round(paymentsSum) >= Math.round(originalAmount) ? "#3baa1a" : "#f1b34b",
                                                    fontWeight: "900",
                                                }]}>{priceFormatter(paymentsSum)}</Text></TouchableOpacity></>)}
                                    </View>
                                    {Number(changeToReturn) > 1 && <View style={styles.infoRow}>
                                        {paymentType === 'Bankkártya' ?
                                            Number(changeToReturn) > 1 && (<>
                                                <Text
                                                    style={[styles.infoLabel, {fontWeight: Number(changeToReturn) > 1 ? "900" : "200"}]}>
                                                    {t("overpayment")}:
                                                </Text>
                                                <Text
                                                    style={[styles.infoValue, {fontWeight: Number(changeToReturn) > 1 ? "900" : "200"}]}>
                                                    {priceFormatter(changeToReturn)}
                                                </Text>
                                            </>)
                                            :
                                            changeToReturn > 4 && <>
                                                <Text
                                                    style={[styles.infoLabel, {fontWeight: Number(changeToReturn) > 4 ? "900" : "200"}]}>
                                                    {t("change")}:
                                                </Text>
                                                <Text
                                                    style={[styles.infoValue, {fontWeight: Number(changeToReturn) > 4 ? "900" : "200"}]}>
                                                    {priceFormatter(hungarianRoundNumbers(changeToReturn))}
                                                </Text>
                                            </>
                                        }


                                    </View>}

                                </View>}
                            {payments?.length > 0 && (
                                <View style={[styles.infoRow, {maxHeight: "300px", paddingLeft: 10, paddingRight: 5}]}>

                                    <ScrollView contentContainerStyle={{height: "300px"}} style={{height:"300px"}}>
                                        <FlatList
                                            data={payments}
                                            keyExtractor={(item, index) => index.toString()}
                                            renderItem={({item, index}) => (
                                                <View style={styles.paymentItem}>
                                                    <Text
                                                        style={styles.paymentType}>{reverseConvertPaymentType(item.paymentType)}</Text>
                                                    <Text style={styles.paymentAmount}>{priceFormatter(item.amount)}</Text>
                                                    <TouchableOpacity onPress={() => handleDeletePayment(index)}>
                                                        <Feather name="trash-2" size={22} color="red"/>
                                                    </TouchableOpacity>
                                                </View>
                                            )}
                                        />
                                    </ScrollView>

                            </View>)}
                        </View>
                        <View style={{flex: 3, paddingLeft: 10}}>{/*2*/}


                    <View style={styles.switchContainer}>
                        <Text style={styles.switchLabel}>{t("request_invoice")}</Text>
                        <Switch
                            vibrate={true}
                            thumbColor="#f1b34b"
                            trackColor={{ false: "#767577", true: "#ecbb69" }}
                            ios_backgroundColor="#3e3e3e"
                            value={needInvoice}
                            onValueChange={handleSwitchChange}
                        />
                        <VatModal visible={modalVisible} onSubmit={setVatInvoiceData} onDismiss={handleModalDismiss} />
                    </View>
                    <View style={styles.pinpadContainer}>
                        <PinPad onButtonClick={(value) => (value === 'X' ? addPayment() : handleInput(value.toString()))}/>
                    </View>
                    </View>
                    </View>
                    {/* Pinpad and Process Payment Button Container */}
                    {(cartItems.length > 0 || alreadySentCartItems?.length > 0) && <View style={styles.bottomContainer}>
                        {/* Pinpad */}


                        {/* Back and Process Payment Buttons */}
                        {(cartItems.length > 0 || alreadySentCartItems?.length > 0) && <View style={styles.buttonContainer}>
                            <TouchableOpacity style={styles.backButton} onPress={() => {
                                if(setIsModalVisible !== undefined) {
                                    setIsModalVisible(false)
                                }

                                navigation.navigate('HomeScreen');
                            }}>
                                <Feather name="arrow-left" size={24} color="#fff"/>
                            </TouchableOpacity>

                            {Math.round(paymentsSum) > Math.round(originalAmount) + 2 ? (
                                <>
                                    <TouchableOpacity style={styles.borravaloButton} onPress={() => {
                                        confirmPaymentAmount(true)
                                    }}>
                                        <Text style={styles.processButtonText}>{t("tip")}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.noHorizontalPaddingButton} onPress={()=>{confirmPaymentAmount(false)}}>
                                        <Text style={styles.processButtonText}>
                                            {t("change")}: {priceFormatter(hungarianRoundNumbers(isSplitOrder ? paymentsSum - originalAmount : changeToReturn))}
                                        </Text>
                                    </TouchableOpacity>
                                </>
                            ) : (
                                <TouchableOpacity style={[styles.processButton, {backgroundColor: ((!isSplitOrder && amountToPay > 1) || (isSplitOrder && paymentsSum < originalAmount -1))  ? "#f3a965" : "#ff8000"}]} disabled={((!isSplitOrder && amountToPay > 1) || (isSplitOrder && paymentsSum < originalAmount - 4))} onPress={confirmPaymentAmount}>
                                    {((!isSplitOrder && amountToPay > 1) || (isSplitOrder && paymentsSum < originalAmount - 4)) && <Text style={styles.processButtonText}>{t("amount_due")}: {isSplitOrder ? priceFormatter(originalAmount - paymentsSum):  priceFormatter(amountToPay)}</Text>}
                                    {canBeClosed() && <Text style={styles.processButtonText}>{t("close_receipt")}</Text>}
                                </TouchableOpacity>
                            )}
                        </View>}
                    </View>}</View>
                {(currentTable && showSplitItems) &&

                    <View style={{flex: 1, backgroundColor: "rgba(255,157,96,0.09)", height: "100%"}}>
                        <View style={{paddingVertical: 22, paddingHorizontal: 10, backgroundColor: "#f1f1f1",  marginBottom: 5}}>
                            <Text style={{fontSize: 24, fontWeight: "bold", color: "#333", textAlign: "center"}}>{t("split_items_to_pay")}</Text>
                        </View>
                        <ScrollView contentContainerStyle={{height:  (height * 0.86)}} style={{height: (height * 0.86)}}>
                            <FlatList
                                data={combinedCartItems}
                                keyExtractor={(item, index) => `${item.id}-${index}`}
                                renderItem={renderItem}
                            />
                        </ScrollView>

                </View>}

                {(isSplitOrder && paymentsModal) && (
                    <Modal
                        visible={paymentsModal}
                        transparent={true}
                        animationType="fade"
                        onRequestClose={toggleModal}
                    >
                        <View style={styles.modalBackdrop}>
                            <View style={styles.modalContainer}>
                                <View style={styles.modalHeader}>
                                    <Text style={styles.modalTitle}>{t("paid_so_far")}</Text>
                                    <TouchableOpacity onPress={toggleModal}>
                                        <Feather name="x" size={24} color="#333" />
                                    </TouchableOpacity>
                                </View>

                                <FlatList
                                    data={payments}
                                    keyExtractor={(item, index) => index.toString()}
                                    renderItem={({ item, index }) => (
                                        <View style={styles.paymentItem}>
                                            <Text style={styles.paymentType}>{reverseConvertPaymentType(item.paymentType)}</Text>
                                            <Text style={styles.paymentAmount}>{priceFormatter(item.amount)}</Text>
                                            <TouchableOpacity onPress={() => handleDeletePayment(index)}>
                                                <Feather name="trash-2" size={22} color="red" />
                                            </TouchableOpacity>
                                        </View>
                                    )}
                                />

                                <TouchableOpacity style={styles.closeButton} onPress={toggleModal}>
                                    <Text style={styles.closeButtonText}>{t("close")}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </Modal>
                )}
                </LinearGradient>
            </View>
        );
    };

        const styles = StyleSheet.create({
            container: {
                flex: 1,
                backgroundColor: '#fff',
                justifyContent: 'space-between',
                zIndex: 1000,
            },
            splitTab: {
                backgroundColor: '#f1b34b',
            },
            activeSplitTabText: {
                color: 'white',
            },
            itemContainer: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingHorizontal: 10,
                paddingVertical: 16,
                borderBottomWidth: 1,
                borderBottomColor: '#bbb',
                backgroundColor: '#ddd'
            },
            itemName: {
                fontSize: 16,
                fontWeight: 'bold'
            },
            unitPrice: {
                fontSize: 14,
                color: '#666'
            },
            extraText: {
                fontSize: 12,
                color: '#888'
            },
            itemPrice: {
                fontSize: 16,
                fontWeight: 'bold'
            },
            tabsContainer: {
                flexDirection: 'row',
                justifyContent: 'space-around',
                padding: 10,
                backgroundColor: '#f1f1f1',
            },
            tab: {
                flex: 1,
                padding: 12,
                alignItems: 'center',
            },
            activeTab: {
                borderBottomWidth: 2,
                borderBottomColor: '#f1b34b',
            },
            tabText: {
                fontSize: 16,
                color: '#666',
                marginTop: 4,
            },
            activeTabText: {
                color: '#f1b34b',
            },
            infoContainer: {
                marginTop: 20,
                padding: 10,
            },
            infoRow: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginVertical: 2,
            },
            infoLabel: {
                fontSize: 18,
                color: '#333',
                fontWeight: "500",
            },
            infoValue: {
                fontSize: 18,
                color: '#333',
                fontWeight: 'bold',
            },
            amountContainer: {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 7,
                width: '100%',
            },
            backspaceButton: {
                marginRight: 10,
                marginLeft: 5,
            },
            backspaceText: {
                fontSize: 26,
                color: '#333',
            },
            amountInput: {
                marginTop: 10,
                fontSize: 30,
                textAlign: 'center',
                color: '#333',
                borderBottomWidth: 1,
                width: "75%"
            },
            clearButton: {
                marginLeft: 10,
                marginRight: 10,
            },
            clearText: {
                fontSize: 26,
                color: '#333',
            },
            bottomContainer: {
                flex: 1,
                justifyContent: 'flex-end',
                paddingBottom: 10,
            },
            pinpadContainer: {
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                width: "100%",
                height: height * 0.45,
                paddingHorizontal: 0,
                paddingBottom: 10,
                marginBottom: 5,
            },
            noHorizontalPaddingButton: {
                backgroundColor: '#F76E24',
                alignItems: 'center',
                paddingTop: 15,
                paddingBottom: 15,
                marginTop: 20,
                flex: 1,
                marginLeft: 0,
                borderBottomRightRadius: 5,
                borderTopRightRadius: 5,
                paddingLeft: 3,
                paddingRight: 3,
            },
            borravaloButton: {
                backgroundColor: '#f1b34b',
                alignItems: 'center',
                paddingTop: 15,
                paddingBottom: 15,
                marginTop: 20,
                paddingLeft: 10,
                paddingRight: 10,
                borderBottomRightRadius: 5,
                borderTopRightRadius: 5,
                width: 'auto',
            },
            buttonContainer: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingHorizontal: 16,
            },
            backButton: {
                backgroundColor: 'black',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 15,
                marginTop: 20,
                borderRadius: 5,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0
            },
            processButton: {
                backgroundColor: '#F76E24',
                alignItems: 'center',
                padding: 15,
                marginTop: 20,
                flex: 1,
                marginLeft: 0,
                borderBottomRightRadius: 5,
                borderTopRightRadius: 5
            },
            processButtonText: {
                fontSize: 20,
                color: '#fff',
                fontWeight: 'bold',
                paddingHorizontal: 5,
            },
            spinnerTextStyle: {
                color: '#FFF'
            },
            switchContainer: {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingHorizontal: 16,
                paddingTop: 20,
                marginVertical: 10,
            },
            switchLabel: {
                fontSize: 16,
                color: '#000',
            },
            modalBackdrop: {
                flex: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                justifyContent: 'center',
                alignItems: 'center',
            },
            modalContainer: {
                width: width > 768 ? '30%' : '80%',
                backgroundColor: '#fff',
                borderRadius: 15,
                padding: 20,
                elevation: 10,
            },
            modalHeader: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottomWidth: 1,
                borderBottomColor: '#ddd',
                paddingBottom: 10,
                marginBottom: 10,
            },
            modalTitle: {
                fontSize: 20,
                fontWeight: 'bold',
                color: '#333',
            },
            paymentItem: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingVertical: 12,
                borderBottomWidth: 1,
                borderBottomColor: '#eee',
            },
            paymentType: {
                fontSize: 16,
                color: '#444',
                flex: 1,
            },
            paymentAmount: {
                fontSize: 16,
                fontWeight: 'bold',
                color: '#333',
                flex: 1,
                textAlign: 'right',
                marginRight: 15,
            },
            closeButton: {
                marginTop: 20,
                padding: 12,
                backgroundColor: '#f1b34b',
                borderRadius: 8,
                alignItems: 'center',
            },
            closeButtonText: {
                fontSize: 18,
                fontWeight: 'bold',
                color: '#fff',
            },
            vipCardContainer: {
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: '#f1b34b',
                padding: 10,
                borderRadius: 5,
            },
            vipCardText: {
                flex: 1,
                color: 'white',
                fontSize: 16,
                textAlign: 'center',
                fontWeight: 'bold',
            },
        });

export default PaymentScreen;