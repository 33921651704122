import React, {createContext, useContext, useState} from 'react';
import {SystemContext} from "./SystemContext";

export const VipCardContext = createContext();


export const VipCardProvider = ({ children }) => {
    const [selectedVipCard, setSelectedVipCard] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const { vipCards, fetchSystemData, admins } = useContext(SystemContext);



    const selectVipCard = (vipCard) => {
        const wholeVIPObject = vipCards.find(v => v.id === vipCard.id);
        if(wholeVIPObject){
            removeEmployee();
            setSelectedVipCard(wholeVIPObject);
        }else{
            removeEmployee();
            setSelectedVipCard(vipCard)
        }
    };
    const selectEmployee = (obj) => {
        if(obj){
            removeVipCard();
            setSelectedEmployee(obj);
        }else{
            console.error("No employee selected");
        }

    }

    const removeVipCard = () => {
        setSelectedVipCard(null);
    };
    const removeEmployee = () => {
        setSelectedEmployee(null);
    };

    return (
        <VipCardContext.Provider value={{ selectedVipCard, selectVipCard, removeVipCard, selectEmployee, selectedEmployee, removeEmployee }}>
            {children}
        </VipCardContext.Provider>
    );
};