import React, { useEffect, useState, useContext, useRef } from 'react';
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    ScrollView,
    Animated,
    Platform,
    Alert,
    ToastAndroid
} from 'react-native';
//import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css'; // Import the Mapbox CSS
//import AsyncStorage from "@react-native-async-storage/async-storage";
import { OrdersContext } from "../helpers/OrdersContext";
import { priceFormatter } from "../helpers/numbers";
import { Ionicons } from "@expo/vector-icons";
import foodpandaIcon from "../../assets/foodpanda-icon.png";
import woltIcon from "../../assets/wolt.png";
import { SystemContext } from "../helpers/SystemContext";
//import { Menu } from "react-native-paper";
import StatisticsHelper from "../helpers/StatisticsHelper";
import { Swipeable, TapGestureHandler, State } from 'react-native-gesture-handler';
import { getAccessToken } from "../helpers/storage";
import HappyAlert from '../helpers/crossPlatformTools/HappyAlert';
import {useTranslation} from "react-i18next";
import DeliveryUsersModal from "../components/DeliveryUserModal";
import DeliveryMap from "../components/DeliveryMap";
import {hungarianRoundNumbers} from "../helpers/hungarianRoundNumbers";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {RawBTPrintTicket} from "../helpers/rawBTDriver";
import {BluetoothContext} from "../helpers/BluetoothContext";

//const MAPBOX_TOKEN = 'pk.eyJ1IjoiZ29vZG8iLCJhIjoiY2t2eHg5cWhuNDM3YjJwa2xxejVubnhhcCJ9.py506Q9y-4k30wIs6xh2Ew';

const OnlineMapScreen = ({ navigation }) => {
    const { t } = useTranslation();
    const swipeableRefs = useRef([]);
    const [viewport, setViewport] = useState({
        latitude: 47.5025613999349,
        longitude: 19.05690176184082,
        zoom: 10,
        bearing: 0,
        pitch: 0,
        width: '100%',
        height: '100%'
    });
    const { sendData } = useContext(BluetoothContext);
    const { restaurantPlace, setRestaurantPlace } = useContext(SystemContext);
    const { orders, fetchOrdersFromAPI } = useContext(OrdersContext);
    const { productsSum, statistics } = StatisticsHelper(orders);
    const [expandedOrder, setExpandedOrder] = useState(null);
    const [menuVisible, setMenuVisible] = useState(false);
    const [deliveryMenuVisible, setDeliveryMenuVisible] = useState(false);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [alertVisible, setAlertVisible] = useState(false); // State for alert visibility
    const [alertMessage, setAlertMessage] = useState(''); // State for alert message
    const [alertType, setAlertType] = useState('info'); // State for alert type
    const [selectedCurrier, setSelectedCurrier] = useState(null);
    const [confirmAction, setConfirmAction] = useState(undefined);
    const [deliveryUsers, setDeliveryUsers] = useState([]);
    const [isDeliveryModalVisible, setDeliveryModalVisible] = useState(false);
    const [modalAnchor, setModalAnchor] = useState(null);


    const updateOdersDelivery = async (userId) => {
        try {
            const token = await getAccessToken();
            const promises = selectedOrders.map(async (orderId) => {
                const response = await fetch(`https://api.happygastro.hu/api/order/${orderId}/update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({delivery: {user: userId, sortOrder: 0}})
                });
                if (!response.ok) {
                    throw new Error(`Failed to update order ${orderId}`);
                }
                return response.json();
            });
            await Promise.all(promises);
            console.log("All orders updated successfully");
            fetchOrdersFromAPI();
        } catch (err) {
            console.log("Error while updating orders:", err);
        }
    };


    useEffect(() => {
        fetchAdmins()
    },[restaurantPlace]);

    const fetchAdmins = async () => {
        try {
            const token = await getAccessToken();
            const response = await fetch(`https://api.happygastro.hu/api/restaurant/admin`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Replace with your actual token
                }
            });
            if (!response.ok) {
                throw new Error('Failed to update order status');
            }
            const users = await response.json()
            setDeliveryUsers([...users.filter((u) => u.roles.includes('restaurantStaff:delivery'))]);
        } catch (err) {
            console.log("Error while updating order status:", err);
        }
    }

    const getNextStatus = (currentStatus) => {
        switch (currentStatus) {
            case 'pending':
                return { nextStatus: 'accepted', buttonText: t('pending'), buttonColor: '#f76e24' };
            case 'accepted':
                return { nextStatus: 'preparing', buttonText: t('preparing'), buttonColor: 'rgba(248,192,98,0.85)' };
            case 'preparing':
                return { nextStatus: 'ready', buttonText: t('prepared'), buttonColor: "#28a745" };
            case 'ready':
                return { nextStatus: 'closed', buttonText: t('close-status'), buttonColor: "#FFA500" };
            case 'busy':
                return { nextStatus: 'closed', buttonText: t('close-status'), buttonColor: "#FFA500" };
            default:
                return { nextStatus: 'pending', buttonText: t('pending') };
        }
    };

    const StatusUpdateButton = ({ order }) => {
        const { nextStatus, buttonText } = getNextStatus(order.status);

        return (
            <TouchableOpacity
                onPress={() => confirmAndUpdateOrderStatus(order._id, nextStatus)}
                style={[styles.detailsButton, styles.statusUpdateButton, { flex: 1, backgroundColor: getNextStatus(order.status).buttonColor }]}
            >
                <Text style={styles.detailsButtonText}>{buttonText}</Text>
            </TouchableOpacity>
        );
    };

    const confirmAndUpdateOrderStatus = (orderId, status) => {
        if (status === "closed") {
            setAlertType('warning');
            setAlertMessage(t("confirm_close_order"))
            setAlertVisible(true);
            setConfirmAction(() => async () => {
                await updateOrderStatus(orderId, status);
                setAlertVisible(false);
            });
        } else {
            updateOrderStatus(orderId, status);
        }
    };

    const updateOrderStatus = async (orderId = "", status) => {
        try {
            const token = await getAccessToken();
            const response = await fetch(`https://api.happygastro.hu/api/order/${orderId}/status/${status}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Replace with your actual token
                }
            });
            if (!response.ok) {
                throw new Error('Failed to update order status');
            }
            await response.json();
            await fetchOrdersFromAPI();
        } catch (err) {
            console.log("Error while updating order status:", err);
        }
    };

    const startSelectedOrders = async (selectedOrders) => {
        if (selectedOrders.length === 0) {
            console.log("No orders selected to start.");
            return;
        }

        try {
            const token = await getAccessToken();
            const response = await fetch("https://api.happygastro.hu/api/restaurant/route/start", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    objectIds: selectedOrders
                })
            });

            if (!response.ok) {
                throw new Error("Failed to start selected orders");
            }

            const result = await response.json();
            setAlertType('info');
            setAlertMessage(t("orders_started_successfully"));
            setAlertVisible(true);
            setSelectedCurrier(null);
        } catch (err) {
            console.error("Hiba a rendelés küldése közben", err);
            setAlertType('error');
            setAlertMessage(t("orders_start_failed"));
            setAlertVisible(true);
        }
    };
    const finishSelectedOrders = async (selectedOrders, orders) => {
        const sentOrders = selectedOrders.filter(orderId => {
            const order = orders?.find(o => o._id === orderId) || [];
            return order?.delivery?.sent === true;
        });

        if (sentOrders.length === 0) {
            setAlertType('warning');
            setAlertMessage(t("only_sent_orders_can_be_finished"));
            setAlertVisible(true);
            return;
        }

        try {
            const token = await getAccessToken();
            const response = await fetch("https://api.happygastro.hu/api/restaurant/route/finish", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    objectIds: sentOrders
                })
            });

            if (!response.ok) {
                throw new Error("Failed to finish selected orders");
            }

            const result = await response.json();

            await fetchOrdersFromAPI();
            setAlertType('success');
            setAlertMessage(t("success_finish_orders"));
            setAlertVisible(true);
            setSelectedCurrier(null);
        } catch (err) {
            console.error("Hiba a rendelés érkeztetése közben", err);
            setAlertType('error');
            setAlertMessage(t("orders_finish_failed"));
            setAlertVisible(true);
        }
        fetchOrdersFromAPI()
    };

    const OrderFromIcon = (orderData) => {

        if (orderData.orderData.orderFrom === "netpincer") {
            return <img src={foodpandaIcon} width={50} alt={"foodpanda"} />;
        } else if (orderData.orderData.orderFrom === "wolt") {
            return <img src={woltIcon} width={50} alt={"wolt"} />;
        }
        else if (orderData.orderData.orderFrom === "webshop") {
            return <Ionicons name="earth-outline" size={24} color="black" />;
        }
        else if (orderData.orderData.orderFrom === "phone") {
            return <Ionicons name="call" size={24} color="black" />;
        }else{
            return <Ionicons name="earth-outline" size={24} color="black" />;
        }
    };

    const handleSingleTap = (orderId) => {
        toggleOrderDetails(orderId);
    };

    function convertType(type) {
        switch (type) {
            case "creditCard":
                return t("credit_card");
            case "cash":
                return t("cash");
            case "voucher":
                return t("voucher");
        }
    }

    const toggleOrderDetails = (orderId) => {
        setExpandedOrder(expandedOrder === orderId ? null : orderId);
    };

    const handleSwipeLeft = (orderId) => {
        setSelectedOrders(prevSelectedOrders =>
            prevSelectedOrders.includes(orderId)
                ? prevSelectedOrders.filter(id => id !== orderId)
                : [...prevSelectedOrders, orderId]
        );
    };

    const renderRightActions = (orderId) => (
        <Animated.View style={styles.swipeButton}>
            {/*<Ionicons name="chevron-forward" size={24} color="black" />*/}
        </Animated.View>
    );

    const renderLeftActions = (orderId) => (
        <Animated.View style={styles.swipeButton}>
            {/*<Ionicons name="chevron-back" size={24} color="black" />*/}
        </Animated.View>
    );

    return (
        <View style={styles.container}>
            <DeliveryUsersModal
                visible={!!modalAnchor}
                onClose={() => setModalAnchor(null)}
                deliveryUsers={deliveryUsers}
                onSelect={(selectedUserId) => {
                    setSelectedCurrier(selectedUserId)
                }}
                updateOdersDelivery={updateOdersDelivery}
                anchorPosition={modalAnchor}
            />
            <View style={styles.leftColumn}>
                <DeliveryMap selectedOrders={selectedOrders} onMarkerClick={handleSwipeLeft} statistics={statistics}/>
            </View>
            <View style={styles.rightColumn}>
                <View style={styles.buttonContainer}>
                    <TouchableOpacity
                        activeOpacity={0.7}
                        style={styles.tabButton}
                        onPress={(event) => {
                            const { pageX, pageY } = event.nativeEvent;
                            setModalAnchor({ top: pageY + 20, left: pageX });
                        }}
                    >
                        <Ionicons name="car" size={24} color="black" />
                        <Text style={styles.tabButtonText}>{t("couriers")}</Text>
                    </TouchableOpacity>


                    <TouchableOpacity activeOpacity={0.7} style={styles.tabButton} onPress={() => startSelectedOrders(selectedOrders)}>
                        <Ionicons name="play" size={24} color="#f1b44c" style={{ color: '#c08b31' }} />
                        <Text style={[styles.tabButtonText, { color: '#c08b31' }]}>{t("start_delivery")}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity activeOpacity={0.7} style={styles.tabButton} onPress={() => finishSelectedOrders(selectedOrders, statistics.onlineOrders
                        .filter((o) => o.status !== "closed" && !o.storno && o.restaurantPlace === restaurantPlace?._id)
                        .concat(statistics.unClosedTakeAwayOrders.filter((o) => o.status !== "closed" && !o.storno))
                        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))}>
                        <Ionicons name="stop" size={24} color="#1f946a" />
                        <Text style={[styles.tabButtonText, { color: '#1f946a' }]}>{t("finish_delivery")}</Text>
                    </TouchableOpacity>
                </View>
                <Text style={styles.restaurantName}>{restaurantPlace.raw}</Text>
                <ScrollView>
                    {statistics.onlineOrders
                        .filter((o) => o.status !== "closed" && !o.storno)
                        .concat(statistics.unClosedTakeAwayOrders.filter((o) => o.status !== "closed" && !o.storno))
                        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort by descending date
                        .filter((o) => o.restaurantPlace === restaurantPlace?._id && o.status !== "pending")
                        .map((order, index) => (
                            <Swipeable
                                key={order._id}
                                ref={ref => swipeableRefs.current[index] = ref}
                                renderRightActions={() => renderRightActions(order._id)}
                                renderLeftActions={() => renderLeftActions(order._id)}
                                onSwipeableWillOpen={(direction) => {
                                    if (direction === 'right') {
                                        toggleOrderDetails(order._id);
                                    } else if (direction === 'left') {
                                        handleSwipeLeft(order._id);
                                    }
                                    swipeableRefs.current[index]?.close();
                                }}
                                style={[
                                    styles.orderContainer,
                                    expandedOrder === order._id && styles.orderSummaryExpanded,
                                    selectedOrders.includes(order._id) && styles.selectedOrder,
                                ]}
                            >
                                <TapGestureHandler
                                    onHandlerStateChange={({ nativeEvent }) => {
                                        if (nativeEvent.state === State.ACTIVE) {
                                            toggleOrderDetails(order._id);
                                        }
                                    }}
                                >
                                    <View style={[
                                        styles.orderContainer,
                                        expandedOrder === order._id && styles.orderSummaryExpanded,
                                        selectedOrders.includes(order._id) && styles.selectedOrder,
                                        (!selectedOrders.includes(order._id) && order.preOrder && !order.delivery?.sent) && styles.preOrderBackground, // Apply the preOrder background style// Apply the sent order background style
                                        (!selectedOrders.includes(order._id) && order.delivery?.sent) && styles.sentBg,
                                    ]}>
                                        <View style={[styles.orderSummary]}>
                                            {order?.delivery?.user && order?.delivery?.user?.name  && <View style={{flexDirection: "column", alignItems: 'center', justifyContent: 'center'}}>
                                                <Ionicons
                                                name={'bicycle-outline'}
                                                size={24}
                                                color="black"
                                            />
                                                <Text style={styles.orderTime}>{order?.delivery?.user?.name?.first}</Text>
                                            </View>}
                                            <View style={{ alignItems: 'center', justifyContent: 'center', flexDirection: "column" }}>
                                                <Text style={styles.orderTime}>{new Date(order.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</Text>
                                                <OrderFromIcon orderData={order} />
                                            </View>
                                            <View>
                                                <Text style={[styles.orderCode, { textAlign: 'center' }]}>#{order.orderCode}</Text>
                                                {order?.userInfo?.name && <Text style={{ textAlign: 'center', fontWeight: "bold" }}>{order.userInfo?.name?.last} {order.userInfo?.name?.first}</Text>}
                                                {(order?.addressInfo && order.orderType === "online") && <Text style={{ textAlign: 'center' }}>{order.addressInfo?.zip} {order.addressInfo?.city}, {order.addressInfo?.address}</Text>}
                                            </View>
                                            <View style={{ flexDirection: "column" }}>
                                                <Text style={styles.orderPrice}>{priceFormatter(order.orderSum)} </Text>
                                                <Text style={styles.itemCount}>{order.items.length} {t("item-s")}</Text>
                                                <Text style={styles.orderPrice}>{convertType(order.paymentType)}</Text>
                                            </View>
                                        </View>
                                        {expandedOrder === order._id && (
                                            <View style={[styles.orderDetails]}>
                                                {order.items.map((item, index) => {
                                                    const selectedSize = item.item.sizes.find(size => size._id === item.size);
                                                    return (
                                                             <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 4 }}>
                                                                <Text style={[styles.itemText, { fontWeight: "bold" }]}>{item.amount}x</Text>
                                                                <Text style={styles.itemText}>{item.name} ({selectedSize.unit})</Text>
                                                                <Text style={[styles.itemText, { fontWeight: "bold" }]}>{priceFormatter(item.remotePrice || item.price)}</Text>
                                                        </View>
                                                    );
                                                })}
                                                {order.preOrder &&  <Text style={{
                                                    fontWeight: "bold",
                                                    fontSize: 18,
                                                    paddingBottom: 6,
                                                }}> {t("preorder")}: {new Date(order.preOrder).toLocaleString('hu-HU', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).replace(',', ' -')}</Text>

                                                }
                                            </View>)}
                                    </View>
                                </TapGestureHandler>
                                {expandedOrder === order._id && (
                                    <View style={{ flexDirection: "row" }}>

                                        {!order.printed && <TouchableOpacity
                                            onPress={async () => {

                                                const generateQRCodeString = (storedOrder) => {
                                                    const { orderCode, items, paymentTypes, orderSum, paymentType } = storedOrder;

                                                    // Initialize the QR code string with the base URL and command
                                                    let qrCodeString = `fiscat/AEE|SLD||${orderCode.replace("-", "")}|6|Rendelés száma:|#${orderCode}|-------------|www.happygastro.hu|Üzemelteti a|Happy Gastro||||${items.length}|`;

                                                    const discountPercentage = storedOrder?.discounts?.percentage || 0;

                                                    const serviceFees = {};

                                                    // Add each item to the QR code string
                                                    items.forEach(item => {
                                                        const itemName = item.remoteName || item.name?.hu || item.name || 'GYŰJTŐ';
                                                        const vatCode = 2; // Assuming VAT code is always 2
                                                        const itemPrice = item.remotePrice || item.price || '';
                                                        const itemQuantity = item.amount || item.quantity || 1;

                                                        qrCodeString += `${itemName}|${vatCode}|${itemPrice}|${itemQuantity}|${discountPercentage > 0 ? "M"+discountPercentage : ""}|`;
                                                    });

                                                    // Add payment types and amounts
                                                    if(storeOrderResponse.paymentTypes && storeOrderResponse.paymentTypes.length > 0) {
                                                        storeOrderResponse.paymentTypes.forEach(payment => {
                                                            const paymentType = payment.paymentType === "cash" ? "P" : "N";
                                                            const paymentAmount = (payment.paymentType === "cash" ? hungarianRoundNumbers(payment.amount) : payment.amount) || 0;

                                                            qrCodeString += `${paymentType}|${paymentAmount}`;
                                                        });
                                                    } else {
                                                        const paymentTypeCode = storeOrderResponse.paymentType === "cash" ? "P" : "N";
                                                        const paymentAmount = orderSum || '';
                                                        qrCodeString += `${paymentTypeCode}|${storeOrderResponse.paymentType === "cash" ? hungarianRoundNumbers(paymentAmount) : paymentAmount}`;
                                                    }

                                                    return qrCodeString;
                                                };

                                                const sendToCashier = () => {
                                                    try {
                                                        new Promise(async () => {
                                                            const paymentCashier = await AsyncStorage.getItem('cashierDevice');
                                                            if (paymentCashier) {
                                                                // Send serial Bluetooth data
                                                                if (Platform.OS === "android") {
                                                                    try {
                                                                        console.log("QR KÓD:", generateQRCodeString(order))
                                                                        let dataToSend = generateQRCodeString(order)
                                                                        console.info("Fiscat Data full:", dataToSend);
                                                                        new Promise(() => sendData(dataToSend));
                                                                    } catch (e) {
                                                                        Alert.alert("Adóügyi nyomtatási hiba", "Nem sikerült a nyugta nyomtatása a pénztárgépre. Kérjük, próbálja újra!");
                                                                    }
                                                                }
                                                            }
                                                        });
                                                    } catch (e) {
                                                        if (Platform.OS === "android") {
                                                            return ToastAndroid.showWithGravity('Nem sikerült a pénztárgép adatküldés!', 15000, ToastAndroid.CENTER);
                                                        } else {
                                                            return alert('Nem sikerült a pénztárgép adatküldés!');
                                                        }
                                                    }
                                                };

                                                try {
                                                    new Promise(() => RawBTPrintTicket(order, order.items));
                                                    sendToCashier();
                                                } catch (e) {
                                                    Alert.alert("Sikertelen nyomtatás!", e.message);
                                                }

                                                try {
                                                    const token = await getAccessToken();
                                                    const response = await fetch(`https://api.happygastro.hu/api/order/${order._id}/update`, {
                                                        method: 'POST',
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            'Authorization': `Bearer ${token}`
                                                        },
                                                        body: JSON.stringify({ printed: true })
                                                    });
                                                    if (!response.ok) {
                                                        Alert.alert(`Failed to update order ${order._id}`, response.statusText);
                                                    }
                                                    return response.json();
                                                } catch (err) {
                                                    console.log("Error while updating orders:", err);
                                                }
                                            }}
                                            style={{ flex: 1, backgroundColor: '#494947', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderRadius: 5 }}
                                        >
                                            <Ionicons name="print" size={24} color="white" />
                                            <Text style={{color: 'white'}}>{t("print")}</Text>
                                        </TouchableOpacity>}
                                        <TouchableOpacity
                                            onPress={() => navigation.navigate('OrderDetailsScreen', { orderData: order, orderId: order._id })}
                                            style={{ flex: 1 }}
                                        >
                                            <Text style={styles.detailsButton}>{t("details")}</Text>
                                        </TouchableOpacity>
                                        <StatusUpdateButton order={order} />
                                    </View>
                                )}
                            </Swipeable>
                        ))}
                </ScrollView>
            </View>
            <HappyAlert
                visible={alertVisible}
                onConfirm={() => typeof confirmAction === 'function' ? confirmAction() : setAlertVisible(false)}
                onCancel={() => setAlertVisible(false)}
                type={alertType}
                message={alertMessage}
                cancelText={t('cancel')}
                confirmText={t('confirm')}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    leftColumn: {
        backgroundColor: "transparent",
        width: '60%',
    },
    rightColumn: {
        backgroundColor: '#f0f0f0',
        width: '40%',
        padding: 10,
    },
    itemText: {
        fontSize: 14,
        color: '#333',
        marginBottom: 4,
    },
    restaurantName: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        paddingVertical: 8,
        borderBottomWidth: 0.8,
        borderBottomColor: 'black'
    },
    orderContainer: {
        padding: 0,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
    },
    orderSummary: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        paddingVertical: 12,
    },
    orderSummaryExpanded: {
        backgroundColor: '#e0e0e0', // Lighter background color for expanded order
    },
    orderDetails: {
        paddingTop: 2,
        backgroundColor: 'rgba(255,255,255,0.19)',
    },
    orderDetailsExpanded: {
        backgroundColor: '#e0e0e0', // Lighter background color for expanded order details
    },
    orderTime: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    orderCode: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    preOrderBackground: {
        backgroundColor: '#ffe5b4', // Light orange background
    },
    sentBg: {
        backgroundColor: 'rgba(84,209,255,0.81)', // Light orange background
    },
    orderDate: {
        fontSize: 12,
        color: '#888', // Grey color
    },
    orderPrice: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    itemCount: {
        fontSize: 16,
        color: '#616161',
        textAlign: 'left'
    },
    detailsButton: {
        color: '#fff',
        backgroundColor: '#f76e24',
        paddingVertical: 8,
        paddingHorizontal: 16,
        borderRadius: 5,
        textAlign: 'center',
        fontWeight: 'bold',
        marginBottom: 5,
    },
    icon: {
        color: '#6e6e6e',
    },
    statusUpdateButton: {
        marginLeft: 10,             // Add spacing between buttons
    },
    detailsButtonText: {
        color: '#fff',
        fontWeight: 'bold',
        textAlign: "center"
    },
    menu: {
        width: 240,
        position: 'absolute',
        top: 0,
        zIndex: 67234,
        backgroundColor: '#fff',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
        paddingVertical: 10,
        backgroundColor: '#e0e0e0',
    },
    tabButton: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    tabButtonText: {
        marginTop: 5,
        fontSize: 14,
        color: '#333',
    },
    swipeButton: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 80,
        backgroundColor: 'transparent',
    },
    selectedOrder: {
        backgroundColor: '#d1e7dd', // Light green background for selected orders
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    }
});

export default React.memo(OnlineMapScreen);