import { SET_AUTHENTICATION, SET_TOKEN, SET_USER } from "../actions/keys";
import DefaultState from "./default";

const INIT_STATE = DefaultState.user;

const UserReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload || action.user };
    case SET_TOKEN:
      return { ...state, token: action.token };
    case SET_AUTHENTICATION:
      return { ...state, token: action.token, user: action.user };
    default:
      return state;
  }
};

export default UserReducer;
