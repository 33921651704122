import React, { useState } from 'react';
import { View, Text, StyleSheet, FlatList, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const ProductTrafficScreen = ({ route }) => {
    const { orders, selectedDate } = route.params;
    const [sortCondition, setSortCondition] = useState('productName');
    const [isAscending, setIsAscending] = useState(true);
    const currentDate = new Date().toLocaleDateString();

    const calculateProductStatistics = (orders) => {
        let productsList = {};

        orders.filter(o => !o.storno && o.status !== 'cancelled').forEach(order => {
            order.items.forEach(cartItem => {
                const productName = cartItem.item.name['hu'];
                const productAmount = cartItem.amount;
                const productPrice = cartItem.price;
                const productDiscount = cartItem.discount || 0;
                const productPriceSum = productAmount * (productPrice - productDiscount);

                if (!productsList[productName]) {
                    productsList[productName] = { amount: 0, price: 0, discounts: 0, priceSum: 0 };
                }

                productsList[productName].amount += productAmount;
                productsList[productName].price = productPrice;
                productsList[productName].discounts += productDiscount;
                productsList[productName].priceSum += productPriceSum;
            });
        });

        return Object.keys(productsList).map(key => ({
            productName: key,
            ...productsList[key]
        }));
    };

    const productStatistics = calculateProductStatistics(orders).sort((a, b) => {
        if (isAscending) {
            return a[sortCondition] > b[sortCondition] ? 1 : -1;
        } else {
            return a[sortCondition] < b[sortCondition] ? 1 : -1;
        }
    });

    return (
        <View style={styles.container}>
            <View style={styles.topContainer}>
                <View style={styles.buttonContainer}>
                    {['productName', 'amount', 'priceSum'].map((condition, index) => (
                        <TouchableOpacity
                            key={index}
                            onPress={() => setSortCondition(condition)}
                            style={[styles.button, sortCondition === condition && styles.selectedButton]}
                        >
                            <Text style={styles.buttonText}>{condition === 'productName' ? 'Alphabetical' : condition.charAt(0).toUpperCase() + condition.slice(1)}</Text>
                        </TouchableOpacity>
                    ))}
                    <TouchableOpacity onPress={() => setIsAscending(!isAscending)} style={styles.button}>
                        <Ionicons name={isAscending ? 'arrow-up' : 'arrow-down'} size={24} color="white" />
                    </TouchableOpacity>
                </View>
                <View style={styles.dateContainer}>
                    <Text style={styles.dateText}>{selectedDate ? selectedDate.toLocaleDateString() : currentDate}</Text>
                </View>
            </View>
            <View style={styles.tableHeader}>
                <Text style={styles.headerText}>Product Name</Text>
                <Text style={styles.headerText}>Amount</Text>
                <Text style={styles.headerText}>Price</Text>
                <Text style={styles.headerText}>Discounts</Text>
                <Text style={styles.headerText}>Price Sum</Text>
            </View>
            <FlatList
                data={productStatistics}
                keyExtractor={(item) => item.productName}
                renderItem={({ item }) => (
                    <View style={styles.tableRow}>
                        <Text style={styles.rowText}>{item.productName}</Text>
                        <Text style={styles.rowText}>{item.amount}</Text>
                        <Text style={styles.rowText}>{item.price}</Text>
                        <Text style={styles.rowText}>{item.discounts}</Text>
                        <Text style={styles.rowText}>{item.priceSum}</Text>
                    </View>
                )}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
        backgroundColor: '#fff',
    },
    topContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
    },
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    button: {
        backgroundColor: '#f1b34b',
        paddingVertical: 8,
        paddingHorizontal: 12,
        marginHorizontal: 5,
        borderRadius: 5,
    },
    selectedButton: {
        backgroundColor: '#ff8800',
    },
    buttonText: {
        fontSize: 16,
        color: 'white',
        fontWeight: 'bold',
    },
    dateContainer: {
        backgroundColor: 'orange',
        paddingVertical: 8,
        paddingHorizontal: 12,
        borderRadius: 5,
    },
    dateText: {
        fontSize: 16,
        color: 'white',
        fontWeight: 'bold',
    },
    tableHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 8,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
    },
    headerText: {
        fontWeight: 'bold',
        flex: 1,
        textAlign: 'center',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 8,
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
    },
    rowText: {
        flex: 1,
        textAlign: 'center',
    },
});

export default ProductTrafficScreen;
