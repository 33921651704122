import React, {useEffect, useState} from "react";
import './shim';
import {Provider as ThemeProvider} from "react-native-paper";
import {
    Dimensions,
    Linking,
    StatusBar,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Platform,
    TextInput, Modal
} from "react-native";
import {NavigationContainer} from "@react-navigation/native";
import {createStackNavigator} from "@react-navigation/stack";
import {theme} from "./app/core/theme";
import OrderNotificationService from "./app/helpers/OrderNotificationService"
import {
    HomeScreen,
    LoginScreen,
    NotificationsScreen,
    OnlineScreen,
    OrderDetailsScreen,
    SettingsPrinterPairingScreen,
    StartScreen,
    SuccessEntryScreen,
    SuccessPaymentScreen,
    TablesScreen,
    TermsOfServicesScreen,
} from "./app/screens";
import VipUserScreen from "./app/screens/VipUserScreen";
import VipUserDetailScreen from './app/screens/VipUserDetailScreen';
import Toast from 'react-native-toast-message';
import SplashScreenComponent from './app/screens/SplashScreen';
import {Ionicons} from '@expo/vector-icons';
import PaymentScreen from "./app/screens/PaymentScreen";
import ResultScreen from "./app/screens/ResultScreen";
import WaitingForPayment from "./app/screens/WaitingForPayment";
import FailedPaymentScreen from "./app/screens/FailedPaymentScreen";
import TicketEntryScreen from "./app/screens/TicketEntryScreen";
import {BluetoothProvider} from "./app/helpers/BluetoothContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {Root} from "react-native-popup-confirm-toast";

import FailedEntryScreen from "./app/screens/FailedEntryScreen";
import PaymentsSettingsScreen from "./app/screens/PaymentsSettingsScreen";
import ScanDevicesScreen from "./app/screens/ScanDevicesScreen";
import PrivacyPolicyScreen from "./app/screens/PrivacyPolicyScreen";
import {activateKeepAwakeAsync} from 'expo-keep-awake';

import * as TaskManager from 'expo-task-manager';
import * as BackgroundFetch from 'expo-background-fetch';
import DayClosingScreen from "./app/screens/DayClosingScreen";
import DayOpeningScreen from "./app/screens/DayOpeningScreen";
import DayOpeningConfirmationScreen from "./app/screens/DayOpeningConfirmationScreen";
import Observables from '@happy-gastro/global-observables';
import DesignSettingsScreen from "./app/screens/DesignSettingsScreen";
import SuccessDayClosingScreen from "./app/screens/SuccessDayClosingScreen";
import SupportChatScreen from "./app/screens/SupportChatScreen";

import {persistor, store} from "./app/store";
import {Provider as ReduxProvider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";

import './happyWebStyles.css';
import {TableProvider} from "./app/helpers/TableContext";
import SettingsPrinterSearchScreen from "./app/screens/SettingsPrinterSearchScreen";
import StatisticsScreen from "./app/screens/StatisticsScreen";
import * as Font from "expo-font";
import SystemProvider from "./app/helpers/SystemContext";
import CartProvider from "./app/helpers/CartContext";
import OrdersProvider from "./app/helpers/OrdersContext";
import OrderListScreen from "./app/screens/OrderListScreen";
import AddVipUserScreen from "./app/screens/AddVipUserScreen";
import {VipCardProvider} from './app/helpers/VipCardContext';
import {priceFormatter} from "./app/helpers";
import OrderAcceptTimePicker from "./app/components/OrderAcceptTimePicker";
import OrderRejectModal from "./app/components/OrderRejectModal";
import OrderDetailsModal from "./app/helpers/OrderDetailsModal";
import axios from "axios";
import {ApiConfig} from "./app/config/ApiConfig";
import {getAccessToken} from "./app/helpers/storage";
import OnlineOrderProvider from './app/helpers/OnlineOrderContext';
import SettingsScreen from "./app/screens/SettingsScreen";
import OrdersScreen from "./app/screens/OrdersScreen";
import TablesBookingsScreen from "./app/screens/TablesBookingsScreen";
import NewBookingModal from "./app/helpers/NewBookingModal";
import BookingResultModal from "./app/helpers/BookingResultModal";
import {useTranslation} from "react-i18next";
import {RawBTPrintKitchen} from "./app/helpers/rawBTDriver";
import i18n from "./i18n"
import OtherSettingsScreen from "./app/screens/OtherSettingsScreen";
import {Notifications} from "react-native-notifications";
import { CurrentScreenProvider } from './app/helpers/CurrentScreenContext';

import { NativeModules } from "react-native";
import ProductTrafficScreen from "./app/screens/ProductTrafficScreen";
import NovoTerminalSettingsScreen from "./app/screens/NovoTerminalSettingsScreen";
import Sam4sSettingsScreen from "./app/screens/Sam4sCashierSettingsScreen";
import ExpensesScreen from "./app/screens/ExpensesScreen";
import {TourProvider} from "./app/helpers/TourContext";
import HappyAlert from "./app/helpers/crossPlatformTools/HappyAlert";
import BookingDetailsScreen from "./app/screens/BookingDetailsScreen";

const { SecondaryScreen } = NativeModules;

let moment = require('moment');
require('moment/locale/hu');
moment.locale('hu');


const BACKGROUND_FETCH_TASK = 'happy-services-sync';
TaskManager.defineTask(BACKGROUND_FETCH_TASK, async (result) => {
    try {
        // Perform your background task here
        // Example: Fetch data from an API
        console.log('Background fetch task running');
        // const orders = await fetchOrdersFromAPI();

        return {type: 'BACKGROUND_TASK', action: BACKGROUND_FETCH_TASK};
    } catch (error) {
        console.error('Background fetch task error:', error);
        return false;
    }
});

const Stack = createStackNavigator();
let interval;
export default function App({navigation, route}) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [appIsReady, setAppIsReady] = useState(false);
    const [isSplashVisible, setIsSplashVisible] = useState(true);
    const [isDayOpen, setIsDayOpen] = useState(false);
    const [currentRestaurant, setCurrentRestaurant] = useState(false);
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const {t} = useTranslation();

    const removeToast = (id) => {
        Toast.hide(id)
    }


    React.useEffect(() => {

        const getSavedLanguage = async () => {
            const savedLanguage = await AsyncStorage.getItem('selectedLanguage');
            return savedLanguage || 'en';
        };

        const initializeLanguage = async () => {
            const language = await getSavedLanguage();
            //console.log("Changing language to:", language)
            i18n.changeLanguage(language);
        };

        initializeLanguage();
    }, []);

    const toastConfig = {
        pendingOrderToast: ({props}) => {
            const {t} = useTranslation();
            const [isAcceptModalVisible, setAcceptModalVisible] = useState(false);
            const [isRejectModalVisible, setIsRejectModal] = useState(false);
            const [isDescriptionOpened, setIsDescriptionOpened] = useState(false);
            const [timer, setTimer] = useState(10);

            function pairingCheck(orderData, callback) {
                const allItemsContainItem = orderData.items.every(item => item.item); //all paired food?
                if(allItemsContainItem) {
                    callback()
                }else{
                    setIsDescriptionOpened(true)
                }
            }

            const handleAccept = async (time, orderData) => {
                const token = await getAccessToken();
                const response = await fetch(`https://api.happygastro.hu/api/order/${props._id}/status/preparing?orderMinutes=${time}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}` // Replace with your actual token
                    }
                });
                setAcceptModalVisible(false);
                RawBTPrintKitchen(orderData, orderData.items);
                console.log(props)
                Observables.call("reloadOrders")
                Toast.hide(props._id);
            }

            const reverseConvertPaymentType = (paymentType) => {
                if (paymentType === "cash") return t("cash");
                if (paymentType === "creditCard") return t("credit_card");
                if (paymentType === "voucher") return t("voucher");
                if (paymentType === "vipBalance") return t("vip_balance");
                if(paymentType === "employeeBalance") return t("employee_balance")
                return `${paymentType}`;
            }

            const handleReject = async (reason) => {
                const token = await getAccessToken();
                await fetch(`${ApiConfig.apiURL}/order/${props._id}/status/cancelled?rejectReason=${reason}&storno=true`, {
                    method: 'GET',
                    headers: {
                        'Accept-Language': 'hu_HU',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                setIsRejectModal(false);
                Toast.hide(props._id);
            }

            const updateURL = "/api/order/" + props._id + "/update";

            React.useEffect(() => {
                const _startTimerIfAutoAcceptIsOn = async () => {
                    const isAllPaired = props?.items?.every(item => item.item); // all paired food?
                    const automaticAcceptOrders = await AsyncStorage.getItem('automaticAcceptOrders');
                    if (automaticAcceptOrders && isAllPaired && !isDescriptionOpened && !isAcceptModalVisible && !isRejectModalVisible) {
                        interval = setInterval(() => {
                            setTimer(prevTimer => {
                                if (prevTimer > 0) {
                                    return prevTimer - 1;
                                } else {
                                    handleAccept(props.orderFrom === "wolt" ? 24 : 35, props);
                                    clearInterval(interval);
                                    return 0;
                                }
                            });
                        }, 1000);
                    }
                };
                _startTimerIfAutoAcceptIsOn();
                return () => clearInterval(interval);
            }, []);

            const enableExternalTouch = () => {
                if (Platform.OS === "android" && SecondaryScreen) {
                    SecondaryScreen.enableTouchEvents();
                }
            };

            React.useEffect(() => {
                enableExternalTouch();
            }, []);

            return (<>

                    { isAcceptModalVisible && <OrderAcceptTimePicker
                        isVisible={isAcceptModalVisible}
                        onClose={() => setAcceptModalVisible(false)}
                        onConfirm={handleAccept}
                        orderData={props}
                    />}
                    <OrderRejectModal
                        isVisible={isRejectModalVisible}
                        onClose={() => setIsRejectModal(false)}
                        onConfirm={handleReject}
                    />
                    <OrderDetailsModal
                        orderData={props}
                        orderId={props._id}
                        isVisible={isDescriptionOpened}
                        onClose={() => setIsDescriptionOpened(false)}
                        onConfirm={() => {
                            setIsDescriptionOpened(false)
                        }}
                    />

                    <View style={styles.container}>
                        <View style={styles.timerContainer}>
                            <Text style={styles.timerText}>{timer}</Text>
                        </View>
                        <Text style={styles.title}>{props.message || t("new_incoming_order")}</Text>

                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <Text
                                style={styles.subText}>{props.orderType === "online" ? t("home_delivery") : t("take_away")}</Text>
                            <Text
                                style={styles.subText}>{t("total_amount")}: {priceFormatter(props.orderSum || 0)}</Text>
                        </View>
                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <Text style={styles.subText}>{t("payment_method")}: </Text>
                            <Text
                                style={[styles.subText, {fontWeight: "bold"}]}>{reverseConvertPaymentType(props.paymentType || props.paymentTypes[0])}</Text>
                        </View>
                        <View style={styles.buttonContainer}>
                            <TouchableOpacity style={[styles.button, styles.rejectButton]}
                                              onPress={() => setIsRejectModal(true)}>
                                <Text style={styles.buttonText}>{t("reject")}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={[styles.button, styles.openButton]}
                                              onPress={() => {
                                                  clearInterval(interval);
                                                  setTimer(10);
                                                  setIsDescriptionOpened(true)
                                              }}>
                                <Text style={styles.buttonText}>{t("view")}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={[styles.button, styles.acceptButton]}
                                              onPress={() => {
                                                  pairingCheck(props, () =>  setAcceptModalVisible(true))
                                              }}>
                                <Text style={styles.buttonText}>{t("accept")}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </>
            );
        }
    };

    React.useEffect(() => {

        const getRestaurantData = async () => {
            const restaurantStored = await AsyncStorage.getItem("restaurant");
            if (restaurantStored) setCurrentRestaurant(JSON.parse(restaurantStored));
        }
        const keepAwake = async () => {
            await activateKeepAwakeAsync();
        }
        getRestaurantData();
        keepAwake();

        Observables.register('reloadDayOpeningClosing', async (data) => {
            const dayOpened = await AsyncStorage.getItem('dayOpened');
            if (dayOpened === 'true' || dayOpened) {
                setIsDayOpen(true);
            } else {
                setIsDayOpen(false);
            }
        });

        const loadFont = async () => {

            await Font.loadAsync({
                "MaterialIcons": require("@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialIcons.ttf"),
                "FontAwesome": require("@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/FontAwesome.ttf"),
                "FontAwesome5": require("@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf"),
                "Ionicons": require("@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Ionicons.ttf"),
                "MaterialCommunityIcons": require("@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf"),
                "Feather": require("@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Feather.ttf")
            });

            const dayOpened = await AsyncStorage.getItem('dayOpened');
            if (dayOpened === 'true' || dayOpened) {
                setIsDayOpen(true);
            } else {
                setIsDayOpen(false);
            }
        };

        loadFont();
    }, []);


    React.useEffect(() => {
        const initBackgroundFetch = async () => {
            try {
                await BackgroundFetch.registerTaskAsync(BACKGROUND_FETCH_TASK, {
                    minimumInterval: 15, // Fetch interval in minutes
                    stopOnTerminate: false, // Continue running after app is terminated
                    startOnBoot: true, // Start task when device is booted
                });
                console.log('Background fetch task registered');
            } catch (error) {
                console.error('Background fetch task registration error:', error);
            }
        };

        initBackgroundFetch();
    }, []);


    const handleAnimationFinish = () => {
        setIsSplashVisible(false);
    };

    React.useEffect(() => {
        if (route?.params?.successCloseDay) {
            setIsDayOpen(route?.params?.successCloseDay || false);
        }
    }, [route]);

    if (isSplashVisible) {
        return <SplashScreenComponent onAnimationFinish={handleAnimationFinish}/>;
    }


    const openAdmin = async () => {
        const link = 'https://admin.happygastro.hu/billing/main?token=' + await getAccessToken(); // Replace with your email address
        Linking.canOpenURL(link)
            .then((supported) => {
                if (supported) {
                    Linking.openURL(link);
                } else {
                    console.log("Don't know how to open URI: " + link);
                }
            })
            .catch((err) => console.error('An error occurred', err));
    };

    const ExternalDisplayWrapper = Platform.OS === "android" ? require("./app/components/ExternalDisplayWrapper").ExternalDisplayWrapper : null;

    // TODO: Change back to StartScreen instead of HomeScreen, and make isLoggedIn: false
    return (
        <ThemeProvider theme={theme}>
            <TourProvider>
            <NavigationContainer>
                <Root>
                    <ReduxProvider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <CurrentScreenProvider>
                                <OrdersProvider>
                                    <OnlineOrderProvider>
                                        <SystemProvider>
                                            <VipCardProvider>
                                            <TableProvider>
                                                <CartProvider>
                                                    <BluetoothProvider>
                                                        {Platform.OS === "android" && <ExternalDisplayWrapper/>}
                                                        <StatusBar hidden={true} backgroundColor="transparent"
                                                                   barStyle="dark-content"/>
                                                        {currentRestaurant && currentRestaurant?.customerStatus === "pausedForPayment" && (
                                                            <View style={{
                                                                position: "absolute",
                                                                top: 30,
                                                                zIndex: 999,
                                                                width: Dimensions.get("screen").width - 60,
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                backgroundColor: '#f8d7da',
                                                                padding: 10,
                                                                borderRadius: 10,
                                                                marginLeft: 30,
                                                                marginRight: 30,
                                                                shadowColor: "#000",
                                                                shadowOffset: {width: 0, height: 2},
                                                                shadowOpacity: 0.25,
                                                                shadowRadius: 3.84,
                                                                elevation: 5,
                                                            }}>
                                                                <Ionicons name="alert-circle-outline" size={24}
                                                                          color="#721c24" style={{marginRight: 10}}/>
                                                                <Text style={{
                                                                    color: '#721c24',
                                                                    textAlign: 'center',
                                                                    flex: 1,
                                                                    fontWeight: 700
                                                                }}>
                                                                    {t("failedSubscriptionFee_alertTextHead")}<br/>{t("failedSubscriptionFee_alertTextSub")}
                                                                </Text>
                                                                <TouchableOpacity
                                                                    style={{
                                                                        backgroundColor: 'orange',
                                                                        paddingVertical: 8,
                                                                        paddingHorizontal: 16,
                                                                        borderRadius: 5,
                                                                        marginLeft: 'auto',
                                                                    }}
                                                                    onPress={() => openAdmin()}
                                                                >
                                                                    <Text style={{
                                                                        color: '#fff',
                                                                        fontWeight: 'bold'
                                                                    }}>{t("failedSubscriptionFee_solvePayment")}</Text>
                                                                </TouchableOpacity>
                                                            </View>
                                                        )}
                                                        <Stack.Navigator
                                                            initialRouteName={!isLoggedIn ? "StartScreen" : (isDayOpen ? "HomeScreen" : "DayOpeningScreen")}>
                                                            <Stack.Screen
                                                                name="StartScreen"
                                                                component={StartScreen}
                                                                options={{
                                                                    title: t('start_screen_title'),
                                                                    headerShown: false,
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    },
                                                                    headerLeft: null, // Disable left icon
                                                                    headerRight: null, // Disable right icon
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="LoginScreen"
                                                                component={LoginScreen}
                                                                options={{
                                                                    title: t('login_screen_title'),
                                                                    headerShown: false,
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    },
                                                                    headerLeft: null, // Disable left icon
                                                                    headerRight: null, // Disable right icon
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="HomeScreen"
                                                                component={HomeScreen}
                                                                options={{
                                                                    title: t('home_screen_title'),
                                                                    headerShown: false,
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="TablesScreen"
                                                                component={TablesScreen}
                                                                options={{
                                                                    title: t('tables_screen_title'),
                                                                    headerShown: false,
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="OnlineScreen"
                                                                component={OnlineScreen}
                                                                options={{
                                                                    title: t('online_screen_title'),
                                                                    headerShown: false,
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="TermsOfServicesScreen"
                                                                component={TermsOfServicesScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('terms_of_services_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="DayOpeningScreen"
                                                                component={DayOpeningScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('day_opening_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="DayOpeningConfirmationScreen"
                                                                component={DayOpeningConfirmationScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('day_opening_confirmation_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="DayClosingScreen"
                                                                component={DayClosingScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('day_closing_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    },
                                                                }}
                                                            />

                                                            <Stack.Screen
                                                                name="StatisticsScreen"
                                                                component={StatisticsScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('statistics_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    },
                                                                }}
                                                            />

                                                            <Stack.Screen name="OrderListScreen"
                                                                          component={OrderListScreen}
                                                                          options={{
                                                                              headerShown: true,
                                                                              title: t('order_list_screen_title'),
                                                                              headerTitleAlign: 'center', // Center align the title text
                                                                              backTitle: t("go_back"),
                                                                              gestureEnabled: true,
                                                                              transitionSpec: {
                                                                                  open: {
                                                                                      animation: 'timing',
                                                                                      config: {duration: 500}
                                                                                  },
                                                                                  close: {
                                                                                      animation: 'timing',
                                                                                      config: {duration: 500}
                                                                                  },
                                                                              },
                                                                          }}/>
                                                            <Stack.Screen
                                                                name="SuccessDayClosingScreen"
                                                                component={SuccessDayClosingScreen}
                                                                options={{
                                                                    headerShown: false,
                                                                    title: t('success_day_closing_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="PrivacyPolicyScreen"
                                                                component={PrivacyPolicyScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('privacy_policy_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="PaymentsSettingsScreen"
                                                                component={PaymentsSettingsScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('payments_settings_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    }
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="NovoTerminalSettingsScreen"
                                                                component={NovoTerminalSettingsScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('novopayments_settings_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    }
                                                                }}
                                                            /><Stack.Screen
                                                                name="Sam4sSettingsScreen"
                                                                component={Sam4sSettingsScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('sam4s_settings_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    }
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="SettingsScreen"
                                                                component={SettingsScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('settings_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    }
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="DesignSettingsScreen"
                                                                component={DesignSettingsScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('design_settings_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    }
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="OtherSettingsScreen"
                                                                component={OtherSettingsScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('other_settings'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    }
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="SettingsPrinterSearchScreen"
                                                                component={SettingsPrinterSearchScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('settings_printer_search_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    }
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="SettingsPrinterPairingScreen"
                                                                component={SettingsPrinterPairingScreen}
                                                                options={({navigation}) => ({
                                                                    headerShown: true,
                                                                    title: t('settings_printer_pairing_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    },
                                                                    headerRight: () => (
                                                                        <TouchableOpacity style={{
                                                                            marginRight: 15,
                                                                            flexDirection: "row"
                                                                        }}
                                                                                          onPress={() => navigation.navigate('SettingsPrinterSearchScreen')}>
                                                                            <Ionicons name="print-outline" size={24}
                                                                                      color="black"/>
                                                                            <Ionicons name="search-outline" size={15}
                                                                                      color="black"/>
                                                                        </TouchableOpacity>
                                                                    ),
                                                                })}
                                                            />
                                                            <Stack.Screen
                                                                name="SupportChatScreen"
                                                                component={SupportChatScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('support_chat_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    }
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="OrderDetailsScreen"
                                                                component={OrderDetailsScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('order_details_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    }
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="ProductTrafficScreen"
                                                                component={ProductTrafficScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('product_statistics'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    }
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="ExpensesScreen"
                                                                component={ExpensesScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('expenses'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    },

                                                                    headerRight: () => {
                                                                        const [expenses, setExpenses] = useState([]);
                                                                        const [modalVisible, setModalVisible] = useState(false);
                                                                        const [newDescription, setNewDescription] = useState("");
                                                                        const [newPrice, setNewPrice] = useState("");
                                                                        const { width, height } = Dimensions.get('window');

                                                                        useEffect(() => {
                                                                            loadExpenses();
                                                                        }, []);

                                                                        const loadExpenses = async () => {
                                                                            try {
                                                                                const storedExpenses = await AsyncStorage.getItem("expenses");
                                                                                if (storedExpenses) {
                                                                                    setExpenses(JSON.parse(storedExpenses));
                                                                                }
                                                                            } catch (error) {
                                                                                console.error("Failed to load expenses", error);
                                                                            }
                                                                        };
                                                                        const addExpense = async () => {
                                                                            if (!newDescription || !newPrice) return;
                                                                            const newExpense = { description: newDescription, price: parseFloat(newPrice) };
                                                                            const updatedExpenses = [...expenses, newExpense];
                                                                            setExpenses(updatedExpenses);
                                                                            await AsyncStorage.setItem("expenses", JSON.stringify(updatedExpenses));
                                                                            setNewDescription("");
                                                                            setNewPrice("");
                                                                            setModalVisible(false);
                                                                            Observables.call("refreshExpenses")
                                                                        };

                                                                        return (<React.Fragment>
                                                                            <TouchableOpacity style={{marginRight: 15}}
                                                                                              onPress={() => setModalVisible(true)}>
                                                                                <Ionicons name="add-circle-outline"
                                                                                          size={35}
                                                                                          color="orange"/>
                                                                            </TouchableOpacity>
                                                                            <Modal visible={modalVisible} animationType="slide" transparent={true}>
                                                                                <View style={styles.modalContainer}>
                                                                                    <View style={styles.modalContent}>
                                                                                        <Text style={styles.title}>{t("add_expense_title")}</Text>
                                                                                        <TextInput
                                                                                            style={styles.input}
                                                                                            placeholder={t("description_text")}
                                                                                            value={newDescription}
                                                                                            onChangeText={setNewDescription}
                                                                                        />
                                                                                        <TextInput
                                                                                            style={styles.input}
                                                                                            placeholder={t("price")}
                                                                                            keyboardType="numeric"
                                                                                            value={newPrice}
                                                                                            onChangeText={(text) => {
                                                                                                const numericText = text.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
                                                                                                setNewPrice(numericText);
                                                                                            }}
                                                                                        />
                                                                                        <View style={{flexDirection: "row", justifyContent: "space-between", paddingTop: 10, width: "100%"}}>
                                                                                            <TouchableOpacity style={styles.smallAddButton} onPress={addExpense} >
                                                                                                <Text>{t("add")}</Text>
                                                                                            </TouchableOpacity>
                                                                                            <TouchableOpacity style={styles.cancelButton} onPress={() => setModalVisible(false)} >
                                                                                                <Text>{t("cancel")}</Text>
                                                                                            </TouchableOpacity>
                                                                                        </View>
                                                                                    </View>
                                                                                </View>
                                                                            </Modal>
                                                                        </React.Fragment>)
                                                                    }
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="ScanDevicesScreen"
                                                                component={ScanDevicesScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('scan_devices_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    }
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="NotificationsScreen"
                                                                component={NotificationsScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('notifications_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    leftIconColor: '#ffffff',
                                                                    gestureEnabled: true,
                                                                    headerStyle: {
                                                                        backgroundColor: '#393939', // Set the desired background color
                                                                    },
                                                                    headerTintColor: '#ffffff', // Change the back arrow color here
                                                                    headerTitleStyle: {
                                                                        color: 'rgba(255,255,255,1)', // Set the desired color
                                                                    },
                                                                    icon: "#FEFEFE",
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 100}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 100}
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="VipUserScreen"
                                                                component={VipUserScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('vip_user_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="AddVipUserScreen"
                                                                component={AddVipUserScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('add_vip_user_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="VipUserDetailScreen"
                                                                component={VipUserDetailScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('vip_user_detail_screen_title'),
                                                                    headerTitleAlign: 'center',
                                                                    backTitle: t("go_back"),
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="PaymentScreen"
                                                                component={PaymentScreen}
                                                                options={{
                                                                    headerShown: false,
                                                                    title: t('payment_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    leftIconColor: '#ffffff',
                                                                    gestureEnabled: true,
                                                                    headerStyle: {
                                                                        backgroundColor: '#393939', // Set the desired background color
                                                                    },
                                                                    headerTintColor: '#ffffff', // Change the back arrow color here
                                                                    headerTitleStyle: {
                                                                        color: 'rgba(255,255,255,1)', // Set the desired color
                                                                    },
                                                                    icon: "#FEFEFE",
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 100}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 100}
                                                                        },
                                                                    },
                                                                    headerLeft: null, // Disable left icon
                                                                    headerRight: null, // Disable right icon
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="SuccessPayment"
                                                                component={SuccessPaymentScreen}
                                                                options={{
                                                                    title: t('success_payment_screen_title'),
                                                                    headerShown: false,
                                                                    gestureEnabled: true,
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    },

                                                                    headerLeft: null, // Disable left icon
                                                                    headerRight: null, // Disable right icon
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="FailedPayment"
                                                                component={FailedPaymentScreen}
                                                                options={{
                                                                    headerShown: false,
                                                                    gestureEnabled: true,
                                                                    title: t('failed_payment_screen_title'),
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 500}
                                                                        },
                                                                    },
                                                                    headerLeft: null, // Disable left icon
                                                                    headerRight: null, // Disable right icon
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="TicketEntry"
                                                                component={TicketEntryScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('ticket_entry_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    gestureEnabled: true,
                                                                    headerStyle: {
                                                                        backgroundColor: '#393939', // Set the desired background color
                                                                    },
                                                                    headerTintColor: '#ffffff', // Change the back arrow color here
                                                                    headerTitleStyle: {
                                                                        color: 'rgba(255,255,255,1)', // Set the desired color
                                                                    },
                                                                    icon: "#FEFEFE",
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 100}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 100}
                                                                        },
                                                                    }
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="SuccessEntryScreen"
                                                                component={SuccessEntryScreen}
                                                                options={{
                                                                    headerShown: false,
                                                                    title: t('success_entry_screen_title'),
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 100}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 100}
                                                                        },
                                                                    },
                                                                    headerLeft: null, // Disable left icon
                                                                    headerRight: null, // Disable right icon
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="FailedEntryScreen"
                                                                component={FailedEntryScreen}
                                                                options={{
                                                                    headerShown: false,
                                                                    title: t('failed_entry_screen_title'),
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 100}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 100}
                                                                        },
                                                                    },
                                                                    headerLeft: null, // Disable left icon
                                                                    headerRight: null, // Disable right icon
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="ResultScreen"
                                                                component={ResultScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('result_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    leftIconColor: '#ffffff',
                                                                    gestureEnabled: true,
                                                                    headerStyle: {
                                                                        backgroundColor: '#393939', // Set the desired background color
                                                                    },
                                                                    headerTintColor: '#ffffff', // Change the back arrow color here
                                                                    headerTitleStyle: {
                                                                        color: 'rgba(255,255,255,1)', // Set the desired color
                                                                    },
                                                                    icon: "#FEFEFE",
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 100}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 100}
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="PendingOrdersScreen"
                                                                component={OrdersScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('pending_orders_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    leftIconColor: '#ffffff',
                                                                    gestureEnabled: true,
                                                                    headerStyle: {
                                                                        backgroundColor: '#393939', // Set the desired background color
                                                                    },
                                                                    headerTintColor: '#ffffff', // Change the back arrow color here
                                                                    headerTitleStyle: {
                                                                        color: 'rgba(255,255,255,1)', // Set the desired color
                                                                    },
                                                                    icon: "#FEFEFE",
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 100}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 100}
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="TablesBookingsScreen"
                                                                component={TablesBookingsScreen}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('tables_bookings_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    leftIconColor: '#ffffff',
                                                                    gestureEnabled: true,
                                                                    headerStyle: {
                                                                        backgroundColor: '#393939', // Set the desired background color
                                                                    },
                                                                    headerTintColor: '#ffffff', // Change the back arrow color here
                                                                    headerTitleStyle: {
                                                                        color: 'rgba(255,255,255,1)', // Set the desired color
                                                                    },
                                                                    icon: "#FEFEFE",
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 100}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 100}
                                                                        },
                                                                    },
                                                                    headerRight: () => {
                                                                        const [modalVisible, setModalVisible] = React.useState(false)
                                                                        const [hasBookingResult, setHasBookingResult] = React.useState(false)
                                                                        const [bookingRaw, setBookingRaw] = React.useState({})
                                                                        const handleBookingSubmit = async (data) => {
                                                                            const token = await getAccessToken();

                                                                            try {
                                                                                const response = await fetch("https://api.happygastro.hu/api/booking", {
                                                                                    method: "POST",
                                                                                    headers: {
                                                                                        "Content-Type": "application/json",
                                                                                        "authorization": `Bearer ${token}`,
                                                                                    },
                                                                                    body: JSON.stringify(data),
                                                                                });

                                                                                if (!response.ok) {
                                                                                    throw new Error(`HTTP error! status: ${response.status}`);
                                                                                }

                                                                                const responseRaw = await response;
                                                                                setBookingRaw(responseRaw)
                                                                                const result = await response.json();
                                                                                setHasBookingResult(result);
                                                                            } catch (error) {
                                                                                console.error("Booking request failed:", error);
                                                                                setAlertMessage("Booking request failed. Please try again.");
                                                                                setIsAlertVisible(true);
                                                                            }
                                                                        };
                                                                        return (<React.Fragment>
                                                                            <TouchableOpacity style={{marginRight: 15}}
                                                                                              onPress={() => setModalVisible(true)}>
                                                                                <Ionicons name="add-circle-outline"
                                                                                          size={35}
                                                                                          color="white"/>
                                                                            </TouchableOpacity>
                                                                            <NewBookingModal
                                                                                visible={modalVisible}
                                                                                onClose={() => setModalVisible(false)}
                                                                                onSubmit={handleBookingSubmit}
                                                                            />
                                                                            <BookingResultModal
                                                                                visible={!!hasBookingResult}
                                                                                bookingResult={hasBookingResult}
                                                                                bookingRaw={bookingRaw}
                                                                                onClose={setHasBookingResult}
                                                                            />
                                                                            <HappyAlert
                                                                                visible={isAlertVisible}
                                                                                onConfirm={() => setIsAlertVisible(false)}
                                                                                onCancel={() => setIsAlertVisible(false)}
                                                                                type="error"
                                                                                message={alertMessage}
                                                                            />
                                                                        </React.Fragment>);
                                                                    }
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="WaitingForPayment"
                                                                component={WaitingForPayment}
                                                                options={{
                                                                    headerShown: true,
                                                                    title: t('waiting_for_payment_screen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    leftIconColor: '#ffffff',
                                                                    gestureEnabled: true,
                                                                    headerStyle: {
                                                                        backgroundColor: '#393939', // Set the desired background color
                                                                    },
                                                                    headerTintColor: '#ffffff', // Change the back arrow color here
                                                                    headerTitleStyle: {
                                                                        color: 'rgba(255,255,255,1)', // Set the desired color
                                                                    },
                                                                    icon: "#FEFEFE",
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: {duration: 100}
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: {duration: 100}
                                                                        },
                                                                    },
                                                                    headerLeft: null, // Disable left icon
                                                                    headerRight: null, // Disable right icon
                                                                }}
                                                            />
                                                            <Stack.Screen
                                                                name="BookingDetailsScreen"
                                                                component={BookingDetailsScreen}
                                                                options={({ navigation }) => ({
                                                                    headerShown: true,
                                                                    title: t('BookingDetailsScreen_title'),
                                                                    headerTitleAlign: 'center', // Center align the title text
                                                                    backTitle: t("go_back"),
                                                                    leftIconColor: '#ffffff',
                                                                    gestureEnabled: true,
                                                                    headerStyle: {
                                                                        backgroundColor: '#393939', // Set the desired background color
                                                                    },
                                                                    headerTintColor: '#ffffff', // Change the back arrow color here
                                                                    headerTitleStyle: {
                                                                        color: 'rgba(255,255,255,1)', // Set the desired color
                                                                    },
                                                                    icon: "#FEFEFE",
                                                                    transitionSpec: {
                                                                        open: {
                                                                            animation: 'timing',
                                                                            config: { duration: 100 }
                                                                        },
                                                                        close: {
                                                                            animation: 'timing',
                                                                            config: { duration: 100 }
                                                                        },
                                                                    },
                                                                    headerRight: null, // Disable right icon
                                                                })}
                                                            />
                                                        </Stack.Navigator>
                                                        <Toast config={toastConfig}/>
                                                    </BluetoothProvider>
                                                </CartProvider>
                                            </TableProvider>
                                            </VipCardProvider>

                                        </SystemProvider>
                                    </OnlineOrderProvider>
                                    <OrderNotificationService />
                                </OrdersProvider>
                            </CurrentScreenProvider>

                        </PersistGate>
                    </ReduxProvider>
                </Root>
            </NavigationContainer>

            </TourProvider>
        </ThemeProvider>
    );
}

const styles = StyleSheet.create({
    modal: {
        justifyContent: 'flex-end',
        alignContent: 'flex-start',
        margin: 10,
        flexGrow: 1,
        zIndex: 1
    },
    container: {
        backgroundColor: 'white',
        width: 400,
        padding: 20,
        borderRadius: 10,
        marginBottom: 10, // Add margin to create space between toasts

        shadowColor: "#000",
        shadowOffset: {
            width: 2,
            height: -1,
        },
        shadowOpacity: 0.29,
        shadowRadius: 4.65,

        elevation: 7,
    },
    subText: {
        fontSize: 14,
        marginVertical: 10,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    button: {
        flex: 1,
        padding: 10,
        borderRadius: 5,
        alignItems: 'center',
        marginHorizontal: 5,
    },
    rejectButton: {
        backgroundColor: 'red',
    },
    acceptButton: {
        backgroundColor: 'green',
    },
    openButton: {
        backgroundColor: 'orange',
    },
    buttonText: {
        color: 'white',
        fontWeight: 'bold',
    },

    background: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        fontSize: 18,
        color: "black",
    },
    title: {
        fontSize: 20,
        fontWeight:"bold",
        color: "black",
        paddingVertical: 5,
        marginBottom: 5,
    },
    deleteButton: {
        color: "red",
        fontWeight: "bold",
    },
    addButton: {
        padding: 15,
        borderRadius: 10,
        alignItems: "center",
        marginTop: 20,
        backgroundColor: "#f1b34b",
    },
    smallAddButton: {
        padding: 15,
        borderRadius: 5,
        alignItems: "center",
        color: "white",
        backgroundColor: "#f1b34b",
    },
    cancelButton:{
        padding: 15,
        borderRadius: 5,
        alignItems: "center",
        backgroundColor: "#ddd",
    },
    addButtonText: {
        color: "white",
        fontSize: 18,
        fontWeight: "bold",
    },
    modalContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    modalContent: {
        width: 400,
        padding: 25,
        backgroundColor: "white",
        borderRadius: 10,
        alignItems: "center",
    },
    input: {
        width: "100%",
        borderBottomWidth: 1,
        marginBottom: 15,
        padding: 10,
    },

});
