import {registerRootComponent} from 'expo';
//import * as ScreenOrientation from 'expo-screen-orientation';
import App from './App';

export const Toast_Alert_Visibility_Time = 5000;
export const Toast_Alert_Position = "top";


// Initialize Firebase with your config
const firebaseConfig = {
    "apiKey": "AIzaSyDds2hPU2uTuZKr4Vc3MbbtsmfNbZwDpKk",
    "authDomain": "happy-services-v2.firebaseapp.com",
    "projectId": "happy-services-v2",
    "storageBucket": "happy-services-v2.firebasestorage.app",
    "messagingSenderId": "806425959155",
    "appId": "1:806425959155:web:d38abbf6c40c0ef4553586",
    "measurementId": "G-ZWE1T4GEQE"
};

async function setLandscapeOrientation() {
    await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE);
}
//setLandscapeOrientation();
// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
