// OrdersScreen.js
import React, {useContext, useEffect, useState} from "react";
import {FlatList, ScrollView, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {LinearGradient} from "expo-linear-gradient";
import {OrdersContext} from "../helpers/OrdersContext";
import StatisticsHelper from "../helpers/StatisticsHelper";
import LottieView from "lottie-react-native";
import OrderRow from "../components/OrderRow";
import {TableContext} from "../helpers/TableContext";
import {CartContext} from "../helpers/CartContext";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function OrdersScreen({navigation, route}) {
    const {selectTable} = useContext(TableContext);
    const {orders} = useContext(OrdersContext);
    const {statistics} = StatisticsHelper(orders);
    const {setAlreadySentCartItems} = useContext(CartContext);
    const [moduleOrders, setModuleOrders] = React.useState([]);
    const [restaurantPlace, setRestaurantPlace] = useState({name: '', raw: '', floors: [], _id: ''});

    useEffect(() => {
        const setDatas = async () => {
            const restaurantPlaceStored = await AsyncStorage.getItem("restaurantPlace");
            setRestaurantPlace(JSON.parse(restaurantPlaceStored));
        };
        setDatas();
    }, [navigation]);

    const dineInOrders = moduleOrders.filter(order => ((order.status !== "closed" || order.status !== "cancelled"))) || [];

    useEffect(() => {
        if (route.name === "OpenTables") {
            setModuleOrders(statistics.dineInOrders.filter(order => order.restaurantPlace === restaurantPlace._id) || []);
        } else if (route.name === "OrdersList") {
            setModuleOrders([
                ...(statistics.onlineOrders || []),
                ...(statistics.unClosedTakeAwayOrders || [])
            ].filter(order => order.restaurantPlace === restaurantPlace._id).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))); // Sort by descending date
        } else if (route.name === "HomeOrdersList") {
            setModuleOrders([
                ...(statistics.dineInOrders || []),
                ...(statistics.onlineOrders || []),
                ...(statistics.unClosedTakeAwayOrders || [])
            ].filter(o => (!o.storno || o.status !== "cancelled" || o.status !== "closed") && o.restaurantPlace === restaurantPlace._id).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))); // Sort by descending date
        }else if (route.name === "PendingOrdersScreen") {
            setModuleOrders([
                ...statistics.allOrders.filter(order => order.restaurantPlace === restaurantPlace._id)
            ].filter(o => (!o.storno || o.status !== "cancelled" || o.status !== "closed") && o.restaurantPlace === restaurantPlace._id && o.preOrder && new Date(o.preOrder) >= new Date().setHours(5, 0, 0, 0)).sort((a, b) => new Date(b.preOrder) - new Date(a.preOrder))); // Sort by descending date
        }
    }, [route.name, orders, restaurantPlace]);

    if(moduleOrders.length === 0) {
        return (<LinearGradient
            colors={['#f2e2d2', '#e6d6c1', '#dcd5ce']}
            style={styles.animationBackground}
        >
            <View style={[styles.animationContainer, {justifyContent: 'center'}]}>
                <LottieView source={require('../../assets/animations/no-data-here.json')} autoPlay loop style={styles.animation} />
                <Text style={styles.warningText}>Jelenleg nincs megjeleníthető számla.</Text>
            </View>
        </LinearGradient>)
    }

    function isPayedNavigation(order) {
        if (order.paymentType === "temporary" || order.paymentTypes?.length < 1) {

            const orderTable = restaurantPlace?.floors.flatMap(floor => floor.elements).find(element => element._id === order.tableId);
            setAlreadySentCartItems([]);// Clear the already sent cart items
            selectTable(orderTable); // Select the table
            navigation.navigate("HomeScreen", {orderData: order, table: orderTable, saleScreen: true});
        }
        return navigation.navigate('OrderDetailsScreen', { orderData: order, orderId: order._id });
    }

    return (
        <LinearGradient
            colors={['#f2e2d2', '#e6d6c1', '#dcd5ce']}
            style={[styles.background, {width: "100%"}]}
        >
            <View style={[styles.container, {width: "100%"}]}>
                {/* Invoice List */}
                <ScrollView style={{width: '99%',  maxHeight: "93svh"}} contentContainerStyle={{width: '100%', maxHeight: "93svh"}}>
                    {[...moduleOrders].map(order => (
                        <OrderRow key={order.orderCode} order={order} onPress={() => {
                            // Deprecated?
                            //navigation.navigate('OrderDetailsScreen', { orderData: order, orderId: order._id })

                            // New method:?
                            //const orderTable = restaurantPlace?.floors.flatMap(floor => floor.elements).find(element => element._id === order.tableId);
                            //console.log(orderTable)
                            //console.log(order)
                            //setAlreadySentCartItems([]);// Clear the already sent cart items
                            //selectTable(orderTable); // Select the table
                            //navigation.navigate("HomeScreen", {table: orderTable, saleScreen: true});

                            //newest method:
                            isPayedNavigation(order)
                        }} />
                    ))
                    }
                </ScrollView>
                {/*<FlatList*/}
                {/*    style={{width: '100%'}}*/}
                {/*    contentContainerStyle={{width: '100%'}}*/}
                {/*    data={[*/}
                {/*        ...dineInOrders.map(order => ({*/}
                {/*            id: '#' + order.orderCode,*/}
                {/*            amount: priceFormatter(order.orderSum),*/}
                {/*            when: order.createdAt,*/}
                {/*            orderData: order*/}
                {/*        })),*/}
                {/*    ]}*/}
                {/*    renderItem={({item}) => (*/}
                {/*        <TouchableOpacity style={[styles.row, {marginVertical: 10, width: '100%'}]}*/}
                {/*                          onPress={() => navigation.navigate('OrderDetailsScreen', {orderData: item.orderData})}>*/}
                {/*            <Surface style={styles.surface} elevation={4}>*/}
                {/*                <Text style={{fontWeight: "700", flex: 1}}><TimeAgo time={new Date(item.when)}*/}
                {/*                                                                    interval={10}/></Text>*/}
                {/*                {route.name === "OpenTables" && <Text style={{fontWeight: "700", flex: 1}}>{item.orderData.tableName}</Text>}*/}
                {/*                <Text style={{fontWeight: "700", flex: 1, textAlign: 'right'}}>{item.id}</Text>*/}
                {/*                <Text*/}
                {/*                    style={{fontWeight: "700", flex: 1, textAlign: 'right'}}>{item.amount}</Text>*/}
                {/*            </Surface>*/}
                {/*        </TouchableOpacity>*/}
                {/*    )}*/}
                {/*    keyExtractor={(item) => item.id}*/}
                {/*/>*/}
            </View>
        </LinearGradient>
    );
}

const styles = StyleSheet.create({
    animation: {
        width: 150,
        height: 150,
        alignSelf: 'center', // Center the animation horizontally
    },
    warningText: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        color: 'black',
    },
    surface: {
        padding: 8,
        height: 60,
        width: '97%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        flexDirection: 'row',
        marginHorizontal: 15,
        alignSelf: 'center',
        marginLeft: 15,
        marginRight: 15
    },
    animationContainer: {
        flex: 1,
        width: '50%',
        height: '50%',
        paddingTop: 16,
        marginBottom: 150,
        alignSelf: 'center',
        alignItems: 'center'

    },
    container: {
        display: "flex",
        flex: 1,
        flexGrow: 1,
        width: '100%',
        height: '100%',
        paddingTop: 16,
        marginBottom: 20,
        alignSelf: 'center',
        alignItems: 'center'

    },
    webview: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
    animationBackground: {
        flex: 1,
        width: '100%'
    },
    background: {
        flex: 1,
        width: '100%'
    },
    section: {
        width: '100%',
        marginBottom: 24,
        padding: 16,
        backgroundColor: '#f9f9f9',
        borderRadius: 8,
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowRadius: 4,
        shadowOffset: {width: 0, height: 2},
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    row: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
});