const baseUrl = "https://api.happygastro.hu"

const baseUrlApi = `${baseUrl}api/`;

let ApiConfig = {
    baseUrl,
    baseUrlApi,
    token: null,
    login: `${baseUrlApi}login`,
    user: `${baseUrlApi}users`,
    apiURL: "https://api.happygastro.hu",
    appURL: "https://admin.happygastro.hu",
};
export {ApiConfig};
