import React, {useEffect} from "react";
import { View, Text, Modal, TouchableOpacity, StyleSheet, Dimensions } from "react-native";
import { useTranslation } from "react-i18next";

const DeliveryModal = ({ visible, onClose, onConfirm, deliveryData }) => {
    if (!deliveryData) return null;

    const { t } = useTranslation();
    const courierArrival = deliveryData?.pickup?.eta_minutes || deliveryData?.data?.pickup?.eta_minutes || "N/A";
    const deliveryAddress = deliveryData?.dropoff?.location?.formatted_address || deliveryData?.data?.dropoff?.location?.formatted_address || "N/A";
    const scheduledTime =
        deliveryData?.dropoff?.options?.scheduled_time ||
        deliveryData?.data?.dropoff?.options?.scheduled_time;

    const scheduledDelivery = scheduledTime
        ? new Date(scheduledTime).toLocaleTimeString()
        : "No scheduled time available"; // Fallback for undefined cases
    const deliveryPrice = `${(deliveryData?.price?.amount ?? deliveryData?.data?.price?.amount) / 100} Ft`;


    useEffect(() => {
        console.log("deliveryData in modal", deliveryData);
    }, [deliveryData]);


    return (
        <Modal transparent visible={visible} animationType="fade">
            <View style={styles.modalContainer}>
                <View style={styles.modalContent}>
                    <Text style={styles.title}>{t("delivery_plan")}</Text>

                    <View style={styles.infoSection}>
                        <View style={styles.infoRow}>
                            <Text style={styles.infoLabel}>{t("courier_arrival")}:</Text>
                            <Text style={styles.infoValue}>~ {courierArrival} {t("minutes")}</Text>
                        </View>

                        <View style={styles.infoRow}>
                            <Text style={styles.infoLabel}>{t("delivery_address")}:</Text>
                            <Text style={styles.infoValue}>{deliveryAddress}</Text>
                        </View>

                        <View style={styles.infoRow}>
                            <Text style={styles.infoLabel}>{t("guest_arrival")}:</Text>
                            <Text style={styles.infoValue}>~ {scheduledDelivery}</Text>
                        </View>
                    </View>

                    <View style={styles.priceSectionContainer}>
                        <View style={styles.priceSection}>
                            <Text style={styles.priceLabel}>{t("delivery_price")}:</Text>
                            <Text style={styles.priceValue}>{deliveryPrice}</Text>
                        </View>
                    </View>

                    <View style={styles.buttonContainer}>
                        <TouchableOpacity onPress={onConfirm} style={styles.confirmButton}>
                            <Text style={styles.buttonText}>{t("accept_delivery")}</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={onClose} style={styles.cancelButton}>
                            <Text style={styles.buttonText}>{t("cancel")}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </Modal>
    );
};

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
    },
    modalContent: {
        width: width * 0.55,
        backgroundColor: "white",
        minWidth: 350,
        padding: 24,
        borderRadius: 16,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    title: {
        fontSize: 22,
        fontWeight: "bold",
        marginBottom: 20,
        textAlign: "center",
        color: "#333",
    },
    infoSection: {
        width: "100%",
        marginBottom: 16,
    },
    infoRow: {
        flexDirection: "row",
        marginBottom: 12,
        alignItems: "center",
    },
    infoLabel: {
        fontSize: 18,
        fontWeight: "600",
        color: "#555",
        width: "40%",
    },
    infoValue: {
        fontSize: 16,
        color: "#333",
        width: "60%",
        flexWrap: "wrap",
    },
    priceSectionContainer: {
        width: "100%",
        borderTopWidth: 1,
        borderTopColor: "#eee",
        paddingTop: 16,
        marginBottom: 16,
    },
    priceSection: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    priceLabel: {
        fontSize: 18,
        fontWeight: "bold",
        color: "#333",
    },
    priceValue: {
        fontSize: 20,
        fontWeight: "bold",
        color: "#333",
    },
    buttonContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        marginTop: 10,
    },
    confirmButton: {
        backgroundColor: "rgb(54, 189, 251)",
        padding: 14,
        borderRadius: 8,
        flex: 1,
        marginRight: 10,
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center"
    },
    cancelButton: {
        backgroundColor: "#888",
        padding: 14,
        borderRadius: 8,
        flex: 1,
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",

    },
    buttonText: {
        color: "white",
        fontWeight: "bold",
        fontSize: 16,
    },
});

export default DeliveryModal;