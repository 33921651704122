import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
import AntDesign from '@expo/vector-icons/AntDesign';

const FailModal = ({ visible, onClose }) => {
    if (!visible) return null;

    return (
        <View style={styles.backdrop}>
            <View style={styles.modal}>
                <Text style={styles.logo}>Valami nem sikerült!</Text>
                <View style={styles.content}>
                    <AntDesign name="warning" size={68} color="#ffc8c8" style={{ paddingBottom: 30 }} />
                    <Text style={[styles.logo, styles.orderId]}>Hibakód: 0x10262, Nem sikerült a rendelés feldolgozása.</Text>
                    <TouchableOpacity style={styles.button} onPress={onClose}>
                        <Text style={styles.buttonText}>Újrapróbálás</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    backdrop: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modal: {
        width: width * 0.45,
        padding: 20,
        height: height * 0.5,
        backgroundColor: '#FF3C3CFF',
        borderRadius: 10,
        alignItems: 'center',
    },
    content: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        fontSize: 40,
        fontWeight: 'bold',
        color: '#FFF',
    },
    orderId: {
        fontSize: 20,
        textAlign: 'center',
        color: '#FFF',
        marginBottom: 20,
        marginTop: 10,
    },
    button: {
        backgroundColor:'#fff1d6',
        paddingVertical: 15,
        paddingHorizontal: 30,
        borderRadius: 5,
    },
    buttonText: {
        color: '#370202',
        fontSize: 20,
        fontWeight: 'bold',
    },
});

export default FailModal;