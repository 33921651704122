import React, { useState } from 'react';
import { View, TextInput, FlatList, TouchableOpacity, Text, StyleSheet } from 'react-native';
import axios from 'axios';

const AddressInput = ({ setAddress }) => {
    const [address, setAddressText] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [isFocused, setIsFocused] = useState(false);

    const fetchSuggestions = async (query) => {
        try {
            const response = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json`, {
                params: {
                    access_token: 'pk.eyJ1IjoiZ29vZG8iLCJhIjoiY2t2eHg5cWhuNDM3YjJwa2xxejVubnhhcCJ9.py506Q9y-4k30wIs6xh2Ew',
                    autocomplete: true,
                    limit: 5,
                },
            });
            setSuggestions(response.data.features);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        }
    };

    const handleAddressChange = (text) => {
        setAddressText(text);
        if (text.length > 2) {
            fetchSuggestions(text);
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionPress = (suggestion) => {
        const zip = suggestion.context.find(context => context.id.includes('postcode'))?.text || '';
        const city = suggestion.context.find(context => context.id.includes('place'))?.text || '';
        const street = suggestion.place_type[0] === "address" ? `${suggestion.text} ${suggestion.address}` : suggestion.properties?.address || suggestion.text;

        setAddress({
            address: suggestion.place_name,
            zip,
            city,
            street,
        });
        setAddressText(suggestion.place_name);
        setSuggestions([]);
        setIsFocused(false);
    };

    return (
        <View style={styles.container}>
            <TextInput
                style={styles.input}
                placeholder="Cím kereső"
                value={address}
                onChangeText={handleAddressChange}
                onFocus={() => setIsFocused(true)}
            />
            {isFocused && suggestions?.length > 0 &&(<FlatList
                data={suggestions}
                keyExtractor={(item) => item.id}
                renderItem={({ item }) => (
                    <TouchableOpacity onPress={() => handleSuggestionPress(item)}>
                        <Text style={styles.suggestionItem}>{item.place_name}</Text>
                    </TouchableOpacity>
                )}
                style={styles.suggestionsContainer}
            />)}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: "auto",
    },
    input: {
        height: 50,
        borderColor: '#a3a3a3',
        borderWidth: 0.4,
        marginBottom: 10,
        paddingHorizontal: 15,
        borderRadius: 5,
        fontSize: 14,
    },
    suggestionsContainer: {
        maxHeight: 150,
        backgroundColor: '#fff',
        zIndex: 999,
    },
    suggestionItem: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
    },
});

export default AddressInput;