import React, { useEffect, useState } from 'react';
import {View, Text, ScrollView, StyleSheet, TouchableOpacity} from 'react-native';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getAccessToken } from '../helpers/storage';

const API_URL = 'https://api.happygastro.hu/api/restaurant/guests';

const GuestList = ({ filterCriteria, onGuestSelect }) => {
    const [guests, setGuests] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchGuests = async () => {
            try {
                const token = await getAccessToken(); // Retrieve the token from AsyncStorage
                if (!token) {
                    console.error('No token found');
                    return;
                }

                const response = await axios.get(API_URL, {
                    headers: {
                        'Authorization': `Bearer ${token}`, // Add the token to the Authorization header
                        'Content-Type': 'application/json'
                    }
                });
                setGuests(response.data);
            } catch (error) {
                console.error('Error fetching guests:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchGuests();
    }, []);


    const toLowerCaseIfString = (value) => {
        return typeof value === 'string' ? value.toLowerCase() : value;
    };

    const getAddressWords = (address) => {
        if (typeof address === 'string') {
            return address.toLowerCase().split(/[\s,]+/);
        } else if (address && address.place_name) {
            return address.place_name.toLowerCase().split(/[\s,]+/);
        }
        return [];
    };

    const filteredGuests = guests.filter(guest => {
        const { firstName, lastName, phoneNumber, zip, city, address } = filterCriteria;
        const fullName = `${guest.userInfo?.name?.first} ${guest.userInfo?.name?.last}`.toLowerCase();
        const guestPhone = toLowerCaseIfString(guest?.userInfo?.phone);
        const guestZip = toLowerCaseIfString(guest?.addressInfo?.zip);
        const guestCity = toLowerCaseIfString(guest?.addressInfo?.city);
        const guestAddress = toLowerCaseIfString(guest?.addressInfo?.address);
        const addressWords = getAddressWords(address);
        const addressMatches = addressWords.every(word => guestAddress?.includes(word));

        const zipMatches = zip ? guestAddress?.includes(zip?.toLowerCase()) : addressMatches;
        const cityString = typeof city === 'object' ? "" : city;
        const cityMatches = cityString ? guestAddress?.includes(cityString.toLowerCase()) : addressMatches;

        return (
            (!firstName || fullName.includes(firstName.toLowerCase())) &&
            (!lastName || fullName.includes(lastName.toLowerCase())) &&
            (!phoneNumber || guestPhone?.includes(phoneNumber.toLowerCase())) &&
            (!zip || zipMatches) &&
            (!city || cityMatches) &&
            (!address || addressMatches)
        );
    });

    if (loading) {
        return (
            <View style={styles.loadingContainer}>
                <Text style={{fontSize : 26}}>Vendégadatok letöltése...</Text>
            </View>
        );
    }

    return (
        <ScrollView style={styles.container}>
            {filteredGuests.map((guest, index) => (
                <TouchableOpacity
                    key={guest.user + " - " + index}
                    style={styles.guestItem}
                    onPress={() => onGuestSelect(guest)}
                >
                    <View style={styles.row}>
                        <Text style={styles.boldText}>{guest.userInfo?.name?.first + " " + guest.userInfo?.name?.last}</Text>
                        <Text>{guest.userInfo.phone}</Text>
                    </View>
                    <Text>{guest.addressInfo?.address}</Text>
                </TouchableOpacity>
            ))}
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        marginVertical: 10,
    },
    guestItem: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    boldText: {
        fontWeight: 'bold',
    },
    loadingContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    guestItemHover: {
        backgroundColor: '#f0f0f0',
    },
});

export default GuestList;