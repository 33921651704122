import React, {useContext, useEffect, useState} from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { View, StyleSheet } from "react-native";
import Icon from 'react-native-vector-icons/FontAwesome';
import IonIcon from 'react-native-vector-icons/Ionicons';
import OrdersScreen from "./OrdersScreen";
import OnlineMapScreen from "./OnlineMapScreen";
import OnlineNewOrder from "./OnlineNewOrder";
import StatisticsHelper from "../helpers/StatisticsHelper";
import { OrdersContext } from "../helpers/OrdersContext";
import CircularButton from '../components/CircularButton';
import FunctionsScreen from "./FunctionsScreen";
import { useTranslation } from 'react-i18next';
import '../../i18n';
import {GestureHandlerRootView} from "react-native-gesture-handler";
import AsyncStorage from "@react-native-async-storage/async-storage";

const Tab = createBottomTabNavigator();

export default function OnlineScreen({ navigation }) {
    const { t } = useTranslation();
    const { orders } = useContext(OrdersContext);
    const { productsSum, statistics } = StatisticsHelper(orders);
    const [restaurantPlace, setRestaurantPlace] = useState({ _id: '' });

    useEffect(() => {
        const fetchRestaurantPlace = async () => {
            const restaurantPlaceStored = await AsyncStorage.getItem("restaurantPlace");
            if (restaurantPlaceStored) {
                setRestaurantPlace(JSON.parse(restaurantPlaceStored));
            }
        };
        fetchRestaurantPlace();
    }, []);

    return (
        <View style={{ flex: 1, width: '100%', height: '100%', flexGrow: 1 }}>
            <Tab.Navigator
                detachInactiveScreens={false}
                tabBarLabelPosition={"below-icon"}
                lazy={false}
                backBehavior={"history"}
                headerMode={"none"}
                screenOptions={{ headerShown: false }}
                tabBarOptions={{
                    activeTintColor: '#a26909',
                    inactiveTintColor: '#575757',
                    activeBackgroundColor: '#f0f0f0',
                    inactiveBackgroundColor: '#ffffff',
                    showLabel: true,
                    labelStyle: {
                        fontSize: 14,
                        fontWeight: 'bold',
                    },
                    tabStyle: {
                        paddingVertical: 10,
                        height: 70,
                        marginTop: -20
                    },
                    style: {
                        height: 70,
                        backgroundColor: '#e0e0e0',
                    }
                }}
            >
                <Tab.Screen name="MapView" component={OnlineMapScreen}
                            options={{
                                tabBarIcon: ({ color, size }) => (
                                    <Icon name="map-o" color={color} size={size} />
                                ),
                                title: t('map_view'),
                                tabBarLabel: t('map_view'),
                            }} />
                <Tab.Screen name="OrdersList" component={OrdersScreen}
                            options={{
                                tabBarIcon: ({ color, size }) => (
                                    <IonIcon name="bag-sharp" color={color} size={size} />
                                ),
                                title: t('open_orders'),
                                tabBarLabel: t('open_orders'),
                                tabBarBadge: (statistics?.onlineOrders?.filter(order => order.restaurantPlace === restaurantPlace?._id)?.length || 0) + (statistics?.unClosedTakeAwayOrders?.filter(order => order.restaurantPlace === restaurantPlace?._id)?.length || 0),
                                tabBarBadgeStyle: statistics?.onlineOrders?.filter(order => order.restaurantPlace === restaurantPlace?._id)?.length + statistics?.unClosedTakeAwayOrders?.filter(order => order.restaurantPlace === restaurantPlace?._id)?.length === 0 ? { display: 'none' } : { minWidth: "fit-content", height: "fit-content", borderRadius: 14, justifyContent: 'center', alignItems: 'center', overflow: "show" }
                            }}
                            initialParams={{ name: "OrdersList" }}
                />
                <Tab.Screen name="NewOrder" component={OnlineNewOrder}
                            options={{
                                tabBarIcon: ({ color, size }) => (
                                    <IonIcon name="add-sharp" color={color} size={size} />
                                ),
                                title: t('new_order'),
                                tabBarLabel: t('new_order'),
                            }} />
                <Tab.Screen name="Functions" component={FunctionsScreen}
                            options={{
                                tabBarIcon: ({ color, size }) => (
                                    <Icon name="server" color={color} size={size} />
                                ),
                                title: t('functions_operations'),
                                tabBarLabel: t('functions_operations'),
                            }} />
            </Tab.Navigator>

            <CircularButton navigation={navigation} iconName="grid" />
        </View>
    );
}

const styles = StyleSheet.create({
    background: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});