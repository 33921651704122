import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';
import {OnlineOrderContext} from "../helpers/OnlineOrderContext";

const { width } = Dimensions.get('window');

const ToggleButton = ({ options, onSelect, selectedOption }) => {
    return (
        <View style={styles.toggleContainer}>
            {options.map((option, index) => (
                <TouchableOpacity
                    key={index}
                    style={[
                        styles.toggleButton,
                        selectedOption === option && styles.selectedButton
                    ]}
                    onPress={() => onSelect(option)}
                >
                    <Text
                        style={[
                            styles.toggleButtonText,
                            selectedOption === option && styles.selectedButtonText
                        ]}
                    >
                        {option}
                    </Text>
                </TouchableOpacity>
            ))}
        </View>
    );
};

const ExpandableListItem = ({ title, isExpanded, onPress }) => {
    return (
        <TouchableOpacity onPress={onPress} style={styles.listItem}>
            <Text style={styles.listItemText}>{title}</Text>
            <Text style={styles.arrow}>{isExpanded ? '▼' : '►'}</Text>
        </TouchableOpacity>
    );
};

const PaymentSelector = ({ onSelect }) => {
    const [selectedPayment, setSelectedPayment] = useState('KÉSZPÉNZ');
    const [expandedItem, setExpandedItem] = useState(null);
    const { orderData, setOrderData } = React.useContext(OnlineOrderContext);

    function convertType(type) {
        if (type === "KÉSZPÉNZ") {
            return "Készpénz";
        } else {
            return "Bankkártya";
        }
    }

    const handleExpand = (item) => {
        setOrderData({...orderData, selectedPortal: item});
        setExpandedItem(expandedItem === item ? null : item);
    };

    return (
        <View style={[styles.container, { width: width >= 768 ? '40%' : '75%' }]}>
            <ExpandableListItem
                title="Telefonos"
                isExpanded={expandedItem === 'phone'}
                onPress={() => handleExpand('phone')}
            />
            {expandedItem === 'phone' && (
                <ToggleButton
                    options={['KÉSZPÉNZ', 'BANKKÁRTYA']}
                    selectedOption={selectedPayment}
                    onSelect={(option) => {
                        setSelectedPayment(option);
                        onSelect(convertType(option));
                    }}
                />
            )}
            <ExpandableListItem
                title="Webshop"
                isExpanded={expandedItem === 'webshop'}
                onPress={() => handleExpand('webshop')}
            />
            {expandedItem === 'webshop' && (
                <ToggleButton
                    options={['KÉSZPÉNZ', 'BANKKÁRTYA']}
                    selectedOption={selectedPayment}
                    onSelect={(option) => {
                        setSelectedPayment(option);
                        onSelect(convertType(option));
                    }}
                />
            )}
            {orderData?.orderType === "takeAway" || orderData?.orderType === "takeaway" && <ExpandableListItem
                title="Elviteles"
                isExpanded={expandedItem === 'Elviteles'}
                onPress={() => handleExpand('Elviteles')}
            />}
            {expandedItem === 'Elviteles' && (
                <ToggleButton
                    options={['KÉSZPÉNZ', 'BANKKÁRTYA']}
                    selectedOption={selectedPayment}
                    onSelect={(option) => {
                        setSelectedPayment(option);
                        onSelect(convertType(option));
                    }}
                />
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
        backgroundColor: '#fff',
        borderRadius: 10,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
        elevation: 5,
    },
    listItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 15,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
    },
    listItemText: {
        fontSize: 16,
    },
    arrow: {
        fontSize: 16,
        color: '#000',
    },
    toggleContainer: {
        flexDirection: 'row',
        marginTop: 10,
    },
    toggleButton: {
        flex: 1,
        paddingVertical: 10,
        alignItems: 'center',
        borderWidth: 1,
        borderColor: '#ccc',
        backgroundColor: '#fff',
        borderRadius: 5,
        marginHorizontal: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
        elevation: 5,
        transition: 'background-color 0.3s',
    },
    selectedButton: {
        backgroundColor: '#ff6600',
    },
    toggleButtonText: {
        color: '#ff6600',
        fontSize: 16,
    },
    selectedButtonText: {
        color: '#fff',
    },
});

export default PaymentSelector;