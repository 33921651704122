import React, { useEffect, useState, useRef } from 'react';
import {View, StyleSheet, Dimensions, Animated, Text, BackHandler, TouchableOpacity, Image} from 'react-native';
import LottieView from 'lottie-react-native';
import AsyncStorage from "@react-native-async-storage/async-storage";

const SuccessDayClosingScreen = ({ navigation, route }) => {
    const [animationFinished, setAnimationFinished] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState('#81F174');
    const animation = useRef(null);
    const [dayClosingDetails, setDayClosingDetails] = useState(0);

    useEffect(()=>{
        setDayClosingDetails(route?.params?.cashCountDetails)
    },[route])

    const setDayClosingAmount = async () => {
        await AsyncStorage.setItem('dayClosed_amount', (dayClosingDetails))
    }

    const handleAnimationFinish = async () => {
        setBackgroundColor('#81F174');
        setAnimationFinished(true)
    };
    return (
        <View style={[styles.container, { backgroundColor : '#81F174' }]}>
            {animationFinished && (
                <Animated.View style={[styles.logoContainer, { opacity: 1 }]}>
                    <Image
                        source={require('../../assets/animations/close-day-animation.gif')}
                        style={{ width: 250, height: 250 }}
                    />
                    <Text style={styles.logo}>Sikeres pénzügyi zárás!</Text>
                    <Text style={[styles.logo, {fontSize: 15, textAlign: 'center'}]}></Text>

                    <TouchableOpacity style={styles.button}
                                      onPress={() => {
                                          setDayClosingAmount()
                                          navigation.navigate("StartScreen", {successCloseDay: true})
                                      }}>
                        <Text style={styles.buttonText}>Kezdőképernyőre</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={[styles.button, {marginTop: 50, backgroundColor: 'black', color: 'white'}]}
                                      onPress={async () => {
                                          await setDayClosingAmount()
                                          BackHandler.exitApp()
                                      }}>
                        <Text style={[styles.buttonText, {color: "white"}]}>Program bezárása</Text>
                    </TouchableOpacity>
                </Animated.View>
            )}
            {!animationFinished && <LottieView
                ref={animation}
                source={require('../../assets/animations/success_animation.json')} // Path to your Lottie animation file
                autoPlay
                loop={false}
                speed={2}
                onAnimationFinish={handleAnimationFinish}
                resizeMode={'contain'}
                renderMode={'HARDWARE'}
                style={[styles.lottie, {width: Dimensions.get('screen').width, height: Dimensions.get('screen').height}]}
            />}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#81F174',
    },
    lottie: {
        width: Dimensions.get('screen').width,
        height: Dimensions.get('screen').height,
    },
    lottieCenter: {
        width: 700,
        height: 700,
    },
    logoContainer: {
        position: 'absolute',
        alignItems: 'center',
        zIndex: 99999999
    },
    logo: {
        fontSize: 25,
        fontWeight: 'bold',
        color: '#FFF',
    },
    text: {
        fontSize: 25,
        fontWeight: 'bold',
        color: 'black',
        marginTop: 10,
    },
    button: {
        backgroundColor: "#F1B34B",
        paddingVertical: 15,
        paddingHorizontal: 30,
        borderRadius: 5,
        marginTop: 40,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.36,
        shadowRadius: 6.68,

        elevation: 11,
    },
    buttonText: {
        fontSize: 24,
        color: "white",
        paddingHorizontal: 20,
        textAlign: 'center',
    },
});

export default SuccessDayClosingScreen;