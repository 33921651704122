import React, {useContext, useEffect, useState} from 'react';
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import TableMapScreen from "./TableMapScreen";
import OrdersScreen from "./OrdersScreen";
import TablesBookingsScreen from "./TablesBookingsScreen";
import FunctionsScreen from "./FunctionsScreen";
import { useTranslation } from 'react-i18next';
import '../../i18n';
import AsyncStorage from "@react-native-async-storage/async-storage";
import CircularButton from "../components/CircularButton";
import Tooltip from 'react-native-walkthrough-tooltip';
import { TourContext } from "../helpers/TourContext"
import CustomTooltip from "../helpers/CustomTooltip";
const Tab = createBottomTabNavigator();

export default function TablesScreen({ navigation }) {
    const { t } = useTranslation();
    const [restaurantPlace, setRestaurantPlace] = useState({ _id: '' });
    const { currentStep, nextStep, endTour } = useContext(TourContext);

    useEffect(() => {
        const fetchRestaurantPlace = async () => {
            const restaurantPlaceStored = await AsyncStorage.getItem("restaurantPlace");
            if (restaurantPlaceStored) {
                setRestaurantPlace(JSON.parse(restaurantPlaceStored));
            }
        };
        fetchRestaurantPlace();
    }, []);

    return (
        <View style={{ flex: 1, width: '100%', height: '100%', flexGrow: 1 }}>
            <Tab.Navigator
                detachInactiveScreens={false}
                tabBarLabelPosition={"below-icon"}
                lazy={false}
                backBehavior={"history"}
                headerMode={"none"}
                screenOptions={{ headerShown: false }}
                tabBarOptions={{
                    activeTintColor: '#a26909',
                    inactiveTintColor: '#575757',
                    activeBackgroundColor: '#f0f0f0',
                    inactiveBackgroundColor: '#ffffff',
                    showLabel: true,
                    labelStyle: {
                        fontSize: 14,
                        fontWeight: 'bold',
                    },
                    tabStyle: {
                        paddingVertical: 10,
                        height: 70,
                        marginTop: -20
                    },
                    style: {
                        height: 70,
                        backgroundColor: '#e0e0e0',
                    }
                }}
            >
                <Tab.Screen
                    name="TableMap"
                    component={TableMapScreen}
                    options={{
                        tabBarIcon: ({ color, size }) => (
                                <TouchableOpacity>
                                    <Icon name="braille" color={color} size={size} />
                                </TouchableOpacity>
                        ),
                        title: t('table_map'),
                        tabBarLabel: t('table_map'),
                    }}
                />
                <Tab.Screen
                    name="OpenTables"
                    component={OrdersScreen}
                    options={{
                        tabBarIcon: ({ color, size }) => (
                                <TouchableOpacity>
                                    <Icon name="cutlery" color={color} size={size} />
                                </TouchableOpacity>
                        ),
                        title: t('open_tables'),
                        tabBarLabel: t('open_tables'),
                    }}
                />
                <Tab.Screen
                    name="Bookings"
                    component={TablesBookingsScreen}
                    options={{
                        tabBarIcon: ({ color, size }) => (
                                <TouchableOpacity>
                                    <Icon name="calendar" color={color} size={size} />
                                </TouchableOpacity>
                        ),
                        title: t('upcoming_bookings'),
                        tabBarLabel: t('upcoming_bookings')
                    }}
                />
                <Tab.Screen
                    name="Functions"
                    component={FunctionsScreen}
                    options={{
                        tabBarIcon: ({ color, size }) => (
                            <CustomTooltip
                                isVisible={false}
                                onNext={()=> {
                                    nextStep()
                                    navigation.navigate('Functions');
                                }}
                                placement="top"
                                module={"table"}
                                onClose={endTour}
                                wrapped={(<TouchableOpacity>
                                    <Icon name="server" color={color} size={size} />
                                </TouchableOpacity>)}
                            />
                        ),
                        title: t('functions_operations'),
                        tabBarLabel: t('functions_operations'),
                    }}
                />
            </Tab.Navigator>

            <CircularButton navigation={navigation} iconName="grid" />
        </View>
    );
}

const styles = StyleSheet.create({
    background: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
