import React, { useState } from 'react';
import { TouchableOpacity, View, Text, StyleSheet } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import {useTranslation} from "react-i18next";

const ItemComponent = ({ item, selectedItemsToPay, setSelectedItemsToPay, priceFormatter }) => {
    const {t} = useTranslation();
    const [selectedCount, setSelectedCount] = useState(0);
    const isSelected = selectedCount > 0;

    const handlePress = () => {
        if (selectedCount < item.amount) {
            setSelectedCount(selectedCount + 1);
            setSelectedItemsToPay([...selectedItemsToPay, { ...item, amount: 1 }]);
        } else if (selectedCount < item.quantity) {
            setSelectedCount(selectedCount + 1);
            setSelectedItemsToPay([...selectedItemsToPay, { ...item, quantity: 1 }]);
        }
    };

    const handleRemoveAll = () => {
        setSelectedItemsToPay(selectedItemsToPay.filter(selectedItem => selectedItem._id !== item._id));
        setSelectedCount(0);
    };

    return (
        <TouchableOpacity
            onPress={handlePress}
            style={[styles.itemContainer, isSelected && styles.selectedItem]}
        >
            <View style={styles.row}>
                <View style={styles.itemDetails}>
                    <Text style={[styles.itemName, isSelected && styles.selectedText]}>
                        {item.name?.hu || item.name || item.item?.name}
                    </Text>
                    <Text style={[styles.unitPrice, isSelected && styles.selectedText]}>
                        {t("quantity")}: {item.quantity || item.amount}
                    </Text>
                    {item.extras?.length > 0 && item.extras.map((extra, index) => (
                        <Text key={index} style={[styles.extraText, isSelected && styles.selectedText]}>
                            + {extra.name || extra.item?.name} - {priceFormatter(extra.price)}
                        </Text>
                    ))}
                </View>
                <Text style={[styles.itemPrice, isSelected && styles.selectedText]}>
                    {priceFormatter(item.price * (item.quantity || item.amount))}
                </Text>
            </View>

            {isSelected && (
                <View style={styles.selectionContainer}>
                    <View style={styles.selectedTag}>
                        <Text style={styles.selectedText}>{t("selected")}: {selectedCount}</Text>
                    </View>
                    <TouchableOpacity onPress={handleRemoveAll} style={[styles.deleteIcon, styles.deleteTag]}>
                        <Feather name="trash-2" size={20} color="white" />
                    </TouchableOpacity>
                </View>
            )}
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    itemContainer: {
        backgroundColor: '#E0E0E0',
        padding: 12,
        borderRadius: 8,
        marginVertical: 5,
        elevation: 3,
    },
    selectedItem: {
        backgroundColor: '#F1B34B', // Full card turns orange when selected
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    itemDetails: {
        flex: 5,
    },
    itemName: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#333',
    },
    unitPrice: {
        fontSize: 14,
        color: '#666',
    },
    extraText: {
        fontSize: 12,
        color: '#777',
    },
    itemPrice: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#333',
    },
    selectionContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 5,
    },
    selectedTag: {
        backgroundColor: '#D88B2E', // Slightly darker orange for contrast
        padding: 5,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
    },
    deleteTag: {
        backgroundColor: '#D88B2E', // Slightly darker orange for contrast
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
    },
    selectedText: {
        color: 'white',
        fontWeight: 'bold',
    },
    deleteIcon: {
        marginLeft: 10,
    },
});

export default ItemComponent;
