import React, {useCallback, useContext, useEffect, useState, useRef} from 'react';
import {
    View,
    Text,
    Dimensions,
    StyleSheet,
    TouchableOpacity,
    Alert,
    ScrollView,
    FlatList,
    Platform, Modal
} from 'react-native';
import TimeAgo from "react-native-timeago";
import {priceFormatter} from "../helpers";
import axios from "axios";
import {ApiConfig} from "../config/ApiConfig";
import {getAccessToken} from "../helpers/storage";
import OrderAcceptTimePicker from "../components/OrderAcceptTimePicker";
import OrderRejectModal from "../components/OrderRejectModal";
import {LinearGradient} from "expo-linear-gradient";
import { HappyDialog } from '../helpers/crossPlatformTools/dialogs';
import Observables from "@happy-gastro/global-observables";
import { Animated } from 'react-native';
import {CurrentScreenContext} from "../helpers/CurrentScreenContext";

// TODO: We can create a more accurate event title based on the event type and details
import { useTranslation } from 'react-i18next';
import {useFocusEffect} from "@react-navigation/native";
import {OrdersContext} from "../helpers/OrdersContext";
import {RawBTPrintKitchen, RawBTPrintTicket} from "../helpers/rawBTDriver";
import PairingModal from "../components/PairingModal";
import {FontAwesome5, Ionicons, MaterialIcons} from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import DeliveryModal from "../components/DeliveryModal";
import {WebView} from "react-native-webview";
import Toast from "react-native-toast-message";

const eventTitle = (title, t) => {
    switch (title) {
        case 'created': return t('created');
        case 'itemsAdded': return t('items_added');
        case 'update': return t('update');
        case 'statusUpdate': return t('status_update');
        default: return t('update');
    }
}

const orderType = (type, t) => {
    switch (type) {
        case 'takeAway': return t('take_away');
        case 'dineIn': return t('dine_in');
        case 'online': return t('online');
        default: return t('take_away');
    }
}

const paymentType = (type, t) => {
    switch (type) {
        case 'temporary': return t('temporary');
        case 'cash': return t('cash');
        case 'creditCard': return t('credit_card');
        case 'szepCard': return t('szep_card');
        case 'szepCreditCard': return t('szep_card');
        case 'voucher': return t('vouchers');
        case 'employeeBalance': return t('employee_balance');
        default: return t('temporary');
    }
}

const Timeline = ({ orderData }) => {
    //todo: test with orders not from our system, orders that have history instead of events
    const { t } = useTranslation();
    const events = [
        ...orderData?.events?.map(event => ({
            date: new Date(event.time).toISOString(),
            title: event.event,
            details: event.details,
            user: event.user ? event.user : false
        }))
    ];

    return (
        <ScrollView contentContainerStyle={{ paddingBottom: 70 }}>
            <Text style={styles.title}>{t('timeline')}</Text>
            <View style={styles.timeline}>
                {events.map((event, index) => (
                    <View key={index + "timeline!" } style={styles.eventRow}>
                        <View style={styles.iconContainer}>
                            <View style={styles.icon} />
                            {index < events.length - 1 && <View style={styles.verticalLine} />}
                        </View>
                        <View style={styles.eventCard}>
                            <Text style={styles.date}><TimeAgo time={new Date(event.date)} /></Text>
                            <Text style={styles.eventTitle}>{eventTitle(event.title, t)}</Text>
                            {/*{event.user && <Text style={styles.eventDescription}>{event.user}</Text>}*/}
                        </View>
                    </View>
                ))}
            </View>
        </ScrollView>
    );
};

const ReprintModal = ({ isVisible, onClose, orderData }) => {
    const { t } = useTranslation();

    const handlePress = (type) => {
        if(type === "invoice") {
            RawBTPrintTicket(orderData, orderData.items);
        }
        if(type === "kitchen_receipt") {
            RawBTPrintKitchen(orderData, orderData.items);
        }
        onClose();
    };

    return (
        <Modal
            transparent={true}
            visible={isVisible}
            animationType="slide"
            onRequestClose={onClose}
        >
            <TouchableOpacity style={styles.modalBackgroundReprint} onPress={onClose}>
                <View style={styles.modalContainerReprint}>
                    <TouchableOpacity style={styles.buttonReprint} onPress={() => handlePress("invoice")}>
                        <Text style={styles.buttonTextReprint}>{t('invoice')}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.buttonReprint} onPress={() => handlePress("kitchen_receipt")}>
                        <Text style={styles.buttonTextReprint}>{t('kitchen_receipt')}</Text>
                    </TouchableOpacity>
                </View>
            </TouchableOpacity>
        </Modal>
    );
};
const getOrderIcon = (source) => {
    switch (source) {
        case 'terminal':
            return <MaterialIcons name="point-of-sale" size={24} color="#555" />;
        case 'phone':
            return <Ionicons name="call" size={24} color="black" />;
        case 'webshop':
            return <Ionicons name="earth-outline" size={24} color="#555" />;
        default:
            return <MaterialIcons name="point-of-sale" size={24} color="#555" />;
    }
};

const OrderDetailsScreen = (props) => {
    const {orders} = useContext(OrdersContext);
    const { route, navigation } = props;
    const { orderId  } = route?.params || props;
    const [haveToPair, setHaveToPair] = useState(false);
    const [orderData, setOrderData] = useState(orders?.find(order => order?._id === orderId));
    const [isReprintModalVisible, setReprintModalVisible] = useState(false);
    const [isAcceptModalVisible, setAcceptModalVisible] = useState(false);
    const [isRejectModalVisible, setIsRejectModal] = useState(false);
    const [isDialogVisible, setIsDialogVisible] = useState({ visible: false });
    const scrollViewRef = useRef(null);
    const [restaurantPlace, setRestaurantPlace] = useState(null);
    const [isDeliveryModalVisible, setDeliveryModalVisible] = useState(false);
    const [deliveryData, setDeliveryData] = useState(null);
    const [isTrackingShown, setTrackingShown] = useState(false);

    const { width, height } = Dimensions.get('window');
    const { t } = useTranslation();


    const [isPairingModalVisible, setPairingModalVisible] = useState(false);
    const [pairingItem, setPairingItem] = useState(null);
    //const [orderId, setOrderId] = useState(null);
    const [showPairingAlert, setShowPairingAlert] = useState(false);
    const fadeAnim = useRef(new Animated.Value(0)).current;
    const { setCurrentScreen } = useContext(CurrentScreenContext);

    useEffect(() => {
        setCurrentScreen('OrderDetailsScreen');
        return () => setCurrentScreen(null);
    }, [setCurrentScreen]);

    const triggerPairingAlert = () => {
        setShowPairingAlert(true);
            if (scrollViewRef.current) {
                scrollViewRef.current.scrollTo({
                    y: 0,
                    animated: true,
                });
            }
        Animated.sequence([
            Animated.timing(fadeAnim, {
                toValue: 1,
                duration: 500,
                useNativeDriver: false,
            }),
            Animated.timing(fadeAnim, {
                toValue: 0,
                duration: 500,
                useNativeDriver: false,
            }),
        ]).start()
    };

    const handleAcceptButtonPress = () => {
        if (isAllItemPaired(orderData)) {
            setAcceptModalVisible(true);
            console.log("all paired");
        } else {
            console.log("not all paired");
            triggerPairingAlert();
        }
    };

    // Function to open the PairingModal
    const openPairingModal = (item) => {
        setPairingItem(item);
        setPairingModalVisible(true);
    };

    // Function to close the PairingModal
    const closePairingModal = () => {
        setPairingModalVisible(false);
        setPairingItem(null);
    };

    useEffect(() => {
        setOrderData(orders?.find(order => order?._id === orderId));
    },[orderId, orders])

    useEffect(() => {
        const fetchRestaurantPlace = async () => {
            try {
                const value = await AsyncStorage.getItem('restaurantPlace');
                if (value !== null) {
                    const parsedValue = JSON.parse(value);
                    setRestaurantPlace(parsedValue);
                }
            } catch (error) {
                console.error('Error fetching restaurant place:', error);
            }
        };

        fetchRestaurantPlace();
    }, []);

    const translateStatus = (status) => {
        const statusTranslations = {
            accepted: t('accepted'),
            preparing: t('preparing'),
            cancelled: t('cancelled'),
            closed: t('closed'),
            busy: t('busy'),
            ready: t('ready'),
            storno: t('storno')
        };
        return statusTranslations[status] || status;
    };


// Update the mockCreateWoltPromise function
//     const mockCreateWoltPromise = () => {
//         const mockResponse = {
//             data: {
//                 id: "67d0559c12edb3f37805816e",
//                 created_at: "2025-03-11T15:24:12.451613+00:00",
//                 dropoff: {location: { formatted_address: "Bécsi út 89-91., 1034, Budapest" },
//                     options: { scheduled_time: "2025-03-11T16:02:46.711000+00:00" }},
//                 eta_minutes: 30,
//                 location: {
//                     coordinates: {
//                         lat: 47.5326294,
//                         lon: 19.0367819
//                     },
//                     formatted_address: "Bécsi út 89-91., 1034, Budapest"
//                 },
//                 options: {
//                     scheduled_time: null
//                 },
//                 is_binding: true,
//                 parcels: null,
//                 pickup: {
//                     venue_id: "66d07308435bc0dd81f43171",
//                     eta_minutes: 30,
//                     location: {
//                         coordinates: {
//                             lat: 47.5326294,
//                             lon: 19.0367819
//                         },
//                         formatted_address: "Bécsi út 89-91, 1034, Budapest"
//                     },
//                     options: {
//                         min_preparation_time_minutes: 30
//                     }
//                 },
//                 price: {
//                     amount: 215000,
//                     currency: "HUF"
//                 },
//                 time_estimate_minutes: 30,
//                 valid_until: "2025-03-11T16:24:12.451613+00:00",
//                 status: "ShipmentPromise created successfully"
//             }
//         };
//
//         setDeliveryData(mockResponse.data);
//         setDeliveryModalVisible(true);
//     };

    const createWoltDelivery = async (data) => {
        console.log("createWoltDelivery -data: ",data)
        try {
            const token = await getAccessToken();
            const response = await axios.post(ApiConfig.apiURL + '/api/delivery/wolt/delivery', {
                pickup: {
                    options: {
                        min_preparation_time_minutes: data.pickup?.options?.min_preparation_time_minutes
                    },
                    comment: data.pickup?.comment || "",
                    display_name: props.restaurant?.name
                },
                dropoff: {
                    location: {
                        coordinates: {
                            lat: orderData?.addressInfo?.coordinates_raw?.lat || orderData?.addressInfo?.coordinates[0],
                            lon: orderData?.addressInfo?.coordinates_raw?.lng || orderData?.addressInfo?.coordinates[1],
                        }
                    },
                    comment: (orderData?.addressInfo?.deliveryInstructions || "") + ", " + (data.dropoff?.comment || "") + ", " + (data.addressInfo?.other || "") || "",
                    options: {
                        is_no_contact: false,
                        scheduled_time: data.dropoff?.options?.scheduled_time
                    }
                },
                price: {
                    amount: data.price?.amount,
                    currency: data.price?.currency
                },
                recipient: {
                    name: `${orderData.userInfo?.name?.first} ${orderData.userInfo?.name?.last}`,
                    phone_number: orderData.userInfo?.phone || orderData?.userInfo?.mobilePhone || orderData?.userInfo?.phoneNumber || orderData?.userInfo?.phone_number,
                    email: orderData.userInfo?.email || "",
                },
                parcels: orderData.items.map(item => ({
                    count: item.amount,
                    dimensions: {
                        weight_gram: 0,
                        width_cm: 0,
                        height_cm: 0,
                        depth_cm: 0
                    },
                    price: {
                        amount: item.price * 100 || item.remotePrice * 100,
                        currency: "HUF"
                    },
                    description: item.name,
                    identifier: item._id,
                    dropoff_restrictions: {
                        id_check_required: false
                    },
                    tags: []
                })),
                shipment_promise_id: data.id,
                customer_support: {
                    url: `${restaurantPlace?.contact?.website}`,
                    email: `${restaurantPlace?.contact?.email}`,
                    phone_number: `${restaurantPlace?.contact?.phone}`,
                },
                merchant_order_reference_id: orderData._id,
                sms_notifications: {
                    received: "A Maharaja étterembe rendelésed megérkezett, azonnal nekilátunk. Itt tudod követni: TRACKING_LINK",
                    picked_up: "A Maharaja étteremből rendelésedet felvette egy futár és feléd tart. Itt tudod követni: TRACKING_LINK"
                },
                tips: [],
                ...(orderData.paymentType === "cash" && {
                    cash: {
                        "amount_to_collect": orderData.orderSum * 100,
                        "amount_to_expect": orderData.orderSum * 100
                    }
                }),
                order_number: orderData.orderCode,
                handshake_delivery: {
                    is_required: false
                }
            }, {
                headers: {
                    "Accept-Language": "hu_HU",
                    "authorization": `Bearer ${token}`,
                    "restaurantplaceid": `${restaurantPlace._id}`
                },
            });
            setDeliveryModalVisible(false);
            setIsDialogVisible({
                visible: true,
                type: "success",
                message: t('delivery_created_successfully'),
                onConfirm: () => setIsDialogVisible({ visible: false }),
            });
        } catch (error) {
            console.error('Error creating delivery:', error);
            setIsDialogVisible({
                visible: true,
                type: "error",
                message: t('delivery_creation_failed'),
                onConfirm: () => setIsDialogVisible({ visible: false }),
            });
        }
    };
    const createWoltPromise = async () => {
        try {
            const currentTime = new Date();
            const dropoffTime = new Date(currentTime.getTime() + (props?.acceptMinutes || 40) * 60000);
            const formattedDropoffTime = dropoffTime.toISOString();

            const promise = {
                city: orderData?.addressInfo?.city,
                street: orderData.addressInfo?.address || `${orderData?.addressInfo?.street} ${orderData?.addressInfo?.number || ''}`,
                post_code: orderData?.addressInfo?.zip,
                lat: orderData?.addressInfo?.coordinates_raw?.lat || orderData?.addressInfo?.coordinates[0],
                lon: orderData?.addressInfo?.coordinates_raw?.lng || orderData?.addressInfo?.coordinates[1],
                language: "hu",
                min_preparation_time_minutes: 0,
                scheduled_dropoff_time: formattedDropoffTime
            };
            const token = await getAccessToken();
            const response = await axios.post(ApiConfig.apiURL + '/api/delivery/wolt/shipment-promise', promise, {
                headers: {
                    "Accept-Language": "hu_HU",
                    "authorization": `Bearer ${token}`,
                    "restaurantplaceid": `${restaurantPlace._id}`
                },
            });
            const data = response.data;
            console.log("response.data(setDeliveryData)", data)
            const dropoffTimeString = new Date(data?.dropoff?.options?.scheduled_time).toLocaleTimeString();

            setDeliveryData(data);
            setDeliveryModalVisible(true);
        } catch (error) {
            console.error(error);
            console.log("error_01")
            setIsDialogVisible({
                visible: true,
                type: "error",
                message: t('delivery_creation_failed'),
                onConfirm: () => setIsDialogVisible({ visible: false }),
                onCancel: () => setIsDialogVisible({ visible: false }),
            });
        }
    };

    const handleAccept = async (time) => {
        const token = await getAccessToken();
        const response = await fetch(`https://api.happygastro.hu/api/order/${orderData._id}/status/preparing?orderMinutes=${time}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // Replace with your actual token
            }
        });
        setAcceptModalVisible(false);
        RawBTPrintKitchen(orderData, orderData.items);
        console.log(orderData)
        Observables.call("reloadOrders")
    };

    function isAllItemPaired(orderData) {
        return orderData.items.every(item => item.item); //all paired food?
    }

    const handleReject = async (reason) => {
        const token = await getAccessToken();
        await fetch(`${ApiConfig.apiURL}/order/${orderData._id}/status/cancelled?rejectReason=${reason}&storno=true`, {
            method: 'GET',
            headers: {
                'Accept-Language': 'hu_HU',
                'Authorization': `Bearer ${token}`,
            },
        });
        setIsRejectModal(false);
    };

    const handleOrderClose = async () => {
        const token = await getAccessToken();
        await axios.get(ApiConfig.apiURL + `/api/order/${orderData._id}/status/closed`,  {
            headers: {
                "Accept-Language": "hu_HU",
                "authorization": `Bearer ${token}`,
            },
        });
        Observables.call("reloadOrders");
        if (Platform.OS === 'web') {
            alert(t('invoice_closed'));
        } else {
            Alert.alert(t('invoice_closed'));
        }
    };

    const handleStorno = async () => {
        const token = await getAccessToken();
        await axios.post(ApiConfig.apiURL + `/api/order/${orderData._id}/update`, {
            storno: true,
        }, {
            headers: {
                "Accept-Language": "hu_HU",
                "authorization": `Bearer ${token}`,
            },
        });
        Observables.call("reloadOrders");

        if (Platform.OS === 'web') {
            alert(t('invoice_stornoed'));
        } else {
            Alert.alert(t('invoice_stornoed'));
        }
    };

    const handleRedButtonPress = () => {
        if (Platform.OS === 'web') {
            if (window.confirm(t('confirm_storno'))) {
                handleStorno();
            }
        } else {
            Alert.alert(
                t('confirm_storno_title'),
                t('confirm_storno'),
                [
                    {
                        text: t('no'),
                        style: "cancel"
                    },
                    {
                        text: t('yes'),
                        onPress: () => handleStorno()
                    }
                ]
            );
        }
    };

    const renderBuyerInfo = () => (
        <View style={[{ flexGrow: 1, backgroundColor: '#ffffff', padding: 16, borderRadius: 5 }]}>
            <Text style={[styles.sectionTitle, { paddingTop: "10px" }]}>{t('buyer_info')}</Text>
            {orderData?.userInfo && (
                <View style={styles.row}>
                    <Text style={styles.label}>{t('buyer')}:</Text>
                    <Text style={styles.value}>
                        {orderData?.userInfo?.name?.last || ""} {orderData?.userInfo?.name?.first || ""}
                    </Text>
                </View>
            )}
            {orderData?.userInfo?.phone && (
                <View style={styles.row}>
                    <Text style={styles.label}>{t('phone_number')}:</Text>
                    <Text style={styles.value}>
                        {orderData?.userInfo?.phone || ""}
                    </Text>
                </View>
            )}
            {(orderData?.userInfo?.mobilePhone) && (
                <View style={styles.row}>
                    <Text style={styles.label}>{t('phone_number')}:</Text>
                    <Text style={styles.value}>
                        {orderData?.userInfo?.mobilePhone || ""}
                    </Text>
                </View>
            )}
            {orderData?.userInfo?.phoneNumber && (
                <View style={styles.row}>
                    <Text style={styles.label}>{t('phone_number')}:</Text>
                    <Text style={styles.value}>
                        {orderData?.userInfo?.phoneNumber || ""}
                    </Text>
                </View>
            )}
            {orderData?.addressInfo && (
                <View style={styles.row}>
                    <Text style={styles.label}>{t('buyer_address')}:</Text>
                    <Text style={styles.value}>
                        {`${orderData?.addressInfo?.zip || ""} ${orderData?.addressInfo?.city || ""}, ${orderData.addressInfo?.address || ""}`}
                    </Text>
                </View>
            )}
        </View>
    );

    const renderPayments = () => (
        <View style={[styles.detailsContainer, { height: "100%" }]}>
            <Text style={styles.sectionTitle}>{t('transactions')}</Text>
            <View style={[styles.row, { borderBottomWidth: 1, borderBottomColor: '#ccc' }]}>
                <Text style={styles.label}>{t('payment_type')}:</Text>
                <Text style={styles.value}>{paymentType(orderData?.paymentType, t)}</Text>
            </View>
            {orderData?.paymentTypes?.length > 0 && (
                orderData?.paymentTypes.map((p, index) => (
                    <View key={`payment-${index}`} style={styles.row}>
                        <Text style={styles.label}>{paymentType(p.paymentType, t)}</Text>
                        <Text style={styles.value}>{priceFormatter(p.amount)}</Text>
                    </View>
                ))
            )}
            {orderData?.tipAmount > 0 && (
                <View style={styles.row}>
                    <Text style={styles.label}>{t('tip')}:</Text>
                    <Text style={styles.value}>{priceFormatter(orderData?.tipAmount)}</Text>
                </View>
            )}
            {orderData?.serviceFee > 0 && (
                <View style={styles.row}>
                    <Text style={styles.label}>{t('service_fee')}:</Text>
                    <Text style={styles.value}>{priceFormatter(orderData?.serviceFee)}</Text>
                </View>
            )}
        </View>
    );

    const renderOrderDetails = () => (
        <View style={[styles.detailsContainer]}>

            <View style={styles.row}>
                <Text style={styles.sectionTitle}>{t('details')}</Text>
                {getOrderIcon(orderData?.orderFrom)}
            </View>
            <View style={styles.row}>
                <Text style={styles.label}>{t('type')}:</Text>
                <Text style={styles.value}>{orderType(orderData?.orderType, t)}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.label}>{t('status')}:</Text>
                <Text style={styles.valuePaid}>{translateStatus(orderData?.status)}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.label}>{t('platform')}:</Text>
                <Text style={styles.value}>{orderData?.origin || "--"}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.label}>{t('created_at')}:</Text>
                <Text style={styles.value}>{new Date(orderData?.createdAt).toLocaleString()}</Text>
            </View>
            {orderData?.paymentTypes?.length > 1 ? (
                <View>
                    <View style={[styles.row]}>
                        <Text style={styles.label}>{t('paid')}:</Text>
                    </View>
                    {orderData?.paymentTypes.map((p, index) => (
                        <View key={`payment-${index}`} style={styles.row}>
                            <Text style={styles.label}></Text>
                            <Text style={styles.value}>{paymentType(p.paymentType, t)} {priceFormatter(p.amount)}</Text>
                        </View>
                    ))}
                </View>
            ) : (
                <View style={[styles.row]}>
                    <Text style={styles.label}>{t('payment_type')}:</Text>
                    <Text style={styles.value}>{paymentType(orderData?.paymentType, t)}</Text>
                </View>
            )}

            {orderData?.tipAmount > 0 && (
                <View style={styles.row}>
                    <Text style={styles.label}>{t('tip')}:</Text>
                    <Text style={styles.value}>{priceFormatter(orderData?.tipAmount)}</Text>
                </View>
            )}
            {orderData?.serviceFee > 0 && (
                <View style={styles.row}>
                    <Text style={styles.label}>{t('service_fee')}(%):</Text>
                    <Text style={styles.value}>{(orderData?.serviceFee)}%</Text>
                </View>
            )}
            {orderData?.serviceFeePrice > 0 && (
                <View style={styles.row}>
                    <Text style={styles.label}>{t('service_fee')}:</Text>
                    <Text style={styles.value}>{priceFormatter(orderData?.serviceFeePrice)}</Text>
                </View>
            )}
            {orderData?.deliveryFee > 0 && (
                <View style={styles.row}>
                    <Text style={styles.label}>Szállítási díj:</Text>
                    <Text style={styles.value}>{priceFormatter(orderData?.deliveryFee)}</Text>
                </View>
            )}
            {orderData?.discountAmount > 0 && (
                <View style={styles.row}>
                    <Text style={styles.label}>Kedvezmény:</Text>
                    <Text style={styles.value}>{priceFormatter(orderData?.discountAmount)}</Text>
                </View>
            )}
            {orderData?.discountPrice > 0 && (
                <View style={styles.row}>
                    <Text style={styles.label}>Kedvezmény:</Text>
                    <Text style={styles.value}>{priceFormatter(orderData?.discountPrice)}</Text>
                </View>
            )}
        </View>
    );

    return (
        <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={{ width: '100%', height: '95svh' }}
        >
            {orderData && (
                <View style={[styles.container, { maxHeight: Dimensions.get("screen").height - 70, height: Dimensions.get("screen").height - 70 }]}>
                    <ScrollView ref={scrollViewRef} contentContainerStyle={[styles.scrollView, { paddingBottom: 70 }]}>
                        {orderData?.status === "closed" && <View style={styles.header}>
                            <Text style={styles.headerText}>{t('invoice_closed_message')}</Text>
                        </View>}
                        {(orderData?.status === "cancelled" || orderData?.storno) && <View style={[styles.header, { backgroundColor: 'darkred' }]}>
                            <Text style={styles.headerText}>{t('invoice_cancelled_message')}</Text>
                        </View>}
                        {(orderData?.preOrder) && <View style={[styles.header, { backgroundColor: 'orange' }]}>
                            <Text style={styles.headerText}>{t('pre_order_message')}: {new Date(orderData?.preOrder).toLocaleString()}</Text>
                        </View>}
                        {isTrackingShown &&
                            <View style={{marginBottom: 15}}>
                                {Platform.OS === 'web' ? (
                                    <View style={{ height: "600px" }}>
                                        <iframe
                                            src={orderData?.woltDeliveryResponse?.tracking?.url}
                                            style={{ width: "100%", height: "600px", border: "none" }}
                                            title="Wolt Delivery Tracking"
                                        />
                                    </View>
                                ) : (
                                    <WebView
                                        source={{ uri: `${orderData?.woltDeliveryResponse?.tracking?.url}`}}
                                        style={{ width: '100%', height: 600 }}
                                    />
                                )}
                            </View>
                        }
                        <View style={styles.topSection}>
                            {orderData?.userInfo && <View style={[styles.leftContainer, { flex: 1, alignSelf: 'stretch' }]}>{renderBuyerInfo()}</View>}
                            <View style={[styles.rightContainer, { flex: 2 }]}>{renderOrderDetails()}</View>
                        </View>

                        <View style={styles.middleSection}>
                            <View style={styles.rightHalf}>
                                {orderData?.VIPCard && <View style={styles.detailsContainer}>
                                    <Text style={styles.sectionTitle}>{t('vip_card')}</Text>
                                    <View style={styles.row}>
                                        <Text style={styles.label}>{t('card_id')}:</Text>
                                        <Text style={styles.value}>{orderData?.VIPCard.cardId}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text style={styles.label}>{t('balance')}:</Text>
                                        <Text style={styles.value}>{orderData?.VIPCard.balance}</Text>
                                    </View>
                                    {orderData?.VIPCard?.discountAmount > 0 &&
                                    <View style={styles.row}>
                                        <Text style={styles.label}>{t('discount')}:</Text>
                                        <Text style={styles.value}>{orderData?.VIPCard.discountAmount}%</Text>
                                    </View>}
                                </View>}
                            </View>
                        </View>

                        <View style={styles.itemsContainer}>
                            {showPairingAlert && (
                                <Animated.View style={{ backgroundColor: fadeAnim.interpolate({
                                        inputRange: [0, 1],
                                        outputRange: ['transparent', 'red']
                                    }), padding: 10, borderRadius: 5 }}>
                                    <Text style={{ color: fadeAnim.interpolate({
                                            inputRange: [0, 1],
                                            outputRange: ['red', 'white']
                                        }), fontSize: 22, fontWeight: "bold", textAlign: "center" }}>
                                        {t("every_item_needs_to_be_paired")}
                                    </Text>
                                </Animated.View>
                            )}
                            <Text style={styles.itemHeader}>{t('order_items')}</Text>
                            {orderData?.items?.map((item, index) => (
                                <View key={`item-${index}`}>
                                    {/* Main Item Row */}
                                    {(orderData?.orderFrom === "netpincer" || orderData?.orderFrom === "wolt" || orderData?.orderFrom === "webshop") ? (
                                        <View style={styles.itemRow}>
                                            {/* Left Side: Price + Amount x Name */}
                                            <Text style={styles.itemPrice}>{priceFormatter(item.remotePrice)}</Text>
                                            <Text style={styles.itemName}>{`${item.amount} x ${item.remoteName || item.name}`}</Text>

                                            {/* Pairing Button */}
                                            <TouchableOpacity
                                                style={[
                                                    styles.pairingButton,
                                                    { backgroundColor: item.item ? "#cbefc5" : "#d3a0a0" }
                                                ]}
                                                onPress={() => openPairingModal(item)}
                                            >
                                                <Text style={{ color: item.item ? "black" : "white", fontWeight: "bold" }}>
                                                    {item.item?.name?.hu || item.item?.name?.en || t("not_paired")}
                                                </Text>
                                            </TouchableOpacity>

                                            {/* Right Side: Final Price */}
                                            <Text style={styles.itemPriceRight}>{priceFormatter(item.amount * item.price)}</Text>
                                        </View>
                                    ) : (
                                        <TouchableOpacity style={styles.itemRow} onPress={() => console.log("item pressed.")}>
                                            <Text style={[styles.itemName, { fontWeight: 'bold' }]}>{item.remoteName || item.name}</Text>
                                            <Text style={[styles.itemQuantity, { fontWeight: 'bold' }]}>{item.amount} {t('pcs')}</Text>
                                            <Text style={[styles.itemPrice, { fontWeight: 'bold' }]}>{priceFormatter(item.amount * (item.remotePrice || item.price))}</Text>
                                        </TouchableOpacity>
                                    )}

                                    {/* Render Extras Directly Under Each Item */}
                                    {item.extras?.length > 0 && item.extras.map((extra, extraIndex) => {
                                       if(orderData?.orderFrom === "netpincer" || orderData?.orderFrom === "wolt" || orderData?.orderFrom === "webshop"){
                                           return (
                                               <View key={`extra-${index}-${extraIndex}`} style={[styles.itemRow]}>

                                                   {/* Left Side: Price + Amount x Extra Name */}
                                                   <Text
                                                       style={styles.itemPrice}>{priceFormatter(extra.remotePrice || extra.price)}</Text>
                                                   <Text
                                                       style={styles.itemName}>{`${extra.amount} x ${extra.remoteName || extra.item?.name?.hu || extra.item?.name?.en || 'Extra'}`}</Text>

                                                   {/* Pairing Button for Extras */}
                                                   <TouchableOpacity
                                                       style={[
                                                           styles.pairingButton,
                                                           {backgroundColor: extra.item ? "#cbefc5" : "#d3a0a0"}
                                                       ]}
                                                       onPress={() => openPairingModal(extra)}
                                                   >
                                                       <Text style={{
                                                           color: extra.item ? "black" : "white",
                                                           fontWeight: "bold"
                                                       }}>
                                                           {extra.item?.name?.hu || extra.item?.name?.en || "Nincs párosítva"}
                                                       </Text>
                                                   </TouchableOpacity>

                                                   {/* Right Side: Final Price */}
                                                   <Text
                                                       style={styles.itemPriceRight}>{priceFormatter(extra.amount * (extra.price))}</Text>

                                               </View>
                                           )
                                       }else{
                                           return(
                                           <TouchableOpacity style={styles.extraItemRow} onPress={() => console.log(extra)}>
                                               <Text style={[styles.itemName, { fontWeight: 'bold' , color: "#575757"}]}>{extra.item?.remoteName || extra.item?.name?.hu}</Text>
                                               <Text style={[styles.itemQuantity, { fontWeight: 'bold', color: "#575757" }]}>{extra.amount} {t('pcs')}</Text>
                                               <Text style={[styles.itemPrice, { fontWeight: 'bold', color: "#575757" }]}>{priceFormatter(extra.amount * (extra.remotePrice || extra.price))}</Text>
                                           </TouchableOpacity>)

                                       }

                                    })}
                                </View>
                            ))}

                            <View style={[styles.totalRow, { borderTopWidth: 0.6, borderTopColor: '#b0b0b0', marginVertical: 8 }]}>
                                <Text style={styles.totalText}>{t('products_total')}:</Text>
                                <Text style={styles.totalValue}>{orderData?.productsPrice}</Text>
                            </View>

                            <View style={styles.totalRow}>
                                <Text style={styles.totalText}>{t('paid')}:</Text>
                                <Text style={styles.totalValue}>{priceFormatter(orderData?.paymentTypes.reduce((a, b) => +a + b.amount, 0))}</Text>
                            </View>
                        </View>


                        <View>
                            {orderData && <Timeline orderData={orderData}/>}
                        </View>


                    </ScrollView>
                    <View style={{ flexDirection: 'column', width: '100%' }}>
                        <View style={[styles.buttonContainer, { alignItems: 'flex-end', flex: 5, width: ((orderData?.status === "accepted" || orderData?.status === "preparing" || !orderData?.storno)) ? "100" : "50%" }]}>

                            {(orderData?.status !== "cancelled" || !orderData?.storno) && orderData?.status !== "pending" && (
                                <TouchableOpacity style={styles.redButton} onPress={handleRedButtonPress}>
                                    <Text style={styles.buttonText}>{t('storno_refund')}</Text>
                                </TouchableOpacity>
                            )}
                            {(orderData?.status !== "closed" || orderData?.status !== "cancelled" || !orderData?.storno) && orderData?.status === "pending" && (
                                <TouchableOpacity style={styles.redButton} onPress={() => setIsRejectModal(true)}>
                                    <Text style={styles.buttonText}>{t('reject')}</Text>
                                </TouchableOpacity>
                            )}
                            {(orderData?.status !== "closed" || orderData.status !== "cancelled" || !orderData.storno) && orderData?.status === "pending" && (
                                <TouchableOpacity style={[styles.button, styles.acceptButton]} onPress={() => {handleAcceptButtonPress()}}>
                                    <Text style={styles.buttonText}>{t('accept')}</Text>
                                </TouchableOpacity>
                            )}
                            {((orderData?.status === "accepted" || orderData?.status === "preparing" || orderData?.status === "ready" || orderData?.status === "busy") && !orderData?.storno && orderData.status !== "closed") && (
                                <>
                                    <TouchableOpacity style={styles.orangeButton} onPress={() => setReprintModalVisible(true)}>
                                        <Text style={styles.buttonText}>{t('reprint')}</Text>
                                    </TouchableOpacity>
                                    {orderData?.orderType === "online" && orderData?.addressInfo && (
                                        <TouchableOpacity style={styles.blueButton} onPress={createWoltPromise}>
                                        <Text style={styles.buttonText}>{t('wolt_courier')}</Text>
                                    </TouchableOpacity>)}


                                    <TouchableOpacity style={styles.orangeButton} onPress={handleOrderClose}>
                                        <Text style={styles.buttonText}>{t('close_invoice')}</Text>
                                    </TouchableOpacity>
                                </>
                            )}
                            {orderData?.woltDeliveryResponse?.tracking?.url &&
                                <TouchableOpacity
                                    style={styles.blueButton}
                                    onPress={() => {
                                        setTrackingShown(!isTrackingShown);
                                        if (scrollViewRef.current) {
                                            scrollViewRef.current.scrollTo({ y: 0, animated: true });
                                        }
                                    }}
                                >
                                    <Text style={styles.buttonText}>{isTrackingShown ? t('Vissza a részletekhez') : t("Nyomonkövetés")}</Text>
                                </TouchableOpacity> }
                        </View>
                    </View>
                    {isReprintModalVisible && (
                        <ReprintModal
                            isVisible={isReprintModalVisible}
                            onClose={() => setReprintModalVisible(false)}
                            orderData={orderData}
                        />
                    )}
                    <HappyDialog.alert
                        visible={isDialogVisible?.visible}
                        type={isDialogVisible?.type || "info"}
                        message={isDialogVisible?.message}
                        confirmText={isDialogVisible?.confirmText}
                        cancelText={isDialogVisible?.cancelText}
                        onConfirm={isDialogVisible?.onConfirm}
                        onCancel={isDialogVisible?.onCancel}
                    />
                    {(isDeliveryModalVisible && deliveryData) && (<DeliveryModal
                        open={isDeliveryModalVisible && deliveryData}
                        onClose={() => setDeliveryModalVisible(false)}
                        onConfirm={() => {
                            // todo: Test createWoltDelivery
                            createWoltDelivery(deliveryData.data)
                            setDeliveryModalVisible(false);
                        }}
                        deliveryData={deliveryData}
                    />)}
                    {orderData.status === "pending" && (
                        <>
                            <OrderAcceptTimePicker
                                isVisible={isAcceptModalVisible}
                                onClose={() => setAcceptModalVisible(false)}
                                onConfirm={handleAccept}
                                orderData={orderData}
                            />
                            <OrderRejectModal
                                isVisible={isRejectModalVisible}
                                onClose={() => setIsRejectModal(false)}
                                onConfirm={handleReject}
                            />
                        </>
                    )}
                    {isPairingModalVisible && (
                        <PairingModal
                            visible={isPairingModalVisible}
                            onClose={closePairingModal}
                            pairingItem={pairingItem}
                            orderId={orderId}
                            order={orderData}
                        />
                    )}
                </View>
            )}
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    container: {
        height: '95vh',
        paddingBottom: 85
    },
    scrollView: {
        padding: 16,
        paddingBottom: 70, // Add padding to avoid content being hidden behind buttons
        marginBottom: 70, // Add padding to avoid content being hidden behind buttons
        height: '95svh'
    },
    header: {
        backgroundColor: '#d6f5d6',
        padding: 10,
        borderRadius: 5,
        marginBottom: 16,
    },
    topSection: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    headerText: {
        color: '#008000',
        fontSize: 14,
        fontWeight: "700",
        textAlign: 'center',
    },
    pairingButton: {
        flex: 1,
        padding: 6,
        borderRadius: 5,
        alignItems: "center",
        justifyContent: "center",
        marginHorizontal: 8,
    },
    detailsContainer: {
        backgroundColor: '#ffffff',
        padding: 16,
        borderRadius: 5,
        marginBottom: 16,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    label: {
        fontSize: 14,
        color: '#555555',
    },
    value: {
        fontSize: 14,
        color: '#000000',
    },
    valuePaid: {
        fontSize: 14,
        color: '#008000',
        fontWeight: 'bold',
    },
    itemsContainer: {
        backgroundColor: '#ffffff',
        padding: 16,
        borderRadius: 5,
        marginBottom: 16,
    },
    itemHeader: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    itemRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    extraItemRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
        paddingLeft: 10
    },
    itemName: {
        flex: 2,
        fontSize: 14,
        color: '#000000',
    },
    itemQuantity: {
        flex: 1,
        fontSize: 14,
        color: '#000000',
        textAlign: 'center',
    },
    itemPrice: {
        flex: 1,
        fontWeight: "400",
        fontSize: 14,
        color: '#000000',
        textAlign: 'left',
    },
    itemPriceRight: {
        flex: 1,
        fontWeight: "400",
        fontSize: 14,
        color: '#000000',
        textAlign: 'right',
    },
    totalRow: {
        fontWeight: "900",
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 4,
    },
    totalText: {
        fontWeight: "900",
        fontSize: 14,
        color: '#555555',
    },
    totalValue: {
        fontWeight: "900",
        fontSize: 14,
        color: '#000000',
    },
    buttonContainer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        flexDirection: 'row',
        padding: 16,
        minHeight: 70,
        backgroundColor: '#f5f5f5',
    },
    greenButton: {
        flex: 1,
        backgroundColor: '#008000',
        padding: 10,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        marginRight: 8,
    },
    orangeButton: {
        flex: 1,
        backgroundColor: '#FFA500',
        padding: 10,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        marginRight: 8,
    },
    redButton: {
        flex: 1,
        backgroundColor: '#FF0000',
        padding: 10,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        marginRight: 8,
    },
    buttonText: {
        color: '#ffffff',
        fontSize: 14,
        fontWeight: 'bold',
        justifyContent: 'center',
        textAlign: 'center',
    },
    button: {
        flex: 1,
        padding: 10,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        marginHorizontal: 5,
    },
    acceptButton: {
        backgroundColor: 'green',
    },
    blueButton: {
        flex: 1,
        backgroundColor: '#36bdfb',
        padding: 10,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        marginRight: 8,
    },
    eventContainer: {
        flexDirection: 'row',
        marginBottom: 24,
    },
    iconLineContainer: {
        alignItems: 'center',
        marginRight: 16,
    },
    sectionTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    leftHalf: {
        flex: 1,
        marginRight: 8,
    },
    rightHalf: {
        flex: 1,
        marginLeft: 8,
    },
    leftContainer: {
        flex: 1,
        marginRight: 8,
        alignSelf: 'stretch',
    },
    rightContainer: {
        flex: 1,
        marginLeft: 8,
    },
    middleSection: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 16,
    },
    line: {
        width: 2,
        height: 40,
        backgroundColor: '#d3d3d3',
        marginTop: 4,
    },
    eventContent: {
        flex: 1,
    },
    description: {
        fontSize: 14,
        color: '#555',
    },
    modalBackgroundReprint: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContainerReprint: {
        width: '60%',
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 20,
        alignItems: 'center',
    },
    buttonReprint: {
        width: '100%',
        padding: 20,
        backgroundColor: '#007bff',
        borderRadius: 5,
        marginVertical: 10,
        alignItems: 'center',
    },
    buttonTextReprint: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
        color: '#333',
    },
    timeline: {
        flexDirection: 'column',
        position: 'relative',
        paddingBottom: 75, // Add padding to avoid content being hidden behind buttons
    },
    eventRow: {
        flexDirection: 'row',
        marginBottom: 20,
    },
    iconContainer: {
        alignItems: 'center',
        width: 50,
    },
    icon: {
        width: 12,
        height: 12,
        borderRadius: 6,
        backgroundColor: '#ff8000',
    },
    verticalLine: {
        width: 2,
        height: 50,
        backgroundColor: '#d3d3d3',
        marginTop: 4,
    },
    eventCard: {
        backgroundColor: '#fff',
        padding: 16,
        borderRadius: 10,
        elevation: 2, // Adds shadow for Android
        shadowColor: '#000', // Adds shadow for iOS
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 4,
        flex: 1,
    },
    date: {
        fontSize: 14,
        color: '#999',
        marginBottom: 8,
    },
    eventTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#333',
        marginBottom: 4,
    },
    eventDescription: {
        fontSize: 14,
        color: '#666',
    },
});

export default OrderDetailsScreen;