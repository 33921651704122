import React from "react";
import {View, Text, ScrollView, StyleSheet, Image} from "react-native";
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import {useTranslation} from "react-i18next";
import woltIcon from "../../assets/wolt_circle.jpg";
import foodora from "../../assets/foodora.jpg";


const PlatformStatistics = ({ statistics, priceFormatter }) => {
    const platforms = ["netpincer", "webshop", "phone", "wolt", "falatozz"];
    const {t} = useTranslation();

    const getOrdersByPaymentType = (platform, paymentType) => {
        return statistics[paymentType]?.filter(order => order.orderFrom === platform) || [];
    };

    function getPlatformIcons(platform) {
        switch (platform) {
            case "netpincer":
                return "delivery-dining";
            case "webshop":
                return "shopping-cart";
            case "phone":
                return "phone";
            case "wolt":
                return "delivery-dining";
            case "falatozz":
                return "delivery-dining";
            default:
                return "phone";
        }
    }

    const renderPlatformSection = (platform) => {
        const cashOrders = getOrdersByPaymentType(platform, "cashOrders");
        const creditCardOrders = getOrdersByPaymentType(platform, "creditCardOrders");

        const getOrderSummary = (orders, type) => {
            const filteredOrders = orders.filter(order => order.orderType === type);
            return {
                count: filteredOrders.length,
                sum: filteredOrders.reduce((a, b) => a + (b.orderSum || 0), 0)
            };
        };
        const {t} = useTranslation();

        const cashDelivery = getOrderSummary(cashOrders, "online");
        const cashTakeAway = getOrderSummary(cashOrders, "takeAway");
        const cardDelivery = getOrderSummary(creditCardOrders, "online");
        const cardTakeAway = getOrderSummary(creditCardOrders, "takeAway");

        return (
            <View key={platform} style={styles.platformContainer}>
                <View style={styles.header}>
                    {platform === "wolt" ? <Image source={woltIcon} style={{ width: 52, height: 24}} />  : platform === "netpincer" ? <Image source={foodora} style={{ width: 52, height: 24, borderRadius: 3}} /> : <MaterialIcons name={getPlatformIcons(platform)} size={24} color="#333"/>}
                    <Text style={styles.platformTitle}>{t(platform)}</Text>
                </View>
                <View style={{flexDirection : "row", justifyContent: "space-between"}}>
                    <View style={[styles.section, {paddingHorizontal: 10}]}>
                        <Text style={styles.sectionTitle}>{t("delivery")}</Text>
                        <View style={styles.orderRow}>
                            <FontAwesome name="credit-card" size={16} color="#333" />
                            <Text style={styles.orderText}>{t("credit_card")}: {cardDelivery.count} {t("orders")} / {priceFormatter(cardDelivery.sum)}</Text>
                        </View>
                        <View style={styles.orderRow}>
                            <FontAwesome name="money" size={16} color="#333" />
                            <Text style={styles.orderText}>{t("cash")}: {cashDelivery.count} {t("orders")} / {priceFormatter(cashDelivery.sum)}</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {paddingHorizontal: 10}]}>
                        <Text style={styles.sectionTitle}>{t("takeAway")}</Text>
                        <View style={styles.orderRow}>
                            <FontAwesome name="credit-card" size={16} color="#333" />
                            <Text style={styles.orderText}>{t("credit_card")}: {cardTakeAway.count} {t("orders")} / {priceFormatter(cardTakeAway.sum)}</Text>
                        </View>
                        <View style={styles.orderRow}>
                            <FontAwesome name="money" size={16} color="#333" />
                            <Text style={styles.orderText}>{t("cash")}: {cashTakeAway.count} {t("orders")} / {priceFormatter(cashTakeAway.sum)}</Text>
                        </View>
                    </View>
                </View>
            </View>
        );
    };

    return (
                <View style={{flex: 1}}>
                    {platforms.map(renderPlatformSection)}
                </View>
    );
};

const styles = StyleSheet.create({
    container: {
        height: "90vh"
    },
    platformContainer: {
        backgroundColor: "#fff",
        borderRadius: 10,
        padding: 15,
        marginBottom: 10,
        elevation: 3,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
    },
    header: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 10,
    },
    platformTitle: {
        fontWeight: "bold",
        fontSize: 18,
        marginLeft: 10,
    },
    section: {
        marginTop: 10,
    },
    sectionTitle: {
        fontWeight: "bold",
        fontSize: 16,
        marginBottom: 5,
    },
    orderRow: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 5,
    },
    orderText: {
        marginLeft: 8,
        fontSize: 14,
    },
});

export default PlatformStatistics;
