import React, { useState } from 'react';
import { Modal, View, Text, TextInput, Button, StyleSheet } from 'react-native';
import {useTranslation} from "react-i18next";

const DenyBookingModal = ({ visible, onClose, onConfirm }) => {
    const [reason, setReason] = useState('');
    const {t} = useTranslation();
    const handleConfirm = () => {
        onConfirm(reason);
        setReason('');
    };

    return (
        <Modal visible={visible} animationType="slide" transparent={true}>
            <View style={styles.modalContainer}>
                <View style={styles.modalContent}>
                    <Text style={styles.title}>{t("delete_booking")}</Text>
                    <TextInput
                        style={styles.input}
                        placeholder={t("enter_reason")}
                        value={reason}
                        onChangeText={setReason}
                    />
                    <View style={styles.buttonRow}>
                        <Button title={t("cancel")} onPress={onClose} color={'#000'} />
                        <Button title={t("confirm")} onPress={handleConfirm} color={"#ff8125"} />
                    </View>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        width: 300,
        elevation: 5,
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: 'center',
    },
    input: {
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        padding: 10,
        marginBottom: 15,
    },
    buttonRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});

export default DenyBookingModal;