import React, {useEffect} from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import { priceFormatter } from '../helpers/numbers';

const TrafficDataStatistics = ({ statistics }) => {
    const { t } = useTranslation();
    const [deliveryFeeSum, setDeliveryFeeSum] = React.useState(0);
    const [showGross, setShowGross] = React.useState(true);

    useEffect(() => {
        setDeliveryFeeSum([...statistics.orders].reduce(
            (total, order) => (showGross ? order.deliveryFee : order.deliveryNetFee) + total,
            0
        ));
    }, [statistics]);

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <Text style={styles.label}>{t("product_income")}:</Text>
                <Text style={styles.value}>{priceFormatter(statistics.productsSum)}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.label}>{t("service_fees")}:</Text>
                <Text style={styles.value}>{priceFormatter(Number(statistics.serviceFeePrice))}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.label}>{t("discounts")}:</Text>
                <Text style={styles.value}>-{priceFormatter(statistics.discountsSum)}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.label}>{t("delivery_fees")}:</Text>
                <Text style={styles.value}>{priceFormatter(deliveryFeeSum)}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.label}>{t("total_traffic")}:</Text>
                <Text style={styles.value}>{priceFormatter(statistics.finalSum)}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.label}>{t("open_tables")}:</Text>
                <Text style={styles.value}>{priceFormatter(statistics?.dineInOrders?.reduce((a, b) => +a + b.orderSum, 0))}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.label}>{t("from_vip_balance")}:</Text>
                <Text style={styles.value}>{priceFormatter(statistics.allSum.vipBalance || 0)}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.label}>{t("tip_take_away")}:</Text>
                <Text style={styles.value}>{priceFormatter(statistics?.tipAmount?.takeAway || 0)}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.label}>{t("tip_online")}:</Text>
                <Text style={styles.value}>{priceFormatter(statistics?.tipAmount?.online || 0)}</Text>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    label: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    value: {
        fontSize: 16,
    },
});

export default TrafficDataStatistics;