import React, { createContext, useState } from "react";
import tooltips from "./StoredToolTips";
import {convertOldItem} from "../constants/converter";

export const TourContext = createContext();

export const TourProvider = ({ children }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [tourCompleted, setTourCompleted] = useState(true);

    const nextStep = () => {
        setCurrentStep((prev) => prev + 1);
    };

    const resetTour = () => {
        setCurrentStep(0);
        setTourCompleted(false);
    };

    const startTour = () => {
        console.log("startTour");
        setTourCompleted(false);
        setCurrentStep(0);
    };
    const endTour = () => {
        setTourCompleted(true);
        setCurrentStep(0);
    };

    return (
        <TourContext.Provider value={{ currentStep, nextStep, tourCompleted, setTourCompleted , resetTour,  endTour, startTour}}>
            {children}
        </TourContext.Provider>
    );
};
