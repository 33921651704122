import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import Modal from 'react-native-modal';
import Icon from 'react-native-vector-icons/Ionicons';
import OrderDetailsScreen from '../screens/OrderDetailsScreen';

const OrderDetailsModal = ({ isVisible, onClose, orderData, orderId }) => {
    return (
        <Modal
            isVisible={isVisible}
            onBackdropPress={onClose}
            style={styles.modal}
            animationIn="slideInUp"
            animationOut="slideOutDown"
        >
            <View style={styles.modalContent}>
                <TouchableOpacity style={styles.closeButton} onPress={onClose}>
                    <Icon name="close-circle-sharp" size={40} color="#000" />
                </TouchableOpacity>
                <OrderDetailsScreen orderData={orderData} orderId={orderId}/>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modal: {
        margin: 0, // Fullscreen modal
        justifyContent: 'flex-end',
    },
    modalContent: {
        flex: 1,
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 20,
    },
    closeButton: {
        position: 'absolute',
        top: 10,
        right: 10,
        zIndex: 1,
    },
});

export default OrderDetailsModal;