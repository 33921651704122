import React, {useState, useContext, useEffect} from 'react';
import { View, TextInput, Button, StyleSheet, TouchableOpacity, ScrollView, Text, Platform } from 'react-native';
import AddressInput from '../components/AddressInput';
import DateTimePicker from '@react-native-community/datetimepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { OnlineOrderContext } from '../helpers/OnlineOrderContext';
import GuestList from '../components/GuestList';
import Ionicons from 'react-native-vector-icons/Ionicons';
import DateTimePickerButton from "../components/DateTimePickerButton";
import { useTranslation } from 'react-i18next';
import HappyAlert from "../helpers/crossPlatformTools/HappyAlert";

const OnlineContainer = ({ setDateTime, showPicker, dateTime, setDateTimeHandler, handleSubmit, formData, setFormData, formErrors, webDateChange, toggleNotes, showNotes }) => {
    const { t } = useTranslation();
    const handlePhoneNumberChange = (text) => {
        const phoneNumberPattern = /^\+\d{0,15}$/;
        if (text === '' || text === '+') {
            setFormData({ ...formData, phoneNumber: '+' });
        } else if (phoneNumberPattern.test(text)) {
            setFormData({ ...formData, phoneNumber: text });
        } else if (/^\d+$/.test(text)) {
            setFormData({ ...formData, phoneNumber: `+${text}` });
        }
    };
    return (
        <ScrollView contentContainerStyle={styles.scrollContainer}>
            <View style={styles.formContainer}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={styles.inputContainer}>
                        <Text style={styles.label}>{t('last_name')}</Text>
                        <TextInput
                            style={[styles.input, formErrors.lastName ? styles.inputError : null]}
                            placeholder={t('last_name')}
                            value={formData.lastName}
                            onChangeText={(text) => setFormData({ ...formData, lastName: text })}
                        />
                        {formErrors.lastName && <Text style={styles.errorText}>{formErrors.lastName}</Text>}
                    </View>
                    <View style={styles.inputContainer}>
                        <Text style={styles.label}>{t('first_name')}</Text>
                        <TextInput
                            style={[styles.input, formErrors.firstName ? styles.inputError : null]}
                            placeholder={t('first_name')}
                            value={formData.firstName}
                            onChangeText={(text) => setFormData({ ...formData, firstName: text })}
                        />
                        {formErrors.firstName && <Text style={styles.errorText}>{formErrors.firstName}</Text>}
                    </View>
                </View>
                <View style={styles.inputContainer}>
                    <Text style={styles.label}>{t('phone_number')}</Text>
                    <TextInput
                        style={[styles.input, formErrors.phoneNumber ? styles.inputError : null]}
                        placeholder={t('phone_number')}
                        keyboardType="phone-pad"
                        value={formData.phoneNumber}
                        onChangeText={handlePhoneNumberChange}
                    />
                    {formErrors.phoneNumber && <Text style={styles.errorText}>{formErrors.phoneNumber}</Text>}
                </View>
                <View style={[styles.inputContainer, { zIndex: 500 }]}>
                    <Text style={styles.label}>{t('address_search')}</Text>
                    <AddressInput
                        setAddress={(address) => setFormData({ ...formData, ...address })}
                    />
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={styles.inputContainer}>
                        <Text style={styles.label}>{t('zip_code')}</Text>
                        <TextInput
                            style={[styles.input, formErrors.zip ? styles.inputError : null]}
                            placeholder={t('zip_code')}
                            value={formData.zip}
                            onChangeText={(text) => setFormData({ ...formData, zip: text })}
                        />
                        {formErrors.zip && <Text style={styles.errorText}>{formErrors.zip}</Text>}
                    </View>
                    <View style={styles.inputContainer}>
                        <Text style={styles.label}>{t('city')}</Text>
                        <TextInput
                            style={[styles.input, formErrors.city ? styles.inputError : null]}
                            placeholder={t('city')}
                            value={formData.city}
                            onChangeText={(text) => setFormData({ ...formData, city: text })}
                        />
                        {formErrors.city && <Text style={styles.errorText}>{formErrors.city}</Text>}
                    </View>
                </View>
                <View style={styles.inputContainer}>
                    <Text style={styles.label}>{t('address')}</Text>
                    <TextInput
                        style={[styles.input, formErrors.street ? styles.inputError : null]}
                        placeholder={t('address')}
                        value={formData.street}
                        onChangeText={(text) => setFormData({ ...formData, street: text })}
                    />
                    {formErrors.street && <Text style={styles.errorText}>{formErrors.street}</Text>}
                </View>
                <View style={styles.inputContainer}>
                    <Text style={styles.label}>{t('additional_address_details')}</Text>
                    <TextInput
                        style={styles.input}
                        placeholder={t('additional_address_details')}
                        value={formData.additionalAddressDetails}
                        onChangeText={(text) => setFormData({ ...formData, additionalAddressDetails: text })}
                    />
                </View>
                {showNotes && (
                    <>
                        <View style={styles.inputContainer}>
                            <Text style={styles.label}>{t('courier_note')}</Text>
                            <TextInput
                                style={[styles.input, { paddingTop: 5 }]}
                                placeholder={t('courier_note')}
                                multiline
                                value={formData.note}
                                onChangeText={(text) => setFormData({ ...formData, note: text })}
                            />
                        </View>
                        <View style={styles.inputContainer}>
                            <Text style={styles.label}>{t('kitchen_note')}</Text>
                            <TextInput
                                style={[styles.input, { paddingTop: 5 }]}
                                placeholder={t('kitchen_note')}
                                multiline
                                value={formData.kitchenNote}
                                onChangeText={(text) => setFormData({ ...formData, kitchenNote: text })}
                            />
                        </View>
                    </>
                )}
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <DateTimePickerButton onDateChange={(date) => webDateChange(date)} isTime={true} isHistory={false} buttonText={"Előrendelés"}/>
                    <TouchableOpacity style={styles.toggleComment} onPress={toggleNotes}>
                        <Ionicons
                            name={showNotes ? "chatbox-ellipses" : "chatbox"}
                            size={22}
                            color="white"
                        />
                        <Text style={styles.toggleCommentText}> {t('notes')}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.orderButton} onPress={handleSubmit}>
                        <Text style={styles.orderButtonText}>{t('create_order')}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </ScrollView>
    );
};

const TakeAwayContainer = ({ setDateTime, showPicker, dateTime, setDateTimeHandler, handleSubmit, formData, setFormData, formErrors, webDateChange, toggleNotes, showNotes }) => {
    const { t } = useTranslation();
    const handlePhoneNumberChange = (text) => {
        const phoneNumberPattern = /^\+\d{0,15}$/;
        if (text === '' || text === '+') {
            setFormData({ ...formData, phoneNumber: '+' });
        } else if (phoneNumberPattern.test(text)) {
            setFormData({ ...formData, phoneNumber: text });
        } else if (/^\d+$/.test(text)) {
            setFormData({ ...formData, phoneNumber: `+${text}` });
        }
    };
    return (
        <ScrollView contentContainerStyle={styles.scrollContainer}>
            <View style={styles.formContainer}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={styles.inputContainer}>
                        <Text style={styles.label}>{t('last_name')}</Text>
                        <TextInput
                            style={[styles.input, formErrors.lastName ? styles.inputError : null]}
                            placeholder={t('last_name')}
                            value={formData.lastName}
                            onChangeText={(text) => setFormData({ ...formData, lastName: text })}
                        />
                        {formErrors.lastName && <Text style={styles.errorText}>{formErrors.lastName}</Text>}
                    </View>
                    <View style={styles.inputContainer}>
                        <Text style={styles.label}>{t('first_name')}</Text>
                        <TextInput
                            style={[styles.input, formErrors.firstName ? styles.inputError : null]}
                            placeholder={t('first_name')}
                            value={formData.firstName}
                            onChangeText={(text) => setFormData({ ...formData, firstName: text })}
                        />
                        {formErrors.firstName && <Text style={styles.errorText}>{formErrors.firstName}</Text>}
                    </View>
                </View>
                <View style={styles.inputContainer}>
                    <Text style={styles.label}>{t('phone_number')}</Text>
                    <TextInput
                        style={[styles.input, formErrors.phoneNumber ? styles.inputError : null]}
                        placeholder={t('phone_number')}
                        keyboardType="phone-pad"
                        value={formData.phoneNumber}
                        onChangeText={handlePhoneNumberChange}
                    />
                    {formErrors.phoneNumber && <Text style={styles.errorText}>{formErrors.phoneNumber}</Text>}
                </View>
                <View style={styles.inputContainer}>
                    <Text style={styles.label}>{t('kitchen_note')}</Text>
                    <TextInput
                        style={[styles.input, { paddingTop: 5 }]}
                        placeholder={t('kitchen_note')}
                        multiline
                        value={formData.kitchenNote}
                        onChangeText={(text) => setFormData({ ...formData, kitchenNote: text })}
                    />
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <DateTimePickerButton onDateChange={(date) => webDateChange(date)} isTime={true} isHistory={false} buttonText={"Előrendelés"}/>
                    <TouchableOpacity style={styles.orderButton} onPress={handleSubmit}>
                        <Text style={styles.orderButtonText}>{t('create_order')}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </ScrollView>
    );
};

const OnlineNewOrder = ({ navigation }) => {
    const { t } = useTranslation();
    const { updateOrderData } = useContext(OnlineOrderContext);
    const [selectedForm, setSelectedForm] = useState('online');
    const [dateTime, setDateTime] = useState(new Date());
    const [showPicker, setShowPicker] = useState(false);
    const [alertVisible, setAlertVisible] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        zip: '',
        city: '',
        street: '',
        additionalAddressDetails: '',
        note: '',
        kitchenNote: '',
        orderType: selectedForm,
    });
    const [formErrors, setFormErrors] = useState({});
    const [showNotes, setShowNotes] = useState(false);

    const toggleNotes = () => {
        setShowNotes(!showNotes);
    };
    useEffect(() => {
        updateOrderData(formData, selectedForm);
    }, [formData, selectedForm]);

    useEffect(() => {
        setFormData({...formData, orderType: selectedForm})
    }, [selectedForm]);

    const handleGuestSelect = (guest) => {
        setFormData({
            ...formData,
            firstName: guest?.userInfo?.name?.first || '',
            lastName: guest?.userInfo?.name?.last || '',
            phoneNumber: guest?.userInfo?.phone || '',
            zip: guest?.addressInfo?.zip || '',
            city: typeof guest?.addressInfo?.city === 'object' ? guest?.addressInfo?.city['_'] : guest?.addressInfo?.city || '',
            street: guest?.addressInfo?.address || '',
            additionalAddressDetails: formData.additionalAddressDetails,
            note: formData.note, //delivery note
            kitchenNote: formData.kitchenNote,
            orderType: selectedForm,
        });
    };

    const validateAndSubmit = (formData, navigation, setAlertVisible) => {
        const now = new Date();
        const preOrderDate = new Date(formData.preOrder);

        if (preOrderDate < now) {
            setAlertVisible(true);
        } else {
            navigation.navigate('HomeScreen', { saleScreen: true, formData: formData });
        }
    };

    return (
        <View style={styles.container}>
            <View style={styles.tabContainer}>
                <TouchableOpacity
                    style={[styles.tabButton, selectedForm === 'online' && styles.activeTabButton]}
                    onPress={() => setSelectedForm('online')}
                >
                    <Ionicons name="bicycle-outline" size={24} style={styles.icon} />
                    <Text style={styles.tabButtonText}>{t('home_delivery')}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[styles.tabButton, selectedForm === 'takeaway' && styles.activeTabButton]}
                    onPress={() => setSelectedForm('takeaway')}
                >
                    <Ionicons name="walk-outline" size={24} style={styles.icon} />
                    <Text style={styles.tabButtonText}>{t('take_away')}</Text>
                </TouchableOpacity>
            </View>
            <View style={styles.mainContainer}>
                <View style={styles.guestListContainer}>
                    <GuestList filterCriteria={formData} onGuestSelect={handleGuestSelect} />
                </View>
                <View style={styles.formContainer}>
                    {selectedForm === 'online' ? (
                        <OnlineContainer
                            setDateTime={() => setShowPicker(true)}
                            showPicker={showPicker}
                            dateTime={dateTime}
                            setDateTimeHandler={(event, date) => setDateTime(date || dateTime)}
                            handleSubmit={() => {validateAndSubmit(formData, navigation, setAlertVisible)}}
                            formData={formData}
                            setFormData={setFormData}
                            formErrors={formErrors}
                            webDateChange={(date) => setFormData({ ...formData, orderType: "online", preOrder: date, saleScreen: true })}
                            toggleNotes={toggleNotes}
                            showNotes={showNotes}
                        />
                    ) : (
                        <TakeAwayContainer
                            setDateTime={() => setShowPicker(true)}
                            showPicker={showPicker}
                            dateTime={dateTime}
                            setDateTimeHandler={(event, date) => setDateTime(date || dateTime)}
                            handleSubmit={() => {validateAndSubmit(formData, navigation, setAlertVisible)}}
                            formData={formData}
                            setFormData={setFormData}
                            formErrors={formErrors}
                            webDateChange={(date) => setFormData({ ...formData, orderType: "takeAway", preOrder: date })}
                            toggleNotes={toggleNotes}
                            showNotes={showNotes}
                        />
                    )}
                    <HappyAlert
                        visible={alertVisible}
                        onConfirm={() => {
                            setAlertVisible(false)
                        }}
                        onCancel={() => setAlertVisible(false)}
                        type="warning"
                        message="The pre-order time should not be earlier than the current time."
                        confirmText="OK"
                    />
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    scrollContainer: {
        flexGrow: 1,
    },
    label: {
        fontSize: 14,
        marginBottom: 5,
    },
    input: {
        borderColor: '#ccc',
        borderWidth: 1,
        borderRadius: 5,
        padding: 10,
        marginBottom: 10,
    },
    orderButton: {
        backgroundColor: '#f76e24',
        padding: 15,
        borderRadius: 8,
        alignItems: 'center',
    },
    orderButtonText: {
        color: 'white',
        fontWeight: 'bold',
    },
    toggleButton: {
        backgroundColor: '#50A5F1',
        padding: 10,
        borderRadius: 8,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 10,
    },
    toggleButtonText: {
        color: 'white',
        fontWeight: 'bold',
        marginLeft: 5,
    },
    toggleComment: {
        backgroundColor: '#50A5F1',
        padding: 10,
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: "row",
        shadowColor: '#000', // Added shadow
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 6,
        elevation: 4,
    },
    toggleCommentText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 16,
    },
    inputContainer: {
        marginBottom: 5,
        flex :1,
    },
    errorText: {
        color: 'red',
        fontSize: 12,
        marginTop: 4,
    },

    inputError: {
        borderColor: 'red',
        borderWidth: 1,
    },
    buttonContainer: {
        flexDirection: 'row',
        backgroundColor: '#f0f0f0',
        paddingVertical: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
    floatLeftButton: {
        alignSelf: 'flex-end',
        width: '250px',
        padding: 8
    },
    collapsed: {
        flex: 0.2,
        paddingBottom: 15,

    },
    container: {
        flex: 1,
    },
    tabContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        backgroundColor: '#f0f0f0',
        paddingVertical: 10,
    },
    tabButton: {
        paddingVertical: 10,
        alignItems: 'center',
        width: '50%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    activeTabButton: {
        borderBottomWidth: 4,
        borderBottomColor: '#f76e24',
    },
    tabButtonText: {
        fontWeight: 'bold',
        marginLeft: 10
    },
    icon: {
        marginBottom: 5,
    },
    mainContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    guestListContainer: {
        flex: 1,
        backgroundColor: '#f9f9f9',
        padding: 10,
        borderRightWidth: 1,
        borderRightColor: '#ddd',
    },
    formContainer: {
        flex: 2,
        padding: 20,
    },
});

export default OnlineNewOrder;