import React, { useState } from 'react';
import {
    Modal,
    View,
    Text,
    TextInput,
    TouchableOpacity,
    StyleSheet,
    ScrollView, KeyboardAvoidingView
} from 'react-native';
import {priceFormatter} from "../helpers";

const CashCountModal = ({
                            isVisible,
                            onClose,
                            onSubmit
                        }) => {
    // State for tracking the count of each denomination
    const [denominations, setDenominations] = useState({
        "5": '',
        "10": '',
        "20": '',
        "50": '',
        "100": '',
        "200": '',
        "500": '',
        "1000": '',
        "2000": '',
        "5000": '',
        "10000": '',
        "20000": ''
    });
    const [employeeName, setEmployeeName] = useState('');
    const [envelopeBackAmount, setEnvelopeBackAmount] = useState(0);
    const [cardTerminalAmount, setCardTerminalAmount] = useState(0);
    const [vivaTerminalAmount, setVivaTerminalAmount] = useState(0);
    const [envelope10, setEnvelope10] = useState(0);


    // Calculate total cash amount
    const calculateTotal = () => {
        return Object.entries(denominations).reduce((total, [denom, count]) => {
            const numericDenom = parseInt(denom);
            const numericCount = parseInt(count) || 0;
            return total + (numericDenom * numericCount);
        }, 0);
    };

    // Handle submission of cash count
    const handleSubmit = () => {
        // Convert string inputs to numbers, replacing empty strings with 0
        const processedDenominations = Object.fromEntries(
            Object.entries(denominations).map(([key, value]) => [
                key,
                value === '' ? 0 : parseInt(value)
            ])
        );

        // Call the onSubmit prop with processed denominations and total
        onSubmit({
            bank_notes: processedDenominations,
            total: calculateTotal(),
            employeeName: employeeName,
            envelopeBackAmount: envelopeBackAmount,
            cardTerminalAmount: cardTerminalAmount,
            vivaTerminalAmount: vivaTerminalAmount,
            envelope10: envelope10
        });

        // Close the modal
        onClose();
    };
    const allowOnlyNumbers = (value) => value.replace(/[^0-9]/g, '');
    // Update count for a specific denomination
    const updateDenomination = (denom, value) => {
        setDenominations(prev => ({
            ...prev,
            [denom]: value
        }));
    };

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={isVisible}
            onRequestClose={onClose}
        >
            <View style={styles.centeredView}>
                <KeyboardAvoidingView style={styles.modalView}>
                    <Text style={styles.modalTitle}>Napi Rovancs (Cash Count)</Text>

                    <ScrollView style={styles.scrollView}>
                        <View style={[styles.inputRow,]}>
                            <Text style={styles.denomLabel}>1/A. BORÍTÉK VISSZA
                                1/A. ENVELOPE BACK</Text>
                            <TextInput
                                style={styles.input}
                                keyboardType="numeric"
                                placeholder="Összeg"
                                onFocus={()=> {
                                    if (envelopeBackAmount === 0 || envelopeBackAmount === "0") setEnvelopeBackAmount("")
                                }}
                                onBlur={()=>{if(envelopeBackAmount === '') setEnvelopeBackAmount("0")}}
                                value={String(envelopeBackAmount)}
                                onChangeText={(value) => setEnvelopeBackAmount(allowOnlyNumbers(value))}
                            />
                        </View>
                        <View style={[styles.inputRow]}>
                            <Text style={styles.denomLabel}>7. ÉTTEREM BANKKÁRTYA TERMINÁL
                                7. RESTAURANT`S BANK CARD TERMINAL</Text>
                            <TextInput
                                style={styles.input}
                                keyboardType="numeric"
                                placeholder="Összeg"
                                value={String(cardTerminalAmount)}
                                onFocus={()=> {
                                    if (cardTerminalAmount === 0 || cardTerminalAmount === "0") setCardTerminalAmount("")
                                }}
                                onBlur={()=>{if(cardTerminalAmount === '') setCardTerminalAmount("0")}}
                                onChangeText={(value) => setCardTerminalAmount( allowOnlyNumbers(value))}
                            />
                        </View>
                        <View style={[styles.inputRow]}>
                            <Text style={styles.denomLabel}>7/A. VIVA ÉTTEREM BANKKÁRTYA TERMINÁL
                                7/A. VIVA RESTAURANT`S BANK CARD TERMINAL
                            </Text>
                            <TextInput
                                style={styles.input}
                                keyboardType="numeric"
                                placeholder="Összeg"
                                value={String(vivaTerminalAmount)}
                                onFocus={()=> {
                                    if (vivaTerminalAmount === 0 || vivaTerminalAmount === "0") setVivaTerminalAmount("")
                                }}
                                onBlur={()=>{if(vivaTerminalAmount === '') setVivaTerminalAmount("0")}}
                                onChangeText={(value) => setVivaTerminalAmount( allowOnlyNumbers(value))}
                            />
                        </View>
                        <View style={[styles.inputRow]}>
                            <Text style={styles.denomLabel}>BORÍTÉK /
                                ENVELOPE</Text>
                            <TextInput
                                style={styles.input}
                                keyboardType="numeric"
                                placeholder="Összeg"
                                value={String(envelope10)}
                                onFocus={()=> {
                                    if (envelope10 === 0 || envelope10 === "0") setEnvelope10("")
                                }}
                                onBlur={()=>{if(envelope10 === '') setEnvelope10("0")}}
                                onChangeText={(value) => setEnvelope10( allowOnlyNumbers(value))}
                            />
                        </View>
                        {Object.keys(denominations).map((denom, index) => (
                            <View key={denom} style={[styles.inputRow, index % 2 === 0 && { backgroundColor: 'rgb(253 240 209)' }]}>
                                <Text style={styles.denomLabel}>{priceFormatter(parseInt(denom))}</Text>
                                <TextInput
                                    style={styles.input}
                                    keyboardType="numeric"
                                    placeholder="Darabszám"
                                    value={denominations[denom]}
                                    onChangeText={(value) => updateDenomination(denom, allowOnlyNumbers(value))}
                                />
                            </View>
                        ))}
                    </ScrollView>
                    <View style={[styles.inputRow]}>
                        <Text style={styles.denomLabel}>Kitöltő neve</Text>
                        <TextInput
                            style={styles.input}
                            placeholder="Kitöltő neve"
                            value={employeeName}
                            onChangeText={(value) => setEmployeeName(value)}
                        />
                    </View>
                    <View style={styles.totalContainer}>
                        <Text style={styles.totalLabel}>Teljes összeg:</Text>
                        <Text style={styles.totalAmount}>
                            {calculateTotal().toLocaleString()} HUF
                        </Text>
                    </View>

                    <View style={styles.buttonContainer}>
                        <TouchableOpacity
                            style={[styles.button, styles.cancelButton]}
                            onPress={onClose}
                        >
                            <Text style={styles.buttonText}>Mégse</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={[styles.button, styles.submitButton]}
                            onPress={handleSubmit}
                        >
                            <Text style={styles.buttonText}>Mentés</Text>
                        </TouchableOpacity>
                    </View>
                </KeyboardAvoidingView>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    modalView: {
        width: '80%',
        backgroundColor: 'white',
        borderRadius: 20,
        paddingVertical: 10,
        paddingHorizontal: 20,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        maxHeight:'100%'
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 15
    },
    scrollView: {
        width: '100%',
        maxHeight: 400
    },
    inputRow: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
        justifyContent: 'space-between',
        backgroundColor: "#fffbf2"
    },
    denomLabel: {
        flex: 1,
        marginRight: 10,
        textAlign: "center"
    },
    input: {
        flex: 2,
        borderWidth: 1,
        borderColor: '#ddd',
        padding: 8,
        textAlign: 'right'
    },
    totalContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 15,
        paddingTop: 10,
        borderTopWidth: 1,
        borderTopColor: '#ddd'
    },
    totalLabel: {
        fontWeight: 'bold'
    },
    totalAmount: {
        fontWeight: 'bold',
        color: 'green'
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 15
    },
    button: {
        padding: 15,
        borderRadius: 5,
        width: '48%',
        alignItems: 'center'
    },
    submitButton: {
        backgroundColor: '#F1b34B'
    },
    cancelButton: {
        backgroundColor: '#f44336'
    },
    buttonText: {
        color: 'white',
        fontWeight: 'bold'
    }
});

export default CashCountModal;