import React, {useContext, useEffect, useState} from 'react';
import {View, Text, TouchableOpacity, StyleSheet, Dimensions, Alert, ScrollView, Platform} from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import AsyncStorage from "@react-native-async-storage/async-storage";
import Observables from "@happy-gastro/global-observables";
import { BluetoothContext } from "../helpers/BluetoothContext";
import { LinearGradient } from "expo-linear-gradient";
import StatisticsHelper from "../helpers/StatisticsHelper";
import { priceFormatter } from "../helpers/numbers";
import { OrdersContext } from "../helpers/OrdersContext";
import { useTranslation } from "react-i18next";
import CashCountModal from '../components/CashCountModal';
import HappyAlert from '../helpers/crossPlatformTools/HappyAlert';
import { format } from 'date-fns';
import * as XLSX from 'xlsx-js-style';


// Set up platform-specific imports
let RNFS;
let Share;
let Mailer;
let FileSystem;

// Import native modules only on native platforms
if (Platform.OS !== 'web') {
    RNFS = require('react-native-fs');
    Share = require('react-native-share').default;
    Mailer = require('react-native-mail').default;
    FileSystem = require('expo-file-system');
}

const { width, height } = Dimensions.get('window');

const DayClosingScreen = ({ navigation }) => {
    const { t } = useTranslation();
    const { orders } = useContext(OrdersContext);
    const { sendData } = useContext(BluetoothContext);
    const { productsSum, statistics, paymentStatistics } = StatisticsHelper(orders);
    const [isCashCountModalVisible, setIsCashCountModalVisible] = useState(false);
    const [isHappyAlertVisible, setIsHappyAlertVisible] = useState(false);
    const [happyAlertMessage, setHappyAlertMessage] = useState('');
    const [cashCountDetails, setCashCountDetails] = useState({});
    const [amount, setAmount] = useState('');
    const [restaurantPlace, setRestaurantPlace] = useState(null);

    const [financialData, setFinancialData] = useState({
        // Left column data
        startingAmount: 0,
        envelopeBack: 0,
        restaurantServiceCharge: 0,
        takeAway: 0,
        restaurantTotalRevenue: 0,
        deliveryCash: 0,
        deliveryCard: 0,
        totalTurnover: 0,
        restaurantBankCardTerminal: 0,
        restaurantVivaCardTerminal: 0,
        totalCashRevenue: 0,
        totalPayments: 0,
        rovancsTotal: 0,
        envelope: 0,
        finalAmount: 0,

        // Right column data
        tipAmount: 0,
        homeDeliveryTotal: 0,
        homeDeliveryCount: 0,
        woltTotal: 0,
        woltCount: 0,
        woltDriveCount: 0,
        woltDriveTotal: 0,
        takeAwayCount: 0,
        onlineBankCardTakeAwayCount: 0,
        totalExpenses: 0,
        name: ""
    });

    useEffect(() => {
        fetchRestaurantPlace()
    }, []);
    const fetchRestaurantPlace = async () => {
        try {
            const restaurantPlace = await AsyncStorage.getItem('restaurantPlace');
            setRestaurantPlace(restaurantPlace ? JSON.parse(restaurantPlace) : null)
        } catch (error) {
            console.error('Error fetching restaurant place:', error);
            return null;
        }
    };

    const calculateStatistics = async (rovancs) => {
        //const alternateDayOpenedAmount = await AsyncStorage.getItem('dayOpened_amount');
        const dayOpenedAmount = await AsyncStorage.getItem('dayClosed_amount');
        const expenseStorage = await AsyncStorage.getItem('expenses');
        const totalPayments1 = (expenseStorage === undefined && expenseStorage === "undefined" ) ? '[]' : expenseStorage;
        console.log("dayOpenedAmount", dayOpenedAmount);
        const startingAmount = dayOpenedAmount ? Number.parseInt(JSON.parse(dayOpenedAmount), 10) || 0 : 0;
        console.log("startingAmount", startingAmount);
        const envelopeBack = parseInt(rovancs.envelopeBackAmount, 10) || 0;
        const restaurantServiceCharge = statistics.allDineInOrders.reduce((a, b) => +a + b.orderSum, 0);
        const takeAway = statistics.takeAwayOrders.reduce((a, b) => +a + b.orderSum, 0);
        const restaurantTotalRevenue = restaurantServiceCharge + takeAway;
        const deliveryCash = paymentStatistics.onlineOrdersCash.reduce((a, b) => +a + b.orderSum, 0);
        const deliveryCard = paymentStatistics.onlineOrdersCreditCard.reduce((a, b) => +a + b.orderSum, 0);
        const totalTurnover = restaurantServiceCharge + takeAway + deliveryCash + deliveryCard -(statistics.serviceFeePrice || 0);
        const restaurantBankCardTerminal = parseInt(rovancs.cardTerminalAmount, 10);
        const restaurantVivaCardTerminal = parseInt(rovancs.vivaTerminalAmount, 10);
        const totalCashRevenue = parseInt(startingAmount,10) + restaurantServiceCharge + takeAway + deliveryCash + envelopeBack - restaurantBankCardTerminal - restaurantVivaCardTerminal;
        const totalPayments = (totalPayments1 && totalPayments1 !== "undefined") ? (JSON.parse(totalPayments1)?.reduce((a, b) => +a + b.price, 0) || 0) : 0;
        const rovancsTotal = parseInt(rovancs.total, 10);
        const envelope = parseInt(rovancs.envelope10, 10);
        const finalAmount = parseInt(rovancs.total, 10)-parseInt(rovancs.envelope10, 10);

        const tipAmount = statistics?.tipAmount?.takeAway || 0;
        const homeDeliveryTotal = statistics.takeAwayOrders.reduce((a, b) => +a + b.orderSum, 0) + paymentStatistics.onlineOrdersCash.reduce((a, b) => +a + b.orderSum, 0) + paymentStatistics.onlineOrdersCreditCard.reduce((a, b) => +a + b.orderSum, 0);
        const homeDeliveryCount = statistics.takeAwayOrders.length + paymentStatistics.onlineOrdersCash.length + paymentStatistics.onlineOrdersCreditCard.length;
        const woltTotal = statistics.woltOrders.reduce((a, b) => +a + b.orderSum, 0) || 0;
        const woltCount = statistics.woltOrders.length || 0;
        const woltDriveCount = parseInt(statistics.woltDriveOrders.length, 10) || 0;
        const woltDriveTotal = parseInt(statistics.woltDriveOrders.length, 10) * 2150 || 0;
        const takeAwayCount = statistics.takeAwayOrders.length || 0;
        const onlineBankCardTakeAwayCount = 0;
        const totalExpenses = totalPayments || 0;
        const employeename = rovancs.employeeName || "";

        setFinancialData({
            startingAmount,
            envelopeBack,
            restaurantServiceCharge,
            takeAway,
            restaurantTotalRevenue,
            deliveryCash,
            deliveryCard,
            totalTurnover,
            restaurantBankCardTerminal,
            restaurantVivaCardTerminal,
            totalCashRevenue,
            totalPayments,
            rovancsTotal,
            envelope,
            finalAmount,
            tipAmount,
            homeDeliveryTotal,
            homeDeliveryCount,
            woltTotal,
            woltCount,
            woltDriveCount,
            woltDriveTotal,
            takeAwayCount,
            onlineBankCardTakeAwayCount,
            totalExpenses,
            name: employeename,
            expensesArray: totalPayments1 ? JSON.parse(totalPayments1) : [],
        });
        return {envelope : envelope, total: finalAmount};
    };

    // Create Excel workbook with formatting
    const createExcelWorkbook = () => {
        // Create workbook and worksheet
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([]);

        const currentDate = format(new Date(), 'yyyy/ MMMM d., EEEE');
        const placeName = restaurantPlace ? (restaurantPlace?.name || restaurantPlace?.raw) : "BÉCSI";
        // Add header row with date and restaurant name
        XLSX.utils.sheet_add_aoa(ws, [
            [{v: currentDate, t: 's'}, {v: '', t: 's'}, {v: '', t: 's'}, {v: `MAHARAJA - ${placeName}`, t: 's'}, {v: '', t: 's'}]
        ], {origin: "A1"});

        // Left column data (using the same labels from the image)
        const dataRows = [
            // Yellow header area
            ['1. NYITÓ / STARTING AMOUNT', financialData.startingAmount || 0, '', 'BORRAVALÓ / TIP', financialData.tipAmount || 0],
            ['1/A. BORÍTÉK VISSZA / ENVELOPE BACK', financialData.envelopeBack || 0, '', `HÁZHOZSZÁLLÍTÁS + ELVITEL ÖSSZESEN -${financialData.homeDeliveryCount || 0} db`, financialData.homeDeliveryTotal || 0],
            ['2. ÉTTEREM + SZERVÍZDÍJ / RESTAURANT + SERVICE CHARGE', financialData.restaurantServiceCharge || 0, '', `ÖSSZES FOODORA -${statistics.foodoraOrders.length || 0} db`, statistics.foodoraOrders.reduce((a, b) => +a + b.orderSum, 0) || 0],
            ['2/A. ÉTTERMI ELVITEL / TAKE AWAY', financialData.takeAway || 0, '', `WOLT -${financialData.woltCount || 0} db`, financialData.woltTotal || 0],
            ['ÉTTERMI ÖSSZES BEVÉTEL (2.+2/A.)', financialData.restaurantTotalRevenue || 0, '', `WEBSHOP -${statistics.webshopOrders.length || 0} db`, statistics.webshopOrders.reduce((a, b) => +a + b.orderSum, 0) || 0],
            ['4. HÁZHOZSZÁLLÍTÁS KP / DELIVERY CASH', financialData.deliveryCash || 0, '', `TELEFONOS -${statistics.phoneOrders.length || 0} db`, statistics.phoneOrders.reduce((a, b) => +a + b.orderSum, 0) || 0],
            ['5. HÁZHOZSZÁLLÍTÁS BK / DELIVERY CARD', financialData.deliveryCard || 0, '', `WOLT DRIVE- -${financialData.woltDriveCount || 0} db`, statistics.woltDriveOrders.reduce((a, b) => +a + b.orderSum, 0) || 0],
            ['6. ÖSSZES FORGALOM (2.+2/A.+4.+5.)', financialData.totalTurnover || 0, '', `WOLT DRIVE KÖLTSÉG -${financialData.woltDriveCount || 0} db`, financialData.woltDriveTotal || 0],
            ['7. ÉTTEREMI BANKKÁRTYA TERMINÁL / RESTAURANT\'S BANK CARD TERMINAL', financialData.restaurantBankCardTerminal || 0, '', `ÉTTERMI ELVITEL / TAKE AWAY -${financialData.takeAwayCount || 0} db`, statistics.takeAwayOrders.reduce((a, b) => +a + b.orderSum, 0) || 0],
            ['7/A. VIVA ÉTTEREM BANKKÁRTYA TERMINÁL / VIVA RESTAURANT\'S BANK CARD TERMINAL', financialData.restaurantVivaCardTerminal || 0, '', `FOODORA/WEBSHOP ONLINE TAKE AWAY -${financialData.onlineBankCardTakeAwayCount || 0} db`, 0],
            ['8. ÖSSZES KÉSZPÉNZ BEVÉTEL (1.+2.+2/A.+4.+1/A.-7.-7/A.)', financialData.totalCashRevenue || 0, '', '', ''],
            ['9. ÖSSZES KIADÁS (részletezve jobbra) / PAYMENTS (on right side)', financialData.totalPayments || 0, '', '', ''],
            ['10. ROVANCS ÖSSZESEN ', financialData.rovancsTotal || 0, '', '', ''],
            ['BORÍTÉK (8.-9.-10.) / ENVELOPE', financialData.envelope || 0, '', ],
            ['12. ZÁRÓ (10-11) / FINAL', financialData.finalAmount || 0, '', 'ÖSSZES KIADÁS', financialData.totalExpenses || 0],
        ];

        // Add rows to worksheet
        XLSX.utils.sheet_add_aoa(ws, dataRows, {origin: "A2"});

        // Add cash count details
        const cashCountRows = [
            ['Rovancs címlet', 'db', 'Összeg / Amount', '', ''],
            ['5 Ft', cashCountDetails.bank_notes ? cashCountDetails.bank_notes["5"] || 0 : 0, (cashCountDetails.bank_notes ? cashCountDetails.bank_notes["5"] * 5 || 0 : 0) + " Ft"],
            ['10 Ft', cashCountDetails.bank_notes ? cashCountDetails.bank_notes["10"] || 0 : 0, (cashCountDetails.bank_notes ? cashCountDetails.bank_notes["10"] * 10 || 0 : 0) + " Ft"],
            ['20 Ft', cashCountDetails.bank_notes ? cashCountDetails.bank_notes["20"] || 0 : 0, (cashCountDetails.bank_notes ? cashCountDetails.bank_notes["20"] * 20 || 0 : 0) + " Ft"],
            ['50 Ft', cashCountDetails.bank_notes ? cashCountDetails.bank_notes["50"] || 0 : 0, (cashCountDetails.bank_notes ? cashCountDetails.bank_notes["50"] * 50 || 0 : 0) + " Ft"],
            ['100 Ft', cashCountDetails.bank_notes ? cashCountDetails.bank_notes["100"] || 0 : 0, (cashCountDetails.bank_notes ? cashCountDetails.bank_notes["100"] * 100 || 0 : 0) + " Ft"],
            ['200 Ft', cashCountDetails.bank_notes ? cashCountDetails.bank_notes["200"] || 0 : 0, (cashCountDetails.bank_notes ? cashCountDetails.bank_notes["200"] * 200 || 0 : 0) + " Ft"],
            ['500 Ft', cashCountDetails.bank_notes ? cashCountDetails.bank_notes["500"] || 0 : 0, (cashCountDetails.bank_notes ? cashCountDetails.bank_notes["500"] * 500 || 0 : 0) + " Ft"],
            ['1 000 Ft', cashCountDetails.bank_notes ? cashCountDetails.bank_notes["1000"] || 0 : 0, (cashCountDetails.bank_notes ? cashCountDetails.bank_notes["1000"] * 1000 || 0 : 0) + " Ft"],
            ['2 000 Ft', cashCountDetails.bank_notes ? cashCountDetails.bank_notes["2000"] || 0 : 0, (cashCountDetails.bank_notes ? cashCountDetails.bank_notes["2000"] * 2000 || 0 : 0) + " Ft"],
            ['5 000 Ft', cashCountDetails.bank_notes ? cashCountDetails.bank_notes["5000"] || 0 : 0, (cashCountDetails.bank_notes ? cashCountDetails.bank_notes["5000"] * 5000 || 0 : 0) + " Ft"],
            ['10 000 Ft', cashCountDetails.bank_notes ? cashCountDetails.bank_notes["10000"] || 0 : 0, (cashCountDetails.bank_notes ? cashCountDetails.bank_notes["10000"] * 10000 || 0 : 0) + " Ft"],
            ['20 000 Ft', cashCountDetails.bank_notes ? cashCountDetails.bank_notes["20000"] || 0 : 0, (cashCountDetails.bank_notes ? cashCountDetails.bank_notes["20000"] * 20000 || 0 : 0) + " Ft"],
            ['Összeg/Total (HUF)', '', cashCountDetails.total ? cashCountDetails.total + " Ft" : "0 Ft"],
        ];

        //XLSX.utils.sheet_add_aoa(ws, cashCountRows, {origin: "A27"});

        // Add expenses details
        const expensesHeaderRow = [['Kiadások / Expenses', '']];
        XLSX.utils.sheet_add_aoa(ws, expensesHeaderRow, {origin: "D17"});

        if (financialData.expensesArray && financialData.expensesArray.length > 0) {
            const expensesRows = financialData.expensesArray.map(expense =>
                [expense.description, expense.price + " Ft"]
            );
            XLSX.utils.sheet_add_aoa(ws, expensesRows, {origin: "D18"});
        }

        // Add signature row
        const signatureRow = [
            ['Üdvözlettel / Best Regards,', ],
            [financialData.name || '',]
        ];
        XLSX.utils.sheet_add_aoa(ws, signatureRow, {origin: "A18"});

        // Set column widths
        ws['!cols'] = [
            {wch: 45}, // Column A width
            {wch: 15}, // Column B width
            {wch: 8},  // Column C width
            {wch: 30}, // Column D width
            {wch: 15}  // Column E width
        ];

        // Set row heights for better readability
        ws['!rows'] = [];
        for (let i = 0; i < 70; i++) {
            ws['!rows'][i] = { hpt: 40 }; // about 22 points height
        }

        // Apply cell styling
        const yellowFill = {fgColor: {rgb: "FFFF99"}};
        const blueFill = {fgColor: {rgb: "0000CC"}};
        const lightBlueFill = {fgColor: {rgb: "5ea1ff"}};
        const whiteFontColor = {color: {rgb: "FFFFFF"}};
        const blackFontColor = {color: {rgb: "000000"}};
        const borderStyle = {
            top: {style: "thin"},
            bottom: {style: "thin"},
            left: {style: "thin"},
            right: {style: "thin"}
        };
        const centerAlignment = {horizontal: "center", vertical: "center"};
        const rightAlignment = {horizontal: "right", vertical: "center"};
        const leftAlignment = {horizontal: "left", vertical: "center"};

        // Apply header styling
        setCellStyle(ws, 'A1', {font: {bold: true, sz: 12}, alignment: centerAlignment});
        setCellStyle(ws, 'D1', {font: {bold: true, sz: 12}, alignment: centerAlignment});

        // Apply styles to data rows
        for (let row = 2; row <= 16; row++) {
            // Apply yellow fill to left data columns (A & B)
            if ((row >= 1 && row <= 25)) { // Main rows
                setCellStyle(ws, `A${row}`, {fill: yellowFill, border: borderStyle, font: blackFontColor, alignment: {...centerAlignment, wrapText: true}});
                setCellStyle(ws, `B${row}`, {fill: yellowFill, border: borderStyle, font: blackFontColor, alignment: {...centerAlignment, wrapText: true}});
            }

            if ((row === 14 || row === 16)) {
                setCellStyle(ws, `A${row}`, {fill: lightBlueFill, border: borderStyle, font: blackFontColor, alignment: {...centerAlignment, wrapText: true}});
                setCellStyle(ws, `B${row}`, {fill: lightBlueFill, border: borderStyle, font: blackFontColor, alignment: {...centerAlignment, wrapText: true}});
            }


            // Blue header rows - for the blue sections in the report (header, tip, wolt drive)
            if (row === 2 ||  row === 14 ) {
                setCellStyle(ws, `D${row}`, {fill: blueFill, border: borderStyle, font: whiteFontColor, alignment: {...centerAlignment, wrapText: true}});
                setCellStyle(ws, `E${row}`, {fill: blueFill, border: borderStyle, font: whiteFontColor, alignment: {...centerAlignment, wrapText: true}});
            } else {
                // Regular yellow rows for other data
                setCellStyle(ws, `D${row}`, {fill: yellowFill, border: borderStyle, font: blackFontColor, alignment: {...centerAlignment, wrapText: true}});
                setCellStyle(ws, `E${row}`, {fill: yellowFill, border: borderStyle, font: blackFontColor, alignment: {...centerAlignment, wrapText: true}});
            }

            // Middle column as separator
            setCellStyle(ws, `C${row}`, {border: borderStyle});
        }

        // Style cash count section
        for (let row = 27; row <= 40; row++) {
            setCellStyle(ws, `A${row}`, {border: borderStyle, alignment: centerAlignment});
            setCellStyle(ws, `B${row}`, {border: borderStyle, alignment: centerAlignment});
            setCellStyle(ws, `C${row}`, {border: borderStyle, alignment: centerAlignment});
        }
        for (let row = 18; row <= 19; row++) {
            if ((row === 18 || row === 19)) {
                setCellStyle(ws, `A${row}`, {fill: yellowFill, border: borderStyle, font: blackFontColor, alignment: {...centerAlignment, wrapText: true}});
            }
        }

        // Summary row styling
        setCellStyle(ws, 'A40', {font: {bold: true}, alignment: centerAlignment, border: borderStyle});
        setCellStyle(ws, 'C40', {font: {bold: true}, alignment: rightAlignment, border: borderStyle});

        // Style expenses header
        setCellStyle(ws, 'A43', {font: {bold: true}, alignment: leftAlignment});

        // Style expenses section
        if (financialData.expensesArray) {
            for (let row = 44; row < 44 + financialData.expensesArray.length; row++) {
                setCellStyle(ws, `A${row}`, {alignment: leftAlignment});
                setCellStyle(ws, `B${row}`, {alignment: rightAlignment});
            }
        }

        // Style signature area
        setCellStyle(ws, 'A62', {font: {bold: true}, alignment: leftAlignment});
        setCellStyle(ws, 'A64', {alignment: leftAlignment});

        // Format currency cells
        for (let row = 2; row <= 25; row++) {
            if (ws[`B${row}`]) {
                ws[`B${row}`].z = '#,##0 "Ft"';
            }
            if (ws[`E${row}`]) {
                ws[`E${row}`].z = '#,##0 "Ft"';
            }
        }

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, "Financial Report");

        return wb;
    };

// Enhanced helper function to set cell style
    const setCellStyle = (ws, cellRef, style) => {
        if (!ws[cellRef]) {
            ws[cellRef] = {
                t: 's',
                v: '',
                s: {} // Initialize styles immediately
            };
        }

        ws[cellRef].s = ws[cellRef].s || {};

        if (style.fill) ws[cellRef].s.fill = style.fill;
        if (style.font) ws[cellRef].s.font = style.font;
        if (style.border) ws[cellRef].s.border = style.border;
        if (style.alignment) ws[cellRef].s.alignment = style.alignment;

        // Enable text wrapping by default for cells that might contain long text
        if (style.wrapText !== false) {
            if (!ws[cellRef].s.alignment) ws[cellRef].s.alignment = {};
            ws[cellRef].s.alignment.wrapText = true;
        }

        if (style.numFmt) ws[cellRef].z = style.numFmt;
    };

    // Web implementation for downloading Excel
    const downloadExcelOnWeb = () => {
        try {
            const wb = createExcelWorkbook();
            const currentDate = format(new Date(), 'yyyy-MM-dd');
            const fileName = `financial_report_${currentDate}.xlsx`;

            // Write workbook to file and trigger download
            XLSX.writeFile(wb, fileName);

            Alert.alert(
                t('Success'),
                t('Financial report downloaded successfully.')
            );
        } catch (error) {
            console.error('Error downloading Excel:', error);
            Alert.alert(
                t('Error'),
                t('Failed to download financial data. Please try again.')
            );
        }
    };

    // Cross-platform function to handle exporting/sharing financial data
    const handleExportFinancialData = async () => {
        if (Platform.OS === 'web') {
            downloadExcelOnWeb();
        } else {
            await handleEmailFinancialData();
        }
    };

    // Function to handle email with Excel attachment
    const handleEmailFinancialData = async () => {
        try {
            if (Platform.OS === 'web') {
                // For web, we'll download the Excel file and suggest emailing it manually
                downloadExcelOnWeb();

                // Create mailto link for convenience
                const currentDate = format(new Date(), 'yyyy-MM-dd');
                const encodedSubject = encodeURIComponent(`Financial Report - ${currentDate}`);
                const encodedBody = encodeURIComponent(`Please find attached the financial report for ${currentDate}.`);
                const mailtoLink = `mailto:?subject=${encodedSubject}&body=${encodedBody}`;

                // Open the mailto link
                setTimeout(() => {
                    window.open(mailtoLink, '_blank');
                }, 1000);

            } else {
                // For native platforms, create and attach Excel file
                const wb = createExcelWorkbook();
                const currentDate = format(new Date(), 'yyyy-MM-dd');
                const fileName = `financial_report_${currentDate}.xlsx`;
                const filePath = `${RNFS.CachesDirectoryPath}/${fileName}`;

                // Convert workbook to binary string
                const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

                // Convert binary string to buffer
                const buf = new ArrayBuffer(wbout.length);
                const view = new Uint8Array(buf);
                for (let i = 0; i < wbout.length; i++) {
                    view[i] = wbout.charCodeAt(i) & 0xFF;
                }

                // Convert buffer to base64 string and write to file
                const base64Data = btoa(String.fromCharCode.apply(null, new Uint8Array(buf)));
                await RNFS.writeFile(filePath, base64Data, 'base64');

                // Send email with attachment
                Mailer.mail({
                    subject: `Financial Report - ${currentDate}`,
                    body: `Please find attached the financial report for ${currentDate}.`,
                    isHTML: false,
                    attachments: [{
                        path: filePath,
                        type: 'xlsx',
                        name: fileName,
                    }],
                }, (error, event) => {
                    if (error) {
                        Alert.alert('Error', 'Could not send mail: ' + error);
                    }

                    // Clean up file
                    RNFS.unlink(filePath).catch(err => console.error('Error deleting file:', err));
                });
            }
        } catch (error) {
            console.error('Error with email:', error);
            Alert.alert(
                t('Error'),
                t('Failed to prepare email. Please try again.')
            );
        }
    };

    const [expenses, setExpenses] = useState({ count: 0, sum: 0 });

    React.useEffect(() => {
        const fetchExpenses = async () => {
            try {
                const expensesData = await AsyncStorage.getItem('expenses');
                const expensesArray = expensesData ? JSON.parse(expensesData) : [];
                const count = expensesArray.length;
                const sum = expensesArray.reduce((total, expense) => total + expense.price, 0);
                setExpenses({ count, sum });
            } catch (error) {
                await AsyncStorage.setItem('expenses', '[]');
                console.error('Error fetching expenses (expenses will be set to empty array)', error);
            }
        };
        const fetchOpeningAmount = async () => {
            try {
                const storedAmount = await AsyncStorage.getItem('dayClosed_amount');
                const amount = storedAmount ? JSON.parse(storedAmount) : 0;
                setExpenses({ count, sum });
            } catch (error) {
                await AsyncStorage.setItem('expenses', '[]');
                console.error('Error fetching expenses (expenses will be set to empty array)', error);
            }
        };

        fetchExpenses();
        fetchOpeningAmount();
    }, []);

    // Handle the submission from the CashCountModal
    const handleCashCountSubmit = async (data) => {
        console.log("data:", data);
        setCashCountDetails(data);
        const {envelope, total} = await calculateStatistics(data);
        setIsCashCountModalVisible(false);
        setHappyAlertMessage(` Záró összeg: ${total}\n Boríték : ${priceFormatter(envelope)}`);
        setIsHappyAlertVisible(true);
    };

    const handleConfirm = async () => {
        await handleExportFinancialData();
        setIsHappyAlertVisible(false);
        try {
            await AsyncStorage.setItem('dayOpened', 'false');
            await AsyncStorage.setItem('expenses', '[]');
            Observables.call("reloadDayOpeningClosing");
            try {
                await sendData(`fiscat/AEE|DC||||1`);
            } catch (fiscatCloseError) {
                console.error("Fiscat close error", fiscatCloseError);
            }
        } catch (dayClosingError) {
            console.error("Day closing error", dayClosingError);
        } finally {
            navigation.navigate("SuccessDayClosingScreen", {cashCountDetails: financialData.finalAmount || {}});
        }
    };

    const handleProcess = () => {
        setIsCashCountModalVisible(true);
    };

    return (
        <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={styles.background}
            start={{ x: 0.3, y: 0 }}
            end={{ x: 0.7, y: 1 }}
        >
            <View style={{ width: '90%', justifyContent: 'flex-start', paddingVertical: 20, marginTop: 40 }}>
                <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                    <View style={styles.section}>
                        <Text style={styles.title}>{t('traffic_data')}</Text>
                        <View style={styles.row}>
                            <Text>{t('product_traffic')}:</Text>
                            <Text>{priceFormatter(statistics.productsSum || 0)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text>{t('service_fees')}:</Text>
                            <Text>{priceFormatter(statistics.serviceFeePrice || 0)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{ fontWeight: "700", fontSize: 17 }}>{t('total_traffic')}:</Text>
                            <Text style={{
                                fontWeight: "700",
                                fontSize: 17
                            }}>{priceFormatter((statistics.finalSum) || 0)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text>{t('tip_takeaway')}:</Text>
                            <Text>{priceFormatter(statistics?.tipAmount?.takeAway || 0)}</Text>
                        </View>
                    </View>

                    {/* Payment Method */}
                    <View style={styles.section}>
                        <Text style={styles.title}>{t('payment_method')}</Text>
                        <View style={styles.row}>
                            <Text>{t('cash')}:</Text>
                            <Text>{statistics.cashOrders.length} {t('orders')}
                                / {priceFormatter(statistics.cashOrders.reduce((a, b) => +a + b.orderSum, 0))}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text>{t('store_credit_card')}:</Text>
                            <Text>{statistics.creditCardOrders.length} {t('orders')}
                                / {priceFormatter(statistics.creditCardOrders.reduce((a, b) => +a + b.orderSum, 0))}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text>{t('online_credit_card')}:</Text>
                            <Text>{statistics.onlineCreditCardOrders.length} {t('orders')}
                                / {priceFormatter(statistics.onlineCreditCardOrders.reduce((a, b) => +a + b.orderSum, 0))}</Text>
                        </View>
                    </View>
                    {/*Expenses*/}
                    <View style={styles.section}>
                        <Text style={styles.title}>{t('expenses')}</Text>
                        <View style={styles.row}>
                            <Text>{t('all_expenses')}:</Text>
                            <Text>{expenses.count} {t('expense(s)')}
                                / {priceFormatter(expenses.sum)}</Text>
                        </View>
                    </View>

                    <View style={styles.buttonContainer}>
                        <TouchableOpacity style={styles.backButton} onPress={() => navigation.navigate('HomeScreen')}>
                            <Feather name="arrow-left" size={24} color="#fff" />
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.processButton} onPress={() => handleProcess()}>
                            <Text style={styles.processButtonText}>{t('close_day')}</Text>
                        </TouchableOpacity>
                    </View>
                </ScrollView>
            </View>

            <CashCountModal
                isVisible={isCashCountModalVisible}
                onClose={() => setIsCashCountModalVisible(false)}
                onSubmit={handleCashCountSubmit}
            />
            <HappyAlert
                visible={isHappyAlertVisible}
                onConfirm={handleConfirm}
                onCancel={() => {
                    setIsHappyAlertVisible(false);
                    setIsCashCountModalVisible(true);
                }}
                type="warning"
                message={happyAlertMessage}
            />
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
    },
    background: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: 10,
        height: height,
    },
    amountContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 5,
        width: '100%',
    },
    amountInput: {
        fontSize: 32,
        textAlign: 'center',
        color: '#333',
        borderBottomWidth: 1,
        width: width * 0.8,
    },
    pinpadContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: width,
        paddingHorizontal: 0,
    },
    pinpadButton: {
        width: width / 3.4,
        height: height / 10,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 5,
        borderColor: '#e8e8e8',
        borderWidth: 0.5,
    },
    pinpadText: {
        fontSize: 28,
        color: '#333',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 16,
        marginTop: 20,
    },
    backButton: {
        backgroundColor: 'black',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 15,
        borderRadius: 5,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
    },
    processButton: {
        backgroundColor: 'orange',
        alignItems: 'center',
        padding: 15,
        flex: 1,
        marginLeft: 0,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
    },
    processButtonText: {
        fontSize: 20,
        color: '#fff',
        fontWeight: 'bold',
    },

    section: {
        marginBottom: 24,
        padding: 16,
        backgroundColor: '#f9f9f9',
        borderRadius: 8,
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowRadius: 4,
        shadowOffset: { width: 0, height: 2 },
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
});

export default DayClosingScreen;