import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import { LinearGradient } from 'expo-linear-gradient';
import { useTranslation } from 'react-i18next';
import {priceFormatter} from "../helpers";

const { width, height } = Dimensions.get('window');

const DayOpeningConfirmationScreen = ({ navigation, route }) => {
    const { cashAmount = 0, creditCardAmount = 0, voucherAmount= 0, otherAmount= 0, handleConfirm } = route.params;
    const { t } = useTranslation();

    return (
        <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={styles.background}
            start={{ x: 0.3, y: 0 }}
            end={{ x: 0.7, y: 1 }}
        >
            <View style={{ width: '90%', justifyContent: 'flex-start', paddingTop: 50, marginTop: 40 }}>
                <View style={styles.section}>
                    <Text style={styles.title}>{t('opening_data')}</Text>
                    <View style={styles.row}>
                        <Text>{t('cash')}:</Text>
                        <Text>{priceFormatter(Number(cashAmount)) || 0}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text>{t('credit_card')}:</Text>
                        <Text>{priceFormatter(Number(creditCardAmount)) || 0}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text>{t('voucher')}:</Text>
                        <Text>{priceFormatter(Number(voucherAmount)) || 0}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text>{t('other')}:</Text>
                        <Text>{priceFormatter(Number(otherAmount)) || 0}</Text>
                    </View>
                </View>
                <View style={styles.buttonContainer}>
                    <TouchableOpacity style={styles.backButton} onPress={() => navigation.goBack()}>
                        <Feather name="arrow-left" size={24} color="#fff" />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.processButton} onPress={handleConfirm}>
                        <Text style={styles.processButtonText}>{t('confirm')}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    background: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: 10,
        height: height,
    },
    section: {
        marginBottom: 24,
        padding: 16,
        backgroundColor: '#f9f9f9',
        borderRadius: 8,
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowRadius: 4,
        shadowOffset: { width: 0, height: 2 },
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 16,
        marginTop: 20,
    },
    backButton: {
        backgroundColor: 'black',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 15,
        borderRadius: 5,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
    },
    processButton: {
        backgroundColor: 'orange',
        alignItems: 'center',
        padding: 15,
        flex: 1,
        marginLeft: 0,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
    },
    processButtonText: {
        fontSize: 20,
        color: '#fff',
        fontWeight: 'bold',
    },
});

export default DayOpeningConfirmationScreen;