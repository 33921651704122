// storage.js
import AsyncStorage from '@react-native-async-storage/async-storage';

const USER_INFO_KEY = 'HG_USER_INFO';
const ACCESS_TOKEN_KEY = 'HG_ACCESS_TOKEN';

export const setUserInfo = async (userInfo) => {
    try {
        await AsyncStorage.setItem(USER_INFO_KEY, JSON.stringify(userInfo));
    } catch (error) {
        console.error('Error setting user info:', error);
    }
};

export const getUserInfo = async () => {
    try {
        const userInfo = await AsyncStorage.getItem(USER_INFO_KEY);
        return userInfo ? JSON.parse(userInfo) : null;
    } catch (error) {
        console.error('Error getting user info:', error);
    }
};

export const removeUserInfo = async () => {
    try {
        await AsyncStorage.removeItem(USER_INFO_KEY);
    } catch (error) {
        console.error('Error removing user info:', error);
    }
};

export const setAccessToken = async (token) => {
    try {
        await AsyncStorage.setItem(ACCESS_TOKEN_KEY, token);
    } catch (error) {
        console.error('Error setting access token:', error);
    }
};

export const getAccessToken = async () => {
    try {
        return await AsyncStorage.getItem(ACCESS_TOKEN_KEY);
    } catch (error) {
        console.error('Error getting access token:', error);
    }
};

export const removeAccessToken = async () => {
    try {
        await AsyncStorage.removeItem(ACCESS_TOKEN_KEY);
    } catch (error) {
        console.error('Error removing access token:', error);
    }
};
