import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json'; // English
import hu from './locales/hu.json'; // Magyar
import de from './locales/de.json'; // German
import cn from './locales/cn.json'; // Chinese (Mandarin)
import ro from './locales/ro.json'; // Romanian
import svn from './locales/svn.json'; // Slovenian

export const selectableLanguages = ["en", "hu", "de", "cn", "ro", "svn"]

i18n
    .use(initReactI18next)
    .init({
        compatibilityJSON: 'v3',  // Ensure compatibility with React Native
        resources: {
            en: { translation: en },
            hu: { translation: hu },
            de: { translation: de },
            cn: { translation: cn },
            ro: { translation: ro },
            svn: { translation: svn },
        },
        lng: 'en', // Default language
        fallbackLng: 'hu', // Fallback language
        interpolation: {
            escapeValue: false, // React already handles escaping
        },
    });

export default i18n;
