const tooltips = {
    default: [
        {
            title: "Qr kód olvasó", //customheader
            description: "Itt olvashat le Qr kódot."
        },
        {
            title: "Vip - Személyzeti számla", //customheader
            description: "Itt választhatja ki a VIP és személyzeti számlát fizetéshez. Vagy hozzáadhat újat."
        },
        {
            title: "Értesítések", //customheader
            description: "Itt jelennek meg a különböző értesítések, mint az elfogadásra váró ételrendelések"
        },
        {
            title: "Termék Választás", //salescreen
            description: "Itt látható az összes kateória, amelyek az ételeket tartalmazzák. Kattintással beléphet a kategóriába és választhat egy ételt."
        },
        {
            title: "Kosár tartalma", //cartscreen
            description: "Itt jelennek meg egymás alatt a kiválasztott termékek, amelyeket a kosárba helyezett."
        },
        {
            title: "Kosár Módosítás", //cartscreen
            description: "Plusz, minusz gombok vagy jobbra, balra csúsztatással változtathatja a darabszámot, hosszú lenyomással pedig további szerkesztési lehetőségek érhetőek el."
        },
        {
            title: "Összegzés", //cartscreen
            description: "Itt látható a végöszeg és az esetleges kedvezmények."
        },
        {
            title: "Egész kosár módosítása", //cartscreen
            description: "A végösszegre kattintva, lehetősége van törölni az összes terméket a kosárból, vagy kedvezményt adni a végösszegre."
        },
        {
            title: "Fizetés megkezdése", //cartscreen
            description: "Ide kattintva érhető el a fizetés ablak ahol elkezdheti a fizettetést"
        },
        {
            title: "Nyitott tételek", //homescreen
            description: "Itt látható az összes még nem lezárt rendelés és további részletei."
        },
        {
            title: "Funkciók", //homescreen
            description: "Itt találhatja meg a beállításokat, statisztikákat és egyéb kiegészítő funkciókat."
        },
        {
            title: "Modul választása", //homescreen
            description: "A Sárga gombbal válthat asztalos kiszolgálás, házhoszszállítás vagy elvitel között."
        },
        {
            title: "Kosár tartalma", //cartscreen
            description: "Plusz, minusz gombok vagy jobbra, balra csúsztatással változtathatja a darabszámot"
        },
        {
            title: "Termék funkciók", //cartscreen
            description: "Hosszú lenyomással, további funkciók érhetőek el a termékekhez. (törlés a kosárból, kedvezmény, megjegyzés)"
        },
        {
            title: "Egész kosár módosítása", //cartscreen
            description: "Ide kattintva lehetősége van törölni az összes terméket a kosárból, vagy kedvezményt adni a végösszegre."
        },
        {
            title: "Fizetés", //cartscreen
            description: "Itt navigálhat a fizetési módokhoz, és a végösszeghez."
        },
    ],

    table: [
        {
            title: "Manage upcoming table bookings",
            description: "This is a brief description of the bookings component."
        },
        {
            title: "Ez egy szép cím",
            description: "Ez egy relatív rövid leírás erről a komponensről."
        }
    ],

    deliver: [
        {
            title: "Delivery Tooltip 1",
            description: "Description for delivery tooltip 1."
        },
        {
            title: "Delivery Tooltip 2",
            description: "Description for delivery tooltip 2."
        }
    ]
};

export default tooltips;