import React, {useState, useEffect, useContext} from "react";
import {
    Modal,
    View,
    Text,
    TextInput,
    FlatList,
    TouchableOpacity,
    StyleSheet, ScrollView
} from "react-native";
import { useSelector, useDispatch } from "react-redux";
import {OrdersContext} from "../helpers/OrdersContext";
import {SystemContext} from "../helpers/SystemContext";
import {priceFormatter} from "../helpers";
import {getAccessToken} from "../helpers/storage";
import Observables from "@happy-gastro/global-observables";



// Improved search function based on the old implementation
export const searchFoodByKeywords = (keywords = "", array = []) => {
    // Helper function to remove diacritics (accents) from text
    const removeDiacritics = (text) => {
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^A-Za-z0-9\s]/g, '');
    };

    const words = keywords.split(' ').filter(word => word.length > 0);

    // If no search words, return empty array
    if (words.length === 0) return [];

    let foundFoods = {};
    let searchListReturn = {};

    array.forEach((food) => {
        if(!foundFoods[food._id]) foundFoods[food._id] = [];

        words.forEach((word) => {
            // Clean the word and create a case-insensitive regex
            const cleanedWord = removeDiacritics(word.replace('(', '').replace(')', ''));
            const regExpCheck = new RegExp(cleanedWord, 'gi');

            // Check if the food name matches the search term
            if (regExpCheck.test(removeDiacritics(food.name['hu'])) ||
                food.name['hu'].toLowerCase().includes(cleanedWord.toLowerCase())) {
                foundFoods[food._id] = [...foundFoods[food._id], word];
            }
        });
    });

    // Filter foods that matched at least one word
    Object.keys(foundFoods).forEach(foodId => {
        if (foundFoods[foodId].length > 0) {
            searchListReturn[foodId] = foundFoods[foodId];
        }
    });

    // Sort results by number of matched words (most matches first)
    const sortedResult = Object.keys(searchListReturn)
        .map(key => {
            return {
                key: key,
                food: array.find(f => f._id === key),
                value: foundFoods[key]
            };
        })
        .sort((a, b) => b.value.length - a.value.length);

    // Return the sorted food items
    return sortedResult.map(r => r.food);
};

const PairingModal = ({ visible, onClose, pairingItem, orderId, order }) => {
    const dispatch = useDispatch();
    const {orders, fetchOrdersFromAPI} = useContext(OrdersContext);
    const {foods} = useContext(SystemContext);
    const [searchValue, setSearchValue] = useState("");
    const [filteredFoods, setFilteredFoods] = useState([]);
    const [selectedFood, setSelectedFood] = useState(null);
    const [selectedFoodSize, setSelectedFoodSize] = useState(null);
    const [defaultSearchTerms, setDefaultSearchTerms] = useState("");

    useEffect(() => {
        setSearchValue("");
        setSelectedFood(null);
        setSelectedFoodSize(null);

        // Set default search terms from pairingItem
        if (pairingItem?.name) {
            setDefaultSearchTerms(pairingItem?.name);
        } else if(pairingItem?.remoteName) {
            setDefaultSearchTerms(pairingItem?.remoteName);
        }
    }, [visible, pairingItem]);

    useEffect(() => {
        // Use the improved search function
        const searchTerm = searchValue?.length > 0 ? searchValue : defaultSearchTerms;

        if (!searchTerm || !foods) {
            setFilteredFoods([]);
            return;
        }

        // Get sorted food results using the searchFoodByKeywords function
        const searchResults = searchFoodByKeywords(searchTerm, foods);

        // Expand each food with its sizes
        const expandedResults = searchResults.flatMap(food =>
            food.sizes.map(size => ({ ...food, size }))
        );
        setFilteredFoods(expandedResults);
    }, [searchValue, foods, defaultSearchTerms]);

    const handleSave = async () => {
        if (!selectedFood || !selectedFoodSize) return;
        const token = await getAccessToken();
        try {
            const response = await fetch(`https://api.happygastro.hu/api/food/pairing/${order?.orderFrom}/${encodeURIComponent(pairingItem?.remoteCode)}/${selectedFoodSize?._id}?platformId=${order?.platformId || ""}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            fetchOrdersFromAPI();
            onClose();
        } catch (error) {
            console.error('Failed to save the pairing:', error);
        }
    };

    return (
        <Modal visible={visible} transparent animationType="slide">
            <View style={styles.modalContainer}>
                <View style={styles.modalContent}>
                    <Text style={styles.title}>{pairingItem?.remoteName}</Text>
                    <TextInput
                        style={styles.searchInput}
                        placeholder="Search food"
                        value={searchValue}
                        onChangeText={setSearchValue}
                    />
                    <ScrollView style={{height: 400}}>
                        <FlatList
                            data={filteredFoods}
                            keyExtractor={(item) => `${item._id}-${item.size._id}`}
                            renderItem={({ item }) => (
                                <TouchableOpacity
                                    style={[
                                        styles.foodItem,
                                        selectedFood?._id === item._id &&
                                        selectedFoodSize?._id === item.size._id &&
                                        styles.selectedFood
                                    ]}
                                    onPress={() => {
                                        setSelectedFood(item);
                                        setSelectedFoodSize(item.size);
                                    }}
                                >
                                    <Text style={styles.foodItemText}>
                                        {item.name.hu} - {item.size.amount}{item.size.unit} ({priceFormatter(item.size.price)})
                                    </Text>
                                </TouchableOpacity>
                            )}
                            ListEmptyComponent={() => (
                                <Text style={styles.noResultsText}>No matching foods found</Text>
                            )}
                        />
                    </ScrollView>

                    <View style={styles.buttonContainer}>
                        <TouchableOpacity style={styles.cancelButton} onPress={onClose}>
                            <Text style={{fontSize: 22, fontWeight: "bold"}}>Cancel</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={[styles.saveButton, !selectedFood && styles.disabledButton]}
                            onPress={handleSave}
                            disabled={!selectedFood}
                        >
                            <Text style={[{fontSize: 22, fontWeight: "bold"}, selectedFood && {color: "white"}]}>Save</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        backgroundColor: "rgba(0,0,0,0.5)",
        justifyContent: "center",
        alignItems: "center",
    },
    modalContent: {
        width: "80%",
        backgroundColor: "white",
        padding: 25,
        borderRadius: 10,
    },
    title: {
        fontSize: 26,
        fontWeight: "bold",
        marginBottom: 10,
    },
    searchInput: {
        height: 50,
        borderWidth: 1,
        borderColor: "gray",
        marginBottom: 10,
        paddingHorizontal: 10,
        borderRadius: 5,
    },
    foodItem: {
        padding: 10,
        borderBottomWidth: 1,
        borderColor: "#ddd",
    },
    foodItemText: {
        fontSize: 18,
    },
    selectedFood: {
        backgroundColor: "#f0a500",
    },
    buttonContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 20,
    },
    cancelButton: {
        paddingVertical: 25,
        paddingHorizontal: 45,
        backgroundColor: "#ccc",
        borderRadius: 5,
    },
    saveButton: {
        paddingVertical: 25,
        paddingHorizontal: 45,
        backgroundColor: "#28a745",
        borderRadius: 5,
    },
    disabledButton: {
        backgroundColor: "#ddd",
    },
    noResultsText: {
        padding: 20,
        textAlign: 'center',
        fontSize: 16,
        color: '#666'
    }
});

export default PairingModal;