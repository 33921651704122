import React, { useContext } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import SaleScreen from './SaleScreen';
import CartScreen from './CartScreen';
import { CartContext } from '../helpers/CartContext';
import PaymentScreen from './PaymentScreen';

const { width } = Dimensions.get('window');

const BigSaleScreen = ({ route, navigation }) => {
    const { numColumns } = route.params || { numColumns: 3 };
    const { paymentModalVisible, setPaymentModalVisible } = useContext(CartContext);
    const isTablet = width >= 768 && width < 1024;
    const isDesktop = width >= 1024;

    const modalContentStyle = {
        width: isDesktop ? '35%' : isTablet ? '50%' : '80%',
        maxHeight: '100%',
        backgroundColor: 'white',
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
    };

    return (<View style={styles.container}>
            <View style={styles.saleScreenContainer}>
                <SaleScreen navigation={navigation} numColumns={numColumns} />
            </View>
            <View style={styles.cartScreenContainer}>
                <CartScreen navigation={navigation} />
            </View>
            {(width >= 768 && paymentModalVisible) && (
                <View style={styles.modalOverlay}>
                    <View style={modalContentStyle}>
                        <PaymentScreen navigation={navigation} setIsModalVisible={setPaymentModalVisible} />
                    </View>
                </View>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    saleScreenContainer: {
        flex: 6.5, // 70% of the screen width
        borderRightWidth: 1,
        borderRightColor: '#ccc',
        zIndex: 999
    },
    cartScreenContainer: {
        flex: 3.5, // 30% of the screen width
    },
    modalOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
    },
});

export default BigSaleScreen;