import React, { useState } from 'react';
import { View, Text, Button, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';
import Slider from '@react-native-community/slider';
import LottieView from 'lottie-react-native';

const OrderAcceptTimePicker = ({ isVisible, onClose, onConfirm, orderData }) => {
    const [time, setTime] = useState(15);

    return (
        <Modal isVisible={isVisible} onBackdropPress={onClose} style={styles.modal}>
            <View style={styles.modalContent}>
                <View style={{width: '60%'}}><LottieView
                    source={require('../../assets/animations/setTiming.json')}
                    autoPlay
                    loop
                    style={styles.animation}
                />
                </View>
                <Text style={styles.title}>Válassza ki az elkészítés idejét:</Text>
                <Text style={styles.timeText}>{time} perc</Text>
                <Slider
                    style={styles.slider}
                    minimumValue={5}
                    maximumValue={orderData.orderFrom === "wolt" ? 25 : 120}
                    step={orderData.orderFrom === "wolt" ? 1 : 5}
                    value={time}
                    color={'#F76E24'}
                    onValueChange={setTime}
                />
                <View style={styles.buttonContainer}>
                    <Button title="Mégsem" style={styles.cancelButton} color={"#dec49b"} onPress={onClose} />
                    <Button title="Elfogadás" style={styles.confirmButton} color={'#F76E24'} onPress={() => onConfirm(time, orderData)} />
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modal: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        alignItems: 'center',
        width: 400,
        textAlign: 'center'
    },
    animation: {
        width: 100,
        height: 100,
        marginBottom: 20,
        textAlign: 'center'
    },
    title: {
        fontSize: 18,
        marginBottom: 10,
        fontWeight: 'bold',
    },
    timeText: {
        fontSize: 16,
        marginBottom: 20,
    },
    slider: {
        width: 300,
        height: 40,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 20,
    },
    cancelButton: {
        backgroundColor: '#dec49b',
        padding: 10,
    },
    confirmButton: {
        backgroundColor: '#F76E24',
        padding: 10,
    },
});

export default OrderAcceptTimePicker;