import {Alert} from "react-native";
import ThermalPrinterModule from "react-native-thermal-printer";
import {ColumnAlignment, COMMANDS, NetPrinter} from "react-native-thermal-receipt-printer-image-qr";
// TODO: Don't forget to add "new EscPosCharsetEncoding("Cp852", 6)" to "@happy-gastro/react-native-thermal-printer/android"

const getOrderType = (orderType, order = {}) => {
    switch (orderType) {
        case 'online': {
            if (order.orderFrom === 'wolt') return 'Wolt';
            if (order.orderFrom === 'foodpanda') return 'FoodPanda';
            if (order.orderFrom === 'foodPanda') return 'FoodPanda';
            if (order.orderFrom === 'netpincer') return 'FoodPanda';
            if (order.orderFrom === 'falatozz') return 'Falatozz.hu';
            if (order.orderFrom === 'ordit') return 'Ordit.hu';
            if (order.orderFrom === 'webshop') return 'Webshop';
            if (order.orderFrom === 'phone') return 'Phone';
            break;
        }
        case 'dineIn':
            return 'A:' + order.tableName
        case 'takeAway':
            return 'Elvitel'
    }
}

const getOrderFrom = (orderFrom) => {
    if (orderFrom === 'wolt') return 'Wolt';
    if (orderFrom === 'foodpanda') return 'FoodPanda';
    if (orderFrom === 'foodPanda') return 'FoodPanda';
    if (orderFrom === 'netpincer') return 'FoodPanda';
    if (orderFrom === 'falatozz') return 'Falatozz.hu';
    if (orderFrom === 'ordit') return 'Ordit.hu';
    if (orderFrom === 'webshop') return 'Webshop';
    if (orderFrom === 'phone') return 'Phone';
    return 'Mobil POS'
}

export const paymentTypes = {
    cash: "Készpénz",
    creditCard: "Bankkártya",
    temporary: "FIZETETLEN",
    vipBalance: "VIP egyenleg",
};

const returnResponse = (cb) => {
    setTimeout(() => Alert.alert(
        "Nem sikerült a nyomtatás",
        "Szeretné újrapróbálni?",
        [
            {
                text: "Nem",
                style: "destructive",
            },
            {
                text: "Igen",
                style: "default",
                onPress: () => {
                    if (cb) cb()
                },
            },
        ],
        {
            cancelable: true,
        }
    ), 1500);
};

export const getPrinterTestData = (printerData) => {
    return (
        "\n\n" +
        "[C]<font size='big'>Nyomtató észlelve!</font>\n" +
        "[C]============================\n" +
        "[C]<font size='big'>" +
        printerData.ip +
        "</font>\n" +
        "[L]Port: " +
        printerData.port +
        "\n[C]\n\n\n\n\n\n\n\n\n\n\n[C]"
    );
};

export const sendPrinterAndroid = async (
    data,
    printer,
    tryAgain = true,
    showAlert = true
) => {
    if (printer.ip && printer.ip !== "") {
        return ThermalPrinterModule.printTcp({
            autoCut: true,
            openCashbox: true,
            mmFeedPaper: 20,
            printerDpi: 200,
            printerWidthMM: 78,
            printerNbrCharactersPerLine: 42,
            timeout: 500,
            ip: printer.ip,
            port: Number(printer.port),
            payload: data
        })
            .then((response) => {
                console.log("Data printed by TCP/IP!");
                return true;
            })
            .catch((error) => {
                if (!tryAgain) {
                    if (showAlert) {
                        sendPrinterAndroid(
                            data,
                            printer,
                            tryAgain,
                            false
                        )
                    }
                    return { error: error, data: data, printer: printer };
                }
                return sendPrinterAndroid(data, printer, false)
                    .then(() => {
                        console.log("Data printed by TCP/IP!");
                        return true;
                    })
                    .catch(() => {
                        return sendPrinterAndroid(data, printer, false)
                            .then(() => {
                                console.log("Data printed by TCP/IP!");
                                return true;
                            })
                            .catch((error) => {

                                if(showAlert) sendPrinterAndroid(
                                    data,
                                    printer,
                                    tryAgain,
                                    false
                                )
                                return { error: error, data: data, printer: printer };
                            });
                    });
            });
    }

    if ((printer.address && printer.address !== "") || (printer.macAddress && printer.macAddress !== "") || (printer._nativeDevice.address && printer._nativeDevice.address !== "")) {
        await ThermalPrinterModule.getBluetoothDeviceList()
        console.log("Printing to ", (printer.address || printer.macAddress || printer._nativeDevice.address))
        return ThermalPrinterModule.printBluetooth({
            printerWidthMM: 76,
            printerNbrCharactersPerLine: 48,
            macAddress: (printer.address || printer.macAddress || printer._nativeDevice.address),
            payload: data
        })
            .then((response) => {
                console.log("Data printed by Bluetooth!", data);
                return true;
            })
            .catch((error) => {
                return console.error(error)
                return sendPrinterAndroid(data, printer)
                    .then(() => {
                        console.log("Data printed by TCP/IP!");
                        return true;
                    })
                    .catch((error) => {
                        return sendPrinterAndroid(data, printer)
                            .then(() => {
                                console.log("Data printed by TCP/IP!");
                                return true;
                            })
                            .catch((error) => {
                                Alert.alert(
                                    "Nem sikerült a nyomtatás!",
                                    "A nyomtatót nem lehetett elérni."
                                );
                                return { error: error, printer: printer };
                            });
                    });
            });
    }
};

export const sendPrinteriOS = async (
    printingOld,
    order,
    restaurant,
    restaurantPlace,
    user,
    printer,
    tryAgain = true,
    showAlert = true
) => {
    if (printer.ip && printer.ip !== "") {
        try {
            await Promise.all(Array.from({length: printer.printerEvent.copies || printer.printerEvent.copy || 1}).map(async () => {
                //await Promise.all(Array.from({length:1}).map(async () => {

                await NetPrinter.connectPrinter(
                    printer?.ip || "",
                    printer?.port || 9100
                )

                const Printer = NetPrinter;
                return new Promise(async (resolve, reject) => {
                    try {

                        Printer.printText(`${COMMANDS.TEXT_FORMAT.TXT_ALIGN_CT}${COMMANDS.TEXT_FORMAT.TXT_BOLD_ON}${COMMANDS.TEXT_FORMAT.TXT_CUSTOM_SIZE(2, 2)}${("#" + (order.orderCode || "123-456"))}${COMMANDS.TEXT_FORMAT.TXT_BOLD_OFF}`)
                        Printer.printText(`${COMMANDS.TEXT_FORMAT.TXT_ALIGN_CT}${COMMANDS.TEXT_FORMAT.TXT_CUSTOM_SIZE(2, 2)}${getOrderType(order.orderType || 'takeAway', order)}`)

                        if (order.orderType === 'online') {
                            Printer.printText(`${COMMANDS.TEXT_FORMAT.TXT_ALIGN_CT}${COMMANDS.TEXT_FORMAT.TXT_CUSTOM_SIZE(1, 1)}${(order.addressInfo.zip + " " + order.addressInfo.city)}\n`)
                            Printer.printText(`${COMMANDS.TEXT_FORMAT.TXT_ALIGN_CT}${COMMANDS.TEXT_FORMAT.TXT_CUSTOM_SIZE(1, 1)}${(order.addressInfo.address)}\n`)
                        }

                        if (order.comments && order.comments.length > 0) {
                            Printer.printText(`${COMMANDS.TEXT_FORMAT.TXT_ALIGN_CT}"${COMMANDS.TEXT_FORMAT.TXT_CUSTOM_SIZE(1, 1)}${COMMANDS.TEXT_FORMAT.TXT_BOLD_ON}${(order.comments.join(', '))}${COMMANDS.TEXT_FORMAT.TXT_BOLD_OFF}"\n`)
                        }
                        if (order.comment) {
                            Printer.printText(`${COMMANDS.TEXT_FORMAT.TXT_ALIGN_CT}"${COMMANDS.TEXT_FORMAT.TXT_CUSTOM_SIZE(1, 1)}${COMMANDS.TEXT_FORMAT.TXT_BOLD_ON}${(order.comment)}${COMMANDS.TEXT_FORMAT.TXT_BOLD_OFF}"\n`)
                        }


                        const _generateItemRow = (row) => {
                            let currentSize = row.item.sizes.find((_) => _._id === row.size);
                            if (!currentSize) currentSize = row.item.sizes[row.sizeIndex] || row.item.sizes[0];
                            let itemName = (row.item.name['hu'] || row.item.name['en']) + ((currentSize && currentSize.amount !== 1) ? " (" + currentSize.amount + "" + currentSize.unit + ")" : "");

                            Printer.printText(`${COMMANDS.TEXT_FORMAT.TXT_CUSTOM_SIZE(2, 2)}${COMMANDS.TEXT_FORMAT.TXT_ALIGN_CT}${COMMANDS.TEXT_FORMAT.TXT_CUSTOM_SIZE(1, 2)}${COMMANDS.TEXT_FORMAT.TXT_ALIGN_LT}${(row.amount || row.quantity || 0) + "x"}${itemName}`);


                            if (row.comment) {
                                Printer.printText(`${COMMANDS.TEXT_FORMAT.TXT_ALIGN_CT}${COMMANDS.TEXT_FORMAT.TXT_CUSTOM_SIZE(1, 1)}${'            ' + '"' + row.comment + '"'}`)
                            }
                            if (row.serveType) {
                                Printer.printText(`${COMMANDS.TEXT_FORMAT.TXT_ALIGN_CT}${COMMANDS.TEXT_FORMAT.TXT_CUSTOM_SIZE(1, 1)}${'            ' + '"' + row.serveType + '"'}`)
                            }

                            if (row.comments && row.comments.length > 0) {
                                Printer.printText(`${COMMANDS.TEXT_FORMAT.TXT_ALIGN_CT}${COMMANDS.TEXT_FORMAT.TXT_CUSTOM_SIZE(1, 1)}${'            ' + '"' + row.comments.join(', ') + '"'}`)
                            }
                            if (row.extras) {
                                row.extras.forEach((extra => {
                                    Printer.printText(`${COMMANDS.TEXT_FORMAT.TXT_ALIGN_CT}${COMMANDS.TEXT_FORMAT.TXT_CUSTOM_SIZE(1, 1)}${'  +' + (extra.item.name.hu || extra.item.name.en)}`)
                                }))
                            }
                        }

                        const _generateItems = () => [...Array(21).keys()].map((key) => {
                            if ([...order.items].filter(item => item.round === key).length === 0) return false;
                            if (key > 0) {
                                Printer.printText(`${COMMANDS.TEXT_FORMAT.TXT_ALIGN_CT}${COMMANDS.TEXT_FORMAT.TXT_CUSTOM_SIZE(1, 2)}${COMMANDS.TEXT_FORMAT.TXT_BOLD_ON}${(key + '. fogás')}${COMMANDS.TEXT_FORMAT.TXT_BOLD_OFF}`);
                            } // Print "X. round" row
                            order.items.filter(item => item.round === key).map((currentItem) => _generateItemRow(currentItem));
                        })

                        _generateItems()

                        Printer.printText(`${COMMANDS.TEXT_FORMAT.TXT_ALIGN_CT}${COMMANDS.TEXT_FORMAT.TXT_CUSTOM_SIZE(1, 1)}${COMMANDS.TEXT_FORMAT.TXT_BOLD_OFF}${COMMANDS.HORIZONTAL_LINE.HR_80MM}`)


                        Printer.printColumnsText([
                                getOrderFrom(order.orderFrom),
                                new Date().toLocaleTimeString('hu-HU'),
                            ], [23, 23], [ColumnAlignment.LEFT, ColumnAlignment.RIGHT], [
                                `${COMMANDS.TEXT_FORMAT.TXT_BOLD_OFF}`,
                                `${COMMANDS.TEXT_FORMAT.TXT_CUSTOM_SIZE(1, 1)}`,
                                ``
                            ]
                        );

                        Printer.printColumnsText([
                                (printer.name || 'Terminál'),
                                new Date().toLocaleDateString('hu-HU'),
                            ], [23, 23], [ColumnAlignment.LEFT, ColumnAlignment.RIGHT], [
                                `${COMMANDS.TEXT_FORMAT.TXT_CUSTOM_SIZE(1, 1)}`,
                                `${COMMANDS.TEXT_FORMAT.TXT_CUSTOM_SIZE(1, 1)}`,
                                ``
                            ]
                        );
                        Printer.printBill(``, {beep: false});
                        return true;
                    } catch (e) {
                        console.error(e)
                        returnResponse(() => sendPrinteriOS(
                            printingOld,
                            order,
                            restaurant,
                            restaurantPlace,
                            user,
                            printer,
                            false,
                            showAlert
                        ));
                    }
                });
            })).then(() => {
                return true;
            }).catch(() => {

            })
            return true;
        } catch (e) {
            if (showAlert) {
                returnResponse(() => sendPrinteriOS(
                    printingOld,
                    order,
                    restaurant,
                    restaurantPlace,
                    user,
                    printer,
                    tryAgain,
                    showAlert
                ));
            }
            return false;
        }
    }

    if (printer.macAddress && printer.macAddress !== "") {
    }
};
