import React, { useState } from 'react';
import {
    Modal,
    View,
    Text,
    TextInput,
    TouchableOpacity,
    StyleSheet,
} from 'react-native';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const InventoryModal = ({ visible, onClose }) => {
    const [warehouse, setWarehouse] = useState('');
    const [warehouseDropdownVisible, setWarehouseDropdownVisible] = useState(false);
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState({
        hours: new Date().getHours(),
        minutes: new Date().getMinutes()
    });
    const [invoiceDate, setInvoiceDate] = useState(new Date());
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showHourPicker, setShowHourPicker] = useState(false);
    const [showMinutePicker, setShowMinutePicker] = useState(false);
    const [note, setNote] = useState('');
    const [search, setSearch] = useState('');

    const warehouses = [
        { label: 'Ital', value: 'Ital' },
        { label: 'Konyha', value: 'Konyha' },
        { label: 'KÖZPONTI', value: 'KÖZPONTI' },
        { label: 'Default raktár', value: 'Default raktár' }
    ];


    return (
        <Modal
            visible={visible}
            transparent
            animationType="slide"
            onRequestClose={onClose}
        >
            <View style={styles.modalOverlay}>
                <View style={styles.modalContent}>
                    <View style={styles.modalHeader}>
                        <Text style={styles.modalHeaderText}>Inventory Modal</Text>
                        <TouchableOpacity onPress={onClose} style={styles.closeButton}>
                            <Text style={styles.closeButtonText}>BEZÁR</Text>
                        </TouchableOpacity>
                    </View>

                    <View style={styles.formContainer}>
                        <View style={styles.formRow}>
                            <View style={styles.formGroup}>
                                <Text style={styles.label}>Raktár választása</Text>
                                <TouchableOpacity
                                    style={styles.dropdownButton}
                                    onPress={() => setWarehouseDropdownVisible(!warehouseDropdownVisible)}
                                >
                                    <Text style={styles.dropdownButtonText}>
                                        {warehouse || 'Válassz egy raktárat'}
                                    </Text>
                                </TouchableOpacity>
                                {warehouseDropdownVisible && (
                                    <View style={styles.dropdown}>
                                        {warehouses.map((item) => (
                                            <TouchableOpacity
                                                key={item.value}
                                                style={styles.dropdownItem}
                                                onPress={() => {
                                                    setWarehouse(item.value);
                                                    setWarehouseDropdownVisible(false);
                                                }}
                                            >
                                                <Text style={styles.dropdownItemText}>{item.label}</Text>
                                            </TouchableOpacity>
                                        ))}
                                    </View>
                                )}
                            </View>

                            <View style={styles.formGroup}>
                                <Text style={styles.label}>Leltár ideje</Text>
                                <View style={styles.datePickerContainer}>
                                    <DatePicker
                                        selected={invoiceDate}
                                        onChange={(date) => setInvoiceDate(date)}
                                        dateFormat="yyyy/MM/dd"
                                        className="datepicker"
                                        popperClassName="datepicker-popper"
                                        popperPlacement="bottom-start"
                                        popperProps={{
                                            positionFixed: true,
                                            style: {
                                                zIndex: 9999
                                            }
                                        }}
                                        popperModifiers={{
                                            preventOverflow: {
                                                enabled: true,
                                                escapeWithReference: true,
                                                boundariesElement: 'viewport'
                                            },
                                            flip: {
                                                enabled: true
                                            }
                                        }}
                                    />
                                </View>
                            </View>
                        </View>

                        <View style={styles.formRow}>
                            <View style={styles.formGroup}>
                                <Text style={styles.label}>Megjegyzés</Text>
                                <TextInput
                                    style={styles.input}
                                    placeholder="Megjegyzés"
                                    value={note}
                                    onChangeText={setNote}
                                />
                            </View>

                            <View style={styles.formGroup}>
                                <Text style={styles.label}>Keresés</Text>
                                <TextInput
                                    style={styles.input}
                                    placeholder="Keresés"
                                    value={search}
                                    onChangeText={setSearch}
                                />
                            </View>
                        </View>
                    </View>

                    <View style={styles.messageContainer}>
                        <Text style={styles.messageText}>Előbb add meg a raktárat és a leltár idejét!</Text>
                    </View>
                </View>
            </View>

        </Modal>
    );
};

const styles = StyleSheet.create({
    modalOverlay: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        width: '90%',
        maxWidth: 600,
        backgroundColor: 'white',
        borderRadius: 4,
        overflow: 'hidden',
    },
    modalHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: '#EEEEEE',
    },
    modalHeaderText: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    closeButton: {
        backgroundColor: '#FF6B6B',
        paddingHorizontal: 15,
        paddingVertical: 8,
        borderRadius: 4,
    },
    closeButtonText: {
        color: 'white',
        fontWeight: 'bold',
    },
    formContainer: {
        padding: 15,
    },
    formRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 15,
    },
    formGroup: {
        flex: 1,
        marginHorizontal: 5,
    },
    label: {
        fontSize: 14,
        marginBottom: 5,
        fontWeight: '500',
    },
    input: {
        borderWidth: 1,
        borderColor: '#CCCCCC',
        borderRadius: 4,
        padding: 10,
        height: 40,
    },
    dropdownButton: {
        borderWidth: 1,
        borderColor: '#CCCCCC',
        borderRadius: 4,
        padding: 10,
        height: 40,
        justifyContent: 'center',
    },
    dropdownButtonText: {
        color: '#333333',
    },
    dropdown: {
        position: 'absolute',
        top: 70,
        left: 0,
        right: 0,
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: '#CCCCCC',
        borderRadius: 4,
        zIndex: 1000,
        elevation: 5,
        maxHeight: 200,
    },
    dropdownItem: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#EEEEEE',
    },
    dropdownItemText: {
        color: '#333333',
    },
    dateTimeContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    dateButton: {
        flex: 1,
        borderWidth: 1,
        borderColor: '#CCCCCC',
        borderRadius: 4,
        padding: 10,
        height: 40,
        justifyContent: 'center',
        marginRight: 10,
    },
    dateButtonText: {
        color: '#333333',
    },
    timeContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    timeButton: {
        width: 40,
        borderWidth: 1,
        borderColor: '#CCCCCC',
        borderRadius: 4,
        padding: 10,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
    },
    timeButtonText: {
        color: '#333333',
    },
    timeSeparator: {
        paddingHorizontal: 5,
        fontSize: 18,
        fontWeight: 'bold',
    },
    messageContainer: {
        padding: 15,
        borderTopWidth: 1,
        borderTopColor: '#EEEEEE',
        justifyContent: 'center',
        alignItems: 'center',
    },
    messageText: {
        fontSize: 16,
        color: '#666666',
    },
    datePickerModalOverlay: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    timePickerContainer: {
        backgroundColor: 'white',
        borderRadius: 8,
        padding: 15,
        width: 150,
        alignItems: 'center',
    },
    timePicker: {
        width: 100,
        height: 150,
    },
    datePickerButton: {
        marginTop: 10,
        backgroundColor: '#4285F4',
        paddingHorizontal: 20,
        paddingVertical: 8,
        borderRadius: 4,
    },
    datePickerContainer: {
        backgroundColor: 'white',
        borderRadius: 8,
        padding: 0,
        width: '100%',
        zIndex: 1000,
        position: 'relative'
    },
    datePickerButtonText: {
        color: 'white',
        fontWeight: 'bold',
    },
});

export default InventoryModal;