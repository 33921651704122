import React, { useContext, useState } from 'react';
import { TouchableOpacity, StyleSheet, View, Alert, Platform, Modal, Text } from 'react-native';
import {Ionicons, MaterialIcons} from '@expo/vector-icons';
import Feather from '@expo/vector-icons/Feather';
import { CartContext } from '../helpers/CartContext';
import useConfirmNavigation from '../helpers/UseConfirmNavigation';
import { TableContext } from "../helpers/TableContext";
import { OnlineOrderContext } from "../helpers/OnlineOrderContext";
import { useTranslation } from 'react-i18next';
import '../../i18n';

const CircularButton = ({ navigation, iconName, style }) => {
    const { t } = useTranslation();
    const [modalVisible, setModalVisible] = useState(false);
    const { cartItems, setAlreadySentCartItems } = useContext(CartContext);
    const { removeTable } = useContext(TableContext);
    const { orderData, updateOrderData, resetOnlineOrder } = useContext(OnlineOrderContext);
    const confirmNavigation = useConfirmNavigation(navigation);

    const handleSelect = (value) => {
        setModalVisible(false);
        const currentRoute = navigation.getState().routes[navigation.getState().index].name;

        const navigateToScreen = (screen) => {
            if (cartItems.length > 0) {
                confirmNavigation(screen);
            } else {
                removeTable();
                setAlreadySentCartItems([]);
                resetOnlineOrder();
                updateOrderData({
                    // Reset other order data fields as needed
                }, '');
                navigation.navigate(screen);
            }
        };

        const showAlert = () => {
            if (Platform.OS === 'web') {
                if (window.confirm(t("home_delivery_alert"))) {
                    updateOrderData({
                        // Reset other order data fields as needed
                    }, '');
                    navigateToScreenBasedOnValue(value, currentRoute, navigateToScreen);
                }
            } else {
                Alert.alert(
                    t("attention"),
                    t("home_delivery_alert"),
                    [
                        {
                            text: t("cancel"),
                            style: "cancel"
                        },
                        {
                            text: t("yes"),
                            onPress: () => {
                                updateOrderData({
                                    userInfo: null,
                                    addressInfo: null,
                                    // Reset other order data fields as needed
                                },'');
                                navigateToScreenBasedOnValue(value, currentRoute, navigateToScreen);
                            }
                        }
                    ]
                );
            }
        };

        const navigateToScreenBasedOnValue = (value, currentRoute, navigateToScreen) => {
            if (value === 'takeAway') {
                if (currentRoute !== 'HomeScreen') {
                    navigateToScreen('HomeScreen');
                }
            } else if (value === 'dineIn') {
                if (currentRoute !== 'TablesScreen') {
                    navigateToScreen('TablesScreen');
                }
            } else if (value === 'delivery') {
                if (currentRoute !== 'OnlineScreen') {
                    navigateToScreen('OnlineScreen');
                }
            }
        };

        if (orderData?.userInfo && orderData?.addressInfo) {
            showAlert();
        } else {
            navigateToScreenBasedOnValue(value, currentRoute, navigateToScreen);
        }
    };

    return (
        <View style={styles.menuContainer}>
            <TouchableOpacity
                activeOpacity={0.6}
                onPress={() => setModalVisible(true)}
                style={[styles.circularButton, style]}
            >
                <Ionicons name={iconName} size={30} style={styles.icon} />
            </TouchableOpacity>
            <Modal
                transparent={true}
                visible={modalVisible}
                onRequestClose={() => setModalVisible(false)}
            >
                <TouchableOpacity
                    style={styles.modalOverlay}
                    activeOpacity={1}
                    onPressOut={() => setModalVisible(false)}
                >
                    <View style={styles.modalContent}>
                        <TouchableOpacity onPress={() => handleSelect('takeAway')} style={styles.modalItem}>
                            <Ionicons name="bag-outline" size={24} color="#000" />
                            <Text style={[styles.modalText,{color: "#000"}]}>{t("quick_receipt")}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => handleSelect('dineIn')} style={styles.modalItem}>
                            <MaterialIcons name="table-bar" size={24} color="#F1B44C" />
                            <Text style={[styles.modalText,{color: "#F1B44C"}]}>{t("table_map")}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => handleSelect('delivery')} style={styles.modalItem}>
                            <Feather name="truck" size={24} color="#50A5F1" />
                            <Text style={[styles.modalText,{color: "#50A5F1"}]}>{t("home_delivery")}</Text>
                        </TouchableOpacity>
                    </View>
                </TouchableOpacity>
            </Modal>
        </View>
    );
};

const styles = StyleSheet.create({
    circularButton: {
        margin: "auto",
        width: 50,
        height: 50,
        borderRadius: 25,
        backgroundColor: '#F1B34B',
        justifyContent: 'center',
        alignItems: 'center',
        elevation: 5,
        zIndex: 999, // Ensure the button is above other elements
    },
    menuContainer: {
        position: 'absolute',
        bottom: 40,
        left: '50%',
        transform: [{ translateX: -25 }],
        zIndex: 999, // Ensure the menu is above other elements
    },
    modalOverlay: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    modalContent: {
        width: 240,
        backgroundColor: '#fff',
        borderRadius: 10,
        padding: 20,
        alignItems: 'center',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.40,
        shadowRadius: 1.41,
        elevation: 2,
        marginBottom: 90, // Position the modal above the circular button
    },
    modalItem: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        width: '100%',
    },
    modalText: {
        marginLeft: 10,
        fontSize: 18,
        fontWeight: '900',
        textAlign: 'center',
    },
    icon: {
        color: '#fff',
    },
});

export default CircularButton;