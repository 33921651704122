import React from 'react';
import { View, Text, StyleSheet, Modal, TouchableOpacity, ScrollView } from 'react-native';
import { useTranslation } from 'react-i18next';
import OrderRow from './OrderRow';

const DriverDetailsModal = ({ visible, onClose, driver, orders }) => {
    const { t } = useTranslation();

    const cashOrdersSum = orders.filter(order => order.paymentType === 'cash').reduce((sum, order) => sum + order.orderSum, 0);
    const creditCardOrdersSum = orders.filter(order => order.paymentType === 'creditCard').reduce((sum, order) => sum + order.orderSum, 0);
    const deliveryFeesSum = orders.reduce((sum, order) => sum + (order.delivery?.fee || 0), 0);

    return (
        <Modal visible={visible} transparent={true} animationType="slide">
            <View style={styles.modalBackground}>
                <View style={styles.modalContainer}>
                    <View style={styles.header}>
                        <Text style={styles.title}>{driver}</Text>
                        <TouchableOpacity onPress={() => console.log('Print')} style={styles.printButton}>
                            <Text style={styles.printButtonText}>{t('print')}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={onClose} style={styles.closeButton}>
                            <Text style={styles.closeButtonText}>{t('close')}</Text>
                        </TouchableOpacity>
                    </View>
                    <View>
                        <View>
                            <Text style={styles.infoText}>{t('order_count')}: <Text style={styles.infoValue}>{orders.length}</Text></Text>
                            <Text style={styles.infoText}>{t('sum_of_cash_orders')}: <Text style={styles.infoValue}>{cashOrdersSum} Ft</Text></Text>
                            <Text style={styles.infoText}>{t('sum_of_credit_card_orders')}: <Text style={styles.infoValue}>{creditCardOrdersSum} Ft</Text></Text>
                            <Text style={styles.infoText}>{t('delivery_fees')}: <Text style={styles.infoValue}>{deliveryFeesSum} Ft</Text></Text>
                        </View>
                        <ScrollView style={{maxHeight: 300}} contentContainerStyle={{maxHeight: 300}}>
                            {orders.map(order => (
                                <OrderRow key={order.id} order={order} onPress={() => {}} />
                            ))}
                        </ScrollView>
                    </View>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalBackground: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContainer: {
        width: '80%',
        height: '80%',
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 20,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    printButton: {
        backgroundColor: '#777474',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 8,
    },
    closeButton: {
        backgroundColor: '#f45162',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 8,
    },
    printButtonText: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
    },
    closeButtonText: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
    },
    infoText: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
        paddingLeft: 10,
    },
    infoValue: {
        fontSize: 16,
        fontWeight: 'normal',
        color: '#555',
    },
    });

export default DriverDetailsModal;