import { ScrollView, Alert, Button } from "react-native";
import React, { useState, useEffect } from 'react';
import {
    SafeAreaView,
    StatusBar,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    PermissionsAndroid
} from 'react-native';
import SerialPortAPI from 'react-native-serial-port-api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { LinearGradient } from 'expo-linear-gradient';
import { Picker } from '@react-native-picker/picker';
export default function Sam4sCashierSettingsScreen({ navigation }) {
    const [selectedUsbSerial, setSelectedUsbSerial] = useState('');
    const [usbSerialList, setUsbSerialList] = useState([]);

    const stringToHex = (str) => {
        return str
            .split('')
            .map(char => char.charCodeAt(0).toString(16).padStart(2, '0')) // Convert to hex
            .join('');
    };


    const getDevices = async () => {
        //await requestPermissions();
        const serialPort = await SerialPortAPI.open("/dev/ttyHS1", { baudRate: 115200, dataBits: 8, stopBits: 1 });
        const openDay = await serialPort.send(stringToHex("PTG|1\\10|0\r"));
        const closeDay = await serialPort.send(stringToHex("PTG|1\\11\r"));

        console.log("Serial Data sent:", openDay)
        serialPort.onReceived((data) => {
            console.log("Serial Data received:", data.toString());
        })
    }
    React.useEffect(() => {
        getDevices();
    }, [])

    const handleSave = async () => {
        return getDevices()
        const settings = {
            usbSerial: selectedUsbSerial
        };
        await AsyncStorage.setItem('sam4sSettings', JSON.stringify(settings));
        navigation.goBack();
    };

    return (
        <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={{ flex: 1, width: '100%', height: '100%' }}
        >
            <SafeAreaView style={{ flex: 1, backgroundColor: 'transparent', paddingTop: 10 }}>
                <StatusBar backgroundColor="transparent" translucent barStyle="dark-content" />
                <ScrollView contentContainerStyle={styles.content}>
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Sam4s Cashier Settings</Text>
                        <View style={styles.sectionBody}>
                            <Picker
                                selectedValue={selectedUsbSerial}
                                onValueChange={(itemValue) => setSelectedUsbSerial(itemValue)}
                                style={styles.picker}
                            >
                                {usbSerialList.map((usbSerial, index) => (
                                    <Picker.Item key={index} label={usbSerial} value={usbSerial} />
                                ))}
                            </Picker>
                            <TouchableOpacity style={styles.button} onPress={handleSave}>
                                <Text style={styles.buttonText}>Save</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </ScrollView>
            </SafeAreaView>
        </LinearGradient>
    );
}

const styles = StyleSheet.create({
    content: {
        paddingHorizontal: 16,
        backgroundColor: 'transparent'
    },
    section: {
        paddingVertical: 12,
    },
    sectionTitle: {
        margin: 8,
        marginLeft: 12,
        fontSize: 13,
        letterSpacing: 0.33,
        fontWeight: '500',
        color: '#a69f9f',
        textTransform: 'uppercase',
    },
    sectionBody: {
        borderRadius: 12,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.5,
        shadowRadius: 1.41,
        elevation: 3,
        backgroundColor: '#fff',
        padding: 16,
    },
    picker: {
        height: 50,
        marginBottom: 12,
    },
    button: {
        backgroundColor: '#FFA500', // Orange color
        padding: 12,
        borderRadius: 8,
        alignItems: 'center',
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: '600',
    },
});