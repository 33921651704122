import React from 'react';
import {View, Text, StyleSheet, Image, Platform} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

const WaitingForPayment = ({navigation}) => {
    // Simulate waiting for NFC activation or credit card swipe
    React.useEffect(() => {
        setTimeout(() => {
            // Navigate to success/failure after payment
            navigation.navigate('ResultScreen', { isSuccess: true }); // Change true to false for failure
        }, 5000); // Simulate 5 seconds for card swipe or NFC read
    }, []);
    return (
        <View style={styles.container}>
            <Icon name={"cellphone-nfc"} size={150}  color={'#6b6b6b'}/>
            <Text style={styles.title}>Várakozás a fizetésre...</Text>
            <Text style={styles.subtitle}>Azonnal továbblépünk a következő képernyőre!</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    subtitle: {
        fontSize: 16,
        marginBottom: 40,
    }
});

export default WaitingForPayment;