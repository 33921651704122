import React, { useEffect, useState } from 'react';
import { View, Text, ScrollView, TouchableOpacity, StyleSheet } from 'react-native';
import { Card } from 'react-native-paper';
import { useTranslation } from "react-i18next";

const RouteDetailsSidebar = ({ routeDetails, legs, selectedOrders, isOpen, startRestaurant, setExtendedLook, native }) => {
    const [isExpanded, setIsExpanded] = useState(isOpen || false);
    const [totalDistance, setTotalDistance] = useState((routeDetails.distance / 1000).toFixed(2)); // Convert to km
    const [totalDuration, setTotalDuration] = useState((routeDetails.duration / 60).toFixed(2)); // Convert to minutes
    const [cardBackgroundColor, setCardBackgroundColor] = useState('#f8f9fa');
    const { t } = useTranslation();

    const toggleCardBackgroundColor = () => {
        setCardBackgroundColor(prevColor => prevColor === '#f8f9fa' ? '#d3d3d3' : '#f8f9fa');
    };

    function getClosestOrder(location) {
        const routeEnd = {
            latitude: location.maneuver.location[1],
            longitude: location.maneuver.location[0]
        };

        const haversineDistance = (coords1, coords2) => {
            const toRad = (x) => x * Math.PI / 180;
            const R = 6371; // Radius of the Earth in km

            const dLat = toRad(coords2.lat - coords1.latitude);
            const dLon = toRad(coords2.lng - coords1.longitude);
            const lat1 = toRad(coords1.latitude);
            const lat2 = toRad(coords2.lat);

            const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            return R * c;
        };

        let closestOrder = null;
        let minDistance = Infinity;

        selectedOrders.forEach(order => {
            const orderCoords = order.addressInfo.coordinates_raw || {
                latitude: order.addressInfo.coordinates[0],
                longitude: order.addressInfo.coordinates[1]
            };
            const distance = haversineDistance(routeEnd, orderCoords);
            if (distance < minDistance) {
                closestOrder = order;
                minDistance = distance
            }
        });
        console.log("closestOrder", closestOrder);
        return closestOrder;
    }

    useEffect(() => {
        if(native){
            setExtendedLook(isOpen)
        }
        setIsExpanded(isOpen);
    }, [isOpen]);

    useEffect(() => {
        console.log(routeDetails);
        console.log(selectedOrders)
        setTotalDistance((routeDetails.distance / 1000).toFixed(2));
        setTotalDuration((routeDetails.duration / 60).toFixed(2));
    }, [routeDetails]);

    return (
        <View style={[styles.container, isExpanded ? styles.expanded : styles.collapsed, native && {maxHeight: '100%'}]}>
            <TouchableOpacity
                style={[styles.toggleButton, isExpanded ? styles.toggleRight : styles.toggleLeft]}
                onPress={() => {
                    if(native){
                        setExtendedLook(isOpen)
                    }
                    setIsExpanded(!isExpanded)
                }}
            >
                <Text style={styles.toggleText}>{isExpanded ? '<' : '>'}</Text>
            </TouchableOpacity>

            {isExpanded && (
                <View style={styles.content}>
                    <Text style={styles.totalInfo}>{totalDistance} km - {totalDuration} {t("minutes")}</Text>
                    <Card style={styles.card}>
                        <Text style={styles.sectionTitle}>🏠 {t("start")}:</Text>
                        <Text style={styles.address}>{startRestaurant.raw}</Text>
                    </Card>

                    {legs?.length > 0 && (
                        <ScrollView contentContainerStyle={{ flexGrow: 1 }} style={!native? { maxHeight: 300 } : {paddingVertical: 15}}>
                            <View style={{ flexGrow: 1 }}>
                                {legs.slice(0, -1).map((stop, index) => {
                                    const closestOrder = getClosestOrder(stop.steps[stop.steps.length - 1]);
                                    return (
                                        <Card
                                            key={index}
                                            style={[styles.card, { backgroundColor: cardBackgroundColor }]}
                                            onPress={toggleCardBackgroundColor}
                                        >
                                            <Text style={styles.orderCode}>#{closestOrder?.orderCode}</Text>
                                            <Text style={styles.address}>{closestOrder?.addressInfo?.address}</Text>
                                            <Text style={styles.details}>{(stop.duration / 60).toFixed(2)} {t("minutes")} - {(stop.distance / 1000).toFixed(2)} km</Text>
                                        </Card>
                                    );
                                })}
                            </View>
                        </ScrollView>
                    )}

                    <Card style={styles.card}>
                        <Text style={styles.sectionTitle}>🏁 {t("return")}:</Text>
                        <Text style={styles.address}>{startRestaurant.raw}</Text>
                        <Text style={styles.details}>{(legs[legs.length - 1]?.duration / 60).toFixed(2)} {t("minutes")}
                            - {(legs[legs.length - 1]?.distance / 1000).toFixed(2)} km</Text>
                    </Card>
                </View>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        alignSelf: 'center', // Center it vertically
        backgroundColor: 'white',
        borderRadius: 10,
        elevation: 5,
        zIndex: 25,
        width: 300, // Adjust width as needed
        maxHeight: 500, // Prevent it from being too large
        padding: 10,
    },
    collapsed: {
        left: 0,
        width: 40,
        backgroundColor: "transparent",
    },
    expanded: {
        left: 0,
        width: 350,
        padding: 5,
    },
    toggleButton: {
        position: 'absolute',
        top: '50%',
        width: 35,
        height: 80,
        backgroundColor: '#FFA500',
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
    },
    toggleLeft: {
        left: 5,
        marginTop: "150px"
    },
    toggleRight: {
        right: -35,
    },
    toggleText: {
        fontSize: 20,
        color: 'white',
        fontWeight: 'bold',
    },
    content: {
        flex: 1,
        padding: 5,
        zIndex: 26
    },
    totalInfo: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 10,
    },
    card: {
        padding: 10,
        marginBottom: 10,
        backgroundColor: '#f8f9fa',
    },
    sectionTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        color: 'rgb(247, 110, 36)',
    },
    orderCode: {
        fontSize: 18,
        fontWeight: 'bold',
        color: 'rgb(247, 110, 36)',
    },
    address: {
        fontSize: 14,
        color: '#555',
    },
    details: {
        fontSize: 14,
        fontStyle: 'italic',
    },
});

export default RouteDetailsSidebar;