import React, { useContext, useState, useEffect } from 'react';
import {
    View,
    Text,
    Modal,
    TouchableOpacity,
    StyleSheet,
    FlatList,
    Animated,
    Dimensions
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SystemContext } from '../helpers/SystemContext';

const STORAGE_KEY = 'todoList';

const TodoList = ({ visible, onClose }) => {
    const { todoList } = useContext(SystemContext);
    // Create local state to ensure immediate UI updates
    const [localTodoList, setLocalTodoList] = useState([]);
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedbackTask, setFeedbackTask] = useState(null);

    // Load tasks and synchronize local state with context
    useEffect(() => {
        if (visible) {
            loadTasks();
        }
    }, [visible]);

    // Keep local state in sync with context state
    useEffect(() => {
        setLocalTodoList([...todoList]);
    }, [todoList]);

    const loadTasks = async () => {
        try {
            const storedTasks = await AsyncStorage.getItem(STORAGE_KEY);
            if (storedTasks !== null) {
                const parsedTasks = JSON.parse(storedTasks);
                setLocalTodoList(parsedTasks);
            }
        } catch (error) {
            console.error('Error loading tasks from AsyncStorage:', error);
        }
    };

    const saveTasks = async (updatedTasks) => {
        try {
            await AsyncStorage.setItem(STORAGE_KEY, JSON.stringify(updatedTasks));
            setLocalTodoList(updatedTasks);
        } catch (error) {
            console.error('Error saving tasks to AsyncStorage:', error);
        }
    };

    const handleCompleteTask = (index) => {
        // Create a copy of the local list to modify
        const updatedTasks = [...localTodoList];

        // Toggle completion status
        updatedTasks[index].completed = !updatedTasks[index].completed;

        // Update local state immediately for visual feedback
        setLocalTodoList(updatedTasks);

        // Show feedback for completed tasks
        setFeedbackTask({
            description: updatedTasks[index].description,
            completed: updatedTasks[index].completed
        });
        setShowFeedback(true);

        // Hide feedback after a delay
        setTimeout(() => {
            setShowFeedback(false);
        }, 1500);

        // Save to AsyncStorage and update context
        saveTasks(updatedTasks);
    };

    const getTimeColor = (time) => {
        const hour = parseInt(time.split(':')[0]);
        if (hour < 10) return '#4CAF50'; // Morning - Green
        if (hour < 14) return '#FF9800'; // Afternoon - Orange
        return '#3F51B5'; // Evening - Indigo
    };

    const completedTasksCount = localTodoList.filter(task => task.completed).length;

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={visible}
            onRequestClose={onClose}
        >
            <View style={styles.modalOverlay}>
                <Animated.View style={styles.modalContainer}>
                    <View style={styles.modalHeader}>
                        <Text style={styles.modalTitle}>Napi teendők</Text>
                        <Text style={styles.modalSubtitle}>
                            {completedTasksCount}/{localTodoList.length} kész
                        </Text>
                    </View>

                    <FlatList
                        data={localTodoList}
                        extraData={localTodoList} // Ensures FlatList re-renders when localTodoList changes
                        keyExtractor={(item, index) => index.toString()}
                        renderItem={({ item, index }) => {
                            const isCompleted = item.completed;
                            const timeColor = getTimeColor(item.time);

                            return (
                                <TouchableOpacity
                                    onPress={() => handleCompleteTask(index)}
                                    activeOpacity={0.7}
                                >
                                    <Animated.View style={[
                                        styles.todoItem,
                                        isCompleted && styles.completedTodoItem
                                    ]}>
                                        <View style={styles.todoContent}>
                                            <View style={[styles.timeBadge, { backgroundColor: timeColor }]}>
                                                <Text style={styles.timeText}>{item.time}</Text>
                                            </View>
                                            <View style={styles.todoTextContainer}>
                                                <Text style={[
                                                    styles.todoText,
                                                    isCompleted && styles.completedText
                                                ]}>
                                                    {item.description}
                                                </Text>
                                            </View>
                                        </View>

                                        <View style={[
                                            styles.checkCircle,
                                            isCompleted && styles.checkedCircle
                                        ]}>
                                            {isCompleted && <Text style={styles.checkmark}>✓</Text>}
                                        </View>
                                    </Animated.View>
                                </TouchableOpacity>
                            );
                        }}
                        contentContainerStyle={styles.listContent}
                        showsVerticalScrollIndicator={false}
                    />

                    <TouchableOpacity
                        onPress={onClose}
                        style={styles.closeButton}
                    >
                        <Text style={styles.closeButtonText}>Bezárás</Text>
                    </TouchableOpacity>
                </Animated.View>

                {/* Task update feedback toast */}
                {showFeedback && feedbackTask && (
                    <Animated.View style={styles.feedbackToast}>
                        <View style={styles.feedbackContent}>
                            <Text style={styles.feedbackIcon}>
                                {feedbackTask.completed ? '✓' : '↻'}
                            </Text>
                            <Text style={styles.feedbackText}>
                                {feedbackTask.completed
                                    ? 'Task completed'
                                    : 'Task marked as incomplete'}
                            </Text>
                        </View>
                    </Animated.View>
                )}
            </View>
        </Modal>
    );
};

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
    modalOverlay: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    modalContainer: {
        width: width * 0.9,
        maxHeight: '80%',
        backgroundColor: '#F5F5F5',
        borderRadius: 16,
        overflow: 'hidden',
        elevation: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
    },
    modalHeader: {
        backgroundColor: '#F1b34b',
        padding: 20,
        alignItems: 'center',
    },
    modalTitle: {
        fontSize: 22,
        color: 'white',
        fontWeight: 'bold',
    },
    modalSubtitle: {
        fontSize: 14,
        color: 'rgba(255, 255, 255, 0.8)',
        marginTop: 4,
    },
    listContent: {
        padding: 16,
    },
    todoItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 16,
        marginBottom: 12,
        backgroundColor: 'white',
        borderRadius: 12,
        elevation: 2,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 1.41,
        borderLeftWidth: 6,
        borderLeftColor: '#5C6BC0',
    },
    completedTodoItem: {
        opacity: 0.7,
        borderLeftColor: '#4CAF50',
    },
    todoContent: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    timeBadge: {
        paddingHorizontal: 8,
        paddingVertical: 4,
        borderRadius: 12,
        marginRight: 12,
    },
    timeText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 12,
    },
    todoTextContainer: {
        flex: 1,
    },
    todoText: {
        fontSize: 16,
        color: '#333',
    },
    completedText: {
        textDecorationLine: 'line-through',
        color: '#888',
    },
    checkCircle: {
        width: 24,
        height: 24,
        borderRadius: 12,
        borderWidth: 2,
        borderColor: '#F76E24',
        justifyContent: 'center',
        alignItems: 'center',
    },
    checkedCircle: {
        backgroundColor: '#4CAF50',
        borderColor: '#4CAF50',
    },
    checkmark: {
        color: 'white',
        fontSize: 14,
        fontWeight: 'bold',
    },
    closeButton: {
        backgroundColor: '#F76E24',
        padding: 16,
        alignItems: 'center',
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
    },
    closeButtonText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 16,
    },
    feedbackToast: {
        position: 'absolute',
        bottom: 40,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderRadius: 24,
        paddingVertical: 8,
        paddingHorizontal: 16,
        flexDirection: 'row',
        alignItems: 'center',
    },
    feedbackContent: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    feedbackIcon: {
        color: '#4CAF50',
        fontSize: 20,
        marginRight: 8,
    },
    feedbackText: {
        color: 'white',
        fontSize: 14,
    }
});

export default TodoList;