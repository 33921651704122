import React, { useState, useEffect } from "react";
import {
    View,
    Text,
    SafeAreaView,
    ScrollView,
    TouchableOpacity,
    StyleSheet,
    Modal,
    TextInput,
    Button,
    Dimensions, FlatList
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { LinearGradient } from "expo-linear-gradient";
import { priceFormatter } from "../helpers";
import Icon from 'react-native-vector-icons/Feather';
import {useTranslation} from "react-i18next";
import Observables from "@happy-gastro/global-observables"

const Row = ({ description, price, onDelete }) => (
    <View style={styles.row}>
        <View><Text style={[styles.text, {fontWeight: "bold"}]}>{description}</Text></View>
        <View style={{flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
            <Text style={[styles.text, {paddingRight: 25}]}>{priceFormatter(price)}</Text>
            <TouchableOpacity style={{padding: 8, backgroundColor: "#fffae4", borderRadius: 5}} onPress={onDelete}>
                <Icon name="trash-2" color="red" size={24} />
            </TouchableOpacity>
        </View>


    </View>
);

const ExpensesScreen = () => {
    const {t} = useTranslation();
    const [expenses, setExpenses] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [newDescription, setNewDescription] = useState("");
    const [newPrice, setNewPrice] = useState("");
    const { width, height } = Dimensions.get('window');

    useEffect(() => {
        loadExpenses();
        Observables.register("refreshExpenses", loadExpenses)
    }, []);

    const loadExpenses = async () => {
        try {
            const storedExpenses = await AsyncStorage.getItem("expenses");
            if (storedExpenses) {
                setExpenses(JSON.parse(storedExpenses));
            }
        } catch (error) {
            console.error("Failed to load expenses", error);
        }
    };

    const deleteExpense = async (index) => {
        try {
            const updatedExpenses = expenses.filter((_, i) => i !== index);
            setExpenses(updatedExpenses);
            await AsyncStorage.setItem("expenses", JSON.stringify(updatedExpenses));
        } catch (error) {
            console.error("Failed to delete expense", error);
        }
    };

    return (
        <LinearGradient colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']} style={{ flex: 1 }}>
            <SafeAreaView style={{ flex: 1 }}>
            <View style={styles.container}>
                <ScrollView contentContainerStyle={{height:  (height * 0.75)}} style={{height: (height * 0.75)}}>

                    <FlatList
                        data={expenses}
                        keyExtractor={(item, index) => index.toString()}
                        renderItem={({ item, index }) => (
                            <Row
                                description={item.description}
                                price={item.price}
                                onDelete={() => deleteExpense(index)}
                            />
                        )}
                    />

                </ScrollView>
                {false && <TouchableOpacity style={styles.addButton} onPress={() => setModalVisible(true)}>
                    <Text style={styles.addButtonText}>{t("add_expense")}</Text>
                </TouchableOpacity>}

            </View>
            </SafeAreaView>
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    container: {
        height: "85vh",
        padding: 20,
        backgroundColor: "transparent",
    },
    row: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingVertical: 15,
        paddingHorizontal: 25,
        backgroundColor: "#fff1e4",
        borderRadius: 10,
        marginBottom: 15,
        borderBottomWidth: 1,
        borderColor: "#9e9e9e",
    },
    text: {
        fontSize: 18,
        color: "black",
    },
    title: {
        fontSize: 20,
        fontWeight:"bold",
        color: "black",
        paddingVertical: 5,
        marginBottom: 5,
    },
    deleteButton: {
        color: "red",
        fontWeight: "bold",
    },
    addButton: {
        padding: 15,
        borderRadius: 10,
        alignItems: "center",
        marginTop: 20,
        backgroundColor: "#f1b34b",
    },
    smallAddButton: {
        padding: 15,
        borderRadius: 5,
        alignItems: "center",
        color: "white",
        backgroundColor: "#f1b34b",
    },
    cancelButton:{
        padding: 15,
        borderRadius: 5,
        alignItems: "center",
        backgroundColor: "#ddd",
    },
    addButtonText: {
        color: "white",
        fontSize: 18,
        fontWeight: "bold",
    },
    modalContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    modalContent: {
        width: 400,
        padding: 25,
        backgroundColor: "white",
        borderRadius: 10,
        alignItems: "center",
    },
    input: {
        width: "100%",
        borderBottomWidth: 1,
        marginBottom: 15,
        padding: 10,
    },
});

export default ExpensesScreen;