import DeviceDefault from "./deviceDefault";
import OrdersDefault from "./ordersDefault";
import UserDefault from "./userDefault";

const appDefaultReducer = {
  user: UserDefault,
  device: DeviceDefault,
  orders: OrdersDefault,
};

export default appDefaultReducer;
