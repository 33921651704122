import * as React from 'react';
import { Button, Dialog, PaperProvider, Portal, Text } from 'react-native-paper';
import { Modal, Dimensions, StyleSheet } from "react-native";
import { useTranslation } from 'react-i18next';

const { width } = Dimensions.get('window');

const HappyAlert = ({visible, onConfirm, onCancel, type, message, confirmText, cancelText}) => {
    /*
    *   visible={boolean}
    *    onConfirm={callback}
    *    onCancel={callback}
    *    type={string} // alert, error, warning, info *optional*
    *    message={string}
    *    confirmText={confirmButtonText} // string *optional*
    *    cancelText={cancelButtonText}   // string *optional*
    */
const {t } = useTranslation();
    function getIcon() {
        switch (type) {
            case "alert":
                return "alert";
            case "error":
                return "alert-circle";
            case "warning":
                return "alert-decagram";
            case "info":
                return "information";
            default:
                return "information-outline";
        }
    }


    function getTitle(type) {
        switch (type) {
            case "alert":
                return t("alert");
            case "error":
                return t("error");
            case "warning":
                return t("warning");
            case "info":
                return t("info");
            default:
                return t("info");
        }
    }

    return (
        <>
            {visible && (
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={visible}
                    onRequestClose={() => onCancel()}
                >
                    <PaperProvider>
                        <Portal>
                            <Dialog visible={visible} onDismiss={onCancel} style={styles.dialog}>
                                <Dialog.Icon icon={getIcon()} />
                                <Dialog.Title>{getTitle()}</Dialog.Title>
                                <Dialog.Content>
                                    <Text variant="bodyMedium">{message || t("are_you_sure")}</Text>
                                </Dialog.Content>
                                <Dialog.Actions>
                                    <Button onPress={onCancel}>{cancelText || t("cancel")}</Button>
                                    <Button onPress={onConfirm}>{confirmText || t("confirm")}</Button>
                                </Dialog.Actions>
                            </Dialog>
                        </Portal>
                    </PaperProvider>
                </Modal>
            )}
        </>
    );
};

const styles = StyleSheet.create({
    dialog: {
        width: width * 0.45,
        alignSelf: 'center',
    },
});

export default HappyAlert;