import React, { useContext, useState } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, Dimensions, BackHandler } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import AsyncStorage from "@react-native-async-storage/async-storage";
import Observables from "@happy-gastro/global-observables";

import { BluetoothContext } from "../helpers/BluetoothContext";
import { OrdersContext } from "../helpers/OrdersContext";
import { LinearGradient } from "expo-linear-gradient";
import {useTranslation} from "react-i18next";
import {LoginScreen} from "./index";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import HappyAlert from "../helpers/crossPlatformTools/HappyAlert";

const { width, height } = Dimensions.get('window');

const DayOpeningScreen = ({ navigation }) => {
    const { orders } = useContext(OrdersContext);
    const { sendData } = useContext(BluetoothContext);
    const {t} = useTranslation();
    const [selectedTab, setSelectedTab] = useState('cash');
    const [cashAmount, setCashAmount] = useState('');
    const [currencyAmount, setCurrencyAmount] = useState('');
    const [creditCardAmount, setCreditCardAmount] = useState('');
    const [voucherAmount, setVoucherAmount] = useState('');
    const [otherAmount, setOtherAmount] = useState('');
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState('');

    const handleInput = (value) => {
        switch (selectedTab) {
            case 'cash':
                setCashAmount((prev) => prev + value);
                break;
            case 'creditCard':
                setCreditCardAmount((prev) => prev + value);
                break;
            case 'currency':
                setCurrencyAmount((prev) => prev + value);
                break;
            case 'voucher':
                setVoucherAmount((prev) => prev + value);
                break;
            case 'other':
                setOtherAmount((prev) => prev + value);
                break;
        }
    };

    const handleBackspace = () => {
        switch (selectedTab) {
            case 'cash':
                setCashAmount((prev) => prev.slice(0, -1));
                break;
            case 'currency':
                setCurrencyAmount((prev) => prev.slice(0, -1));
                break;
            case 'creditCard':
                setCreditCardAmount((prev) => prev.slice(0, -1));
                break;
            case 'voucher':
                setVoucherAmount((prev) => prev.slice(0, -1));
                break;
            case 'other':
                setOtherAmount((prev) => prev.slice(0, -1));
                break;
        }
    };

    const handleClear = () => {
        switch (selectedTab) {
            case 'cash':
                setCashAmount('');
                break;
            case 'currency':
                setCurrencyAmount('');
                break;
            case 'creditCard':
                setCreditCardAmount('');
                break;
            case 'voucher':
                setVoucherAmount('');
                break;
            case 'other':
                setOtherAmount('');
                break;
        }
    };

    const handleProcess = async () => {
        if (!cashAmount || cashAmount === "" || Number(cashAmount) === 0) {
            setConfirmMessage(t("confirm_no_cash"));
            setIsConfirmModalVisible(true);
        }else{
            await handleConfirm();
        }
    };

    const handleConfirm = async () => {
        setIsConfirmModalVisible(false);
        navigation.navigate('DayOpeningConfirmationScreen', {
            cashAmount,
            creditCardAmount,
            voucherAmount,
            otherAmount,
            handleConfirm: async () => {
                await AsyncStorage.setItem('dayOpened', 'true');
                await AsyncStorage.setItem('dayOpened_amount', JSON.stringify({ cash: cashAmount, currency: currencyAmount, creditCard: creditCardAmount, voucher: voucherAmount, other: otherAmount }));
                await AsyncStorage.setItem('dayOpened_time', new Date().toISOString());
                try {
                    sendData(`fiscat/AEE|OP|LOCA||${cashAmount.toString().replace(",",".")}`);
                } catch (fiscatDayOpenError) {
                    console.error("Fiscat day open error: ", fiscatDayOpenError);
                }
                Observables.call("reloadDayOpeningClosing");
                navigation.navigate('HomeScreen', {successCloseDay: false});
            }
        });

    };

    const getAmount = () => {
        switch (selectedTab) {
            case 'cash':
                return cashAmount
            case 'currency':
                return currencyAmount
            case 'creditCard':
                return creditCardAmount
            case 'voucher':
                return voucherAmount
            case 'other':
                return otherAmount
        }
    }

    return (
        <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={styles.background}
            start={{ x: 0.3, y: 0 }}
            end={{ x: 0.7, y: 1 }}
        >
            <View styles={styles.container}>
                {/* Tabs */}
                <View style={styles.tabsContainer}>
                    {[
                        { type: 'cash', label: t('cash'), amount: cashAmount, icon: 'cash-multiple' },
                        { type: 'currency', label: t('currency'), amount: currencyAmount, icon: 'currency-eur' },
                        { type: 'creditCard', label: t('credit_card'), amount: creditCardAmount, icon: 'credit-card' },
                        { type: 'voucher', label: t('voucher'), amount: voucherAmount, icon: 'ticket-percent-outline' },
                    ].map(({ type, label, amount, icon }) => (
                        <TouchableOpacity
                            key={type}
                            style={[styles.tab, selectedTab === type && styles.activeTab]}
                            onPress={() => setSelectedTab(type)}
                        >
                            <MaterialCommunityIcons name={icon} size={24} color={selectedTab === type ? '#fff' : '#666'} />
                            <Text style={[styles.tabText, selectedTab === type && styles.activeTabText]}>{label}</Text>
                            {(amount && Number(amount) !== 0) ? <View style={styles.badge} /> : null}
                        </TouchableOpacity>
                    ))}
                </View>

                <View style={styles.amountContainer}>
                    <TextInput
                        style={styles.amountInput}
                        value={getAmount()}
                        editable={false}
                        placeholder={t('cash_placeholder')}
                        placeholderTextColor="#999"
                    />
                </View>
                <View style={styles.pinpadContainer}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, '⌫', 0, 'X'].map((value, index) => (
                        <TouchableOpacity
                            key={index}
                            style={styles.pinpadButton}
                            onPress={() => (value === '⌫' ? handleBackspace() : value === 'X' ? handleClear() : handleInput(value.toString()))}
                        >
                            <Text style={styles.pinpadText}>{value}</Text>
                        </TouchableOpacity>
                    ))}
                </View>
                <View style={styles.buttonContainer}>
                    <TouchableOpacity style={styles.backButton} onPress={() => {
                        BackHandler.exitApp();
                    }}>
                        <Feather name="arrow-left" size={24} color="#fff" />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.processButton} onPress={() => handleProcess()}>
                        <Text style={styles.processButtonText}>{t('open_day')}</Text>
                    </TouchableOpacity>
                </View>
            </View>
            <HappyAlert
                visible={isConfirmModalVisible}
                onConfirm={handleConfirm}
                onCancel={() => setIsConfirmModalVisible(false)}
                type="warning"
                message={confirmMessage}
            />
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
    },
    background: {
        flex: 1,
        alignItems: 'center',
        paddingTop: 10,
        height: height,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        textAlign: 'center',
        marginVertical: 20,
    },
    tabsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        paddingRight: 10,
        paddingLeft: 10,
        backgroundColor: '#f1f1f1',
        marginBottom: 35,
    },
    tab: {
        flex: 1,
        padding: 12,
        paddingVertical: 16,
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
    },
    activeTab: {
        backgroundColor: '#f1b34b',
    },
    tabText: {
        fontSize: 12,
        color: '#666',
        marginTop: 4,
    },
    activeTabText: {
        color: '#fff',
    },
    badge: {
        position: 'absolute',
        top: 5,
        right: 5,
        width: 10,
        height: 10,
        borderRadius: 5,
        backgroundColor: '#6ec550',
    },
    amountContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 5,
        width: '100%',
    },
    amountInput: {
        fontSize: 32,
        textAlign: 'center',
        color: '#333',
        borderBottomWidth: 1,
        width: width * 0.8,
    },
    pinpadContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: width,
        paddingHorizontal: 0,
    },
    pinpadButton: {
        width: width / 3.4,
        height: height / 10,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 5,
        borderColor: '#e8e8e8',
        borderWidth: 0.5,
    },
    pinpadText: {
        fontSize: 28,
        color: '#333',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 16,
        marginTop: 20,
    },
    backButton: {
        backgroundColor: 'black',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 15,
        borderRadius: 5,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
    },
    processButton: {
        backgroundColor: '#f1b34b',
        alignItems: 'center',
        padding: 15,
        flex: 1,
        marginLeft: 0,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
    },
    processButtonText: {
        fontSize: 20,
        color: '#fff',
        fontWeight: 'bold',
    },
});

export default DayOpeningScreen;