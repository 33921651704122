import React from "react";
import { FlatList, StyleSheet, Text, TouchableOpacity, View, RefreshControl } from "react-native";
import TimeAgo from "react-native-timeago";
import { Surface } from "react-native-paper";
import IonIcons from "react-native-vector-icons/Ionicons";
import LottieView from "lottie-react-native";

const DailyBookingsList = ({ bookings, navigation, refreshing, onRefresh }) => {
    // Show empty state for the day if no bookings for this day
    if (bookings.length === 0) {
        return (
            <View style={styles.emptyContainer}>
                <LottieView
                    source={require('../../assets/animations/no-data-here.json')}
                    autoPlay
                    loop
                    style={styles.emptyAnimation}
                />
                <Text style={styles.emptyText}>Ezen a napon nincs foglalás.</Text>
            </View>
        );
    }

    return (
        <FlatList
            style={{ width: '100%' }}
            contentContainerStyle={{ width: '100%' }}
            data={bookings.map(booking => ({
                id: '#' + booking._id,
                fullName: booking?.guestInfo?.name,
                phone: booking?.guestInfo?.phone,
                guests: booking.guests,
                dateFrom: booking.dateFrom,
                dateTo: booking.dateTo,
                comment: booking.comment,
                status: booking.status,
                denyReason: booking.denyReason,
                bookingData: booking
            }))}
            renderItem={({ item }) => (
                <TouchableOpacity
                    style={[styles.row, { marginVertical: 10, width: '100%' }]}
                    onPress={() => navigation.navigate('BookingDetailsScreen', { bookingData: item.bookingData })}
                >
                    <Surface
                        style={[
                            styles.surface,
                            {backgroundColor: item.status === "accepted" ? "#cbfaa9" : item.status === "pending" ? "#f1b34b" : "#f6abb4"}
                        ]}
                        elevation={4}
                    >
                        <View style={{flex: 4, width: '100%', flexDirection: 'row', display: 'flex'}}>
                            <View style={{flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', display: 'flex'}}>
                                <Text style={{ fontWeight: "700", flex: 1 }}>
                                    <TimeAgo time={new Date(item.dateFrom)} interval={10} /> - <TimeAgo time={new Date(item.dateTo)} interval={10} />
                                </Text>
                                <Text style={{ flex: 1 }}>
                                    {new Date(item.dateFrom).toLocaleString("hu-HU")} - {new Date(item.dateTo).toLocaleString("hu-HU")}
                                </Text>
                            </View>
                            <View style={{ flex: 5, alignItems: 'center', justifyContent: 'center', }}>
                                <Text style={{ fontWeight: "700", flexDirection: 'column' }}>
                                    {item.fullName || "Nincs név megadva"}
                                </Text>
                                <Text style={{ fontWeight: "700", flexDirection: 'column' }}>
                                    {item.phone || "Nincs telefonszám megadva"}
                                </Text>
                            </View>
                            <View style={{flex: 3, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                                <Text style={{ flex: 1, textAlign: 'right' }}>Megjegyzés:</Text>
                                <Text style={{ fontWeight: "700", flex: 1, textAlign: 'right' }}>{item.comment || "Nincs megjegyzés"}</Text>
                            </View>
                            <View style={{flex: 3, flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center', display: 'flex'}}>
                                <Text style={{ flex: 1, textAlign: 'right' }}>Vendégek:</Text>
                                <Text style={{ fontWeight: "700", flex: 1, textAlign: 'right' }}>{item.guests}</Text>
                            </View>
                            <TouchableOpacity
                                style={{flex: 1, alignItems: 'flex-end', justifyContent: 'center', display: 'flex'}}
                                onPress={() => {
                                    if (item.status !== "accepted") {
                                        alert(item.denyReason || "Nincs megadva az elutasítás oka!");
                                    }
                                }}>
                                <IonIcons
                                    name={item.status === "accepted" ? "checkmark-sharp" : "ban"}
                                    size={24}
                                    color={item.status === "accepted" ? "green" : "red"}
                                />
                            </TouchableOpacity>
                        </View>
                    </Surface>
                </TouchableOpacity>
            )}
            keyExtractor={(item) => item.id}
            refreshControl={
                <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
        />
    );
};

const styles = StyleSheet.create({
    emptyContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
    },
    emptyAnimation: {
        width: 120,
        height: 120,
    },
    emptyText: {
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 10,
        color: '#666',
        textAlign: 'center',
    },
    surface: {
        padding: 8,
        height: 60,
        width: '97%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        flexDirection: 'row',
        marginHorizontal: 15,
        alignSelf: 'center',
        marginLeft: 15,
        marginRight: 15
    },
    row: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
});

export default DailyBookingsList;