import React, { useContext, useEffect, useState } from 'react';
import { Modal, View, Text, TextInput, TouchableOpacity, FlatList, StyleSheet } from 'react-native';
import { SystemContext } from '../helpers/SystemContext';
import { priceFormatter } from "../helpers";

const ExtrasModal = ({ visible, item, onClose, onSave }) => {
    const { foods } = useContext(SystemContext);
    const [selectedExtras, setSelectedExtras] = useState([]);
    const [filteredFoods, setFilteredFoods] = useState([]);

    useEffect(() => {
        if (item?.extras) {
            const matchingFoods = foods.filter(food => item.extras.includes(food.category));
            setFilteredFoods(matchingFoods);
            console.log(matchingFoods);
        }
    }, [item, foods]);

    const toggleExtra = (extraItem) => {
        const extra = {
            item: extraItem,
            price: extraItem.price || extraItem?.sizes[0]?.price
        };
        setSelectedExtras((prev) =>
            prev.some((extra) => extra.item.id === extraItem.id)
                ? prev.filter((extra) => extra.item.id !== extraItem.id)
                : [...prev, extra]
        );
    };

    return (
        <Modal visible={visible} animationType="slide" transparent>
            <View style={styles.overlay}>
                <View style={styles.container}>
                    <TouchableOpacity style={styles.closeButton} onPress={()=> {
                        onClose(false)
                        setSelectedExtras([]);
                    }}>
                        <Text style={styles.closeText}>CLOSE</Text>
                    </TouchableOpacity>
                    <Text style={styles.title}>Extras, Toppings, and Modifiers</Text>
                    <FlatList
                        data={filteredFoods}
                        keyExtractor={(item) => item.id}
                        renderItem={({ item }) => (
                            <TouchableOpacity
                                style={[
                                    styles.extraItem,
                                    selectedExtras.some((extra) => extra?.item?.id === item.id) && styles.selectedExtra
                                ]}
                                onPress={() => toggleExtra(item)}
                            >
                                <Text style={styles.extraText}>{item?.name?.hu || item?.name?.en || item?.name}</Text>
                                <Text style={styles.price}>{priceFormatter(item.price || item?.sizes[0]?.price)}</Text>
                            </TouchableOpacity>
                        )}
                    />
                    <TouchableOpacity style={styles.saveButton} onPress={() => {
                        onSave({...item, extras: selectedExtras})
                        setSelectedExtras([]);
                    }}>
                        <Text style={styles.saveText}>SAVE</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    overlay: {
        flex: 1,
        backgroundColor: 'rgba(0,0,0,0.5)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        backgroundColor: 'white',
        width: '90%',
        padding: 20,
        borderRadius: 10,
    },
    closeButton: {
        backgroundColor: 'red',
        padding: 10,
        alignItems: 'center',
        borderRadius: 5,
        marginBottom: 10,
    },
    closeText: {
        color: 'white',
        fontWeight: 'bold',
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    input: {
        borderWidth: 1,
        borderColor: '#ccc',
        padding: 10,
        marginBottom: 10,
        borderRadius: 5,
    },
    extraItem: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ddd',
    },
    selectedExtra: {
        backgroundColor: '#FFDDC1',
    },
    extraText: {
        flex: 1,
    },
    price: {
        fontWeight: 'bold',
    },
    saveButton: {
        backgroundColor: 'orange',
        padding: 10,
        alignItems: 'center',
        borderRadius: 5,
        marginTop: 10,
    },
    saveText: {
        color: 'white',
        fontWeight: 'bold',
    },
});

export default ExtrasModal;