import React, {useEffect, useState} from 'react';
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    Alert,
    ScrollView
} from 'react-native';
import { format, isToday  } from 'date-fns';
import { useNavigation } from '@react-navigation/native';
import {getAccessToken} from "../helpers/storage";
import NewBookingModal from "../helpers/NewBookingModal";
import DenyBookingModal from "../components/DenyBookingModal";
import {useTranslation} from "react-i18next";

const BookingDetailsScreen = ({ route }) => {
    const { bookingData } = route.params;
    const navigation = useNavigation();
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [bookingToModify, setBookingToModify] = useState(null);
    const [isDenyModalVisible, setIsDenyModalVisible] = useState(false);
    const { t } = useTranslation();

    const handleBookingSubmit = async (data) => {
        const token = await getAccessToken();
        try {
            const response = await fetch(`https://api.happygastro.hu/api/booking/${bookingData._id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            navigation.goBack()
            Alert.alert(t('booking_modified'), t('booking_modified_success'), [{ text: t('ok'), onPress: () => navigation.goBack() }]);
        } catch (error) {
            console.error("Booking modification failed:", error);
            Alert.alert(t('modification_failed'), t('modification_failed_message'));
        }
    };

    useEffect(() => {
        console.log(bookingData)
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedTime = format(date, 'h:mm a');

        if (isToday(date)) {
            return `${t('today')} - ${formattedTime}`;
        }

        return `${format(date, 'EEEE, MMMM do, yyyy')} - ${formattedTime}`;
    };

    const handleModifyBooking = () => {
        setBookingToModify(bookingData);
        setIsModalVisible(true);
    };

    const handleDeleteBooking = () => {
        setIsDenyModalVisible(true);
    };

    const handleConfirmDeny = async (reason) => {
        setIsDenyModalVisible(false);
        setIsLoading(true);
        const token = await getAccessToken();
        try {
            const response = await fetch(`https://api.happygastro.hu/api/booking/${bookingData._id}/deny/${reason}`, {
                method: "GET",
                headers: {
                    "authorization": `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            Alert.alert(
                t('booking_denied'),
                t('booking_denied_success'),
                [{ text: t('ok'), onPress: () => navigation.goBack() }]
            );
        } catch (error) {
            console.error("Booking denial failed:", error);
            Alert.alert(t('denial_failed'), t('denial_failed_message'));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <View style={styles.container}>

            <ScrollView contentContainerStyle={styles.scrollContainer}>
                <Text style={styles.title}>{t('booking_details')}</Text>

                {bookingData.status === 'cancelled' && (
                    <View style={styles.detailSection}>
                        <Text style={[styles.sectionTitle, { color: "red" }]}>{t('booking_cancelled')}</Text>
                        {bookingData.denyReason && <Text>{t('reason')}: {bookingData.denyReason}</Text>}
                    </View>
                )}

                {/* Booking Information Sections */}
                <View style={styles.detailSection}>
                    <Text style={styles.sectionTitle}>{t('guest_information')}</Text>
                    <Text>{t('name')}: {bookingData.guestInfo.name}</Text>
                    <Text>{t('email')}: {bookingData.guestInfo.email || t('email_not_provided')}</Text>
                    <Text>{t('phone')}: {bookingData.phone}</Text>
                </View>

                <View style={styles.detailSection}>
                    <Text style={styles.sectionTitle}>{t('booking_details_section')}</Text>
                    <Text>{t('date_from')}: {formatDate(bookingData.dateFrom)}</Text>
                    <Text>{t('date_to')}: {formatDate(bookingData.dateTo)}</Text>
                    <Text>{t('number_of_guests')}: {bookingData.guests}</Text>
                    <Text>{t('status')}: {bookingData.status}</Text>
                </View>

                <View style={styles.detailSection}>
                    <Text style={styles.sectionTitle}>{t('venue_information')}</Text>
                    <Text>{t('restaurant')}: {bookingData.restaurant.name || t('unknown')}</Text>
                    <Text>{t('table')}: {bookingData.table}</Text>
                </View>

                {bookingData.comment && (
                    <View style={styles.detailSection}>
                        <Text style={styles.sectionTitle}>{t('additional_comments')}</Text>
                        <Text>{bookingData.comment}</Text>
                    </View>
                )}

                {/* Action Buttons */}
                <View style={styles.actionButtonContainer}>
                    <TouchableOpacity
                        style={styles.modifyButton}
                        onPress={handleModifyBooking}
                    >
                        <Text style={styles.buttonText}>{t('modify_booking')}</Text>
                    </TouchableOpacity>

                    {bookingData.status !== "cancelled" && <TouchableOpacity
                        style={styles.deleteButton}
                        onPress={handleDeleteBooking}
                        disabled={isLoading}
                    >
                        <Text style={styles.buttonText}>
                            {isLoading ? t('deleting') : t('delete_booking')}
                        </Text>
                    </TouchableOpacity>}
                </View>
            </ScrollView>
            <NewBookingModal
                visible={isModalVisible}
                onClose={() => setIsModalVisible(false)}
                onSubmit={handleBookingSubmit}
                initialData={bookingData}
            />
            <DenyBookingModal
                visible={isDenyModalVisible}
                onClose={() => setIsDenyModalVisible(false)}
                onConfirm={handleConfirmDeny}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f2e2d2',
    },
    scrollContainer: {
        padding: 20,
    },
    backButton: {
        padding: 15,
        backgroundColor: '#f0f0f0',
    },
    backButtonText: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: 'center',
    },
    detailSection: {
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 15,
        marginBottom: 15,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 3,
    },
    sectionTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
        color: '#333',
    },
    actionButtonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    modifyButton: {
        backgroundColor: '#4CAF50',
        padding: 15,
        borderRadius: 10,
        flex: 0.48,
        alignItems: 'center',
    },
    cancelledText: {
        color: 'red',
        fontWeight: 'bold',
         textAlign: "center",
        fontSize: 16,
        marginTop: 10,
},
    deleteButton: {
        backgroundColor: '#F44336',
        padding: 15,
        borderRadius: 10,
        flex: 0.48,
        alignItems: 'center',
    },
    buttonText: {
        color: 'white',
        fontWeight: 'bold',
    },
});

export default BookingDetailsScreen;