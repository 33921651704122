import React, { useState } from 'react';
import { View, Text, Button, StyleSheet } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import Modal from 'react-native-modal';
import LottieView from 'lottie-react-native';

const OrderRejectModal = ({ isVisible, onClose, onConfirm }) => {
    const [reason, setReason] = useState('');

    return (
        <Modal isVisible={isVisible} onBackdropPress={onClose} style={styles.modal}>
            <View style={styles.modalContent}>
                <View style={{width: '100%'}}>
                    <LottieView
                        source={require('../../assets/animations/rejectOrderAnimation.json')}
                        autoPlay
                        loop
                        style={styles.animation}
                    />
                </View>
                <Text style={styles.title}>Válassza ki az elutasítás okát:</Text>
                <Picker
                    selectedValue={reason}
                    style={styles.picker}
                    onValueChange={(itemValue) => {
                        setReason(itemValue);
                    }}
                >
                    <Picker.Item label="-- Válassz indoklást --" value="" enabled={false}/>
                    <Picker.Item label="Egyéb ok" value="Other" />
                    <Picker.Item label="A felhasználó törölte" value="Cancelled by user" />
                    <Picker.Item label="A rendelés időkorlátja lejárt" value="Order timed out" />
                    <Picker.Item label="Hozzávalók kifogytak" value="Out of ingredients" />
                    <Picker.Item label="Túlterhelt konyha" value="Kitchen overloaded" />
                    <Picker.Item label="Nem megfelelő szállítási cím" value="Invalid delivery address" />
                    <Picker.Item label="Rendelés a nyitvatartási időn kívül" value="Outside operating hours" />
                    <Picker.Item label="Fizetési probléma" value="Payment issue" />
                    <Picker.Item label="Hibás rendelési adatok" value="Incorrect order details" />
                    <Picker.Item label="Az étel nem szerepel az aktuális étlapon" value="Item not available on menu" />
                    <Picker.Item label="Kiszállítási kapacitáshiány" value="Delivery capacity exceeded" />
                    <Picker.Item label="A rendelés sérülése vagy elvesztése" value="Order damaged or lost" />
                    <Picker.Item label="Az étel minősége nem megfelelő" value="Food quality issue" />
                </Picker>
                <View style={styles.buttonContainer}>
                    <Button title="Mégsem" style={styles.cancelButton} color={"#dec49b"} onPress={onClose} />
                    <Button title="Elutasítás" enabled={!!reason} disabled={!reason} style={styles.confirmButton} color={'#F76E24'} onPress={() => {
                        if(reason) {
                            onConfirm(reason);
                        }
                    }} />
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modal: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        alignItems: 'center',
        width: 400,
        textAlign: 'center'
    },
    animation: {
        width: 100,
        height: 100,
        marginBottom: 20,
        textAlign: 'center'
    },
    title: {
        fontSize: 18,
        marginBottom: 10,
        fontWeight: 'bold',
    },
    picker: {
        width: 300,
        height: 40,
        marginBottom: 20,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 20,
    },
    cancelButton: {
        backgroundColor: '#dec49b',
        padding: 10,
    },
    confirmButton: {
        backgroundColor: '#F76E24',
        padding: 10,
    },
});

export default OrderRejectModal;