import React, { useEffect, useState } from 'react';
import Map, { Marker, Source, Layer } from 'react-map-gl';
import { useContext } from 'react';
import { SystemContext } from '../helpers/SystemContext';
import { OrdersContext } from '../helpers/OrdersContext';
import {Text, View} from "react-native";
import RouteDetailsSidebar from './RouteDetailsSidebar';
import OrderListScreen from "../screens/OrderListScreen"; // Adjust path as needed
import HomeMarkerImage from "../../assets/markers/marker-home.png";
import PreorderMarkerChecked from "../../assets/markers/marker-preorder-checked.png";
import PreorderMarker from "../../assets/markers/marker-preorder.png";
import PendingMarkerChecked from "../../assets/markers/marker-pending-checked.png";
import BusyMarkerChecked from "../../assets/markers/marker-busy-checked.png";
import BusyMarker from "../../assets/markers/marker-busy.png";
import PendingMarker from "../../assets/markers/marker-pending.png";

const MAPBOX_TOKEN = 'pk.eyJ1IjoiZ29vZG8iLCJhIjoiY2t2eHg5cWhuNDM3YjJwa2xxejVubnhhcCJ9.py506Q9y-4k30wIs6xh2Ew';

const DeliveryMap = ({ selectedOrders ,onMarkerClick, statistics} ) => {
    const { restaurantPlace } = useContext(SystemContext);
    const { orders } = useContext(OrdersContext);
    const [route, setRoute] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [routeDetails, setRouteDetails] = useState([]);
    const [routeLegs, setRouteLegs] = useState([]);
    const [orderList, setOrderList] = useState([]);

    useEffect(() => {
        console.log("statistics", statistics)
        if (restaurantPlace && selectedOrders.length > 0) {
            setOrderList(statistics.onlineOrders.filter(oo => selectedOrders.includes(oo._id)))
            calculateAndDisplayRoute(restaurantPlace, statistics.onlineOrders.filter(oo => selectedOrders.includes(oo._id)));
        }else {
            setRoute(null);
        }
    }, [restaurantPlace, selectedOrders]);

    const getIcon = (orderData) => {
        // Check if the order is in the selected orders
        console.log("selectedOrders", selectedOrders)
        console.log(orderData);
        const isInSelectedOrders = selectedOrders?.some(order => order === orderData._id);
        console.log("isInSelectedOrders", isInSelectedOrders)
        // Preorder
        if (isInSelectedOrders && orderData.preOrder && orderData.status !== "busy") {
            return PreorderMarkerChecked;
        } else if (orderData.preOrder && orderData.status !== "busy") {
            return PreorderMarker;
        }

        // Default markers (preparing)
        if (isInSelectedOrders && orderData.status === "preparing") {
            return PendingMarkerChecked;
        } else if (orderData.status === "preparing") {
            return PendingMarker;
        }

        // Busy (in delivery)
        if (isInSelectedOrders && orderData.status === "busy") {
            console.log("asd")
            return BusyMarkerChecked;
        } else if (orderData.status === "busy") {
            console.log("asd2")
            return BusyMarker;
        }

        // Ready
        if (isInSelectedOrders && orderData.status === "ready") {
            return PendingMarkerChecked;
        } else if (orderData.status === "ready") {
            return PendingMarker;
        }
        return PendingMarker;
    };

    const calculateAndDisplayRoute = (restaurantPlace, ordersToCalculate) => {
        const [restaurantLng, restaurantLat] = restaurantPlace.location.coordinates;

        const waypoints = ordersToCalculate.map(order => {
            const coords = order.addressInfo.coordinates || order.addressInfo.coordinates_raw;
            return `${coords[1]},${coords[0]}`;
        });

        const start = `${restaurantLat},${restaurantLng}`;
        const coordinates = [start, ...waypoints, start].join(';'); // Added the restaurant as the endpoint

        const url = `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${coordinates}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=${MAPBOX_TOKEN}`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.routes.length > 0) {
                    const route = data.routes[0].geometry;
                    const legs = data.routes[0].legs; // Contains distance & duration per step
                    setRoute(route);
                    setRouteDetails(data.routes[0]);
                    console.log("legs", legs)
                    setRouteLegs(legs);

                    // Extract total distance & duration
                    const totalDistance = (data.routes[0].distance / 1000).toFixed(2); // Convert to km
                    const totalDuration = (data.routes[0].duration / 60).toFixed(2); // Convert to minutes

                    console.log(`Total Distance: ${totalDistance} km`);
                    console.log(`Total Duration: ${totalDuration} mins`);

                    // Extract distances & durations between stops
                    data.routes[0].legs.forEach((leg, index) => {
                        const legDistance = (leg.distance / 1000).toFixed(2); // Convert to km
                        const legDuration = (leg.duration / 60).toFixed(2); // Convert to minutes
                        console.log(`Leg ${index + 1}: Distance = ${legDistance} km, Duration = ${legDuration} mins`);
                    });
                }
            })
            .catch(error => console.error('Error fetching directions:', error));
    };


    return (
        <View style={{ flex: 1, flexDirection: 'row' }}>

        <Map
            mapboxAccessToken={MAPBOX_TOKEN}
            initialViewState={{
                latitude: 47.5025613999349,
                longitude: 19.05690176184082,
                zoom: 10,
            }}
            style={{ width: '100%', height: '100%' , zIndex: 20}}
            mapStyle="mapbox://styles/mapbox/streets-v11"
        >
            { (routeLegs?.length > 0 && selectedOrders?.length > 0) &&
                <View style={{ flex: 0.3, flexDirection: "row", alignItems: 'center' , marginTop: "20vh", width: "fit-content"}}>
                    <RouteDetailsSidebar
                        isOpen={isSidebarOpen}
                        startRestaurant={restaurantPlace}
                        toggleSidebar={() => setIsSidebarOpen(prev => !prev)}
                        routeDetails={routeDetails}
                        legs={routeLegs}
                        selectedOrders={orderList}
                        style={{
                            width: 350, // Adjust width
                            maxHeight: '80%', // Limits height, allowing map visibility
                            backgroundColor: 'white',
                            borderRadius: 10,
                            elevation: 5,
                            zIndex: 25,
                            alignSelf: 'center' // Centers it vertically
                        }}
                        restaurantAddress={restaurantPlace?.address}
                    />
                </View>
            }
            {restaurantPlace && (
                <Marker
                    longitude={restaurantPlace.location.coordinates[1]}
                    latitude={restaurantPlace.location.coordinates[0]}
                    anchor="bottom"
                >
                    <img src={HomeMarkerImage} alt="Restaurant"/>
                </Marker>
            )}
            {statistics.onlineOrders && statistics.onlineOrders
                .filter((o) => o.status !== "closed" && !o.storno && o.orderType === "online")
                .filter((o) => o.restaurantPlace === restaurantPlace?._id)
                .map((order, index) => {
                    //console.log(order)
                    if(order.addressInfo?.coordinates_raw){
                        return (
                            <Marker
                                key={index}
                                longitude={order.addressInfo?.coordinates_raw.lng}
                                latitude={order.addressInfo?.coordinates_raw.lat}
                                anchor="bottom"
                                onClick={()=> onMarkerClick(order._id)}
                            >
                                    <img src={getIcon(order)} alt="order"/>
                            </Marker>
                        )
                    }else if(order.addressInfo?.coordinates?.length > 0){
                        console.log("order", order)
                        return (
                            <Marker
                                key={index}
                                longitude={order.addressInfo?.coordinates[1]}
                                latitude={order.addressInfo?.coordinates[0]}
                                anchor="bottom"
                            >
                                <img src={getIcon(order)} alt="order"/>
                            </Marker>
                        )
                    }

                })}

            {route && selectedOrders && (
                <Source id="route" type="geojson" data={{ type: 'Feature', geometry: route }}>
                    <Layer
                        id="route"
                        type="line"
                        source="route"
                        layout={{ 'line-join': 'round', 'line-cap': 'round' }}
                        paint={{ 'line-color': '#1db7dd', 'line-width': 8 }}
                    />
                </Source>
            )}
        </Map>
        </View>
    );
};

export default DeliveryMap;