import React, { useEffect, useState } from 'react';
import { Modal, View, Text, TextInput, Button, TouchableOpacity, StyleSheet } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { SystemContext } from "./SystemContext";
import DateTimePickerButton from "../components/DateTimePickerButton";
import { useTranslation } from "react-i18next";

const NewBookingModal = ({ visible, onClose, onSubmit, initialData }) => {
    const [firstName, setFirstName] = useState(initialData?.guestInfo?.name.split(' ')[0] || '');
    const [lastName, setLastName] = useState(initialData?.guestInfo?.name.split(' ')[1] || '');
    const [phone, setPhone] = useState(initialData?.guestInfo?.phone || '+');
    const [guestCount, setGuestCount] = useState(initialData?.guests?.toString() || '1');
    const [date, setDate] = useState(new Date(initialData?.dateFrom) || null);
    const [duration, setDuration] = useState(initialData ? ((new Date(initialData.dateTo) - new Date(initialData.dateFrom)) / (3600 * 1000)).toString() : '2');
    const [notes, setNotes] = useState(initialData?.comment || '');
    const { restaurantPlace, restaurant } = React.useContext(SystemContext);
    const { t } = useTranslation();

    const resetFields = () => {
        setFirstName('');
        setLastName('');
        setPhone('+');
        setGuestCount('1');
        setDate(null);
        setDuration('2');
        setNotes('');
    }

    useEffect(()=>{
        console.log("duration",duration)
    },[duration])
    const handlePhoneChange = (text) => {
        const formattedText = text.replace(/[^0-9+]/g, '').slice(0, 17);
        if (formattedText.startsWith('+')) {
            setPhone(formattedText);
        } else {
            setPhone('+' + formattedText);
        }
    };

    const handleGuestCountChange = (text) => {
        const formattedText = text.replace(/[^0-9]/g, '').slice(0, 4);
        setGuestCount(formattedText);
    };

    const handleSubmit = () => {
        const name = `${firstName} ${lastName}`;
        const dateFrom = date;

        const guestInfo = { name: `${firstName} ${lastName}`, email: "", phone, saidTime: dateFrom || "" };
        const gustInfo = { name: `${firstName} ${lastName}`, email: "", phone, saidTime: dateFrom || "" };
        const bookingData = { status: "accepted", name, phone, guests: guestCount, dateFrom, bookingLength: duration, comment: notes, placeId: restaurantPlace?._id, fullName: `${firstName} ${lastName}`, guestInfo, gustInfo };
        console.log(bookingData);
        onSubmit(bookingData);
        resetFields();
        onClose();
    };

    return (
        <Modal visible={visible} animationType="slide" transparent={true}>
            <View style={styles.modalContainer}>
                <View style={styles.modalContent}>
                    <Text style={styles.title}>{t('new_booking_modal_title')}</Text>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flex: 1, marginRight: 5 }}>
                            <Text style={styles.label}>{t('guest_last_name')}</Text>
                            <TextInput
                                style={styles.input}
                                placeholder={t('guest_last_name')}
                                value={lastName}
                                onChangeText={setLastName}
                            />
                        </View>
                        <View style={{ flex: 1, marginLeft: 5 }}>
                            <Text style={styles.label}>{t('guest_first_name')}</Text>
                            <TextInput
                                style={styles.input}
                                placeholder={t('guest_first_name')}
                                value={firstName}
                                onChangeText={setFirstName}
                            />
                        </View>
                    </View>

                    <Text style={styles.label}>{t('phone_number')}</Text>
                    <TextInput
                        style={styles.input}
                        placeholder={t('phone_number')}
                        keyboardType="phone-pad"
                        value={phone}
                        onChangeText={handlePhoneChange}
                    />

                    <Text style={styles.label}>{t('guest_count')}</Text>
                    <TextInput
                        style={styles.input}
                        placeholder={t('guest_count')}
                        keyboardType="numeric"
                        value={guestCount}
                        onChangeText={handleGuestCountChange}
                    />

                    <Text style={styles.label}>{t('booking_duration')}</Text>
                    <View style={styles.pickerContainer}>
                        <Picker
                            selectedValue={duration}
                            onValueChange={(itemValue) => setDuration(itemValue)}>
                            <Picker.Item label="30 perc" value="0.5" />
                            <Picker.Item label="1 óra" value="1" />
                            <Picker.Item label="2 óra" value="2" />
                            <Picker.Item label="3 óra" value="3" />
                            <Picker.Item label="4 óra" value="4" />
                            <Picker.Item label="5 óra" value="5" />
                            <Picker.Item label="6 óra" value="6" />
                            <Picker.Item label="7 óra" value="7" />
                            <Picker.Item label="8 óra" value="8" />
                        </Picker>
                    </View>

                    <Text style={styles.label}>{t('booking_notes')}</Text>
                    <TextInput
                        style={styles.input}
                        placeholder={t('booking_notes')}
                        value={notes}
                        onChangeText={setNotes}
                    />
                    <View style={{ marginBottom: 15 }}>
                        <DateTimePickerButton buttonText={t('booking_date')} onDateChange={setDate} isTime={true} isHistory={false} />
                    </View>

                    <View style={styles.buttonRow}>
                        <View style={{ flex: 1, marginRight: 5 }}>
                            <Button title={t('cancel')} onPress={onClose} color={'#000'} />
                        </View>
                        <View style={{ flex: 1, marginLeft: 5 }}>
                            <Button title={t('save_booking')} color={"#ff8125"} onPress={handleSubmit} />
                        </View>
                    </View>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        width: 420,
        elevation: 5,
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: 'center',
    },
    input: {
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        padding: 10,
        marginBottom: 15,
    },
    datePickerButton: {
        padding: 10,
        backgroundColor: '#f0f0f0',
        borderRadius: 5,
        marginBottom: 15,
        alignItems: 'center',
    },
    datePickerText: {
        color: '#333',
    },
    pickerContainer: {
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        marginBottom: 15,
    },
    buttonRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});

export default NewBookingModal;