import React, { createContext, useEffect, useState } from 'react';

export const OnlineOrderContext = createContext();

const OnlineOrderProvider = ({ children }) => {
    const [orderType, setOrderType] = useState('');
    const [orderData, setOrderData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        note: '',
        kitchenNote: '',
        dateTime: new Date(),
        orderType: orderType
    });

    useEffect(() => {
    }, [orderData, orderType]);

    const updateOrderData = (data, type) => {
        const convertedType = type?.length > 0 ? type.toLowerCase() === 'online' ? 'online' : 'takeAway': '';
        setOrderData({...data, orderType:convertedType});
        setOrderType(type);
    };

    const resetOnlineOrder = () => {
        setOrderType('');
        setOrderData({
            firstName: '',
            lastName: '',
            phoneNumber: '',
            note: '',
            kitchenNote: '',
            dateTime: new Date(),
            orderType: '',
        });
    };

    return (
        <OnlineOrderContext.Provider value={{ orderData, orderType, updateOrderData, resetOnlineOrder, setOrderData }}>
            {children}
        </OnlineOrderContext.Provider>
    );
};

export default OnlineOrderProvider;