import React from "react";
import { View, Text, Modal, ScrollView, TouchableOpacity } from "react-native";
import { t } from "i18next";

const PaymentStatistics = ({  orders, priceFormatter, }) => {
    const paymentMethods = [
        { key: "cashOrders", label: t("cash") },
        { key: "npOrders", label: "Netpincér" },
        { key: "npCash", label: `- ${t("cash")}` },
        { key: "npCard", label: `- ${t("credit_card")}` },
        { key: "orditCard", label: `Ordit ${t("credit_card")}` },
        { key: "woltCard", label: `Wolt ${t("credit_card")}` },
        { key: "boltCard", label: `Bolt ${t("credit_card")}` },
        { key: "phoneOrders", label: t("phone") },
        { key: "phoneCardOrders", label: `- ${t("credit_card")}` },
        { key: "phoneCashOrders", label: `- ${t("cash")}` },
        { key: "takeAwayOrders", label: t("takeAway") },
        { key: "takeAwayOrdersCreditCard", label: `- ${t("credit_card")}` },
        { key: "takeAwayOrdersCash", label: `- ${t("cash")}` },
        { key: "onlineOrders", label: "Online" },
        { key: "onlineOrdersCreditCard", label: `- ${t("credit_card")}` },
        { key: "onlineOrdersCash", label: `- ${t("cash")}` },
        { key: "temporaryOrders", label: t("temporary") },
        { key: "vipOrders", label: t("vip_balance") },
    ];

    return (
                <View style={{ width: "100%", backgroundColor: "#fff", borderRadius: 10, padding: 20 }}>
                        {paymentMethods.map(({ key, label }) => {
                            const orderList = key === "temporaryOrders"
                                ? orders.all.filter(o => o.paymentType === 'temporary')
                                : orders[key] || [];
                            const totalOrders = orderList.length;
                            const totalSum = orderList.reduce((sum, order) => sum + (order.orderSum || 0), 0);


                            return (
                                <View key={key} style={{ flexDirection: "row", justifyContent: "space-between", paddingVertical: 5 }}>
                                    <Text style={{ color: "#000" }}>{label}</Text>
                                    <Text style={{ fontWeight: "bold", color: "#000" }}>{totalOrders} {t("orders")} / {priceFormatter(totalSum)}</Text>
                                </View>
                            );
                        })}
                </View>
    );
};

export default PaymentStatistics;
