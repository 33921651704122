import React, { useState } from "react";
import { View, Text, TextInput, TouchableOpacity, StyleSheet } from "react-native";
import Modal from "react-native-modal";
import { Picker } from "@react-native-picker/picker";

const CharsetModal = ({ isVisible, onClose, onSave }) => {
    const charsetOptions = [
        { label: "Nyugati és közép-európai kódolások", value: null, section: true },
        { label: "CP437 – USA, standard Európai karakterek", value: "CP437" },
        { label: "CP850 – Nyugat-Európa", value: "CP850" },
        { label: "CP852 – Közép-Európa (magyar is!)", value: "CP852" },
        { label: "CP858 – Nyugat-Európa, módosított CP850 az € jellel", value: "CP858" },
        { label: "CP1252 – Nyugat-Európa (Windows Latin 1)", value: "CP1252" },
        { label: "ISO 8859-1 – Nyugat-Európa (Latin-1)", value: "ISO 8859-1" },
        { label: "ISO 8859-2 – Közép- és Kelet-Európa (Latin-2, magyar is!)", value: "ISO 8859-2" },
        { label: "Kelet-európai, orosz és balkáni kódolások", value: null, section: true },
        { label: "CP855 – Cirill (orosz, bolgár, szerb)", value: "CP855" },
        { label: "CP866 – Orosz DOS", value: "CP866" },
        { label: "CP1251 – Orosz (Windows Latin-1)", value: "CP1251" },
        { label: "ISO 8859-5 – Latin/Cirill", value: "ISO 8859-5" },
        { label: "KOI8-R – Orosz", value: "KOI8-R" },
        { label: "Görög és török kódolások", value: null, section: true },
        { label: "CP737 – Görög", value: "CP737" },
        { label: "CP869 – Görög DOS", value: "CP869" },
        { label: "CP1253 – Görög (Windows Latin-1)", value: "CP1253" },
        { label: "ISO 8859-7 – Görög", value: "ISO 8859-7" },
        { label: "CP857 – Török", value: "CP857" },
        { label: "CP1254 – Török (Windows Latin-1)", value: "CP1254" },
        { label: "ISO 8859-9 – Török", value: "ISO 8859-9" },
        { label: "Balti és skandináv kódolások", value: null, section: true },
        { label: "CP775 – Balti nyelvek", value: "CP775" },
        { label: "CP1257 – Balti nyelvek (Windows Latin-1)", value: "CP1257" },
        { label: "ISO 8859-4 – Balti nyelvek", value: "ISO 8859-4" },
        { label: "ISO 8859-10 – Skandináv nyelvek", value: "ISO 8859-10" },
        { label: "Ázsiai nyelvek és speciális kódolások", value: null, section: true },
        { label: "CP932 – Japán (Shift-JIS)", value: "CP932" },
        { label: "CP936 – Kínai (GB2312, Simplified Chinese)", value: "CP936" },
        { label: "CP949 – Koreai (Hangul)", value: "CP949" },
        { label: "CP950 – Kínai (Big5, Traditional Chinese)", value: "CP950" },
        { label: "GB18030 – Modern kínai karakterkészlet", value: "GB18030" },
        { label: "TIS-620 – Thai", value: "TIS-620" },
        { label: "VSCII – Vietnámi", value: "VSCII" }

    ];

    const [charset, setCharset] = useState("");
    const [encodingId, setEncodingId] = useState("");
    const [paperSize, setPaperSize] = useState("6");
    const [characterCount, setCharacterCount] = useState("42");

    return (
        <Modal isVisible={isVisible} onBackdropPress={onClose}>
            <View style={styles.modalContainer}>
                <Text style={styles.label}>Válassz karakterkódolást:</Text>
                <View style={styles.pickerContainer}>
                    <Picker
                        selectedValue={charset}
                        onValueChange={(itemValue) => setCharset(itemValue)}
                        style={styles.picker}
                    >
                        {charsetOptions.map((option, index) => (
                            option.section ? (
                                <Picker.Item key={index} label={option.label} value={null} enabled={false} />
                            ) : (
                                <Picker.Item key={index} label={option.label} value={option.value} />
                            )
                        ))}
                    </Picker>
                </View>

                <Text style={styles.label}>Encoding ID:</Text>
                <TextInput
                    style={styles.input}
                    keyboardType="numeric"
                    value={encodingId}
                    onChangeText={setEncodingId}
                />

                <Text style={styles.label}>Papírméret (cm): {paperSize}</Text>
                <View style={styles.toggleContainer}>
                    <TouchableOpacity
                        style={[styles.toggleButton, paperSize === "6" && styles.activeButton]}
                        onPress={() => setPaperSize("6")}
                    >
                        <Text style={styles.toggleText}>6 cm</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={[styles.toggleButton, paperSize === "8" && styles.activeButton]}
                        onPress={() => setPaperSize("8")}
                    >
                        <Text style={styles.toggleText}>8 cm</Text>
                    </TouchableOpacity>
                </View>

                <Text style={styles.label}>Karakterek száma:</Text>
                <TextInput
                    style={styles.input}
                    keyboardType="numeric"
                    value={characterCount}
                    onChangeText={setCharacterCount}
                />

                <View style={styles.buttonContainer}>
                    <TouchableOpacity onPress={onClose} style={[styles.button, {backgroundColor: "#ccc"}]}><Text>Close</Text></TouchableOpacity>
                    <TouchableOpacity onPress={() => onSave({ charset, encodingId, paperSize, characterCount })} style={[styles.button, {backgroundColor: "#f1b34b"}]}><Text>Save</Text></TouchableOpacity>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalContainer: { backgroundColor: "white", padding: 20, borderRadius: 10 },
    label: { marginBottom: 5, fontWeight: "bold" },
    input: { borderWidth: 1, padding: 5, marginBottom: 10, borderRadius: 5 },
    pickerContainer: { borderWidth: 1, borderRadius: 5, marginBottom: 10 },
    picker: { height: 50, width: "100%" },
    toggleContainer: { flexDirection: "row", justifyContent: "space-between", marginBottom: 10 },
    toggleButton: { flex: 1, padding: 10, borderRadius: 5, alignItems: "center", backgroundColor: "#ddd", marginHorizontal: 5 },
    activeButton: { backgroundColor: "#f1b34b" },
    toggleText: { fontWeight: "bold" },
    buttonContainer: { flexDirection: "row", justifyContent: "space-between" },
    button: { padding: 10, borderRadius: 5 }
});

export default CharsetModal;
