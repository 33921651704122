import React, {useContext, useState} from 'react';
import {
    SafeAreaView,
    ScrollView,
    StatusBar,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Modal,
    Button,
    Alert,
    Platform, Switch, TextInput
} from 'react-native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { LinearGradient } from "expo-linear-gradient";
import {SystemContext} from "../helpers/SystemContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {useTranslation} from "react-i18next";

export default function OtherSettingsScreen({ navigation }) {
    const { defaultView, setEventColumnCount, setCategoryColumnCount } = useContext(SystemContext);

    const {t} = useTranslation();

    const [automaticAcceptOrders, setAutomaticAcceptOrders] = useState(false);

    React.useEffect(() => {
        const _getState = async () => {
            return await AsyncStorage.getItem('automaticAcceptOrders') === "true" || false;
        }
        _getState().then(setAutomaticAcceptOrders);
    }, []);

    const handleChange = (value) => {
        setAutomaticAcceptOrders(value);
        AsyncStorage.setItem('automaticAcceptOrders', value.toString());
    }

    return (
            <SafeAreaView style={{ flex: 1, backgroundColor: 'transparent', paddingTop: 10 }}>
                <StatusBar backgroundColor="transparent" translucent barStyle="dark-content" />
                <ScrollView contentContainerStyle={styles.content}>
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Kényelmi funkciók</Text>
                        <View style={styles.sectionBody}>
                            <View style={styles.row}>
                                <Text style={styles.rowLabel}>{t('automatic_accept_orders')}</Text>

                                <View style={styles.rowSpacer}/>

                                <Switch
                                    onValueChange={handleChange}
                                    style={{transform: [{scaleX: 0.95}, {scaleY: 0.95}]}}
                                    trackColor={{false: '#767577', true: '#f3c498'}}
                                    thumbColor={'#fca34c'}
                                    ios_backgroundColor="#fca34c"
                                    value={automaticAcceptOrders}/>
                            </View>
                        </View>
                    </View>
                </ScrollView>
            </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    content: {
        paddingHorizontal: 16,
        backgroundColor: 'transparent'
    },
    section: {
        paddingVertical: 12,
    },
    sectionTitle: {
        margin: 8,
        marginLeft: 12,
        fontSize: 13,
        letterSpacing: 0.33,
        fontWeight: '500',
        color: '#a69f9f',
        textTransform: 'uppercase',
    },
    sectionBody: {
        borderRadius: 12,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.5,
        shadowRadius: 1.41,
        elevation: 3,
    },
    row: {
        height: 44,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingRight: 12,
        paddingLeft: 16,
        backgroundColor: '#fff',
        borderTopWidth: 1,
        borderColor: '#f0f0f0',
    },
    rowLabel: {
        fontSize: 16,
        letterSpacing: 0.24,
        color: '#000',
    },
    rowSpacer: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        width: '80%',
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 20,
        alignItems: 'center',
    },
    modalTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    modalButton: {
        backgroundColor: '#2196F3',
        borderRadius: 5,
        padding: 10,
        marginVertical: 5,
        width: '100%',
        alignItems: 'center',
    },
    modalButtonText: {
        color: 'white',
        fontWeight: 'bold',
    },
    closeButton: {
        marginTop: 20,
        backgroundColor: '#f44336',
        borderRadius: 5,
        padding: 10,
        width: '100%',
        alignItems: 'center',
    },
    closeButtonText: {
        color: 'white',
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
        padding: 10,
        borderColor: '#ccc',
        borderWidth: 1,
        borderRadius: 5,
        marginBottom: 10,
    },
});