import React, { useContext, useEffect, useState } from "react";
import { FlatList, StyleSheet, Text, TouchableOpacity, View, RefreshControl } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { SystemContext } from "../helpers/SystemContext";
import TimeAgo from "react-native-timeago";
import { Surface } from "react-native-paper";
import LottieView from "lottie-react-native";
import IonIcons from "react-native-vector-icons/Ionicons";
import { getAccessToken } from "../helpers/storage";
import DailyBookingsList from "./DailyBookingsList";
import MonthlyCalendarView from "./MonthlyCalendarView";

export default function TablesBookingsScreen({ navigation, route }) {
    const { bookings } = useContext(SystemContext);
    const [refreshing, setRefreshing] = useState(false);
    const [viewMode, setViewMode] = useState("daily"); // "daily" or "monthly"
    const [selectedDate, setSelectedDate] = useState(new Date());

    const fetchBookings = async () => {
        const token = await getAccessToken();

        try {
            const response = await fetch("https://api.happygastro.hu/api/booking/restaurant", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
        } catch (error) {
            console.error("Booking request failed:", error);
        }
    };

    useEffect(() => {
        fetchBookings();
    }, []);

    const onRefresh = async () => {
        setRefreshing(true);
        await fetchBookings();
        setRefreshing(false);
    };

    // Go to today function
    const goToToday = () => {
        setSelectedDate(new Date());
    };

    // Filter bookings for the selected date
    const getDailyBookings = () => {
        if (!bookings || bookings.length === 0) return [];

        return bookings.filter(booking => {
            const bookingDate = new Date(booking.dateFrom);
            return (
                bookingDate.getDate() === selectedDate.getDate() &&
                bookingDate.getMonth() === selectedDate.getMonth() &&
                bookingDate.getFullYear() === selectedDate.getFullYear()
            );
        });
    };

    // Get the filtered bookings based on the selected date
    const dailyBookings = getDailyBookings();

    // Render view toggle buttons
    const renderViewToggle = () => (
        <View style={styles.viewToggleContainer}>
            <TouchableOpacity
                style={[
                    styles.toggleButton,
                    viewMode === "daily" ? styles.activeToggle : {}
                ]}
                onPress={() => setViewMode("daily")}
            >
                <IonIcons name="list" size={20} color={viewMode === "daily" ? "#fff" : "#333"} />
                <Text style={[styles.toggleText, viewMode === "daily" ? styles.activeToggleText : {}]}>Napi nézet</Text>
            </TouchableOpacity>

            <TouchableOpacity
                style={[
                    styles.toggleButton,
                    viewMode === "monthly" ? styles.activeToggle : {}
                ]}
                onPress={() => setViewMode("monthly")}
            >
                <IonIcons name="calendar" size={20} color={viewMode === "monthly" ? "#fff" : "#333"} />
                <Text style={[styles.toggleText, viewMode === "monthly" ? styles.activeToggleText : {}]}>Havi nézet</Text>
            </TouchableOpacity>

            <TouchableOpacity
                style={styles.todayButton}
                onPress={goToToday}
            >
                <IonIcons name="today-outline" size={20} color="#fff" />
                <Text style={styles.todayButtonText}>Ma</Text>
            </TouchableOpacity>
        </View>
    );

    // Render selected date header for daily view
    const renderDateHeader = () => {
        if (viewMode !== "daily") return null;

        const options = { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' };
        const formattedDate = selectedDate.toLocaleDateString('hu-HU', options);

        return (
            <View style={styles.dateHeaderContainer}>
                <TouchableOpacity onPress={() => {
                    const prevDay = new Date(selectedDate);
                    prevDay.setDate(prevDay.getDate() - 1);
                    setSelectedDate(prevDay);
                }}>
                    <IonIcons name="chevron-back" size={24} color="#333" />
                </TouchableOpacity>

                <Text style={styles.dateHeaderText}>{formattedDate}</Text>

                <TouchableOpacity onPress={() => {
                    const nextDay = new Date(selectedDate);
                    nextDay.setDate(nextDay.getDate() + 1);
                    setSelectedDate(nextDay);
                }}>
                    <IonIcons name="chevron-forward" size={24} color="#333" />
                </TouchableOpacity>
            </View>
        );
    };

    // Show empty state if there are no bookings at all
    if (bookings.length === 0) {
        return (
            <LinearGradient
                colors={['#f2e2d2', '#e6d6c1', '#dcd5ce']}
                style={styles.animationBackground}
            >
                <View style={[styles.animationContainer, { justifyContent: 'center' }]}>
                    <LottieView source={require('../../assets/animations/no-data-here.json')} autoPlay loop style={styles.animation} />
                    <Text style={styles.warningText}>Jelenleg nincs elérhető asztalfoglalás.</Text>
                </View>
            </LinearGradient>
        );
    }

    return (
        <LinearGradient
            colors={['#f2e2d2', '#e6d6c1', '#dcd5ce']}
            style={[styles.background, { width: "100%" }]}
        >
            <View style={[styles.container, { width: "100%" }]}>
                {renderViewToggle()}

                {viewMode === "daily" ? (
                    <>
                        {renderDateHeader()}
                        <DailyBookingsList
                            bookings={dailyBookings}
                            navigation={navigation}
                            refreshing={refreshing}
                            onRefresh={onRefresh}
                        />
                    </>
                ) : (
                    <MonthlyCalendarView
                        bookings={bookings}
                        selectedDate={selectedDate}
                        onDateSelect={setSelectedDate}
                        onViewChange={() => setViewMode("daily")}
                        refreshing={refreshing}
                        onRefresh={onRefresh}
                    />
                )}
            </View>
        </LinearGradient>
    );
}

const styles = StyleSheet.create({
    animation: {
        width: 150,
        height: 150,
        alignSelf: 'center',
    },
    warningText: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        color: 'black',
    },
    viewToggleContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginVertical: 10,
        width: '90%',
    },
    toggleButton: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 8,
        paddingHorizontal: 16,
        borderRadius: 20,
        marginHorizontal: 5,
        backgroundColor: '#f5f5f5',
        width: 200,
    },
    toggleText: {
        marginLeft: 5,
        fontWeight: '600',
        color: '#333',
    },
    activeToggle: {
        backgroundColor: '#795548',
    },
    activeToggleText: {
        color: '#fff',
    },
    todayButton: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 8,
        paddingHorizontal: 16,
        borderRadius: 20,
        marginHorizontal: 5,
        backgroundColor: '#F1b34b',
        width: 80,
    },
    todayButtonText: {
        marginLeft: 5,
        fontWeight: '600',
        color: '#fff',
    },
    dateHeaderContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '90%',
        marginBottom: 10,
        paddingHorizontal: 10,
    },
    dateHeaderText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#333',
    },
    animationContainer: {
        flex: 1,
        width: '50%',
        height: '50%',
        paddingTop: 16,
        marginBottom: 150,
        alignSelf: 'center',
        alignItems: 'center'
    },
    container: {
        display: "flex",
        flex: 1,
        flexGrow: 1,
        width: '100%',
        height: '100%',
        paddingTop: 16,
        marginBottom: 30,
        alignSelf: 'center',
        alignItems: 'center'
    },
    animationBackground: {
        flex: 1,
        width: '100%'
    },
    background: {
        flex: 1,
        width: '100%'
    },
});